import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, RadioGroup, Tooltip, FormControlLabel, FormGroup, Checkbox, Radio, Grid } from '@mui/material';
import FormField from 'components/FormField'; // Adjust path as per your file structure
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const OptionButton = ({
  label,
  type,
  name,
  options = [],
  additionalInfoLabel,
  additionalInfoName,
  onChange,
  value,
  additionalInfoValue,
  setFieldValue,
  error,
  toolTipText,
  triggerValues = [],
  row = true,
  ...rest
}) => {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  useEffect(() => {
    if (type === 'radio' && triggerValues.includes(parseInt(value))) {
      setShowAdditionalInfo(true);
    } else if (type === 'checkbox' && Array.isArray(value) && value.some(v => triggerValues.includes(parseInt(v)))) {
      setShowAdditionalInfo(true);
    } else {
      setShowAdditionalInfo(false);
    }
  }, [value, type, triggerValues]);
  

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (type === 'radio') {
      onChange(name, newValue);
    } else if (type === 'checkbox') {
      const newValues = Array.isArray(value)
        ? value.includes(newValue)
          ? value.filter((v) => v !== newValue)
          : [...value, newValue]
        : [newValue];
      onChange(name, newValues);
    }
  };

  return (
    <Grid item xs={12}>
      <Box p={2} bgcolor={'grey.50'} borderRadius={1}>
        <FormControl>
          <Box mb={1} lineHeight={1} display="flex" alignItems="center">
            <Typography component="label" variant="body1" fontWeight="500" mr={1}>
              {label}
            </Typography>
            {toolTipText && (
              <Tooltip
                title={toolTipText}
                arrow
                placement="bottom-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#363740',
                      color: 'white',
                    },
                  },
                  arrow: {
                    sx: {
                      color: '#363740',
                    },
                  },
                }}
                style={{ cursor: "pointer" }}
              >
                <InfoOutlinedIcon fontSize="small" sx={{ color: "grey.500" }} />
              </Tooltip>
            )}
          </Box>
          {type === 'radio' && (
            <RadioGroup row={row} name={name} value={value} onChange={handleChange}>
              {options.map(option => (
                <FormControlLabel
                  key={option.id}
                  value={option.id.toString()}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          )}
          {type === 'checkbox' && (
            <FormGroup row={row}>
              {options.map(option => (
                <FormControlLabel
                  key={option.id}
                  control={
                    <Checkbox
                      checked={Array.isArray(value) && value.includes(option.id.toString())}
                      onChange={handleChange}
                    />
                  }
                  label={option.label}
                  value={option.id.toString()}
                />
              ))}
            </FormGroup>
          )}
        </FormControl>
        <Box mt={2} />
        {showAdditionalInfo && (
          <FormField
            type={rest.type}
            label={additionalInfoLabel}
            name={additionalInfoName}
            size="small"
            multiline
            rows={2}
            isRequired={false}
            value={additionalInfoValue}
            onChange={(e) => setFieldValue(additionalInfoName, e.target.value)}
            placeholder={rest.placeholder}
            error={error}
          />
        )}
      </Box>
    </Grid>
  );
};

export default OptionButton;
