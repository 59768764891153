import React from 'react';
import { Document, Page, Text, StyleSheet, Font } from '@react-pdf/renderer';
import ReportTitle from './components/ReportTitle';
import InfoBox from './components/InfoBox';
import PurposeOfEval from './components/PurposeOfEval';
import SignatureBox from './components/SignatureBox';
import commonStyles from './commonStyles';
import BackgroundInfo from './components/BackgroundInfo';

const PDFPreview = ({ values }) => {
  return (
    <Document>
      <Page size="A4" style={commonStyles.page} wrap>
        <ReportTitle title={values?.generalInformation?.school} subTitle="SPEECH AND LANGUAGE EVALUATION REPORT" />
        <InfoBox data={values?.generalInformation} />
        <PurposeOfEval data={values.purposeOfEvaluation} />
        <BackgroundInfo data={values.backgroundInformation} />
         <SignatureBox data={values?.signature} />
      </Page>
    </Document>
  );
};

export default PDFPreview;
