import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import FormField from "components/FormField";
import contentTemplates from './content.json';
import moment from "moment";

const PurposeOfEvaluation = (props) => {
    const { formField, values, setFieldValue } = props.formData;
    const {
        evaluationType,
        eval_type_other,
        //referal type
        eval_type_referredBy,
        eval_referred_title,
        eval_referred_name,
        eval_referred_hospital_name,
        eval_type_referral_other,
        eval_type_dateOfReferal,
        eval_type_doc_content,
        eval_type_doc_referral_content,
    } = formField.purposeOfEvaluation;

    const genderPronoun = (gender) => {
        switch (gender?.toLowerCase()) {
            case 'male': return 'his';
            case 'female': return 'her';
            default: return 'their';
        }
    };

    const replacePlaceholders = (template) => {
        if (!template) return null;

        const gender = values?.generalInformation?.gender?.label?.toLowerCase();
        let pronounSubject = 'they';
        let pronounPossessive = 'their';

        if (gender === 'male') {
            pronounSubject = 'he';
            pronounPossessive = 'his';
        } else if (gender === 'female') {
            pronounSubject = 'she';
            pronounPossessive = 'her';
        } else if (gender === 'non binary') {
            pronounSubject = 'they';
            pronounPossessive = 'their';
        } else {
            pronounSubject = "<b style=color:red>NO GENDER SELECTED</b>";
            pronounPossessive = "<b style=color:red>NO GENDER SELECTED</b>";
        }

        return template
            .replace(/{pronoun_subject}/g, pronounSubject ? pronounSubject : "<b style='color:red'>SELECT GENDER</b>")
            .replace(/{pronoun_possessive}/g, pronounPossessive ? pronounPossessive : "<b style='color:red'>SELECT GENDER</b>")
            .replace(/{genderPronouns}/g, genderPronoun(values?.generalInformation?.gender?.label) ? genderPronoun(values?.generalInformation?.gender?.label) : "<b style='color:red'>SELECT GENDER</b>")
            .replace(/{firstName}/g, values?.generalInformation?.firstName ? values.generalInformation.firstName : "<b style='color:red'>STUDENT NAME</b>")
            .replace(/{other_eval_type}/g, values?.purposeOfEvaluation?.eval_type_other ? values.purposeOfEvaluation.eval_type_other : "<b style='color:red'>ADDITIONAL INFO</b>")
            .replace(/{dateOfReferral}/g, values?.purposeOfEvaluation?.eval_type_dateOfReferal ? moment(values.purposeOfEvaluation.eval_type_dateOfReferal).format("LL") : "<b style='color:red'>REFERRAL DATE</b>")
            .replace(/{teacherName}/g, values?.purposeOfEvaluation?.eval_referred_name ? values.purposeOfEvaluation.eval_referred_name : "<b style='color:red'>NO TEACHER NAME FOUND</b>")
            .replace(/{doctorName}/g, values?.purposeOfEvaluation?.eval_referred_name ? values.purposeOfEvaluation.eval_referred_name : "<b style='color:red'>NO DOCTOR NAME</b>")
            .replace(/{guardianName}/g, values?.purposeOfEvaluation?.eval_referred_name ? values.purposeOfEvaluation.eval_referred_name : "<b style='color:red'>NO Caregiver/Guardian FOUND</b>")
            .replace(/{title}/g, values?.purposeOfEvaluation?.eval_referred_title?.label ? values.purposeOfEvaluation.eval_referred_title.label : "<b style='color:red'>NO TITLE FOUND</b>")
            .replace(/{parentName}/g, values?.purposeOfEvaluation?.eval_referred_name ? values.purposeOfEvaluation.eval_referred_name : "<b style='color:red'>NO PARENT NAME FOUND</b>")
            .replace(/{other_referral_type}/g, values?.purposeOfEvaluation?.eval_type_other ? values.purposeOfEvaluation.eval_type_other : "<b style='color:red'>NO OTHER CONTENT FOUND</b>")
            .replace(/{eval_type_referral_other}/g, values?.purposeOfEvaluation?.eval_type_referral_other ? values.purposeOfEvaluation.eval_type_referral_other : "<b style='color:red'>NO CONTENT FOUND</b>")
            .replace(/{hospitalName}/g, values?.purposeOfEvaluation?.eval_referred_hospital_name ? values.purposeOfEvaluation.eval_referred_hospital_name : "<b style='color:red'>HOSPITAL NAME</b>");


    };

    const handleEvalType = (option) => {


        if (!option) {
            setFieldValue(evaluationType.path, null);
            setFieldValue(eval_type_doc_content.path, null);
            setFieldValue(eval_type_referredBy.path, null)

            return;
        }
        setFieldValue(evaluationType.path, option);
        setFieldValue(eval_type_referredBy.path, null)

    };

    useEffect(() => {
        const { id } = values.purposeOfEvaluation?.evaluationType || {};
        let selectedTemplate = null;
        switch (id) {
            case 1:
                selectedTemplate = contentTemplates.initial_evaluation;
                break;
            case 2:
                selectedTemplate = contentTemplates.re_evaluation;
                break;
            case 3:
                selectedTemplate = contentTemplates.supplemental_evaluation;
                break;
            case 4:
                selectedTemplate = contentTemplates.comprehensive_evaluation;
                break;
            case 5:
                selectedTemplate = contentTemplates.other;
                break;
            default:
                selectedTemplate = null;
        }

        if (selectedTemplate) {
            const contentWithValues = replacePlaceholders(selectedTemplate);
            setFieldValue(eval_type_doc_content.path, contentWithValues);
        } else {
            setFieldValue(eval_type_doc_content.path, null);
        }
    }, [values.generalInformation, values.purposeOfEvaluation]);

    useEffect(() => {
        const { id } = values.purposeOfEvaluation?.eval_type_referredBy || {};

        let selectedReferral = null;
        switch (id) {
            case 1:
                selectedReferral = contentTemplates.referral_content.teacher;
                break;
            case 2:
                selectedReferral = contentTemplates.referral_content.parent;
                break;
            case 3:
                selectedReferral = contentTemplates.referral_content.guardian;
                break;
            case 4:
                selectedReferral = contentTemplates.referral_content.doctor;
                break;
            case 5:
                selectedReferral = contentTemplates.referral_content.sst;
                break;
            case 6:
                selectedReferral = contentTemplates.referral_content.other;
                break;
            default:
                selectedReferral = null;
        }

        const referral_content = selectedReferral
            ? replacePlaceholders(selectedReferral)
            : null;
        setFieldValue(eval_type_doc_referral_content.path, referral_content);
    }, [values.purposeOfEvaluation]);




    const handleReferral = (option) => {
        console.log(option)
        setFieldValue(eval_referred_title.path, '')
        setFieldValue(eval_referred_name.path, '')
        setFieldValue(eval_referred_hospital_name.path, '')
        setFieldValue(eval_type_referredBy.path, option)
        setFieldValue(eval_type_dateOfReferal.path, '');


        if (!option) {
            setFieldValue(eval_type_referredBy.path, null);
            setFieldValue(eval_type_doc_referral_content.path, null);
            return;
        }
    };

    function ReferralName(option) {
        let selectedReferral;
        switch (option?.id) {
            case 1:
                selectedReferral = 'Teacher Name'
                break;
            case 2:
                selectedReferral = 'Parent Name'
                break;
            case 3:
                selectedReferral = 'Caregiver/Guardian Name';
                break;
            case 4:
                selectedReferral = 'Doctor Name';
                break;
            case 5:
                selectedReferral = 'Student Success Team Name';
                break;
            default:
                selectedReferral = 'Name';
        }
        return selectedReferral;
    }

    return (
        <Grid container spacing={2} p={0}>
            <Grid item xs={6}>
                <FormField
                    isClearable
                    menuPlacement="auto"
                    inputType="select"
                    label={evaluationType.label}
                    isRequired={false}
                    name={evaluationType.name}
                    size="small"
                    options={props.options?.EvaluationType}
                    onChange={handleEvalType}
                    value={values.purposeOfEvaluation.evaluationType}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    placeholder={evaluationType.placeholder}
                />
            </Grid>
            {values.purposeOfEvaluation?.evaluationType?.id === 5 &&
                <Grid item xs={6}>
                    <FormField
                        type={eval_type_other.type}
                        label={eval_type_other.label}
                        name={eval_type_other.name}
                        size="small"
                        onChange={(e) => setFieldValue(eval_type_other.path, e.target.value)}
                        isRequired={false}
                        value={values.purposeOfEvaluation.eval_type_other}
                        placeholder={eval_type_other.placeholder}
                    />
                </Grid>
            }

            {values.purposeOfEvaluation?.evaluationType?.id !== 2 && values.purposeOfEvaluation?.evaluationType?.id !== 5 &&
                <Grid item xs={6}>
                    <FormField
                        isClearable
                        menuPlacement="auto"
                        inputType="select"
                        label={eval_type_referredBy.label}
                        isRequired={false}
                        name={eval_type_referredBy.name}
                        size="small"
                        options={props.options?.ReferredBy}
                        onChange={handleReferral}
                        value={values.purposeOfEvaluation.eval_type_referredBy}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        placeholder={eval_type_referredBy.placeholder}
                    />
                </Grid>
            }

            {values.purposeOfEvaluation?.eval_type_referredBy?.id !== 6 && values.purposeOfEvaluation?.eval_type_referredBy?.id && (
                <>
                    <Grid item xs={4}>
                        <FormField
                            inputType="select"
                            label={eval_referred_title.label}
                            isRequired={false}
                            name={eval_referred_title.name}
                            size="small"
                            options={props.options?.Title}
                            onChange={(option) => setFieldValue(eval_referred_title.path, option)}
                            value={values.purposeOfEvaluation.eval_referred_title}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.id}
                            placeholder={eval_referred_title.placeholder}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormField
                            type={eval_referred_name.type}
                            label={ReferralName(values?.purposeOfEvaluation?.eval_type_referredBy)}
                            name={eval_referred_name.name}
                            size="small"
                            onChange={(e) => setFieldValue(eval_referred_name.path, e.target.value)}
                            isRequired={false}
                            value={values.purposeOfEvaluation.eval_referred_name}
                            placeholder={eval_referred_name.placeholder}
                        />
                    </Grid>
                </>
            )}

            {values.purposeOfEvaluation?.eval_type_referredBy?.id &&


                <Grid item xs={values?.purposeOfEvaluation.eval_type_referredBy?.id === 6 ? 6 : 4}>
                    <FormField
                        type={eval_type_dateOfReferal.type}
                        label={eval_type_dateOfReferal.label}
                        name={eval_type_dateOfReferal.name}
                        size="small"
                        onChange={(e) => {
                            const date = moment(e.target.value).startOf('day').utc().toISOString();
                            setFieldValue(eval_type_dateOfReferal.path, date);
                        }}
                        isRequired={false}
                        value={moment(values.purposeOfEvaluation.eval_type_dateOfReferal).format('YYYY-MM-DD')}
                        placeholder={eval_type_dateOfReferal.placeholder}
                    />
                </Grid>

            }
            {values.purposeOfEvaluation?.eval_type_referredBy?.id === 4 &&

                <Grid item xs={12}>
                    <FormField
                        type={eval_referred_hospital_name.type}
                        label={eval_referred_hospital_name.label}
                        name={eval_referred_hospital_name.name}
                        size="small"
                        onChange={(e) => setFieldValue(eval_referred_hospital_name.path, e.target.value)}
                        isRequired={false}
                        value={values.purposeOfEvaluation.eval_referred_hospital_name}
                        placeholder={eval_referred_hospital_name.placeholder}
                    />
                </Grid>
            }



            {values.purposeOfEvaluation?.eval_type_referredBy?.id === 6 &&
                <Grid item xs={6}>
                    <FormField
                        type={eval_type_referral_other.type}
                        label={eval_type_referral_other.label}
                        name={eval_type_referral_other.name}
                        size="small"
                        onChange={(e) => setFieldValue(eval_type_referral_other.path, e.target.value)}
                        isRequired={false}
                        value={values.purposeOfEvaluation.eval_type_referral_other}
                        placeholder={eval_type_referral_other.placeholder}
                    />
                </Grid>

            }

            <Grid item xs={12}>
                <Typography variant="h5" mb={2}>
                    Purpose of Evaluation - Review
                </Typography>
                <Typography variant="subtitle1">
                    <div dangerouslySetInnerHTML={{ __html: values?.purposeOfEvaluation?.eval_type_doc_content }} />
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h5" mb={2}>
                    Referral - Review
                </Typography>
                <Typography variant="subtitle1">
                    <div dangerouslySetInnerHTML={{ __html: values?.purposeOfEvaluation?.eval_type_doc_referral_content }} />
                </Typography>
            </Grid>
        </Grid>
    );
};

export default PurposeOfEvaluation;
