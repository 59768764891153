import React from "react";
import { Grid } from "@mui/material";
import FormField from "components/FormField";
import OptionButton from "../components/OptionButton";
const Voice = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        voice_obs_method,
        voice_obs_pitch,
        voice_obs_loudness,
        voice_obs_quality,
        voice_obs_resonance,
        voice_obs_overall_voice,
        voice_doc_description
    } = formField.voiceObservation;

    // Render the rich text for background information
    return (
        <Grid container spacing={2} p={2}>
            <Grid item xs={6}>
                <FormField
                    type={voice_obs_method.type}
                    label={voice_obs_method.label}
                    name={voice_obs_method.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(voice_obs_method.path, value)}
                    value={values.voiceObservation.voice_obs_method}
                    placeholder={voice_obs_method.placeholder}
                />
            </Grid>

            <OptionButton
                label={voice_obs_pitch.label}
                type="radio"
                row={false}

                name={voice_obs_pitch.name}
                options={props.options?.voice_obs_pitch}
                value={values.voiceObservation.voice_obs_pitch}
                onChange={(name, value) => setFieldValue(voice_obs_pitch.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={voice_obs_pitch.toolTipText}
                error={errors.voiceObservation?.voice_obs_pitch && touched.voiceObservation?.voice_obs_pitch}
            />
            <OptionButton
                label={voice_obs_loudness.label}
                type="radio"
                row={false}

                name={voice_obs_loudness.name}
                options={props.options?.voice_obs_loudness}
                value={values.voiceObservation.voice_obs_loudness}
                onChange={(name, value) => setFieldValue(voice_obs_loudness.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={voice_obs_loudness.toolTipText}
                error={errors.voiceObservation?.voice_obs_loudness && touched.voiceObservation?.voice_obs_loudness}
            />
            <OptionButton
                label={voice_obs_quality.label}
                type="radio"
                row={false}

                name={voice_obs_quality.name}
                options={props.options?.voice_obs_quality}
                value={values.voiceObservation.voice_obs_quality}
                onChange={(name, value) => setFieldValue(voice_obs_quality.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={voice_obs_quality.toolTipText}
                error={errors.voiceObservation?.voice_obs_quality && touched.voiceObservation?.voice_obs_quality}
            />
            <OptionButton
                label={voice_obs_resonance.label}
                type="radio"
                row={false}

                name={voice_obs_resonance.name}
                options={props.options?.voice_obs_resonance}
                value={values.voiceObservation.voice_obs_resonance}
                onChange={(name, value) => setFieldValue(voice_obs_resonance.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={voice_obs_resonance.toolTipText}
                error={errors.voiceObservation?.voice_obs_resonance && touched.voiceObservation?.voice_obs_resonance}
            />
            <OptionButton
                label={voice_obs_overall_voice.label}
                type="radio"
                row={false}

                name={voice_obs_overall_voice.name}
                options={props.options?.voice_obs_overall_voice}
                value={values.voiceObservation.voice_obs_overall_voice}
                onChange={(name, value) => setFieldValue(voice_obs_overall_voice.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={voice_obs_overall_voice.toolTipText}
                error={errors.voiceObservation?.voice_obs_overall_voice && touched.voiceObservation?.voice_obs_overall_voice}
            />
        </Grid>
    );
};

export default Voice;
