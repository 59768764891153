import React from "react";
import { Grid } from "@mui/material";
import OptionButton from "../components/OptionButton";
import HeaderComp from "components/Common/HeaderComp";
const EvaluationObservQuestionnaire = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;
    // REMOVE OTHERS
    const {
        eval_obv_Q_01,
        eval_obv_Q_02,
        eval_obv_Q_03,
        eval_obv_Q_04,
        eval_obv_Q_04_other,
        eval_obv_Q_05,
        eval_obv_Q_06,
        eval_obv_Q_07,
        eval_obv_Q_08,
        eval_obv_Q_09,
        eval_doc_description
    } = formField.evaluationObservQuestionnaire;

    return (
        <Grid container spacing={2} p={2}>
            <HeaderComp title="Non-Verbal Communication" />

            <OptionButton
                label={eval_obv_Q_01.label}
                type="radio"
                name={eval_obv_Q_01.name}
                options={props.options?.eval_obv_Q_01}
                value={values.evaluationObservQuestionnaire.eval_student_engament}
                onChange={(name, value) => setFieldValue(eval_obv_Q_01.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eval_obv_Q_01.toolTipText}
                error={errors.evaluationObservQuestionnaire?.eval_obv_Q_01 && touched.evaluationObservQuestionnaire?.eval_obv_Q_01}
            />
            <OptionButton
                label={eval_obv_Q_02.label}
                type="radio"
                name={eval_obv_Q_02.name}
                options={props.options?.eval_obv_Q_02}
                value={values.evaluationObservQuestionnaire.eval_obv_Q_02}
                onChange={(name, value) => setFieldValue(eval_obv_Q_02.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eval_obv_Q_02.toolTipText}
                error={errors.evaluationObservQuestionnaire?.eval_obv_Q_02 && touched.evaluationObservQuestionnaire?.eval_obv_Q_02}
            />
            <OptionButton
                label={eval_obv_Q_05.label}
                type="radio"
                name={eval_obv_Q_05.name}
                row={false}
                options={props.options?.eval_obv_Q_05}
                value={values.evaluationObservQuestionnaire.eval_obv_Q_05}
                onChange={(name, value) => setFieldValue(eval_obv_Q_05.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eval_obv_Q_05.toolTipText}
                error={errors.evaluationObservQuestionnaire?.eval_obv_Q_05 && touched.evaluationObservQuestionnaire?.eval_obv_Q_05}
            />

            <OptionButton
                label={eval_obv_Q_04.label}
                type="radio"
                name={eval_obv_Q_04.name}
                options={props.options?.eval_obv_Q_04}
                value={values.evaluationObservQuestionnaire.eval_obv_Q_04}
                onChange={(name, value) => setFieldValue(eval_obv_Q_04.path, value)}
                additionalInfoLabel={eval_obv_Q_04_other.label}
                additionalInfoName={eval_obv_Q_04_other.path}
                additionalInfoValue={values.evaluationObservQuestionnaire.eval_obv_Q_04_other}
                triggerValues={[5]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={eval_obv_Q_04.toolTipText}
                error={errors.evaluationObservQuestionnaire?.eval_obv_Q_04 && touched.evaluationObservQuestionnaire?.eval_obv_Q_04}
            />
            <HeaderComp title="Verbal Communication" />


            <OptionButton
                label={eval_obv_Q_03.label}
                type="radio"
                name={eval_obv_Q_03.name}
                options={props.options?.eval_obv_Q_03}
                value={values.evaluationObservQuestionnaire.eval_obv_Q_03}
                onChange={(name, value) => setFieldValue(eval_obv_Q_03.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eval_obv_Q_03.toolTipText}
                error={errors.evaluationObservQuestionnaire?.eval_obv_Q_03 && touched.evaluationObservQuestionnaire?.eval_obv_Q_03}
            />
            {/* <OptionButton
                label={eval_obv_Q_06.label}
                type="radio"
                name={eval_obv_Q_06.name}
                options={props.options?.eval_obv_Q_06}
                value={values.evaluationObservQuestionnaire.eval_obv_Q_06}
                onChange={(name, value) => setFieldValue(eval_obv_Q_06.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eval_obv_Q_06.toolTipText}
                error={errors.evaluationObservQuestionnaire?.eval_obv_Q_06 && touched.evaluationObservQuestionnaire?.eval_obv_Q_06}
            /> */}
            <OptionButton
                label={eval_obv_Q_07.label}
                type="radio"
                name={eval_obv_Q_07.name}
                options={props.options?.eval_obv_Q_07}
                value={values.evaluationObservQuestionnaire.eval_obv_Q_07}
                onChange={(name, value) => setFieldValue(eval_obv_Q_07.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eval_obv_Q_07.toolTipText}
                error={errors.evaluationObservQuestionnaire?.eval_obv_Q_07 && touched.evaluationObservQuestionnaire?.eval_obv_Q_07}
            />
            <OptionButton
                label={eval_obv_Q_08.label}
                type="radio"
                name={eval_obv_Q_08.name}
                options={props.options?.eval_obv_Q_08}
                value={values.evaluationObservQuestionnaire.eval_obv_Q_08}
                onChange={(name, value) => setFieldValue(eval_obv_Q_08.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eval_obv_Q_08.toolTipText}
                error={errors.evaluationObservQuestionnaire?.eval_obv_Q_08 && touched.evaluationObservQuestionnaire?.eval_obv_Q_08}
            />
            <OptionButton
                label={eval_obv_Q_09.label}
                type="radio"
                name={eval_obv_Q_09.name}
                options={props.options?.eval_obv_Q_09}
                value={values.evaluationObservQuestionnaire.eval_obv_Q_09}
                onChange={(name, value) => setFieldValue(eval_obv_Q_09.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eval_obv_Q_09.toolTipText}
                error={errors.evaluationObservQuestionnaire?.eval_obv_Q_09 && touched.evaluationObservQuestionnaire?.eval_obv_Q_09}
            />
        </Grid>
    );
};

export default EvaluationObservQuestionnaire;
