import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#171d26",
      dark: "#006db3",
    },
    secondary: {
      main: "#1b3a57",
    },
    button: {
      light: "#171d26",
      main: "#5163f3",
      dark: "#006db3",
    },
  },
  typography: {
    fontFamily: "Google Sans, sans-serif",
    h1: {
      fontSize: "2.125rem", // was 1.2146rem, increased for better hierarchy
      color: "#171d26",
      fontWeight: 500,
      lineHeight: 1.235,
    },
    h2: {
      fontSize: "1.75rem", // was 1.5rem, adjusted for clearer hierarchy
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "1.5rem", // adjusted for differentiation
      color: "#171d26",
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: "1.25rem", // adjusted from 1.2146rem for consistency
      color: "#171d26",
      letterSpacing: "-0.02em",
      fontWeight: 500,
    },
    h5: {
      margin: 0,
      fontSize: "1.125rem", // adjusted from 1.2144rem for differentiation
      color: "#171d26",
      letterSpacing: "-0.02em",
      fontWeight: 600,
      lineHeight: "1.334",
    },
    h6: {
      fontSize: "1rem", // kept as is, example size
      color: "#171d26",
      fontWeight: 500,
      lineHeight: 1.2,
    },

    caption: {
      fontSize: "0.75rem", // standard size for captions
      color: "#171d26",
      lineHeight: 1.66,
    },
    overline: {
      fontSize: "0.75rem", // standard size for overlines
      color: "#171d26",
      letterSpacing: "0.1em",
      lineHeight: 2.66,
    },
    subtitle1: {
      fontSize: "1rem", // Comfortable reading size for secondary titles/info
      fontWeight: 400, // Regular weight to differentiate from headings
      lineHeight: 1.75, // Increased line height for readability
    },
    subtitle2: {
      fontSize: "0.875rem", // Slightly smaller for less prominence
      fontWeight: 500, // Medium weight for slight emphasis
      lineHeight: 1.57, // Optimized for secondary subtitles
    },
    body1: {
      fontSize: "1rem", // Standard body text size, ensures readability
      fontWeight: 400, // Regular weight for body text
      lineHeight: 1.5, // Standard line height for body text
    },
    body2: {
      fontSize: "0.875rem", // Slightly smaller, for less critical information
      fontWeight: 400, // Keeping it regular for consistency with body1
      lineHeight: 1.43, // Slightly tighter line height for compactness
    },

    // h5: {
    //   fontWeight: 500,
    //   fontSize: 26,
    //   letterSpacing: 0.5,
    // },
    //   h2: {
    //     fontSize: `calc(14px + 2.0vw)` // Responsive font size based on viewport width
    // },
    // body1: {
    //   fontSize: `calc(12px + 0.7vw)` // Responsive font size based on viewport width

    // }
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

// theme.typography.h1 = {
//   fontSize: "1.2146rem",
//   color: "#171d26",
//   // letterSpacing: '-0.02em',
//   fontWeight: 500,
//   lineHeight: 1.235,
// };

// theme.typography.h4 = {
//   fontSize: "1.2146rem",
//   color: "#171d26",
//   letterSpacing: "-0.02em",
//   fontWeight: 500,
// };

// theme.typography.h5 = {
//   margin: 0,
//   fontSize: "1.2144rem",
//   color: "#171d26",
//   letterSpacing: "-0.02em",
//   fontWeight: 600,
//   lineHeight: "1.334",
// };

// theme.typography.h5 = {
//   ...theme.typography.h5,
//   [theme.breakpoints.down("sm")]: {
//     fontSize: 16, // Adjust the font size for mobile view
//   },
// };

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#171d26",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#F7F8FC",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "4px",
        },
        contained: {
          boxShadow:
            "0px 2px 8px 0px rgba(0, 0, 0, .1), 0px 1px 0px 0px rgba(255, 255, 255, .3) inset",
          "&:active": {
            boxShadow: "none",
          },
          backgroundColor: theme.palette.button.main,

          "&:hover": {
            backgroundColor: theme.palette.button.main,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          backgroundColor: "#5163f3", // Set active indicator color
          borderTopRightRadius: 3,
          // backgroundColor: theme.palette.common.white,
        },
      },
    },
    // MuiTabs: {
    //   styleOverrides: {
    //     indicator: {
    //       backgroundColor: "#5163f3", // Set active indicator color
    //       height: "2px", // Set the height of the tab indicator
    //     },
    //   },
    // },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1.06em",
          lineHeight: 1.25,
          fontWeight: 500, // Default font weight
          "&.Mui-selected": {
            fontWeight: 600, // Font weight for the active tab
            color: "#5163f3 !important",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#f3f3f3", // Customize the boxShadow here
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(251, 252, 254, 0.1)",
            color: "#fff",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
          "&.Mui-selected": {
            backgroundColor: "rgba(251, 252, 254, 0.1)",
            color: "#fff",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

export default theme;
