const generalInformationForm = {
    generalInformation: {
        firstName: {
            name: "firstName",
            label: "First Name",
            type: "text",
            path: "generalInformation.firstName",
            placeholder: "First Name",
            errorMsg: "First Name is required.",
            invalidMsg: "",
        },
        middleName: {
            name: "middleName",
            label: "Middle Name",
            type: "text",
            path: "generalInformation.middleName",
            placeholder: "Middle Name",
            errorMsg: "Middle Name is required.",
            invalidMsg: "Invalid Name",
        },
        lastName: {
            name: "lastName",
            label: "Last Name",
            type: "text",
            path: "generalInformation.lastName",
            placeholder: "Last Name",
            errorMsg: "Last Name is required.",
            invalidMsg: "",
        },
        gender: {
            name: "gender",
            label: "Gender",
            type: "text",
            path: "generalInformation.gender",
            placeholder: "Select ...",
            errorMsg: "Gender is required.",
            invalidMsg: "Gender is required.",
        },
        caseManager: {
            name: "caseManager",
            label: "Case Manager",
            type: "text",
            path: "generalInformation.caseManager",
            placeholder: "Please type case manager.",
            errorMsg: "Case Manager is required.",
            invalidMsg: "",
        },
        evaluatorName: {
            name: "evaluatorName",
            label: "Evaluator Name",
            type: "text",
            path: "generalInformation.evaluatorName",
            placeholder: "Please type evaluator name.",
            errorMsg: "Evaluator Name is required.",
            invalidMsg: "Invalid Name",
        },
        dateOfBirth: {
            name: "dateOfBirth",
            label: "Date of Birth",
            type: "date",
            path: "generalInformation.dateOfBirth",
            placeholder: "DOB",
            errorMsg: "DOB is required.",
            invalidMsg: "",
        },
        school: {
            name: "school",
            label: "School",
            type: "text",
            path: "generalInformation.school",
            placeholder: "Please type school name.",
            errorMsg: "School name is required.",
            invalidMsg: "",
        },
        schoolDistrict: {
            name: "schoolDistrict",
            label: "School District",
            type: "text",
            path: "generalInformation.schoolDistrict",
            placeholder: "Please type school district name.",
            errorMsg: "School district name is required.",
            invalidMsg: "",
        },
        primaryLanguage: {
            name: "primaryLanguage",
            type: "text",
            label: "Primary Language",
            path: "generalInformation.primaryLanguage",
            placeholder: "Please select primary language",
            errorMsg: "At least one primary language is required.",
            invalidMsg: "",
        },
        grade: {
            name: "grade",
            label: "Grade",
            type: "text",
            path: "generalInformation.grade",
            placeholder: "grade",
            errorMsg: "Grade is required.",
            invalidMsg: "",
        },
        testDates: {
            name: "testDates",
            label: "Test Dates",
            type: "date",
            path: "generalInformation.testDates",
            placeholder: "testDates",
            errorMsg: "Test Dates are required.",
            invalidMsg: "",
        },
        dateOfReport: {
            name: "dateOfReport",
            label: "Date of Report",
            type: "date",
            path: "generalInformation.dateOfReport",
            placeholder: "dateOfReport",
            errorMsg: "Date of Report is required.",
            invalidMsg: "",
        },
        reasonForTesting: {
            name: "reasonForTesting",
            label: "Reason for Testing",
            type: "text",
            path: "generalInformation.reasonForTesting",
            placeholder: "Select ...",
            errorMsg: "Reason for Testing is required.",
            invalidMsg: "",
        },
        currentProgram: {
            name: "currentProgram",
            label: "Current Program",
            type: "text",
            path: "generalInformation.currentProgram",
            placeholder: "Select ...",
            errorMsg: "Current Program is required.",
            invalidMsg: "",
        },
        primaryDisability: {
            name: "primaryDisability",
            label: "Primary Disability",
            type: "text",
            path: "generalInformation.primaryDisability",
            placeholder: "Select ...",
            errorMsg: "Primary disability is required.",
            invalidMsg: "",
        },
        secondaryDisability: {
            name: "secondaryDisability",
            label: "Secondary Disability",
            type: "text",
            path: "generalInformation.secondaryDisability",
            placeholder: "Select ...",
            errorMsg: "Secondary disability is required.",
            invalidMsg: "",
        },
        identificationDate: {
            name: "identificationDate",
            label: "Identification Date",
            type: "date",
            path: "generalInformation.identificationDate",
            placeholder: "Date",
            errorMsg: "Identification date is required.",
            toolTipText: "When was the student initially identified with the specified disability?",
            invalidMsg: "",
        },
        serviceMinutes: {
            name: "serviceMinutes",
            label: "Service Minutes",
            type: "number",
            path: "generalInformation.serviceMinutes",
            placeholder: "Total Minutes Per Week",
            errorMsg: "Service minutes is required.",
            toolTipText: "How many minutes per week does the student receive speech and language services?",
            invalidMsg: "",
        },
        serviceArea: {
            name: "serviceArea",
            label: "Service Area(s)",
            type: "text",
            path: "generalInformation.serviceArea",
            placeholder: "Service Area(s)",
            errorMsg: "Service area is required.",
            toolTipText: "What specific areas or needs are addressed during the speech and language services?",
            invalidMsg: "",
        },
    },
};

export default generalInformationForm;
