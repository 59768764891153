import React from "react";
import { useState } from "react";
import StudentTable from "./components/StudentTable";
import { grey } from "@mui/material/colors";
import {
  Box,
  Button,
  Stack,
  DialogActions,
  DialogContent,
  Typography,
  Container,
  useMediaQuery,
  IconButton
} from "@mui/material";
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import FeedbackButton from "components/Common/FeedbackButton";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import GeneralDialog from "components/GeneralDialog";
import { initialValues, form, validations } from "./AddStudent/schemas";
import { Formik, Form } from "formik";
import AddStudent from "./AddStudent";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import BoxButton from "components/BoxButton";
import { createStudent } from "redux/actions/studentActions";
import { useDispatch } from "react-redux";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import ActionHeader from "components/MainLayout/ActionHeader";
import { PlayCircleFilledOutlined } from "@mui/icons-material";
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import InviteAssistant from "pages/InviteAssistant";
import Collaboration from "./Collaboration";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const getSchoolYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const isCurrentYear = currentMonth >= 6; // August is month 7

  const startYear = isCurrentYear ? currentYear : currentYear - 1;
  const endYear = startYear + 1;

  return [
    `${startYear - 1}-${endYear - 1}`,
    `${startYear}-${endYear}`,
    `${startYear + 1}-${endYear + 1}`,
  ];
};

const SchoolYearSelector = ({ currentYear, onYearChange }) => {
  const schoolYearOptions = getSchoolYearOptions();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
    onYearChange(event.target.value);
  };

  return (
    <FormControl variant="outlined" size="small"
      sx={{
        height: '32px', // Adjust the height as needed
        width: '100%',
        '& .MuiOutlinedInput-root': {
          height: '32px', // Match the height of the FormControl
          fontSize: '12px', // Adjust font size for smaller input
        },
        '& .MuiOutlinedInput-input': {
          padding: '8px 14px', // Adjust padding for smaller input
          color: '#5163f3', // Change the value color
          fontWeight: 600
        },
        '& .MuiInputLabel-root': {
          fontSize: '12px', // Adjust font size for label
        },
        '& .MuiSelect-icon': {
          fontSize: '16px', // Adjust the size of the select dropdown icon
        }
      }}
    >
      <InputLabel sx={{ fontWeight: 500 }}>School Year</InputLabel>
      <Select
        value={selectedYear}
        onChange={handleChange}
        label="School Year"
      >
        {schoolYearOptions.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};



export default function Student() {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [schoolYear, setSchoolYear] = useState(getSchoolYearOptions()[1]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openCollab, setOpenCollab] = useState(false);

  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [uploadedFile, setUploadedFile] = useState(null);

  function newForm(formData) {
    return <AddStudent formData={formData} />;
  }

  function closeDialog() {
    setOpen(!open);
  }


  const handleOpenCollab = () => {
    setOpenCollab(true);
  };


  const submitForm = (values, actions) => {
    setLoading(true);

    let payload = {
      ...values,
      gender: values.gender.label,
      currentAcademicYear: {
        schoolYear: values.currentAcademicYear.id, // Use `id` if you need it for identification
        grade: values.grade.label, // or another value from form
        school: values.school, // or another value from form
      }
    };

    dispatch(createStudent(payload))
      .then(() => {
        setLoading(false);
        actions.setTouched({});
        actions.setSubmitting(false);
        actions.resetForm();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setUploadedFile(selectedFile);
    }
  };


  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <React.Fragment>
      <ActionHeader>
        {/* <Box>
          <InviteAssistant />
        </Box> */}
        <Box>
          <SchoolYearSelector currentYear={schoolYear} onYearChange={setSchoolYear} />
        </Box>
        <Box>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            {isSmallScreen ?
              <>
                <IconButton
                  variant="standard"
                  size="medium"
                  color="secondary"
                >
                  <PlayCircleFilledOutlined />
                </IconButton>



                <IconButton
                  variant="standard"
                  size="medium"
                  color="secondary"
                >
                  <ArchiveRoundedIcon />
                </IconButton>


                <IconButton
                  variant="standard"
                  size="medium"
                  color="secondary"
                  component="label"
                >
                  <input
                    type="file"
                    accept=".csv, .xlsx"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                  <FileUploadIcon />
                </IconButton>


                <IconButton
                  variant="standard"
                  size="medium"
                  color="secondary"
                  onClick={handleOpen}
                >

                  <AddIcon />
                </IconButton>

              </>
              :
              <>
                <FeedbackButton />

                <Button
                  component="label"
                  variant="standard"
                  size="medium"
                  sx={{
                    bgShadow: 0,
                    mr: 1,
                    color: '#5263f3',
                    borderColor: grey[200],
                    background: '#eeeffe',
                  }}
                  onClick={handleOpenCollab}
                  startIcon={<GroupWorkIcon />}
                >
                  SLPA Collaboration
                </Button>
                <Button
                  component="label"
                  variant="standard"
                  color="secondary"
                  sx={{
                    bgShadow: 0,
                    mr: 1,
                    color: '#5263f3',
                    borderColor: grey[200],
                    background: '#eeeffe',
                  }}
                  size="medium"
                  startIcon={<FileUploadIcon />}
                >
                  Import Caseload
                  <VisuallyHiddenInput type="file" accept=".csv, .xlsx" onChange={handleFileUpload} />
                </Button>
                <Button variant="contained" size="medium" onClick={handleOpen} startIcon={<AddIcon />}>
                  Add Student
                </Button>

              </>
            }

          </Stack>
        </Box>
      </ActionHeader>

      <GeneralDialog open={open} title="Add Student" onClose={closeDialog} maxWidth="md" fullWidth>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidation}
          onSubmit={submitForm}
        >
          {({ values, errors, touched, setFieldValue, isSubmitting, resetForm }) => (
            <Form id={formId} autoComplete="off">
              <DialogContent style={{ background: "white" }}>
                {newForm({
                  values,
                  touched,
                  formField,
                  setFieldValue,
                  resetForm,
                  errors,
                  open,
                })}
              </DialogContent>
              <DialogActions>
                <Box mr={2}>
                  <BoxButton
                    loading={loading}
                    type="submit"
                    color="success"
                    size="large"
                    variant="contained"
                    autoFocus
                  >
                    Submit
                  </BoxButton>
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </GeneralDialog>
      <Collaboration
        openCollab={openCollab}
        setOpenCollab={setOpenCollab}
      />
      <Container maxWidth="xl">
        <StudentTable
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          reload={open}
          schoolYear={schoolYear} // Pass the school year here

        />
      </Container>
    </React.Fragment>
  );
}
