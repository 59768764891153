import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  List,
  ListItemButton,
  Button,
  Container,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import AACP from "../AssessmentToolsComponents/AACP";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SCard from "components/SCard";
import GeneralInfomation from "../TabComponents/GeneralInfomation";
import BackgroundInformation from "../TabComponents/BackgroundInformation";
import { useDispatch, useSelector } from "react-redux";
import PurposeOfEvaluation from "../TabComponents/PurposeOfEvaluation";
import TeacherInput from "../TabComponents/TecherInput";
import ParentInput from "../TabComponents/ParentInput";
import ClassroomObservation from "../TabComponents/ClassroomObservation";
import EvaluationObservQuestionnaire from "../TabComponents/EvaluationObservQuestionnaire";
import BehavioralObservation from "../TabComponents/BehavioralObservation";
import OPME from "../TabComponents/OPME";
import Fluency from "../TabComponents/Fluency";
import Voice from "../TabComponents/Voice";
import Articulation from "../TabComponents/Articulation";
import Pragmatics from "../TabComponents/Pragmatics";
import Recommendations from "../TabComponents/Recommendations";
import Goals from "../TabComponents/Goals";
import PrevAssesment from "../TabComponents/PrevAssesment";
import ShowPDF from "../PDFPreview/ShowPDF";
import ReportSummary from "../TabComponents/ReportSummary";
import Eligibility from "../TabComponents/Eligibility";
import Signature from "../TabComponents/Signature";
import NormativeChart from "../TabComponents/NormativeChart";
import { reportAllDropdowns } from "redux/actions/reportAction";

const headersWithColors = [
  { header: "General Information" },
  { header: "Purpose of Evaluation" },
  { header: "Background Information" },
  { header: "Previous Evaluation and Services" },
  { header: "Teacher Input" },
  { header: "Parent/ Caretaker Interview" },
  { header: "Evaluation Observation" },
  { header: "Behavioral Observation" },
  { header: "Classroom observation" },
  { header: "Oral Peripheral Observation" },
  { header: "Fluency" },
  { header: "Voice" },
  { header: "Articulation" },
  { header: "Pragmatics" },
  { header: "Standardized Assessment" },
  { header: "Report Summary" },
  { header: "Recommendations" },
  { header: "Goals" },
  { header: "Eligibility" },
  { header: "Normative Chart-Images" },
  { header: "Signature" },
  { header: "Preview" },
];

const ReportTemplateForm = ({ formData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeValue, setActiveValue] = useState("");
  const dispatch = useDispatch();

  const [isScrollUpDisabled, setIsScrollUpDisabled] = useState(true);
  const [isScrollDownDisabled, setIsScrollDownDisabled] = useState(false);
  const listRef = useRef(null);
  const contentRef = useRef(null);


  useEffect(() => {
    const handleScroll = () => {
      const list = listRef.current;

      if (list) {
        setIsScrollUpDisabled(list.scrollTop === 0);
        setIsScrollDownDisabled(list.scrollTop + list.clientHeight >= list.scrollHeight);
      }
    };

    const list = listRef.current;
    if (list) {
      list.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (list) {
        list.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleScrollUp = () => {
    const list = listRef.current;
    if (list) {
      list.scrollTop -= 50; // Adjust the scroll amount as needed
    }
  };

  const handleScrollDown = () => {
    const list = listRef.current;
    if (list) {
      list.scrollTop += 50; // Adjust the scroll amount as needed
    }
  };

  const handleChipClick = (index, value) => {
    setActiveIndex(index);
    setActiveValue(value);
    // Scroll content to top
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (activeIndex === 0) {
      setActiveValue("General Information");
    }
  }, [activeIndex]);


  const options = useSelector((state) => state.reportReducer.reportAllDropdowns);

  useEffect(() => {
    dispatch(reportAllDropdowns());
  }, [dispatch]);


  return (
    <Container maxWidth="xl">
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} lg={3} sx={{ position: 'sticky', top: 100, height: 'calc(100vh - 100px)', overflowY: 'auto' }}>
          <SCard style={{ background: "#fff" }}>
            <Typography variant="h3" align="center" gutterBottom>
              Categories
            </Typography>
            <Box>
              <Button
                endIcon={<ArrowDownwardIcon />}
                onClick={handleScrollDown}
                disabled={isScrollDownDisabled}
                fullWidth
                color="secondary"
              >
                Scroll Down
              </Button>
            </Box>
            <List style={{ overflowX: "auto", height: "60vh" }} ref={listRef} dense={false}>
              {headersWithColors.map((item, index) => (
                <ListItemButton
                  key={index}
                  selected={index === activeIndex}
                  onClick={() => handleChipClick(index, item.header)}
                >
                  <Typography
                    color={index === activeIndex ? "primary" : "default"}
                    variant="subtitle2"
                    style={{
                      fontWeight: index === activeIndex ? 800 : 400,
                      fontSize: index === activeIndex ? 14 : 13,
                    }}
                  >
                    {item.header}
                  </Typography>
                </ListItemButton>
              ))}
            </List>
            <Box>
              <Button
                startIcon={<ArrowUpwardIcon />}
                onClick={handleScrollUp}
                disabled={isScrollUpDisabled}
                fullWidth
                color="secondary"
              >
                Scroll Up
              </Button>
            </Box>
          </SCard>
        </Grid>
        <Grid item xs={12} lg={9} ref={contentRef} 
        // style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}
        >
          <SCard>
            <Typography variant="h3" align="center" gutterBottom>
              {activeValue}
            </Typography>
            {activeIndex === 0 && <GeneralInfomation options={options.generalInformation} formData={formData} />}
            {activeIndex === 1 && <PurposeOfEvaluation options={options.purposeOfEvaluation} formData={formData} />}
            {activeIndex === 2 && <BackgroundInformation options={options.backgroundInformation} formData={formData} />}
            {activeIndex === 3 && <PrevAssesment options={options.prevEvaluation} formData={formData} />}
            {activeIndex === 4 && <TeacherInput  options={options.teacherInput} formData={formData} />}
            {activeIndex === 5 && <ParentInput  options={options.parentInput}  formData={formData} />}
            {activeIndex === 6 && <EvaluationObservQuestionnaire  options={options.evaluationObservation} formData={formData} />}
            {activeIndex === 7 && <BehavioralObservation  options={options.behavioralObservation} formData={formData} />}
            {activeIndex === 8 && <ClassroomObservation options={options.classroomObservation} formData={formData} />}
            {activeIndex === 9 && <OPME options={options.oralPeripheral} formData={formData} />}
            {activeIndex === 10 && <Fluency options={options.fluency} formData={formData} />}
            {activeIndex === 11 && <Voice  options={options.voice} formData={formData} />}
            {activeIndex === 12 && <Articulation  options={options.articulation} formData={formData} />}
            {activeIndex === 13 && <Pragmatics  options={options.pragmatics} formData={formData} />}
            {activeIndex === 14 && <AACP formData={formData}/>}
            {activeIndex === 15 && <ReportSummary options={options.reportSummary} formData={formData} />}
            {activeIndex === 16 && <Recommendations options={options.recommendations}  formData={formData}/>}
            {activeIndex === 17 && <Goals  formData={formData} />}
            {activeIndex === 18 && <Eligibility options={options.eligibility}  formData={formData} />}
            {activeIndex === 19 && <NormativeChart formData={formData} />}
            {activeIndex === 20 && <Signature formData={formData} />}
            {activeIndex === 21 && <ShowPDF formData={formData} />}


          </SCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReportTemplateForm;
