import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SUCCESS } from "redux/constants/notificationType";
import { useState } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  Container,
  ListItemButton,
  ListItemText,
  Checkbox,
  ListItemIcon,
  Box,
  Paper,
  Stack,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import SMessageCard from "components/SMessageCard";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Add, Delete } from "@mui/icons-material";
import moment from "moment";
import SCard from "components/SCard";
import { getEventsByDate } from "redux/actions/scheduleAction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Calendar from "../../assets/icons/calendar.png";
import Tracking from "../../assets/icons/yes-or-no.png";
import TaskList from "../../assets/icons/task-list.png";
import { updateDataTracking } from "redux/actions/dataTrackingAction";
import BoxButton from "components/BoxButton";
import { showNotification } from "redux/actions/notificationAction";
import CustomQuillEditor from "components/Common/CustomQuillEditor";
export default function DataCollection() {
  const dispatch = useDispatch();

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [students, setStudents] = useState([]);
  const handleToggleStudent = (student) => () => {
    setSelectedStudent(student === selectedStudent ? null : student);
    setSelectedGoals([]);
  };

  const CustomButton = () => <span className="octicon octicon-star" />;


  const [isLoading, setIsLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});

  useEffect(() => {
    setIsLoading(true);
    const start = moment().startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
    const end = moment().endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
    let payload = {
      startDate: start,
      endDate: end,
    };

    dispatch(getEventsByDate(payload)).then((response) => {
      if (response.response.length > 0) {
        setStudents(response.response);
        setIsLoading(false);

      }
    });
  }, [selectedStudent]);

  const handleToggleGoal = (clickedGoal) => () => {
    setSelectedGoals((prevSelectedGoals) => {
      const isGoalSelected = prevSelectedGoals.some(
        (selectedGoal) => selectedGoal._id === clickedGoal._id
      );

      if (isGoalSelected) {
        // If the goal is already selected, remove it
        return prevSelectedGoals.filter((selectedGoal) => selectedGoal._id !== clickedGoal._id);
      } else {
        // If the goal is not selected, add it to the selected goals
        return [...prevSelectedGoals, clickedGoal];
      }
    });
  };

  const selectedStudentGoals = selectedStudent
    ? students.find((s) => s._id === selectedStudent)?.goals || []
    : [];

  const selectedStudentObject = students && students?.find((s) => s._id === selectedStudent);

  const ToggleBox = ({ value, onClick }) => {
    const renderIcon = () => {
      if (value === true) {
        return <CheckIcon />;
      } else if (value === false) {
        return <CloseIcon />;
      } else {
        return "Select";
      }
    };
    const backgroundColor =
      value === true ? "#29e47f" : value === false ? "#fd446e" : "transparent";

    return (
      <>
        <Paper
          variant="outlined"
          square
          style={{
            width: "70px",
            height: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: 10,
            marginRight: 9,
            backgroundColor: backgroundColor,
          }}
          onClick={onClick}
        >
          {isLoading && (
            <CircularProgress size={24} style={{ position: "absolute", color: "#fff" }} />
          )}
          {renderIcon()}
        </Paper>
      </>
    );
  };

  const AddBox = ({ onClick }) => {
    const renderIcon = () => {
      return <Add />;
    };
    const backgroundColor = "#171d26";

    return (
      <Paper
        variant="outlined"
        square
        style={{
          width: "70px",
          height: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          borderRadius: 10,
          marginRight: 9,
          backgroundColor: backgroundColor,
          color: "#fff",
        }}
        onClick={onClick}
      >
        {renderIcon()}
      </Paper>
    );
  };

  const DeleteBox = ({ onClick }) => {
    const renderIcon = () => {
      return <Delete sx={{ color: '#f25d5d' }} />; // Replace with your delete icon
    };
    const backgroundColor = "#f0f0f0"; // Example red color for delete

    return (
      <Paper
        variant="outlined"
        square
        style={{
          width: "70px",
          height: "70px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          borderRadius: 10,
          marginRight: 9,
          backgroundColor: backgroundColor,
          color: "#fff",
        }}
        onClick={onClick}
      >
        {renderIcon()}
      </Paper>
    );
  };


  const handleAddQuestions = (goalIndex) => {
    const updatedGoals = [...selectedGoals];
    const currentResults = updatedGoals[goalIndex].results;

    if (currentResults.length === 0) {
      // If results array is empty, set it to an array of 5 null values
      updatedGoals[goalIndex].results = Array(5).fill(null);
    } else {
      // Otherwise, add a single null value
      updatedGoals[goalIndex].results.push(null);
    }

    setSelectedGoals(updatedGoals);
  };

  const handleDeleteQuestions = (goalIndex) => {
    const updatedGoals = [...selectedGoals];
    const currentResults = updatedGoals[goalIndex].results;

    console.log('Before deletion:', currentResults);

    // Remove the last element from the array
    if (currentResults.length > 0) {
      currentResults.pop(); // Removes the last element from the array
      console.log('After deletion:', currentResults);
    } else {
      console.log('No values to delete.');
    }

    // Update the state with the modified goals
    setSelectedGoals(updatedGoals);
    handlePostUpdate(goalIndex);
  };




  const handleToggle = (goalIndex, optionIndex) => {
    const updatedGoals = [...selectedGoals];
    const currentValue = updatedGoals[goalIndex].results[optionIndex];

    if (currentValue === true) {
      updatedGoals[goalIndex].results[optionIndex] = false;
    } else if (currentValue === false) {
      updatedGoals[goalIndex].results[optionIndex] = null;
    } else {
      updatedGoals[goalIndex].results[optionIndex] = true;
    }

    setSelectedGoals(updatedGoals);
    handlePostUpdate(goalIndex);
  };

  const todayDate = moment().format("MMMM D, YYYY");

  const handleNotesChange = (index, value) => {
    const updatedGoals = [...selectedGoals];
    updatedGoals[index].notes = value;
    setSelectedGoals(updatedGoals);
  };

  const handleUpdateNotes = (index) => {
    const updatedGoals = [...selectedGoals];
    const studentToUpdate = students.find((s) => s._id === selectedStudent);

    if (studentToUpdate) {
      const payload = {
        studentId: studentToUpdate.studentId,
        eventId: studentToUpdate._id,
        goals: updatedGoals,
        isBillable: studentToUpdate.isBillable,
        sendEmailAlert: studentToUpdate.sendEmailAlert,
      };

      // Update loading state for specific goal
      setLoadingStates((prev) => ({ ...prev, [index]: true }));

      dispatch(updateDataTracking(payload))
        .then((response) => {
          // After successful update, set loading to false for this specific goal
          setLoadingStates((prev) => ({ ...prev, [index]: false }));
          showNotification({
            message: "Updated Notes Succesfully !",
            type: SUCCESS,
          });
        })
        .catch(() => {
          setLoadingStates((prev) => ({ ...prev, [index]: false }));
        });
    }
  };


  const handlePostUpdate = (goalIndex) => {
    const studentToUpdate = students.find((s) => s._id === selectedStudent);

    if (studentToUpdate) {
      const updatedGoals = studentToUpdate.goals.map((goal) => {
        const selectedGoal = selectedStudentGoals.find((selected) => selected._id === goal._id);
        return selectedGoal ? selectedGoal : goal;
      });

      const payload = {
        studentId: studentToUpdate.studentId,
        eventId: studentToUpdate._id,  // Use event ID
        goals: updatedGoals,
        isBillable: studentToUpdate.isBillable, // Ensure these are included if needed
        sendEmailAlert: studentToUpdate.sendEmailAlert, // Ensure these are included if needed
      };

      // Update loading state for specific goal
      setLoadingStates((prev) => ({ ...prev, [goalIndex]: true }));

      setTimeout(() => {
        dispatch(updateDataTracking(payload))
          .then((response) => {
            // After successful update, set loading to false for this specific goal
            setLoadingStates((prev) => ({ ...prev, [goalIndex]: false }));
          })
          .catch(() => {
            setLoadingStates((prev) => ({ ...prev, [goalIndex]: false }));
          });
      }, 1000);
    }
  };

  const toolbarOptions = ['bold', 'italic', 'underline'];


  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} lg={3}>
            <SCard style={{ position: "sticky", top: 70 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color={"secondary"}>
                    Date : {todayDate}
                  </Typography>
                  {students.length > 0 ? (
                    <Typography variant="caption" color="secondary">
                      You have {students.length} scheduled sessions for today.
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="secondary">
                      You have no scheduled sessions for today.
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3">Students</Typography>
                  {students.length === 0 && (
                    <SMessageCard
                      imgUrl={Calendar}
                      imgWidth="5rem"
                      subText="You have no scheduled sessions for today."
                    />
                  )}
                  <List sx={{ width: "100%", maxWidth: 360 }}>
                    {students.map((student) => {
                      const labelId = `checkbox-list-label-${student._id}`;

                      return (
                        <ListItem key={student._id} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={handleToggleStudent(student._id)}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={student._id === selectedStudent}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={student.eventTitle.split(" - ")[0]} // Splitting the eventTitle at ' - ' and taking the first part
                              secondary={
                                student.eventTitle.split(" - ")[1] +
                                " - " +
                                moment(student.startTime).format("LT")
                              }
                              style={{ fontWeight: 400 }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                  {/* <Box textAlign={'right'}>
                                    <Button variant="outlined" color="secondary" size="small"> Add Student</Button>
                                </Box> */}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3">Goals</Typography>
                  {selectedStudentGoals.length === 0 && (
                    <SMessageCard
                      imgUrl={TaskList}
                      text=""
                      imgWidth="5rem"
                      subText="Please select a student"
                    />
                  )}
                  <List>
                    {selectedStudentObject?.goals?.map((goal, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemButton role={undefined} onClick={handleToggleGoal(goal)} dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={selectedGoals.some(
                                (selectedGoal) => selectedGoal._id === goal._id
                              )}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": `checkbox-list-label-${goal._id}` }} // Use goal.name as the identifier
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={`checkbox-list-label-${goal._id}`}
                            primary={goal.name}
                            primaryTypographyProps={{
                              style: {
                                fontWeight: 400,
                                letterSpacing: 0.3,
                                fontSize: 14,
                              },
                            }}
                          />{" "}
                          {/* Use goal.name as the primary text */}
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </SCard>
          </Grid>
          <Grid item xs={12} lg={9}>
            {selectedGoals.length === 0 && (

              <SCard>
                                        <Box height="70vh">

                <SMessageCard
                  imgUrl={Tracking}
                  imgWidth="5rem"
                  fontSize="24px"
                  text={
                    students.length > 0 ? (
                      `You have ${students.length} scheduled ${students.length === 1 ? 'session' : 'sessions'} for today.`
                    ) : (
                      `You have no scheduled session(s) for today.`
                    )
                  }

                  subText={
                    <>
                      <Typography variant="body1" component="div">
                        To start tracking data, please ensure the following fields are selected:
                      </Typography>
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="body1" component="div">
                          <ul style={{ textAlign: 'left', padding: 0, listStylePosition: 'inside' }}>
                            <li>Select a student</li>
                            <li>Check the goals you want to start tracking</li>
                          </ul>
                        </Typography>
                      </Box>
                    </>

                  }
                />
                </Box>
              </SCard>
            )}

            <Box>
              {selectedGoals?.map((goal, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={12} sx={{ marginBottom: 1 }}>
                    <SCard
                      title={
                        <Typography variant="body1" fontWeight={500} width={'50rem'}>
                          {goal.name}
                        </Typography>
                      }
                      renderActions={() => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {loadingStates[index] || false ? (                             
                              <Typography
                                mt={2}
                                variant="body2"
                                color="text.secondary"
                              >
                                Saving ...
                              </Typography>
                          ) : (
                            <Typography variant="body2" fontWeight={200} mt={2} color={"text.secondary"}>
                              Auto Saved
                            </Typography>
                          )}
                        </div>
                      )}
                    >
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        {goal?.results.map((value, optionIndex) => (
                          <ToggleBox
                            key={optionIndex}
                            value={value}
                            onClick={() => handleToggle(index, optionIndex)}
                          />
                        ))}
                        <AddBox key={index} onClick={() => handleAddQuestions(index)} />
                        {goal.results.length > 0 && (
                          <DeleteBox key="delete" onClick={() => handleDeleteQuestions(index)} />
                        )}
                      </div>
                      <Stack
                        direction="row"
                        spacing={10}
                        mt={2}
                        divider={<Divider orientation="vertical" flexItem />}
                      >
                        <Box>
                          <Typography variant="h6" color={"text.secondary"}>
                            Total
                          </Typography>
                          <Typography
                            variant="h2"
                            fontSize={28}
                            color={"secondary"}
                            fontWeight={600}
                            textAlign={"center"}
                          >
                            {goal.results.length}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6" color={"text.secondary"}>
                            Accuracy
                          </Typography>
                          <Typography
                            variant="h2"
                            fontSize={28}
                            color={"secondary"}
                            fontWeight={600}
                          >
                            {goal.results.length !== 0
                              ? (
                                (goal.results.filter((value) => value === true).length /
                                  goal.results.length) *
                                100
                              ).toFixed(2) + "%"
                              : "0.00%"}
                          </Typography>
                        </Box>
                      </Stack>

                      <Box mt={2}>
                        <CustomQuillEditor
                          placeholder={'Write notes if there is any'}
                          value={goal.notes} // Ensure this is properly set
                          onChange={(value) => handleNotesChange(index, value)}
                          toolbarId={`toolbar-${index}`} // Pass the unique ID
                        />
                        {/* Conditionally render the "Update Notes" button */}
                        {(goal.notes && goal.notes.replace(/<\/?[^>]+(>|$)/g, '').trim().length > 0) || goal.notes === '<p><br></p>' ? (
                          <Box mt={2}>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              loading={loadingStates[index]}
                              onClick={() => handleUpdateNotes(index)}
                            >
                              Update Notes
                            </Button>
                          </Box>
                        ) : null}
                      </Box>
                    </SCard>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
