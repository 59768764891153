const form = {
  formId: "schedulingLinks",
  formField: {
    eventTitle: {
      name: "eventTitle",
      label: "Event Title",
      type: "text",
      placeholder: "Enter a name for your meeting",
      errorMsg: "Event Title is required.",
      invalidMsg: "",
    },
    dateSlots: {
      name: "dateSlots",
      label: "Date Slot",
      type: "datetime-local",
      placeholder: "Date Slots",
      errorMsg: "Start Time is required.",
      invalidMsg: "",
    },
    guestEmail: {
      name: "guestEmail",
      label: "Guest Email",
      type: "email",
      placeholder: "Enter guest email address",
      errorMsg: "guestEmail Type is required.",
      invalidMsg: "Invalid Email",
    },
    invitedList: {
      name: "invitedList",
      label: "Invities",
      type: "text",
      placeholder: "Event Type",
      errorMsg: "Event Type is required.",
      invalidMsg: "",
    },
    dateTimeSlots: {
      name: "dateTimeSlots",
      label: "dateTimeSlots",
      type: "text",
      placeholder: "dateTimeSlots",
      errorMsg: "dateTimeSlots is required.",
      invalidMsg: "",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
      placeholder: "Description",
      errorMsg: "Description is required.",
      invalidMsg: "",
    },
    duration: {
      name: "duration",
      label: "Duration",
      type: "text",
      placeholder: "15min, 30min, 1hr ...",
      errorMsg: "Duration is required.",
      invalidMsg: "",
    },
  },
};

export default form;
