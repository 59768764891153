import { authRequest } from "./request";
import * as Actions from "../constants/constants";
import { showNotification } from "./notificationAction";
import { SUCCESS } from "redux/constants/notificationType";

export function sendInvite(payload) {
  return (dispatch) => {
    return authRequest(`invite/send-meeting-invite`, "POST", payload)
      .then((response) => {
        dispatch({
          type: Actions.SEND_MEETING_INVITE,
          payload: response,
        });
        showNotification({
          message: "Invite Sent Succesfully !",
          type: SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function fetchAllSentInvites(payload) {
  const queryParams = new URLSearchParams(payload.filter).toString();

  return (dispatch) => {
    return authRequest(`invite/getAllMeetingInvites?${queryParams}`, "GET")
      .then((response) => {
        dispatch({
          type: Actions.FETCH_INVITED_LIST,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function fetchSlotSelection(payload) {
  return (dispatch) => {
    return authRequest(
      `invite/meeting-invite/${payload.meetingId}/${payload.email}/slot-selection`,
      "GET"
    )
      .then((response) => {
        dispatch({
          type: Actions.FETCH_SLOTS_LIST,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function postSlotSelection(inviteId, payload) {
  return (dispatch) => {
    return authRequest(`invite/${inviteId}/slot-selection`, "POST", payload)
      .then((response) => {
        dispatch({
          type: Actions.SEND_MEETING_INVITE,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}
