import { useEffect, useState } from "react";
// import * as moment from 'moment';
import moment from "moment-timezone";
import "moment-timezone";

function DateAndTimeConvert({ timestamp, noTime, format = null }) {
  const [localTimestamp, setLocalTimestamp] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  // console.log(timeZone);
  //
  // useEffect(() => {
  //   const now = new Date();
  //   const utcTimeOffset = now.getTimezoneOffset() / 60;

  //   console.log('utcTimeOffset', utcTimeOffset)
  //   const date = new Date(timestamp);
  //   date.setHours(date.getHours() - utcTimeOffset);
  //   setLocalTimestamp(date.toLocaleString());
  //   console.log('utcTimeOffset', utcTimeOffset);
  // }, [timestamp]);

  // return <>{localTimestamp}</>;
  useEffect(() => {
    const date = moment.utc(timestamp).tz(timeZone);
    setLocalTimestamp(date.format());
  }, [timestamp, timeZone]);

  return format === null
    ? moment(localTimestamp).tz(timeZone).format("MM/DD/YYYY")
    : moment(localTimestamp).tz(timeZone).format(format);
}

export default DateAndTimeConvert;
