import React, { useState } from "react";
import { Grid, Typography, Paper, TextareaAutosize, useMediaQuery, Divider, IconButton, Box, Stack, Button } from "@mui/material";
import OptionSelector from "../components/OptionSelectior";
import DeleteIcon from "@mui/icons-material/Delete";
import FormField from "components/FormField";
import TaskList from "../../../../assets/icons/task-list.png";
import SMessageCard from "components/SMessageCard";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { TaskAltOutlined, Add, AutoAwesome, Close } from "@mui/icons-material";

const Goals = (props) => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        goals
    } = formField.goals;

    console.log('va', values.goals.goals)
    const handleAddRow = () => {
        setFieldValue("goals.goals", [...values.goals.goals, { name: "" }]);
    };

    const handleDeleteRow = (index) => {
        const newGoals = [...values.goals.goals];
        newGoals.splice(index, 1);
        setFieldValue("goals.goals", newGoals);
    };


    const handleChange = (index, event) => {
        const newGoals = [...values.goals.goals];
        newGoals[index].name = event.target.value;
        setFieldValue("goals.goals", newGoals);
    };

    //   const handleGoalAssistance = () => {
    //     setOpenGoalBuider(!openGoalBuilder);
    //   };

    const replaceStudentName = (label) => {
        const studentName = values?.basicInformation?.firstName || '[student info not-found]';
        return label.replace('STUDENT', studentName);
    };
    // Render the rich text for background information
    return (
        <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
                <Stack
                    direction={isSmallScreen ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    divider={<Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} flexItem />}
                    spacing={2}
                >
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={handleAddRow}
                            startIcon={<Add />}
                        >
                            Add Goal
                        </Button>
                    </Box>

                    <Box>

                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Paper variant="outlined" style={{ padding: "2rem", backgroundColor: "#fff" }}>
                    {values.goals.goals.length === 0 && (
                        <SMessageCard
                            imgUrl={TaskList}
                            text=" No goals added"
                            subText="Use the AI Goal Builder to automatically generate or manually enter goals. Click 'Add Goals' to begin.'"
                        />
                    )}
                    {values.goals.goals.map((goal, index) => (
                        <>
                            <Box mb={1} lineHeight={0}>
                                <Typography
                                    component="label"
                                    // variant="subtitle2"
                                    fontWeight="500"
                                // textTransform="capitalize"
                                >
                                    Goal {index + 1}
                                </Typography>
                            </Box>

                            <div
                                key={index}
                                style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
                            >
                                <TextareaAutosize
                                    name={goals.name}
                                    minRows={2}
                                    style={{
                                        width: '100%',
                                        borderColor: 'lightGrey',
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        padding: '9px',
                                        borderRadius: '4px',

                                    }}
                                    // size="small"
                                    variant="outlined"
                                    value={goal.name}
                                    onChange={(event) => handleChange(index, event)}
                                />

                                <IconButton onClick={() => handleDeleteRow(index)} sx={{ marginLeft: 2 }}>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </div>
                        </>
                    ))}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Goals;
