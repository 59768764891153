import checkout from "./form";

const {
  formField: {
    eventTitle,
    dateSlots,
    invitedList,
    description,
    duration,
    guestEmail,
    dateTimeSlots,
  },
} = checkout;

const initialValues = {
  [eventTitle.name]: "",
  [guestEmail.name]: "",
  [dateSlots.name]: "",
  [dateTimeSlots.name]: [],
  [invitedList.name]: [],
  [description.name]: "",
  [duration.name]: "",
};

export default initialValues;
