import React from 'react';
import { Grid, Divider, Typography } from '@mui/material';

const HeaderComp = ({ title }) => {
    return (
        <Grid item md={12} sm={12} xs={12}>
            <Divider textAlign="left">
                <Typography variant="overline" color="text.secondary" fontWeight={800} fontSize={14}>
                    {title}
                </Typography>
            </Divider>
        </Grid>
    );
};

export default HeaderComp;
