import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  Divider,
  Paper,
  Pagination,
  IconButton,
  Chip,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";
import ScheduleAssessment from "pages/Student/ScheduleAssessment";
import { initialValues, form, validations } from "pages/Student/ScheduleAssessment/schemas";
import GeneralDialog from "components/GeneralDialog";
import { Formik, Form } from "formik";
import { getDataTrackingHistory } from "redux/actions/scheduleAction";
import { useSelector, useDispatch } from "react-redux";
import SLoadingIndicator from "components/SLoadingIndicator";
import { MoreVert, AttachMoney, FilterList, Close } from "@mui/icons-material";
import SMessageCard from "components/SMessageCard";
import { SummarizeOutlined } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import DateRangePickerValues from "../DateRangePickerValues";
import moment from "moment";
import { groupBy } from "lodash";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import Menu from "@mui/material/Menu";
import ViewDataTrackingPDF from "../ViewDataTrackingPDF.js";
import SCard from "../../../../../components/SCard/index.js";
import NoData from '../../../../../assets/icons/test.png'
function DateChip({ startDate, endDate, onDelete }) {
  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };

  return (
    <Chip
      label={`${formatDateString(startDate)} - ${formatDateString(endDate)}`}
      onDelete={onDelete}
      color="warning"
      size="large"
    />
  );
}

const AssessmentList = ({ data, onItemClick, handleViewReport, handleOpenReport }) => {
  const handleItemClick = (item) => {
    onItemClick && onItemClick(item);
  };

  const groupedData = groupBy(data, (item) => moment(item.startTime).format("YYYY-MM-DD"));

  const handleView = (item) => {
    handleOpenReport(true);
    handleViewReport(item);
  };

  return (
    <div>
      {Object.keys(groupedData).map((date, index) => (
        <div key={index}>
          <Typography variant="subtitle1" color={"text.secondary"} mb={1}>
            {moment(date).format("LL")}
          </Typography>
          {groupedData[date].map((item) => (
            <Paper
              key={item._id}
              elevation={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderLeft: `2px solid ${item.backgroundColor}`, // Replace with your desired color
                padding: "10px 12px 8px 16px",
                background: "#faf9f9",
                marginBottom: 1,
                position: "relative",
                cursor: "pointer",
                borderRadius: "1px",
                backgroundColor: "#faf9f9",
                transition: "transform 0.3s",

                "&:hover": {
                  transform: "scale(1.01)",

                  boxShadow: "0px 0px 10px 0px rgb(13 183 242 / 9%)",
                  cursor: "pointer",
                  borderColor: (theme) => theme.palette.primary["main"],
                },
              }}
              onClick={() => handleItemClick(item)}
            >
              <Grid container spacing={2} sx={{ flex: 1 }}>
                <Grid item xs={9}>
                  <Stack
                    spacing={2}
                    useFlexGap
                    direction={{ xs: "column", sm: "row" }}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Box width={150} sx={{ maxWidth: 200, display: "flex" }}>
                      {!item.isBillable ? (
                        <AttachMoney style={{ marginTop: 0, color: grey[500] }} />
                      ) : null}
                      <Typography
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        fontWeight={500}
                        variant="body1"
                        textTransform={"capitalize"}
                      >
                        {item.eventType}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        fontWeight={400}
                        variant="body1"
                      >
                        Score: {item.goals.length}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        overflow={"hidden"}
                        color={"text.secondary"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        fontWeight={400}
                        variant="body1"
                      >
                        Session Time : {moment.utc(item.startTime).format("LT")} -{" "}
                        {moment.utc(item.endTime).format("LT")}
                        {/* {calculatedHours(item.startTime, item.endTime)} */}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs sx={{ justifyContent: "flex-end", display: "flex" }}>
                  <Stack spacing={2} direction={"row"}>
                    <PictureAsPdfOutlinedIcon
                      fontSize="medium"
                      style={{ color: grey[500] }}
                      onClick={() => handleView(item)}
                    />
                    <MoreVert fontSize="medium" style={{ color: grey[500] }} />
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </div>
      ))}
    </div>
  );
};

function Assessments({ data }) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const { formId, formField } = form;
  const [loading, setLoading] = useState(false);
  const currentValidation = validations[0];
  const [filteredDates, setFilteredDates] = useState(null);

  const { dataTrackingHistory } = useSelector((state) => ({
    dataTrackingHistory: state.studentReducer.getDataTrackingHistory,
  }));

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 5; // Number of items per page

  const [anchorEl, setAnchorEl] = useState(null);

  const filterOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  function newForm(formData) {
    return <ScheduleAssessment formData={formData} />;
  }

  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  const submitForm = (values, actions) => {
    //TODO
  };

  const [selectedDates, setSelectedDates] = useState([]);

  const handleDateRangeChange = (dates) => {
    setSelectedDates(dates);
  };

  const payload = {
    filter: {
      pageNumber: page,
      eventType: [],
      searchValue: "",
      startDate: "",
      endDate: "",
    },
  };

  const fetchData = async () => {
    if (data?.response._id) {
      setLoading(true);

      // Create a delay of 3 seconds
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

      try {
        await delay(1000); // 3000ms = 3 seconds

        const response = await dispatch(getDataTrackingHistory(data?.response._id, payload));
        setTotalCount(response.totalCount);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, data?.response._id]); // Fetch data when the page changes

  const handleApplyFilters = () => {
    const startDateLocal = moment(selectedDates.startDate).startOf("day");
    const endDateLocal = moment(selectedDates.endDate).endOf("day");

    if (startDateLocal && endDateLocal) {
      payload.filter.startDate = startDateLocal.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
      payload.filter.endDate = endDateLocal.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
      setFilteredDates(payload);
    }
    setAnchorEl(null);
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDelete = () => {
    setSelectedDates({});
    setFilteredDates(null);
    fetchData();
  };

  const [openReport, setOpenReport] = useState(false);
  const [viewReportData, setViewReportData] = useState({});

  function onCloseView() {
    setOpenReport(false);
  }

  const viewDataTackingData = (data) => {
    setViewReportData(data);
  };

  const handleDownloadPdf = () => {};

  return (
    <React.Fragment>
      <SCard>
        <Grid container spacing={2}>
        {dataTrackingHistory && dataTrackingHistory?.response?.length > 0 && (

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <div>
                {filteredDates?.filter?.startDate && filteredDates?.filter?.endDate && (
                  <DateChip
                    startDate={filteredDates?.filter?.startDate}
                    endDate={filteredDates?.filter?.endDate}
                    onDelete={handleDelete}
                  />
                )}
              </div>

              <div>
                <Button
                  color="primary"
                  variant="outlined"
                  sx={{ bgShadow: 0, mr: 1 }}
                  size="medium"
                  aria-controls={filterOpen ? "filter-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={filterOpen ? "true" : undefined}
                  style={{ cursor: "pointer" }} // Show pointer cursor
                  onClick={handleClick}
                  startIcon={<FilterList />}
                >
                  Filter
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  id="filter-menu"
                  open={filterOpen}
                  // onClick={handleCloseFilter}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  sx={{
                    "& .MuiMenu-list": {
                      backgroundColor: "#f8fafb",
                    },
                    "& .MuiPaper-root": {
                      borderRadius: 1,
                      marginTop: theme.spacing(1.5),
                    },
                  }}
                >
                  <Box style={{ padding: 20, backgroundColor: "#f8fafb" }}>
                    <Box mb={3}>
                      <IconButton
                        style={{ position: "absolute", top: 8, right: 8 }}
                        onClick={handleCloseFilter}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                    <Box mb={2}>
                      <DateRangePickerValues onChange={handleDateRangeChange} />
                    </Box>
                    <Button
                      variant="contained"
                      onClick={handleApplyFilters}
                      fullWidth
                      color="primary"
                    >
                      Apply Filters
                    </Button>
                  </Box>
                </Menu>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ bgShadow: 0 }}
                  size="medium"
                  startIcon={<SummarizeOutlined />}
                >
                  Generate Report
                </Button>
              </div>
            </Stack>
          </Grid>
          )}
          {loading ? (
            <Grid item xs={12}>
              <SLoadingIndicator height="25vh" />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                {dataTrackingHistory && dataTrackingHistory?.response?.length === 0 && (
                          <Box height="70vh">

                  <SMessageCard
                    imgWidth="5rem"
                    // text="No Records Found"
                    imgUrl={NoData}
                    subText="No Records Found"
                  />
                  </Box>
                )}
                <AssessmentList
                  data={dataTrackingHistory && dataTrackingHistory?.response}
                  onItemClick={null}
                  handleViewReport={(data) => viewDataTackingData(data)}
                  handleOpenReport={(value) => setOpenReport(value)}
                />
              </Grid>
              {dataTrackingHistory && dataTrackingHistory?.response?.length > 0 && (

              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
              >
                <Pagination
                  count={Math.ceil(totalCount / perPage)}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  shape="circular"
                  variant="outlined"
                  size="medium"
                  showFirstButton
                  showLastButton
                />
              </Grid>
              )}
            </>
          )}
        </Grid>
      </SCard>
      <GeneralDialog
        open={open}
        title="Goal Data Tracking"
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidation}
          onSubmit={submitForm}
        >
          {({ values, errors, touched, setFieldValue, isSubmitting, resetForm }) => (
            <Form id={formId} autoComplete="off">
              <Box>
                {newForm({
                  values,
                  touched,
                  formField,
                  setFieldValue,
                  resetForm,
                  errors,
                  open,
                })}
              </Box>
            </Form>
          )}
        </Formik>
      </GeneralDialog>

      <GeneralDialog
        open={openReport}
        title="View Student Data"
        onClose={onCloseView}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <ViewDataTrackingPDF
            studentName={data.response.firstName + " " + data.response.lastName}
            data={viewReportData}
            handleDownloadPdf={handleDownloadPdf}
          />
        </DialogContent>
        <DialogActions>
          {/* <Box mr={2}>
            <BoxButton color="success" size="large" variant="contained" autoFocus onClick={handleDownloadPdf}>
              Submit
            </BoxButton>
          </Box> */}
        </DialogActions>
      </GeneralDialog>
    </React.Fragment>
  );
}

export default Assessments;
