import React, { useState } from "react";
import {
  Grid,
  Typography,
  FormHelperText,
  TextField,
  Box,
  Stack,
  Alert,
  Divider,
} from "@mui/material";
import FormField from "components/FormField";
import "react-quill/dist/quill.snow.css";
import SCard from "components/SCard";

const ScoreInput = ({ label, placeholder, value, onChange, sx, hint }) => (
  <div style={{ marginBottom: "1rem" }}>
    <TextField
      variant="outlined"
      size="small"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(label, e.target.value)}
      sx={sx}
      //   style={{height: '0.8rem'}}
    />
    <FormHelperText>{hint}</FormHelperText>
  </div>
);

const AACP = () => {
  const assessmentToolOptions = ChooseToolTemplate.map((tool, index) => ({
    value: tool.value, // Removing spaces for short values
    label: tool.label,
  }));

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [toolOptions, setToolOptions] = useState([]);

  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedTool, setSelectedTool] = useState([]);

  const handleSelectChange = (selectedOptions) => {
    if (selectedOptions.length === 0) {
      setSelectedTool([]);
    }
    setSelectedOptions(selectedOptions);
    // Update the list based on selected options
    const updatedToolOptions = selectedOptions.flatMap(
      (selectedOption) => secondaryOptions[selectedOption.value] || []
    );

    console.log("Updated Tool Options:", updatedToolOptions);

    // Remove duplicates from updatedToolOptions
    const uniqueToolOptions = Array.from(
      new Set(updatedToolOptions.map((option) => option.value))
    ).map((value) => updatedToolOptions.find((option) => option.value === value));

    console.log("Unique Tool Options:", uniqueToolOptions);

    setToolOptions(uniqueToolOptions);
  };

  const handleToolChange = (selectedOptions) => {
    setSelectedTool(selectedOptions);
  };

  const isDAYC = selectedTool.some((option) => option.value === "DAYC-2");
  const IT = selectedTool.some((option) => option.value === "IT");
  const AACG = selectedTool.some((option) => option.value === "AACG");
  const AAPScale = selectedTool.some((option) => option.value === "AAPScale");
  const CELF5 = selectedTool.some((option) => option.value === "CELF-5");

  console.log("isDAYC", isDAYC);
  console.log("selectedTool", selectedTool);

  const [scoreValues, setScoreValues] = useState({
    "Core Language": { score: "", deviation: "", description: "" },
    "Reception Language": { score: "", deviation: "", description: "" },
    "Expressive Language": { score: "", deviation: "", description: "" },
    "Language Content": { score: "", deviation: "", description: "" },
    "Language Structure": { score: "", deviation: "", description: "" },
  });

  console.log("selectedOptions", selectedOptions);

  const handleScoreChange = (area, type, value) => {
    setScoreValues((prevValues) => ({
      ...prevValues,
      [area]: {
        ...prevValues[area],
        [type]: value,
      },
    }));
  };

  const [subTestValues, setSubTestValues] = useState({
    "Sentence Comprehension": { score: "", description: "" },
    "Word Structure": { score: "", description: "" },
    "Formulated Sentences": { score: "", description: "" },
    "Recalling Sentences": { score: "", description: "" },
    "Word Classes": { score: "", description: "" },
    "Following Directions": { score: "", description: "" },
    "Linguistic Concepts": { score: "", description: "" },
    "Understanding Spoken Paragraphs": { score: "", description: "" },
  });

  const handleSubTestChange = (area, type, value) => {
    setSubTestValues((prevValues) => ({
      ...prevValues,
      [area]: {
        ...prevValues[area],
        [type]: value,
      },
    }));
  };

  const customStyles = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#5163f3", // Change the background color here
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#fff", // Change the text color here
    }),
    // multiValueRemove: (styles) => ({
    //   ...styles,
    //   ':hover': {
    //     backgroundColor: 'red', // Background color of the remove button on hover
    //     color: '#fff', // Text color of the remove button on hover
    //   },
    // }),
  };

  const customStyles2 = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#6a6a6a", // Change the background color here
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#fff", // Change the text color here
    }),
    // multiValueRemove: (styles) => ({
    //   ...styles,
    //   ':hover': {
    //     backgroundColor: 'red', // Background color of the remove button on hover
    //     color: '#fff', // Text color of the remove button on hover
    //   },
    // }),
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
          <FormField
            label="Select Category"
            name={"reports"}
            inputType="select"
            options={assessmentToolOptions}
            isMulti
            styles={customStyles}
            value={selectedOptions}
            onChange={handleSelectChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="Select Tool"
            name={"reports"}
            inputType="select"
            options={toolOptions}
            styles={customStyles2}
            isMulti
            isDisabled={toolOptions.length === 0}
            value={selectedTool} // No preselected values
            onChange={handleToolChange}
          />
        </Grid>
      </Grid>
      {IT && (
        <Grid container spacing={2} p={2}>
          <HeaderComp title="IT Tool" />
          <Grid item xs={12}>
            <Typography variant="body1">Content for IT tool</Typography>
          </Grid>
        </Grid>
      )}
      {AACG && (
        <Grid container spacing={2} p={2}>
          <HeaderComp title="AACG" />

          <Grid item xs={12}>
            <Typography variant="body1">Content for AACG tool</Typography>
          </Grid>
        </Grid>
      )}
      {AAPScale && (
        <Grid container spacing={2} p={2}>
          <HeaderComp title="AAPScale" />

          <Grid item xs={12}>
            <Typography variant="body1">Content for AAPScale tool</Typography>
          </Grid>
        </Grid>
      )}
      {isDAYC && (
        <Grid container spacing={2} p={2}>
          <SCard>
            <HeaderComp title="DAYC-2" />
            <Grid item xs={12}></Grid>
          </SCard>
        </Grid>
      )}
      {CELF5 && (
        <Grid container spacing={2} p={2}>
          <SCard>
            <HeaderComp title="Erica's Performance on CELF-5" />

            <Grid item xs={12}>
              <Typography variant="overline" fontSize={16} color={"secondary"} pb={2}>
                Composite Score Area
              </Typography>

              {Object.keys(scoreValues).map((area, index) => (
                <Grid item xs={12} key={area}>
                  <Typography variant="body1" fontWeight={500} mb={1}>
                    {area}
                  </Typography>
                  <Stack direction={"row"} spacing={2}>
                    <ScoreInput
                      placeholder="Standard Score"
                      value={scoreValues[area].score}
                      onChange={(type, value) => handleScoreChange(area, "score", value)}
                      hint={
                        area === "Core Language"
                          ? "Interval - (74 - 84)"
                          : area === "Reception Language"
                          ? "Interval - (80 - 90)"
                          : area === "Expressive Language"
                          ? "Interval - (73 - 83)"
                          : area === "Language Content"
                          ? "Interval - (83 - 93)"
                          : area === "Language Structure"
                          ? "Interval - (74 - 84)"
                          : null
                      }
                    />
                    <ScoreInput
                      placeholder="Standard Deviation"
                      value={scoreValues[area].deviation}
                      onChange={(type, value) => handleScoreChange(area, "deviation", value)}
                    />
                    <ScoreInput
                      sx={{ width: "30rem" }}
                      placeholder="Description"
                      value={scoreValues[area].description}
                      onChange={(type, value) => handleScoreChange(area, "description", value)}
                    />
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Alert severity="info">
                  Scores between 85 and 115 are considered to be average
                </Alert>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="overline" fontSize={16} color={"secondary"} pb={2}>
                Subtests
              </Typography>
              {Object.keys(subTestValues).map((area, index) => (
                <Grid item xs={12} key={area}>
                  <Typography variant="body1" fontWeight={500} mb={1}>
                    {area}
                  </Typography>
                  <Stack direction={"row"} spacing={2}>
                    <ScoreInput
                      placeholder="Standard Score"
                      value={subTestValues[area].score}
                      onChange={(type, value) => handleSubTestChange(area, "score", value)}
                    />

                    <ScoreInput
                      sx={{ width: "30rem" }}
                      placeholder="Description"
                      value={subTestValues[area].description}
                      onChange={(type, value) => handleSubTestChange(area, "description", value)}
                    />
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </SCard>
        </Grid>
      )}
    </React.Fragment>
  );
};

const ChooseToolTemplate = [
  { value: "Apraxia", label: "Apraxia" }, //added
  { value: "Articulation", label: "Articulation" }, //added
  { value: "Phonology", label: "Phonology" }, //added
  { value: "Fluency", label: "Fluency" }, //added
  { value: "CL", label: "Comprehensive Language" }, //added
  { value: "EL", label: "Expressive Language" }, //added
  { value: "RL", label: "Receptive Language" }, //added
  { value: "IS", label: "Informal Screeners" },
  { value: "Vocabulary", label: "Vocabulary" }, //added
  { value: "SA", label: "Spanish Assessments" }, //added
  { value: "Voice", label: "Voice" },
  { value: "FS", label: "Feeding & Swallowing" },
  { value: "AAC", label: "Alternative and Augmentative Communication" },
  { value: "AT", label: "Assistive Technology" },
  { value: "APD", label: "Auditory Processing DEAF H.O.H" }, //added
  { value: "PL", label: "Pragmatic Language" }, //added
  { value: "ECP", label: "Early Childhood PreSchool" }, //added
];

const secondaryOptions = {
  Apraxia: [
    { value: "KSPT", label: "Kaufman Speech Praxis Test for Children (KSPT)" },
    { value: "ScreeningTest", label: "Screening Test for Developmental Apraxia of Speech" },
  ],
  Articulation: [
    { value: "Arizona4", label: "Arizona Articulation and Phonology Scale-Fourth Edition (AZ-4)" },
    { value: "CAAP", label: "Clinical Assessment of Articulation and Phonology (CAAP)" },
    {
      value: "CAAP2",
      label: "Clinical Assessment of Articulation and Phonology-Second Edition (CAAP-2)",
    },
    { value: "CPAC", label: "Contextual Probes of Articulation Competence (CPAC)" },
    { value: "DEAP", label: "The Diagnostic Evaluation of Articulation and Phonology’s (DEAP)" },
    { value: "EWRA", label: "Entire World of R-Advanced Screening for /R/" },
    { value: "FisherLogemann", label: "Fisher-Logemann Test of Articulation Competence" },
    { value: "GFTA2", label: "Goldman Fristoe Test of Articulation-2 (GFTA-2)" },
    { value: "GFTA3", label: "Goldman Fristoe Test of Articulation-Third Edition (GFTA-3)" },
    { value: "LAT", label: "LinguiSystems Articulation Test (LAT)" },
    { value: "McDonaldDeep", label: "McDonald-Deep Test of Articulation" },
    { value: "PAT3", label: "Photo Articulation Test-Third Edition (PAT-3)" },
    { value: "PTAP", label: "Practical Test of Articulation and Phonology (PTAP)" },
    { value: "SPAT2", label: "Structured Photographic Articulation Test-Second Edition (SPAT-2)" },
    { value: "SAPT", label: "SUNNY Articulation and Phonology Test Kit (SAPT) (Sunny)" },
    { value: "WCAT", label: "Weiss Comprehensive Articulation Test (WCAT)" },
  ],
  Phonology: [
    { value: "ALPHA", label: "Assessment Link between Phonology and Articulation (ALPHA)" },
    { value: "APP-R", label: "Assessment of Phonological Processes-Revised (APP-R)" },
    { value: "CTOPP", label: "Comprehensive Test of Phonological Processing (CTOPP)" },
    { value: "CTOPP2", label: "Comprehensive Test of Phonological Processing - 2 (CTOPP-2)" },
    { value: "GDAP", label: "Glaspey Dynamic Assessment of Phonology (GDAP)" },
    {
      value: "HAPP3",
      label: "The Hodson Assessment of Phonological Patterns-third edition (HAPP-3)",
    },
    { value: "KLPA2", label: "Khan-Lewis Phonological Analysis-2 (KLPA-2)" },
    { value: "KLPA3", label: "Khan-Lewis Phonological Analysis-3 (KLPA-3)" },
    { value: "PhonAwarenessTest2", label: "The Phonological Awareness Test-2" },
  ],
  APD: [
    { value: "APAT", label: "Auditory Processing Abilities Test (APAT)" },
    {
      value: "CPVT",
      label: "Carolina Picture Vocabulary Test for Deaf and Hearing Impaired (CPVT)",
    },
    { value: "RenfrewAction", label: "Renfrew Action Picture Test" },
    { value: "TAPS3", label: "Test of Auditory Processing Skills-Third Edition (TAPS-3)" },
  ],
  PL: [
    { value: "ABC", label: "Autism Behavior Checklist (ABC)" },
    { value: "LNVOCC", label: "Limited Non-Verbal and Verbal Observation Checklist" },
    { value: "CPIOC", label: "Component of Pragmatic Intent Observation Checklist" },
    { value: "CARS", label: "Childhood Autism Rating Scale (CARS)" },
    { value: "CARS2", label: "Childhood Autism Rating Scale-Second Edition (CARS-2)" },
    { value: "CEPR", label: "Conversational Effectiveness Profile-Revised (CEP-R)" },
    { value: "EASIC", label: "Evaluating Acquired Skills in Communication (EASIC)" },
    {
      value: "EASIC3",
      label: "Evaluating Acquired Skills in Communication-Third Edition (EASIC-3)",
    },
    { value: "GADS", label: "Gilliam Asperger’s Disorder Scale (GADS)" },
    { value: "GARS", label: "Gilliam Autism Rating Scale (GARS)" },
    { value: "GARS3", label: "Gilliam Autism Rating Scale-Third Edition (GARS-3)" },
    { value: "PLSI", label: "Pragmatic Language Skills Inventory (PLSI)" },
    { value: "PLOS", label: "Pragmatic Language Observation Scale (PLOS)" },
    { value: "SCQ", label: "The Social Communication Questionnaire" },
    { value: "SEE", label: "Social-Emotional Evaluation (SEE)" },
    {
      value: "SLDTA-NU",
      label: "Social Language Development Test-Adolescent: Normative Update (SLDT-A:NU)",
    },
    {
      value: "SLDTE-NU",
      label: "Social Language Development Test-Elementary: Normative Update (SLDT-E:NU)",
    },
    { value: "TOPL", label: "Test of Pragmatic Language (TOPL)" },
    { value: "TOPL2", label: "Test of Pragmatic Language-Second Edition (TOPL-2)" },
  ],
  ECP: [
    {
      value: "CELF-PRE",
      label: "Clinical Evaluation of Language Fundamentals-Preschool (CELF-PRE)",
    },
    {
      value: "CELF:P-3",
      label: "Clinical Evaluation of Language Fundamentals: Preschool-Third Edition (CELF:P-3)",
    },
    {
      value: "DAYC-2",
      label: "Developmental Assessment of Young Children-Second Edition (DAYC-2)",
    },
    {
      value: "Fluharty-2",
      label: "Fluharty Preschool Speech and Language Screening-Second Edition (Fluharty-2)",
    },
    { value: "KLST-2", label: "Kindergarten Language Screening Test-Second Edition (KLST-2)" },
    { value: "PLAI", label: "Preschool Language Assessment Instrument (PLAI)" },
    { value: "PLS-4", label: "Preschool Language Scale-4 (PLS-4)" },
    { value: "PLS-5", label: "Preschool Language Scale-5 (PLS-5)" },
    {
      value: "REEL-3",
      label: "Receptive-Expressive Emergent Language Test-Third edition (REEL-3)",
    },
    {
      value: "REEL-4",
      label: "Receptive-Expressive Emergent Language Test-Fourth Edition (REEL-4)",
    },
    { value: "Rossetti", label: "Rossetti Infant-Toddler Language Scale" },
    {
      value: "SPELT-P",
      label: "Structured Photographic Expressive Language Test-Preschool (SPELT-P)",
    },
    { value: "SymbolicPlayTest", label: "The Symbolic Play Test (2nd Edition)" },
    { value: "TELD-2", label: "Test of Early Language Development-Second Edition (TELD-2)" },
    { value: "TELD-4", label: "Test of Early Language Development-Fourth Edition (TELD-4)" },
    { value: "TOPV", label: "Test of Preschool Vocabulary (TOPV)" },
    { value: "TOPP", label: "Test of Pretend Play (TOPP)" },
    { value: "WestbyScale", label: "The Westby Symbolic Play Scale" },
    { value: "WABC", label: "Wiig Assessment of Basic Concepts (WABC)" },
  ],
  Fluency: [
    {
      value: "BAB",
      label: "Behavior Assessment Battery for School-Aged Children Who Stutter (BAB)",
    },
    { value: "CALMS", label: "CALMS Assessment Inventory for Children Who Stutter" },
    {
      value: "OASES",
      label: "Overall Assessment of the Speaker’s Experience of Stuttering (OASES)",
    },
    { value: "SSI-3", label: "Stuttering Severity Instrument-Third edition (SSI-3)" },
    { value: "SSI-4", label: "Stuttering Severity Instrument-Fourth edition (SSI-4)" },
    { value: "SSI", label: "Stuttering Severity Scale" },
    { value: "TOCS", label: "Test of Childhood Stuttering (TOCS)" },
  ],
  CL: [
    { value: "CCC-2", label: "Children’s Communication Checklist-2 (CCC-2)" },
    { value: "CASL", label: "Comprehensive Assessment of Spoken Language (CASL)" },
    {
      value: "CASL-2",
      label: "Comprehensive Assessment of Spoken Language-Second Edition (CASL-2)",
    },
    {
      value: "CELF-4",
      label: "Clinical Evaluation of Language Fundamentals-Fourth Edition (CELF-4)",
    },
    {
      value: "CELF-5",
      label: "Clinical Evaluation of Language Fundamentals-Fifth Edition (CELF-5)",
    },
    {
      value: "EarlyFunctionalCommunicationProfile",
      label: "The Early Functional Communication Profile",
    },
    {
      value: "FullertonLanguageTest",
      label: "Fullerton Language Test for Adolescents-Second Edition",
    },
    { value: "FunctionalCommunicationProfile", label: "Functional Communication Profile" },
    {
      value: "FunctionalCommunicationProfileRevised",
      label: "Functional Communication Profile-Revised",
    },
    { value: "OWLS", label: "Oral-Written Language Scales (OWLS)" },
    { value: "OWLS-2", label: "Oral-Written Language Scales-2(OWLS-2)" },
    {
      value: "RESCA-E",
      label: "Receptive, Expressive, & Social Communication Assessment-Elementary (RESCA-E)",
    },
    { value: "RenfrewBusStoryTest", label: "The Renfrew Language Scales Bus Story Test" },
    { value: "TOAL-3", label: "Test of Adolescent and Adult Language-Third Edition (TOAL-3)" },
    { value: "TOAL-4", label: "Test of Adolescent and Adult Language-Fourth Edition (TOAL-4)" },
    { value: "TECEL", label: "Test of Early Communication and Emerging Language (TECEL)" },
    { value: "TOLD-P-3", label: "Test of Language Development-Primary: Third Edition (TOLD-P:3)" },
    { value: "TOLD-P-5", label: "Test of Language Development-Primary: Fifth Edition (TOLD-P:5)" },
    {
      value: "TOLD-I-3",
      label: "Test of Language Development-Intermediate: Third Edition (TOLD-I:3)",
    },
    {
      value: "TOLD-I-5",
      label: "Test of Language Development-Intermediate: Fifth Edition (TOLD-I:5)",
    },
    { value: "TOSS-P", label: "Test of Semantic Skills-Primary (TOSS-P)" },
    {
      value: "TOSS-I-NU",
      label: "Test of Semantic Skills-Intermediate: Normative Update (TOSS-I:NU)",
    },
    { value: "TONI-4", label: "Test of Nonverbal Intelligence – Fourth Edition (TONI-4)" },
  ],
  EL: [
    { value: "TNL", label: "The Test of Narrative Language (TNL)" },
    { value: "ELT", label: "The Expressive Language Test (ELT)" },
    {
      value: "ELT-2-NU",
      label: "The Expressive Language Test: Second Edition: Normative Update (ELT-2:NU)",
    },
    {
      value: "EOWPVT-4",
      label: "Expressive One-Word Picture Vocabulary Test-Fourth Edition (EOWPVT-4)",
    },
    { value: "EVT", label: "Expressive Vocabulary Test (EVT)" },
    { value: "EVT-3", label: "Expressive Vocabulary Test-Third Edition (EVT-3)" },
    { value: "HELPTestElementary", label: "The HELP Test - Elementary" },
    { value: "PEST", label: "Patterned Elicitation Syntax Test (PEST)" },
    {
      value: "ReynellDevelopmentalLanguageScales-3",
      label: "Reynell Developmental Language Scales-3",
    },
    {
      value: "SPELT-3",
      label: "Structured Photographic Expressive Language Test-Third Edition (SPELT-3)",
    },
    { value: "TEEM", label: "Test for Examining Expressive Morphology (TEEM)" },
    { value: "TEXL", label: "The Test of Expressive Language (TEXL)" },
    { value: "WORD-A2", label: "The WORD Test - Adolescent Second Edition (WORD-A2)" },
    { value: "WORD-E2", label: "The WORD Test – Elementary Second Edition (WORD-E2)" },
  ],
  RL: [
    { value: "LPT-R", label: "The Language Processing Test - Revised (LPT-R)" },
    {
      value: "LPT-3-E",
      label: "The Language Processing Test –Third Edition: Elementary (LPT-3:E)",
    },
    { value: "LCT-A", label: "The Listening Comprehension Test-Adolescent (LCT-A)" },
    { value: "LCT-2", label: "The Listening Comprehension Test-Second Edition (LCT-2)" },
    { value: "ListeningTest", label: "The Listening Test" },
    { value: "OPUS", label: "Oral Passage Understanding Scale (OPUS)" },
    { value: "ROWPVT", label: "Receptive One-Word Picture Vocabulary Test (ROWPVT)" },
    {
      value: "ROWPVT-4",
      label: "Receptive One-Word Picture Vocabulary Test- Fourth Edition (ROWPVT-4)",
    },
    { value: "RITLS", label: "Rhode Island Test of Language Structure (RITLS)" },
    { value: "TACL-3", label: "Test of Auditory Comprehension of Language-Third Edition (TACL-3)" },
    {
      value: "TACL-4",
      label: "Test of Auditory Comprehension of Language-Fourth Edition (TACL-4)",
    },
    { value: "TokenTestChildren", label: "Token Test for Children" },
    { value: "TTFC-2", label: "Token Test for Children: Second Edition (TTFC-2)" },
  ],
  Vocabulary: [
    { value: "ASSET", label: "Assessing Semantic Skills through Everyday Themes (ASSET)" },
    {
      value: "CREVT-2",
      label: "Comprehensive Receptive and Expressive Vocabulary Test-second edition (CREVT-2)",
    },
    {
      value: "CREVT-3",
      label: "Comprehensive Receptive and Expressive Vocabulary Test-Third Edition (CREVT-3)",
    },
    { value: "EOWPVT", label: "Expressive One-Word Picture Vocabulary Test (EOWPVT)" },
    {
      value: "EOWPVT-UE",
      label: "Expressive One-Word Picture Vocabulary Test-Upper Extension (EOWPVT-UE)",
    },
    { value: "MAVA", label: "Montgomery Assessment of Vocabulary Acquisition (MAVA)" },
    { value: "PPVT-3", label: "Peabody Picture Vocabulary Test-3 (PPVT-3)" },
    { value: "PPVT-5", label: "Peabody Picture Vocabulary Test-Fifth Edition (PPVT-5)" },
  ],
  SA: [
    { value: "APP-Sp", label: "Assessment of Phonological Processes, Spanish (APP -Sp)" },
    { value: "Austin", label: "Austin Spanish Articulation Test (Austin)" },
    { value: "BESA", label: "Bilingual English Spanish Assessment (BESA)" },
    { value: "BSM", label: "Bilingual Syntax Measure, 1 or 2 (BSM, 1 or 2)" },
    { value: "BVAM", label: "Bilingual Vocabulary Assessment Measure (BVAM)" },
    { value: "BTBC-R-SP", label: "Boehm Test of Basic Concepts-Revised, Spanish (BTBC-R-SP)" },
    { value: "BBCS-R-Sp", label: "Bracken Basic Concepts Scale-Revised-Spanish (BBCS-R-Sp)" },
    {
      value: "CAAP-S",
      label: "Clinical Assessment of Articulation and Phonology-Spanish Edition (CAAP-S)",
    },
    {
      value: "CELF-3-SP",
      label: "Clinical Evaluation of Language Fundamentals-3, Spanish (CELF-3-SP)",
    },
    { value: "CPAC-S", label: "Contextual Probes of Articulation Competence – Spanish (CPAC-S)" },
    { value: "DRLST", label: "Del Rio Language Screening Test (DRLST)" },
    { value: "DosAmigos", label: "Dos Amigos Verbal Language Scales" },
    {
      value: "EOWPVT-UE-Revised-Sp",
      label: "Expressive One-Word Picture Vocabulary Test-Upper Extension-Revised, Spanish",
    },
    { value: "EOWPVT-2000-Sp", label: "Expressive One-Word Picture Vocabulary Test-2000, Spanish" },
    {
      value: "EOWPVT-4:SB",
      label:
        "Expressive One-Word Picture Vocabulary Test-Fourth Edition: Spanish Bilingual Edition (EOWPVT-4:SB)",
    },
    { value: "LAS", label: "Language Assessment Scale (LAS)" },
    { value: "LanguageSampleSpanish", label: "Language Sample - Spanish" },
    { value: "MEDA", label: "Medida Española de Articulación (MEDA)" },
    { value: "MVT", label: "Multicultural Vocabulary Test (MVT)" },
    { value: "PLAI-Sp", label: "Preschool Language Assessment Instrument-Spanish (PLAI-Sp)" },
    { value: "PLS-3-Sp", label: "Preschool Language Scale-3, Spanish (PLS-3, Sp)" },
    { value: "PLS-5-Sp", label: "Preschool Language Scale-5, Spanish (PLS-5, Sp)" },
    {
      value: "PEOPLE",
      label: "Pruebas de Expresión Oral y Percepción de La Lenguaje Española (PEOPLE)",
    },
    { value: "PDIL", label: "Prueba del Desarrollo Inicial del Lenguaje (PDIL)" },
    {
      value: "ROWPVT-R-Sp",
      label: "Receptive One-Word Picture Vocabulary Test, Revised-Spanish (ROWPVT-R-Sp)",
    },
    { value: "ROWPVT-2000-Sp", label: "Receptive One-Word Picture Vocabulary Test-2000, Spanish" },
    {
      value: "ROWPVT-4:SB",
      label:
        "Receptive One-Word Picture Vocabulary Test-Fourth Edition: Spanish Bilingual Edition (ROWPVT-4:SB)",
    },
    { value: "STSG", label: "Screening Test of Spanish Grammar (STSG)" },
    { value: "SAM", label: "Spanish Articulation Measure (SAM)" },
    { value: "SEVT", label: "Spanish Expressive Vocabulary Test (SEVT)" },
    {
      value: "SLAP",
      label: "Spanish Language Assessment Procedures: A Communication Skills Inventory (SLAP)",
    },
    {
      value: "SPELT-P-Sp",
      label: "Spanish Structured Photographic Expressive Language Test-Preschool (SPELT-P-Sp)",
    },
    {
      value: "S:SPELT-2",
      label: "Spanish Structured Photographic Expressive Language Test-2 (S:SPELT-2)",
    },
    {
      value: "S:SPELT-3",
      label: "Spanish Structured Photographic Expressive Language Test-3 (S:SPELT-3)",
    },
    { value: "STAMP", label: "Spanish Test for Assessing Morphologic Production (STAMP)" },
    {
      value: "TELD-3:S",
      label: "Test of Early Language Development-Third Edition:Spanish (TELD-3:S)",
    },
    { value: "TVIP", label: "Test de Vocabulario de Imágenes, Peabody (TVIP)" },
    {
      value: "TAPS-3:SBE",
      label: "Test of Auditory Processing Skills, 3rd Edition: Spanish/Bilingual (TAPS-3:SBE)",
    },
    { value: "TTRV", label: "Toronto Test of Receptive Vocabulary (TTRV)" },
    { value: "WABC-S", label: "Wiig Assessment of Basic Concepts-Spanish (WABC-S)" },
  ],
  Voice: [],
  AT: [],
  FS: [],
  AAC: [],
};

const HeaderComp = ({ title }) => {
  return (
    <Grid item md={12} sm={12} xs={12}>
      <Divider textAlign="left">
        <Typography variant="overline" color="primary" fontWeight={800} fontSize={14}>
          {title}
        </Typography>
      </Divider>
    </Grid>
  );
};

export default AACP;
