import checkout from "./form";

const {
  formField: { email, password, lastName, firstName },
} = checkout;

const initialValues = {
  [email.name]: "",
  [password.name]: "",
  [firstName.name]: "",
  [lastName.name]: "",
};

export default initialValues;
