import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import logo from "../../assets/slpbox-logo-full-black.svg";
import { fetchSlotSelection, postSlotSelection } from "redux/actions/meetingInviteAction";
import { useParams } from "react-router-dom";
import moment from "moment";
import SMessageCard from "components/SMessageCard";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
// meeting-invite/${payload.id}/${payload.email}/slot-selection

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://slpbox.com">
        SLPBOX, LLC.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function InvitedPagePublic() {
  const dispatch = useDispatch();
  const { meetingId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const [selectedSlots, setSelectedSlots] = useState([]);
  const { response } = useSelector((state) => state.meetingInviteReducer.slots);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    let payload = {
      meetingId: meetingId,
      email: email,
    };
    dispatch(fetchSlotSelection(payload));
  }, [meetingId, email, dispatch]);

  const handleSlotSelect = (slot) => {
    // Check if the slot is already selected
    const isSlotSelected = selectedSlots.some((selectedSlot) => selectedSlot.id === slot.id);

    if (isSlotSelected) {
      // If already selected, remove it from the list
      setSelectedSlots((prevSelectedSlots) =>
        prevSelectedSlots.filter((selectedSlot) => selectedSlot.id !== slot.id)
      );
    } else {
      // If not selected, add it to the list
      setSelectedSlots((prevSelectedSlots) => [...prevSelectedSlots, slot]);
    }
  };

  function handleSubmit() {
    const payload = {
      email: email,
      selectedSlots: selectedSlots,
    };
    setLoading(true);
    dispatch(postSlotSelection(meetingId, payload))
      .then((response) => {
        if (response.success) {
          setLoading(false);
          setConfirmation(true);
        }
      })
      .catch(() => setLoading(false));
  }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={2}
        md={4}
        sx={{
          backgroundColor: "#171d26",
          width: "100%",
          display: "flex",
          alignItems: "center", // Vertical centering
          justifyContent: "center", // Horizontal centering
          textAlign: "center", // Center content within the grid item
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <img src={logo} alt="brandlogo" style={{ width: "15rem" }} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={10} md={8} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            // display: 'flex',
            // flexDirection: 'column',
          }}
        >
          <div>
            <Box mb={10}>
              <Typography variant="h3" color={"secondary"}>
                {response?.eventTitle}
              </Typography>
              <Typography variant="h6" color="text.secondary">
                Description: {response?.description}
              </Typography>
            </Box>
            {confirmation && (
              <Box sx={{ textAlign: "center" }}>
                <SMessageCard
                  icon={<SentimentSatisfiedAltIcon />}
                  text="Your request has been successfully submitted!"
                  subText="We will promptly notify you of a meeting time if it aligns with the availability of other members. Thank you!"
                />
              </Box>
            )}
            {!confirmation && (
              <>
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h4" mb={2} color="text.secondary">
                    Choose your availability
                  </Typography>

                  {response?.slots.map((slot) => (
                    <Box
                      key={slot.id}
                      onClick={() => handleSlotSelect(slot)}
                      mb={1}
                      sx={{
                        border: 1,
                        borderColor: "grey.300",
                        borderRadius: "10px",
                        transition: "transform 0.3s",
                        padding: "18px 20px",
                        // width: '40em',
                        backgroundColor: selectedSlots.some(
                          (selectedSlot) => selectedSlot.id === slot.id
                        )
                          ? "#96f8b594"
                          : "white",

                        "&:hover": {
                          transform: "scale(1.01)",
                          boxShadow: "0px 0px 10px 0px rgb(13 183 242 / 9%)",
                          cursor: "pointer",
                          borderColor: "grey.500",
                        },
                      }}
                    >
                      <Typography variant="body1" fontWeight={600}>
                        Date: {moment(slot.start).format("ll")}
                      </Typography>
                      <Typography variant="subtitle1">
                        Time: {moment(slot.start).format("LT")} - {moment(slot.end).format("LT")}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box textAlign={"center"} mt={5}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSubmit}
                    disabled={selectedSlots.length === 0}
                  >
                    Submit
                  </Button>
                </Box>
              </>
            )}
          </div>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
