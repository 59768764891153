import React from "react";
import { Grid } from "@mui/material";
import FormField from "components/FormField";
import OptionButton from "../components/OptionButton";
import { getIn } from "formik";


const ReportSummary = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        report_summary_Q_01,
        report_summary_Q_01_other,
        report_summary_Q_02,
        report_summary_Q_02_other,
        report_summary_Q_03,
        report_summary_Q_03_other,
        report_summary_Q_04,
        report_summary_Q_04_other,
        report_summary_Q_05,
        report_summary_Q_06,
        report_summary_doc_description
    } = formField.reportSummary;

    return (
        <Grid container spacing={2} p={2}>
            <OptionButton
                type="radio"
                row={false}
                label={report_summary_Q_01.label}
                name={report_summary_Q_01.name}
                options={props.options?.report_summary_Q_01}
                value={values.reportSummary.report_summary_Q_01}
                onChange={(name, value) => setFieldValue(report_summary_Q_01.path, value)}
                additionalInfoLabel={report_summary_Q_01_other.label}
                additionalInfoName={report_summary_Q_01_other.path}
                additionalInfoValue={values.reportSummary.report_summary_Q_01_other}
                setFieldValue={setFieldValue}
                toolTipText={report_summary_Q_01.toolTipText}
                error={errors.reportSummary?.report_summary_Q_01 && touched.reportSummary?.report_summary_Q_01}
                triggerValues={[2, 3, 4]} // Values that should trigger the additional input field
            />
            <OptionButton
                type="radio"
                row={false}
                label={report_summary_Q_02.label}
                name={report_summary_Q_02.name}
                options={props.options?.report_summary_Q_02}
                value={values.reportSummary.report_summary_Q_02}
                onChange={(name, value) => setFieldValue(report_summary_Q_02.path, value)}
                additionalInfoLabel={report_summary_Q_02_other.label}
                additionalInfoName={report_summary_Q_02_other.path}
                additionalInfoValue={values.reportSummary.report_summary_Q_02_other}
                setFieldValue={setFieldValue}
                toolTipText={report_summary_Q_02.toolTipText}
                error={errors.reportSummary?.report_summary_Q_02 && touched.reportSummary?.report_summary_Q_02}
                triggerValues={[2, 3, 4]} // Values that should trigger the additional input field
            />

            <OptionButton
                row={false}
                type="radio"

                label={report_summary_Q_03.label}
                name={report_summary_Q_03.name}
                options={props.options?.report_summary_Q_03}
                value={values.reportSummary.report_summary_Q_03}
                onChange={(name, value) => setFieldValue(report_summary_Q_03.path, value)}
                additionalInfoLabel={report_summary_Q_03_other.label}
                additionalInfoName={report_summary_Q_03_other.path}
                additionalInfoValue={values.reportSummary.report_summary_Q_03_other}
                setFieldValue={setFieldValue}
                toolTipText={report_summary_Q_03.toolTipText}
                error={errors.reportSummary?.report_summary_Q_03 && touched.reportSummary?.report_summary_Q_03}
                triggerValues={[2, 3, 4]} // Values that should trigger the additional input field
            />
            <OptionButton
                row={false}
                type="radio"
                label={report_summary_Q_04.label}
                name={report_summary_Q_04.name}
                options={props.options?.report_summary_Q_04}
                value={values.reportSummary.report_summary_Q_04}
                onChange={(name, value) => setFieldValue(report_summary_Q_04.path, value)}
                additionalInfoLabel={report_summary_Q_04_other.label}
                additionalInfoName={report_summary_Q_04_other.path}
                additionalInfoValue={values.reportSummary.report_summary_Q_04_other}
                setFieldValue={setFieldValue}
                toolTipText={report_summary_Q_04.toolTipText}
                error={errors.reportSummary?.report_summary_Q_04 && touched.reportSummary?.report_summary_Q_04}
                triggerValues={[2, 3, 4]} // Values that should trigger the additional input field
            />
            <OptionButton
                row={false}
                type="radio"
                label={report_summary_Q_05.label}
                name={report_summary_Q_05.name}
                options={props.options?.report_summary_Q_05}
                value={values.reportSummary?.report_summary_Q_05 ?? ''} // Provide a default value if undefined
                onChange={(name, value) => setFieldValue(report_summary_Q_05.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={report_summary_Q_05.toolTipText}
                error={errors.reportSummary?.report_summary_Q_05 && touched.reportSummary?.report_summary_Q_05}
            />
            <Grid item xs={12} sm={12} lg={12}>
                <FormField
                    type={report_summary_Q_06.type}
                    label={report_summary_Q_06.label}
                    name={report_summary_Q_06.name}
                    size="small"
                    isRequired={false}
                    rows={2}
                    multi
                    nested={true}
                    path={report_summary_Q_06.path}
                    onChange={(e) => setFieldValue(report_summary_Q_06.path, e.target.value)}
                    value={values.reportSummary.report_summary_Q_06}
                    placeholder={report_summary_Q_06.placeholder}
                    error={getIn(errors, report_summary_Q_06.path) && getIn(touched, report_summary_Q_06.path)}
                />
            </Grid>
        </Grid>
    );
};

export default ReportSummary;
