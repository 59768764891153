import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Container } from "@mui/material";
import PersonalInformation from "./components/PersonalInformation";
import Overview from "./components/Overview";
import { useSelector } from "react-redux";
import Assessments from "./components/Assessments";
import SLoadingIndicator from "components/SLoadingIndicator";
import CollebrationSLPA from "./components/CollebrationSLPA";
export default function ViewStudent() {
  const { studentDetails } = useSelector((state) => ({
    studentDetails: state.studentReducer.studentById,
  }));

  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (studentDetails) {
      setLoading(false);
    }
  }, [studentDetails]);

  const getContentForSelectedTab = () => {
    switch (selectedTab) {
      case 0:
        return <Overview data={studentDetails} />;
      case 1:
        return <PersonalInformation data={studentDetails} />;
      case 2:
        return <Assessments data={studentDetails} />;
      case 3:
        return <CollebrationSLPA data={studentDetails} />;
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="lg">
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="secondary tabs example"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
      >
        <Tab label="Overview" style={{ fontWeight: 500 }} />
        <Tab label="Student Information" style={{ fontWeight: 500 }} />
        <Tab label="Data Tracking History" style={{ fontWeight: 500 }} />
        <Tab label="SLPA Collobration " style={{ fontWeight: 500 }} />

      </Tabs>
      <Box mt={5}>{loading ? <SLoadingIndicator /> : getContentForSelectedTab()}</Box>
    </Container>
  );
}
