import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import FormField from "components/FormField";
import OptionButton from "../components/OptionButton";
import moment from "moment";

const TeacherInput = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        teacher_input_Q_01,
        teacher_input_Q_02,
        teacher_input_Q_03,
        teacher_input_Q_03_other,
        teacher_input_Q_04,
        teacher_input_Q_05,
        teacher_input_Q_06,
        teacher_input_Q_06_other,
        teacher_input_Q_07,
        teacher_input_Q_07_other,
        teacher_input_Q_08,
        teacher_input_Q_08_other,
        teacher_input_Q_09,
        teacher_input_Q_10,
        teacher_input_Q_11,
        teacher_input_Q_12,
        teacher_input_Q_13,
        teacher_input_Q_14,
        teacher_input_Q_15,
        teacher_input_Q_16,
        teacher_input_Q_17_A,
        teacher_input_Q_17_B,
        teacher_input_Q_17_C,
        teacher_input_Q_18,
        teacher_input_Q_19,
        teacher_input_Q_19_other,
        teacher_input_Q_20,
        teacher_input_Q_20_other,
        teacher_input_Q_21,
        teacher_input_Q_21_other,
        teacher_input_Q_22,
        teacher_input_Q_22_other,
        teacher_additional_info,
        teacher_doc_description,
    } = formField.teacherInput;

    const replaceStudentName = (label) => {
        const studentName = values?.generalInformation?.firstName || '[student info not-found]';
        return label.replace('STUDENT', studentName);
    };
    // Render the rich text for background information
    return (
        <Grid container spacing={2} p={2}>
            {/* <HeaderComp title="Education History" /> */}
            <Grid item xs={12} sm={6} lg={6}>
                <FormField
                    type={teacher_input_Q_01.type}
                    label={teacher_input_Q_01.label}
                    name={teacher_input_Q_01.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(teacher_input_Q_01.path, value)}
                    value={values.teacherInput.teacher_input_Q_01}
                    placeholder={teacher_input_Q_01.placeholder}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField
                    type={teacher_input_Q_02.type}
                    label={teacher_input_Q_02.label}
                    name={teacher_input_Q_02.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(teacher_input_Q_02.path, value)}
                    value={values.teacherInput.teacher_input_Q_02}
                    placeholder={teacher_input_Q_02.placeholder}
                />
            </Grid>

            <OptionButton
                label={teacher_input_Q_03.label}
                type="radio"
                name={teacher_input_Q_03.name}
                options={props.options?.teacher_info_collected}
                value={values.teacherInput.teacher_input_Q_03}
                onChange={(name, value) => setFieldValue(teacher_input_Q_03.path, value)}
                additionalInfoLabel={teacher_input_Q_03_other.label}
                additionalInfoName={teacher_input_Q_03_other.path}
                additionalInfoValue={values.teacherInput.teacher_input_Q_03_other}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_03.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_03 && touched.backgroundInformation?.edu_history_prev_schools}
                triggerValues={[4]} // Single trigger value
            />

            <Grid item xs={12} sm={6} lg={4}>
                <FormField
                    type={teacher_input_Q_04.type}
                    label={teacher_input_Q_04.label}
                    name={teacher_input_Q_04.name}
                    size="small"
                    onChange={(e) => {
                        const date = moment(e.target.value).startOf('day').utc().toISOString();
                        setFieldValue(teacher_input_Q_04.path, date);
                    }}
                    value={moment(values.teacherInput.teacher_input_Q_04).format('YYYY-MM-DD')}
                    placeholder={teacher_input_Q_04.placeholder}
                />

            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
                <FormField
                    type={teacher_input_Q_05.type}
                    label={teacher_input_Q_05.label}
                    name={teacher_input_Q_05.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(teacher_input_Q_05.path, value)}
                    value={values.teacherInput.teacher_input_Q_05}
                    placeholder={teacher_input_Q_05.placeholder}
                />
            </Grid>
            <OptionButton
                label={teacher_input_Q_06.label}
                type="checkbox"
                row={false}
                name={teacher_input_Q_06.name}
                options={props.options?.receptive_options}
                value={values.teacherInput.teacher_input_Q_06}
                onChange={(name, value) => setFieldValue(teacher_input_Q_06.path, value)}
                additionalInfoLabel={teacher_input_Q_06_other.label}
                additionalInfoName={teacher_input_Q_06_other.path}
                additionalInfoValue={values.teacherInput.teacher_input_Q_06_other}
                //TODO ADD OTHER FIELD
                setFieldValue={setFieldValue}
                triggerValues={[7]} // Single trigger value
                toolTipText={teacher_input_Q_06.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_06 && touched.teacherInput?.teacher_input_Q_06}
            />
            <OptionButton
                label={teacher_input_Q_07.label}
                type="checkbox"
                row={false}
                name={teacher_input_Q_07.name}
                options={props.options?.expressive_options}
                value={values.teacherInput.teacher_input_Q_07}
                onChange={(name, value) => setFieldValue(teacher_input_Q_07.path, value)}
                additionalInfoLabel={teacher_input_Q_07_other.label}
                additionalInfoName={teacher_input_Q_07_other.path}
                additionalInfoValue={values.teacherInput.teacher_input_Q_07_other}
                setFieldValue={setFieldValue}
                triggerValues={[7]} // Single trigger value
                toolTipText={teacher_input_Q_07.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_07 && touched.teacherInput?.teacher_input_Q_07}
            />
            <OptionButton
                label={teacher_input_Q_08.label}
                type="checkbox"
                row={false}
                name={teacher_input_Q_08.name}
                options={props.options?.articulation_options}
                value={values.teacherInput.teacher_input_Q_08}
                onChange={(name, value) => setFieldValue(teacher_input_Q_08.path, value)}
                additionalInfoLabel={teacher_input_Q_08_other.label}
                additionalInfoName={teacher_input_Q_08_other.path}
                additionalInfoValue={values.teacherInput.teacher_input_Q_08_other}
                setFieldValue={setFieldValue}
                triggerValues={[7]} // Single trigger value
                toolTipText={teacher_input_Q_08.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_08 && touched.teacherInput?.teacher_input_Q_08}
            />
            <OptionButton
                label={teacher_input_Q_09.label}
                type="checkbox"
                row={true}
                name={teacher_input_Q_09.name}
                options={props.options?.fluency_options}
                value={values.teacherInput.teacher_input_Q_09}
                onChange={(name, value) => setFieldValue(teacher_input_Q_09.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_09.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_09 && touched.teacherInput?.teacher_input_Q_09}
            />
            <OptionButton
                label={teacher_input_Q_10.label}
                type="checkbox"
                row={true}
                name={teacher_input_Q_10.name}
                options={props.options?.social_interaction_options}
                value={values.teacherInput.teacher_input_Q_10}
                onChange={(name, value) => setFieldValue(teacher_input_Q_10.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_10.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_10 && touched.teacherInput?.teacher_input_Q_10}
            />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_11.label)}
                type="radio"
                row={true}
                name={teacher_input_Q_11.name}
                options={props.options?.skills_options}
                value={values.teacherInput.teacher_input_Q_11}
                onChange={(name, value) => setFieldValue(teacher_input_Q_11.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_11.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_11 && touched.teacherInput?.teacher_input_Q_11}
            />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_12.label)}
                type="radio"
                row={true}
                name={teacher_input_Q_12.name}
                options={props.options?.skills_options}
                value={values.teacherInput.teacher_input_Q_12}
                onChange={(name, value) => setFieldValue(teacher_input_Q_12.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_12.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_12 && touched.teacherInput?.teacher_input_Q_12}
            />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_13.label)}
                type="radio"
                row={true}
                name={teacher_input_Q_13.name}
                options={props.options?.skills_options}
                value={values.teacherInput.teacher_input_Q_13}
                onChange={(name, value) => setFieldValue(teacher_input_Q_13.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_13.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_13 && touched.teacherInput?.teacher_input_Q_13}
            />
            <HeaderComp title="Class Performance and Behavior" />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_14.label)}
                type="radio"
                row={true}
                name={teacher_input_Q_14.name}
                options={props.options?.behave_options}
                value={values.teacherInput.teacher_input_Q_14}
                onChange={(name, value) => setFieldValue(teacher_input_Q_14.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_14.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_14 && touched.teacherInput?.teacher_input_Q_14}
            />

            <HeaderComp title="Social/Emotional Behavior" />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_15.label)}
                type="radio"
                row={true}
                name={teacher_input_Q_15.name}
                options={props.options?.behave_options}
                value={values.teacherInput.teacher_input_Q_15}
                onChange={(name, value) => setFieldValue(teacher_input_Q_15.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_15.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_15 && touched.teacherInput?.teacher_input_Q_15}
            />
            <HeaderComp title="Gross and Fine Motor Skills/Handwriting/Penmanship" />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_17_A.label)}
                type="radio"
                name={teacher_input_Q_17_A.name}
                options={props.options?.behave_options}
                value={values.teacherInput.teacher_input_Q_17_A}
                onChange={(name, value) => setFieldValue(teacher_input_Q_17_A.path, value)}
                row={true}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_17_A.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_17_A && touched.teacherInput?.teacher_input_Q_17_A}
            />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_17_B.label)}
                type="radio"
                name={teacher_input_Q_17_B.name}
                options={props.options?.behave_options}
                value={values.teacherInput.teacher_input_Q_17_B}
                onChange={(name, value) => setFieldValue(teacher_input_Q_17_B.path, value)}
                row={true}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_17_B.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_17_B && touched.teacherInput?.teacher_input_Q_17_B}
            />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_17_C.label)}
                type="radio"
                name={teacher_input_Q_17_C.name}
                options={props.options?.behave_options}
                value={values.teacherInput.teacher_input_Q_17_C}
                onChange={(name, value) => setFieldValue(teacher_input_Q_17_C.path, value)}
                row={true}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_17_C.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_17_C && touched.teacherInput?.teacher_input_Q_17_C}
            />
            <HeaderComp title="Other Information" />
            <OptionButton
                label={teacher_input_Q_16.label}
                type="radio"
                name={teacher_input_Q_16.name}
                options={props.options?.attendance_options}
                value={values.teacherInput.teacher_input_Q_16}
                onChange={(name, value) => setFieldValue(teacher_input_Q_16.path, value)}
                row={true}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_16.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_16 && touched.teacherInput?.teacher_input_Q_16}
            />
            <OptionButton
                label={teacher_input_Q_18.label}
                type="radio"
                name={teacher_input_Q_18.name}
                options={props.options?.performance_options}
                value={values.teacherInput.teacher_input_Q_18}
                onChange={(name, value) => setFieldValue(teacher_input_Q_18.path, value)}
                row={true}
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_18.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_18 && touched.teacherInput?.teacher_input_Q_18}
            />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_19.label)}
                type="radio"
                name={teacher_input_Q_19.name}
                options={props.options?.condition_options}
                value={values.teacherInput.teacher_input_Q_19}
                onChange={(name, value) => setFieldValue(teacher_input_Q_19.path, value)}
                row={true}
                additionalInfoLabel={teacher_input_Q_19_other.label}
                additionalInfoName={teacher_input_Q_19_other.path}
                additionalInfoValue={values.teacherInput.teacher_input_Q_19_other}
                triggerValues={[1]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_19.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_19 && touched.teacherInput?.teacher_input_Q_19}
            />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_20.label)}
                row={true}
                type="radio"
                name={teacher_input_Q_20.name}
                options={props.options?.condition_options}
                value={values.teacherInput.teacher_input_Q_20}
                onChange={(name, value) => setFieldValue(teacher_input_Q_20.path, value)}
                additionalInfoLabel={teacher_input_Q_20_other.label}
                additionalInfoName={teacher_input_Q_20_other.path}
                additionalInfoValue={values.teacherInput.teacher_input_Q_20_other}
                triggerValues={[1]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_20.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_20 && touched.teacherInput?.teacher_input_Q_20}
            />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_21.label)}
                row={true}
                type="radio"
                name={teacher_input_Q_21.name}
                options={props.options?.condition_options}
                value={values.teacherInput.teacher_input_Q_21}
                onChange={(name, value) => setFieldValue(teacher_input_Q_21.path, value)}
                additionalInfoLabel={teacher_input_Q_21_other.label}
                additionalInfoName={teacher_input_Q_21_other.path}
                additionalInfoValue={values.teacherInput.teacher_input_Q_21_other}
                triggerValues={[1]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_21.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_21 && touched.teacherInput?.teacher_input_Q_21}
            />
            <OptionButton
                label={replaceStudentName(teacher_input_Q_22.label)}
                type="radio"
                name={teacher_input_Q_22.name}
                options={props.options?.condition_options}
                value={values.teacherInput.teacher_input_Q_22}
                onChange={(name, value) => setFieldValue(teacher_input_Q_22.path, value)}
                row={true}
                additionalInfoLabel={teacher_input_Q_22_other.label}
                additionalInfoName={teacher_input_Q_22_other.path}
                additionalInfoValue={values.teacherInput.teacher_input_Q_22_other}
                triggerValues={[1]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={teacher_input_Q_22.toolTipText}
                error={errors.teacherInput?.teacher_input_Q_22 && touched.teacherInput?.teacher_input_Q_22}
            />
            <Grid item xs={12} sm={12} lg={12}>
                <FormField
                    type={teacher_additional_info.type}
                    label={teacher_additional_info.label}
                    name={teacher_additional_info.name}
                    size="small"
                    rows={3}
                    multiline
                    onChange={(name, value) => setFieldValue(teacher_additional_info.path, value)}
                    value={values.teacherInput.teacher_additional_info}
                    placeholder={teacher_additional_info.placeholder}
                />
            </Grid>
        </Grid>
    );
};

const HeaderComp = ({ title }) => {
    return (
        <Grid item md={12} sm={12} xs={12}>
            <Divider textAlign="left">
                <Typography variant="overline" color="text.secondary" fontWeight={800} fontSize={14}>
                    {title}
                </Typography>
            </Divider>
        </Grid>
    );
};
export default TeacherInput;
