import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import commonStyles from '../../commonStyles';


const ReportTitle = ({ title, subTitle }) => (
    <View style={commonStyles.headerContainer}>
        <Image style={commonStyles.logo} src={'https://fremontunified.org/wp-content/uploads/2023/05/fusd-160-2.png'} />
        <View style={commonStyles.headerBox}>
            <Text style={commonStyles.header}>{'Fremont Unified School District'}</Text>
            <Text style={commonStyles.header}>{subTitle}</Text>
        </View>
    </View>
);

export default ReportTitle