import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Stack, Box, Button, DialogActions, DialogContent, Container } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { initialValues, form, validations } from "./AddEvent/schemas";
import { Formik, Form } from "formik";
import AddStudent from "./AddEvent";
import GeneralDialog from "components/GeneralDialog";
import { createSchedule, fetchAllEvents, editScheduler } from "redux/actions/scheduleAction";
import AddIcon from "@mui/icons-material/Add";
import { showNotification } from "redux/actions/notificationAction";
import { INFO } from "../../redux/constants/notificationType";
import { Settings } from "@mui/icons-material";
import moment from "moment";
import BoxButton from "components/BoxButton";
import CalendarSettings from "./CalendarSettings";
import ActionHeader from "components/MainLayout/ActionHeader";
import { useAuth0 } from "@auth0/auth0-react";
import 'moment-timezone'; // Import moment-timezone for timezone support
import { getCurrentSchoolYear } from "components/Common/getSchoolYearOptions";
import FeedbackButton from "components/Common/FeedbackButton";
export default function Scheduler() {
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const [events, setEvents] = useState([]);
  const [userEvents, setUserEvents] = useState([]);
  const [combinedEvents, setCombinedEvents] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]);


  const [open, setOpen] = useState(false);
  const calendarRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [editInfo, setEditInfo] = useState({});

  const { formId, formField } = form;
  const currentValidation = validations[0];
  const headerToolbar = {
    right: "prev,next today",
    left: "title",
    center: "dayGridMonth,timeGridWeek,timeGridDay",
  };


  const [currentView, setCurrentView] = useState('dayGridMonth');
  const [currentRange, setCurrentRange] = useState({ start: '', end: '' });

  
  function newForm(formData) {
    return <AddStudent formData={formData} />;
  }

  function closeDialog() {
    setOpen(!open);
  }

  
  

  function handleOpen(data) {
    setOpen(true);
  }

  function handleEdit(data) {
    if (data?.event?._def?.extendedProps.type !== "google") {
      setEditInfo(data.event);
      setOpen(true);
    } else {
      showNotification({
        message: "You are not allowed to modify google events.",
        type: INFO,
      });
    }
  }

  console.log('user', user.assigned_role === 'assistant');
  // function reloadEvents() {
  //   setLoading(true);
  //   dispatch(fetchAllEvents()).then((response) => {
  //     console.log('response', response.response)
  //     if (response && response?.response.length > 0) {
  //       const modifiedEvents =
  //         response &&
  //         response?.response?.map((event) => ({
  //           ...event,
  //           title: event.eventTitle,
  //           start: event.startTime,
  //           end: event.endTime,
  //           // type: "google", // You can set the type as needed
  //         }));

  //       const allEvents = [...modifiedEvents];

  //       setLoading(false);
  //       setUserEvents(allEvents);
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // }

 
  



  useEffect(() => {
    if (!open) {
      setEditInfo({});
    }
  }, [open]);



  useEffect(() => {
    if (events.length > 0 || userEvents.length > 0 || blockedDates.length > 0) {
      const modifiedBlackoutDays = blockedDates?.map((day) => ({
        title: day.title,
        allDay: true,
        start: day.date,
        editable: false,
        color: "#0e1217", // Assuming 'black' is the color for all blackout days
      }));
      setCombinedEvents([...events, ...userEvents, ...modifiedBlackoutDays]);
    }
  }, [events, userEvents, blockedDates]);

  const submitForm = (values, actions) => {
    const { startTime, duration } = values;

    const startMoment = moment(startTime);

    // Validate startTime
    if (!startMoment.isValid()) {
      throw new Error('Invalid start time');
    }

    // Calculate endTime based on duration in minutes
    const endMoment = startMoment.clone().add(Number(duration), 'minutes');

    // Convert startTime and endTime to UTC
    const startUtc = startMoment.utc().toISOString();
    const endUtc = endMoment.utc().toISOString();

    let payload = values;
    payload.recurrencePattern = values.recurrencePattern.id;
    payload.studentId = values.studentId._id;
    payload.startTime = startUtc
    payload.endTime = endUtc
    payload.schoolYear = getCurrentSchoolYear()
    if (values.eventType) {
      payload.backgroundColor = values.eventType.color;
      payload.eventType = values.eventType.id;
    }
    payload.goals = values.goals;
    payload.timeZone = user.timezone;

    setLoading(true);
    dispatch(createSchedule(values))
      .then(() => {
        setLoading(false);
        actions.setTouched({});
        actions.setSubmitting(false);
        actions.resetForm();
        reloadEvents(currentRange.start, currentRange.end, getCurrentSchoolYear() )
        setOpen(false);
      })
      .catch((err) => setLoading(false));
  };

  const handleEventDrop = (info) => {
    const event = info.event;
    let payload = {};
    let id = event._def.extendedProps._id;

    // FullCalendar returns dates in local time zone by default, convert to UTC
    let startTime = event.start.toISOString(); // Convert to UTC in ISO format
    let endTime = event.end.toISOString(); // Convert to UTC in ISO format

    payload.timeZone = user.timezone; // Assuming you still want to store the timezone
    payload.startTime = startTime;
    payload.endTime = endTime;

    dispatch(editScheduler(id, payload));
  };


  const [openGoalBuilder, setOpenGoalBuider] = useState(false);
  const currentDate = new Date().toISOString(); // Get current date in "YYYY-MM-DD" format
  const currentTime = new Date().toLocaleTimeString("en-US", { hour12: false }); // Get current time in "HH:mm" format

  // Settings

  const [openSettings, setOpenSettings] = useState(false);

  function handleCloseSettings() {
    setOpenSettings(false);
  }

  function handleOpenSettings() {
    setOpenSettings(true);
  }


  // Render Calender Data Using DataSets

  const handleDatesSet = (dateInfo) => {
    const { start, end, view } = dateInfo;
    const startISO = start.toISOString();
    const endISO = end.toISOString();
  
    // Compare with current range and view type to avoid unnecessary updates
    if (
      startISO !== currentRange.start || 
      endISO !== currentRange.end || 
      view.type !== currentView
    ) {
      setCurrentRange({ start: startISO, end: endISO });
      setCurrentView(view.type);
      const schoolYear =  getCurrentSchoolYear(); // Example school year
      reloadEvents(startISO, endISO, schoolYear);
    }
  };
  
  const reloadEvents = (start, end, schoolYear) => {
    setLoading(true);
    dispatch(fetchAllEvents(start, end, schoolYear)).then((response) => {
      console.log('response', response)
      if (response && response?.response?.length > 0) {
        const modifiedEvents = response?.response.map((event) => ({
          ...event,
          title: event.eventTitle,
          start: event.startTime,
          end: event.endTime,
        }));
        setLoading(false);
        setUserEvents(modifiedEvents);
      } else {
        setLoading(false);
        setUserEvents([]); // Clear events if none are returned
      }
    });
  };



  function settingsForm(formData) {
    return <CalendarSettings formData={formData} />;
  }

  return (
    <React.Fragment>
      {user && user.assigned_role !== 'assistant' && 
      <ActionHeader>
      <Box style={{ display: "flex", alignItems: "center" }}>
        {/* <GoogleSync setEvents={setEvents} events={events} /> */}
      </Box>
      <Box ml={6}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <FeedbackButton />
          <Button
            variant="standard"
            size="medium"
            color="secondary"
            onClick={handleOpenSettings}
            startIcon={<Settings />}
          >
            Settings
          </Button>

          <Button variant="contained" size="medium" onClick={handleOpen} startIcon={<AddIcon />}>
            Add Session
          </Button>
        </Stack>
      </Box>
    </ActionHeader>
      }
      

      <Container maxWidth="xl">
        <Box mt={2}>
            <FullCalendar
             ref={calendarRef}
            timeZone="local"
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            headerToolbar={headerToolbar}
            views={{
              dayGridMonth: { buttonText: "Month" },
              timeGridWeek: { buttonText: "Week" },
              timeGridDay: { buttonText: "Day" },
              listWeek: { buttonText: "List Week" },
            }}
            initialView={currentView}
            weekends={true}
            events={combinedEvents}
            dayMaxEventRows={false}
            windowResizeDelay={100}
            height='85vh'
            // aspectRatio={2.15}
            // dayMaxEvents={0}
            // eventClick={handleEdit}
            nowIndicator={true}
            longPressDelay={1}
            now={new Date()}
            scrollTime={currentTime}
            editable={true}
            eventDrop={handleEventDrop} // Handle event drop
            selectable={true}
            datesSet={handleDatesSet}
          />
        </Box>
      </Container>
      <GeneralDialog
        open={open}
        title={
          editInfo?._def?.extendedProps?._id || editInfo?._def?.extendedProps?.id
            ? "Edit Session"
            : "Add Session"
        }
        onClose={closeDialog}
        maxWidth={!openGoalBuilder ? "md" : ""}
        fullWidth
        fullScreen={openGoalBuilder ? true : false}

      >
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidation}
          onSubmit={submitForm}
        >
          {({ values, errors, touched, setFieldValue, isSubmitting, resetForm }) => (
              <Form id={formId} autoComplete="off" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <DialogContent dividers={true} style={{ background: "white", flex: '1 1 auto', overflowY: 'auto' }}>
                {newForm({
                  values,
                  touched,
                  formField,
                  setFieldValue,
                  resetForm,
                  errors,
                  open,
                  editInfo,
                  setOpenGoalBuider,
                  openGoalBuilder,
                })}
              </DialogContent>
              <DialogActions style={{ justifyContent: 'flex-end', position: 'sticky', bottom: 0, backgroundColor: '#f5f5f5', padding: '8px 24px' }}>
              <Box mr={2}>
                  <BoxButton
                    loading={loading}
                    size="large"
                    type="submit"
                    color="success"
                    variant="contained"
                    autoFocus
                  >
                    Submit
                  </BoxButton>
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </GeneralDialog>

      <GeneralDialog
        open={openSettings}
        title={"Calendar Settings"}
        onClose={handleCloseSettings}
        maxWidth={"md"}
        fullWidth
      >
        <DialogContent style={{ background: "white" }}>
          <Box mt={2}>
            {settingsForm({
              setBlockedDates,
              blockedDates,
              setOpenGoalBuider,
              openGoalBuilder,
              setLoading,
              // updateSettings
            })}
          </Box>
        </DialogContent>
      </GeneralDialog>
    </React.Fragment>
  );
}
