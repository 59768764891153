import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Typography, Avatar, Stack, Skeleton } from "@mui/material";

const SInfoCard = ({ icon, text, fontSize, subText, loading, ...props }) => {
  if (loading) {
    return (
      <Card
        sx={{
          boxShadow:
            "0 5px 6px -3px rgba(23,29,38,.06), 0 9px 12px 1px rgba(23,29,38,.03), 0 3px 16px 2px rgba(23,29,38,.05)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "stretch",
          alignItems: "stretch",
          gap: "32px",
          flex: "1 1 auto",
          background: "#fff",
          padding: "19px",
          borderRadius: "12px",
          border: "1px solid rgba(24,29,37,.05)",
          position: "relative",
        }}
      >
        <CardContent sx={{ padding: 0, paddingBottom: "0px !important" }}>
          <Stack direction="row" spacing={5} alignItems="center" justifyContent="center" {...props}>
            <Box mb={0}>
              <Skeleton variant="circular" width={70} height={70} />
            </Box>
            <Box sx={{ width: "100%", textAlign: "left", marginBottom: 2 }}>
              <Skeleton variant="text" width={50} height={30} />
              <Skeleton variant="text" width={100} height={30} />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        boxShadow:
          "0 5px 6px -3px rgba(23,29,38,.06), 0 9px 12px 1px rgba(23,29,38,.03), 0 3px 16px 2px rgba(23,29,38,.05)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
        gap: "32px",
        flex: "1 1 auto",
        background: "#fff",
        padding: "19px",
        borderRadius: "12px",
        border: "1px solid rgba(24,29,37,.05)",
        position: "relative",
      }}
    >
      <CardContent sx={{ padding: 0, paddingBottom: "0px !important" }}>
        <Stack direction="row" spacing={5} alignItems="center" justifyContent="center" {...props}>
          <Box mb={0}>
            <Avatar sx={{ bgcolor: "#eeeffe", width: 70, height: 70 }}>
              {icon &&
                React.cloneElement(icon, { style: { color: "#5263f3", fontSize: "2.5rem" } })}
            </Avatar>
          </Box>
          <Box sx={{ width: "100%", textAlign: "left", marginBottom: 2 }}>
            <Typography variant="h3" fontWeight={500} fontSize={fontSize}>
              {text}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {subText}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SInfoCard;
