const fluencyForm = {
    fluency: {
      flu_student_describe: {
        name: "flu_student_describe",
        label: "How would you describe the STUDENT's fluency when speaking?",
        type: "text",
        placeholder: "Enter observation method",
        errorMsg: "flu_student_describe",
        invalidMsg: "flu_student_describe",
        toolTipText: '',
        path: "fluency.opme_teeth",
      },
      flu_student_experience: {
        name: "flu_student_experience",
        label: "How often does the STUDENT experience moments of stuttering during conversation?",
        toolTipText: '',
        path: "fluency.flu_student_experience",
        errorMsg: "flu_student_experience",
        invalidMsg: "flu_student_experience",
      },
      flu_student_report: {
        name: "flu_student_report",
        label: "Does the STUDENT report any specific situations or triggers that tend to increase their stuttering?",
        toolTipText: '',
        path: "fluency.flu_student_report",
        errorMsg: "flu_student_report",
        invalidMsg: "flu_student_report",
      },
      flu_doc_description: {
        name: "flu_doc_description",
        label: "Additional Description",
        type: "text",
        path: "fluency.flu_doc_description",
        placeholder: "Enter additional description",
        errorMsg: "Additional description is required.",
        invalidMsg: "Invalid input for additional description.",
      },
    },
  };
  
  export default fluencyForm;
  