import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENTID;
  const redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI;
  const audience = process.env.REACT_APP_AUTH_AUDIENCE;

  // const onRedirectCallback = (appState) => {
  //   navigate(appState?.returnTo || window.location.pathname);
  // };

  const onRedirectCallback = (appState) => {
    navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      
      scope="openid profile email"
      authorizationParams={{
        redirect_uri: window.location.origin,
        // redirect_uri: redirectUri,
        audience: audience,
        
      }}
      onRedirectCallback={onRedirectCallback}
      
    >
      {children}
    </Auth0Provider>
  );
};