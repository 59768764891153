import React from 'react';
import { Text, View} from '@react-pdf/renderer';
import commonStyles from '../../commonStyles';
import HtmlToPdfText from '../../HtmlToPdfText';

const PurposeOfEval = ({ data }) => (
  <View style={commonStyles.box}>
    <Text style={commonStyles.subTitle}>Purpose Of Evaluation</Text>
    <HtmlToPdfText html={data?.eval_type_doc_content} />
    <Text style={commonStyles.subTitle}>Referral</Text>
    <HtmlToPdfText html={data?.eval_type_doc_referral_content} />
  </View>
);

export default PurposeOfEval;
