const form = {
  formId: "dealer",
  formField: {
    eventTitle: {
      name: "eventTitle",
      label: "Event Title",
      type: "text",
      placeholder: "Event Title",
      errorMsg: "Event Title is required.",
      invalidMsg: "",
    },
    startTime: {
      name: "startTime",
      label: "Start Time",
      type: "datetime-local", // Assuming you want to capture date and time
      placeholder: "Start Time",
      errorMsg: "Start Time is required.",
      invalidMsg: "",
    },
    endTime: {
      name: "endTime",
      label: "End Time",
      type: "datetime-local", // Assuming you want to capture date and time
      placeholder: "End Time",
      errorMsg: "End Time is required.",
      invalidMsg: "",
    },
    isRecurring: {
      name: "isRecurring",
      label: "Is Recurring",
      type: "checkbox",
      errorMsg: "Is Recurring is required.",
      invalidMsg: "",
    },
    sendEmailAlert: {
      name: "sendEmailAlert",
      label: "Send Alerts",
      type: "checkbox",
      errorMsg: "email alert is required.",
      invalidMsg: "",
    },
    recurrencePattern: {
      name: "recurrencePattern",
      label: "Recurrence Pattern",
      type: "text",
      placeholder: "Recurrence Pattern",
      errorMsg: "Recurrence Pattern is required.",
      invalidMsg: "",
    },
    isBillable: {
      name: "isBillable",
      label: "Is Billable ?",
      type: "checkbox",
      errorMsg: "isBillable is required.",
      invalidMsg: "",
    },
    studentId: {
      name: "studentId",
      label: "Select Student",
      type: "text",
      placeholder: "Enter first or last name to search...",
      errorMsg: "Student is required.",
      invalidMsg: "",
    },
    eventType: {
      name: "eventType",
      label: "Event Type",
      type: "text",
      placeholder: "Choose ...",
      errorMsg: "Event Type is required.",
      invalidMsg: "",
    },
    goalName: {
      name: "goalName",
      label: "Goal Name",
      type: "text",
      placeholder: "Goal Name",
      errorMsg: "Goal name is required.",
      invalidMsg: "",
    },
    goals: {
      name: "goals",
      label: "Goals",
      placeholder: "Goals",
      errorMsg: "Goals name is required.",
      invalidMsg: "",
    },
    duration: {
      name: "duration",
      label: "Duration",
      type: "text",
      placeholder: "Duration",
      errorMsg: "Duration is required.",
      invalidMsg: "",
    },
    assignedAssistant: {
      name: "assignedAssistant"
    }
  },
};

export default form;
