const recommendationsForm = {
    recommendations: {
      recomm_Q_01: {
        name: "recomm_Q_01",
        label: "Select recommendations:",
        type: "text",
        path: "recommendations.recomm_Q_01",
        errorMsg: "Please select at least one recommendation.",
        invalidMsg: "Invalid selection for recommendations.",
      },
      recomm_Q_01_other: {
        name: "recomm_Q_01_other",
        label: "Other Recommendations",
        type: "text",
        path: "recommendations.recomm_Q_01_other",
        placeholder: "Enter other recommendations",
        errorMsg: "Other recommendations are required.",
        invalidMsg: "Invalid input for other recommendations.",
      },
      recomm_Q_02: {
        name: "recomm_Q_02",
        label: "Would you like to include a validity statement in your report?",
        type: "text",
        path: "recommendations.recomm_Q_02",
        options: [
          { id: 1, label: "Yes" },
          { id: 2, label: "No" },
        ],
        errorMsg: "Please select whether recommendations are agreed upon.",
        invalidMsg: "Invalid selection for agreement on recommendations.",
      },
      recomm_doc_description: {
        name: "recomm_doc_description",
        label: "Additional Description",
        path: "recommendations.recomm_doc_description",
        type: "text",
        placeholder: "Enter additional description",
        errorMsg: "Additional description is required.",
        invalidMsg: "Invalid input for additional description.",
      },
    },
  };
  
  export default recommendationsForm;
  