import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Stack,
} from "@mui/material";
import moment from "moment";
import { Add, Delete} from "@mui/icons-material";
import { updateUser } from "redux/actions/subscriptionAction";
import googleIcon from "../../../assets/google-icon.png";
import microsoftIcon from "../../../assets/icons/microsoft.png";
import SMessageCard from "components/SMessageCard";
import CalendarBlock from "../../../assets/icons/calendar-block.png";
import momenttz from "moment-timezone";

const CalendarSettings = ({ formData }) => {
  const dispatch = useDispatch();

  const { blockedDates, setBlockedDates } = formData;
  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const updateSettings = () => {
    console.log('Main', blockedDates);
    let payload = {
      scheduler: {
        blackoutDays: blockedDates.map((dateObj) => ({
          date: momenttz.tz(dateObj.date, "America/Los_Angeles").toISOString(), // Convert date to UTC
          title: dateObj.title || "", // Ensure title is an empty string if not provided
        })),
      },
    };

    console.log('payload', payload)
    setLoading(true);
    dispatch(updateUser(payload))
      .then(() => {
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => setLoading(false));
  };

  const handleAdd = () => {
    if (!date || !title) return;
    const newDate = { date, title };
    if (!blockedDates.some((item) => item.date === date)) {
      setBlockedDates([...blockedDates, newDate]);
      setDate("");
      setTitle("");
      console.log('blockedDates', blockedDates)
      updateSettings()
    }
  };

  console.log('blockedDates', blockedDates)

 


  const handleDelete = (index) => {
    const updatedDates = blockedDates.filter((_, i) => i !== index);
    setBlockedDates(updatedDates);
    updateSettings()
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={5} xs={12}>
        <Typography component="label" variant="body1" fontWeight="600" mb={1}>
          Black Out Dates
        </Typography>
        <Typography color={"text.secondary"}>
          Select the days when you want to mark as blackout days.
        </Typography>
      </Grid>
      <Grid item lg={7} xs={12}>
        <Grid container spacing={1}>
          <Grid item lg={4} xs={12}>
            <Typography component="label" variant="body1" fontWeight="500" mb={1}>
              Select Date
            </Typography>
            <TextField
              fullWidth
              size="small"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Grid>
          <Grid item lg={7} xs={10}>
            <Typography component="label" variant="body1" fontWeight="500" mb={1}>
              Title
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item lg={1} xs={2}>
            <IconButton
              aria-label="add"
              sx={{
                mt: 2.8,
                bgcolor: "rgba(81, 99, 243, 0.1)",
                "&:hover": {
                  bgcolor: "rgba(81, 99, 243, 0.3)", // Adjust the opacity as needed
                },
              }}
              onClick={handleAdd}
            >
              <Add sx={{ color: "#5163f3" }} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{ height: "30vh", overflowY: "auto", background: "#fbfbfb" }}
              variant="outlined"
            >
              {blockedDates.length === 0 && (
                <SMessageCard imgWidth="3rem" imgUrl={CalendarBlock} subText="No Dates Found" />
              )}
              <List dense disablePadding>
                {blockedDates.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText secondary={moment(item.date).format("LL")} primary={item.title} />
                    <IconButton aria-label="delete" onClick={() => handleDelete(index)}>
                      <Delete />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item lg={5} xs={12}>
        <Typography component="label" variant="body1" fontWeight="600" mb={1}>
          Sync Calendar
        </Typography>
        {/* <Typography color={'text.secondary'}>Stay on top of your calendar meetings.</Typography> */}
        <Typography color={"text.secondary"}>
          {" "}
          Link your Google or Outlook calendar and keep all your schedules organized in one view.
        </Typography>
      </Grid>
      <Grid item lg={7} xs={12}>
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <Button
            variant="outlined"
            size="large"
            // onClick={handAuth}
            style={{
              display: "flex",
              alignItems: "center",
              width: "50%",
              borderColor: "lightgray",
              fontSize: 13,
            }}
          >
            <img src={googleIcon} alt="" style={{ width: "22px", marginRight: "8px" }} />
            Sync Google
          </Button>
          <Button
            variant="outlined"
            size="large"
            // onClick={handAuth}
            style={{
              display: "flex",
              alignItems: "center",
              width: "50%",
              borderColor: "lightgray",
              fontSize: 13,
            }}
          >
            <img src={microsoftIcon} alt="" style={{ width: "18px", marginRight: "8px" }} />
            Sync Microsoft
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CalendarSettings;
