import React, { useState } from "react";
import moment from "moment"; // Use Moment.js for date-time functions
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Grid, Box } from "@mui/material";

function DateRangePickerValues({ onChange }) {
  const [startDate, setStartDate] = useState(null); // Initialize with null
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    if (!date) return;
    setStartDate(date);
    onChange({ startDate: date, endDate });
  };

  const handleEndDateChange = (date) => {
    if (!date) return;
    setEndDate(date);
    onChange({ startDate: startDate, endDate: date });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item xs={12} md={6}>
          <DatePicker
            sx={{ width: "100%" }}
            label="From"
            autoFocus
            slotProps={{ textField: { size: "small", variant: "filled" } }}
            value={startDate}
            onChange={handleStartDateChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            sx={{ width: "100%" }}
            label="To"
            autoFocus
            slotProps={{ textField: { size: "small", variant: "filled" } }}
            value={endDate}
            onChange={handleEndDateChange}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export default DateRangePickerValues;
