import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import BoxButton from "components/BoxButton";
import moment from "moment";
import Select from "react-select";
import { v1 as uuidv1 } from "uuid";
import { generateImage } from "../../../../redux/actions/studentActions";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { List, ListItem, ListItemText, Checkbox } from "@mui/material";
import SCard from "../../../../components/SCard";
import SMessageCard from "components/SMessageCard";
import Creative from "../../../../assets/icons/creative.png";
import SkeletonListLoader from "components/SkeletonListLoader";
import { showNotification } from "redux/actions/notificationAction";
import { ERROR } from '../../../../redux/constants/notificationType'
import { grey } from "@mui/material/colors";
export default function GoalBuilder({
  name,
  date,
  setFieldValue,
  goalsV,
  openGoalBuilder,
  values,
}) {
  const dispatch = useDispatch();
  const [response, setResponse] = useState(null);
  const [aiLoading, setAiLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [goals, setGoals] = useState([]);

  const handleToggle = (index, question) => {
    const currentIndex = goals.findIndex((goal) => goal.name === question);
    const newGoals = [...goals];

    if (currentIndex === -1) {
      // If the goal is not in the list, add it.
      newGoals.push({ id: index, name: question });
      setFieldValue("goals", [...goalsV, { name: question }]);
    } else {
      // If the goal is already in the list, remove it.
      newGoals.splice(currentIndex, 1);
      setFieldValue("goals", newGoals);
    }

    setGoals(newGoals);
  };

  useEffect(() => {
    if (!openGoalBuilder) setResponse(null);
  }, [openGoalBuilder]);

  function handleClearAll() {
    setGoals([]);
  }

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const options = categoryList.map((category) => ({
    value: category.label, // Assuming you want to use the label as the value
    label: category.label,
  }));

  const generate = async () => {
    if (selectedCategory && selectedCategory.label) {
      handleClearAll();
      setAiLoading(true);
      setResponse("");

      let payload = {
        category: selectedCategory.label,
        // grade: selectedGrade.label,
        // age: inputAge,
        // additionalPrompt: inputText,
      };

      let payloadString = JSON.stringify(payload);
      dispatch(generateImage(payloadString))
        .then((response) => {
          if (response?.allowAccess === false) {
            showNotification({
              message: response.message,
              type: ERROR,
            });
            setAiLoading(false);
          } else {
            setResponse(response.data.content);
            setAiLoading(false);
          }

        })
        .catch((err) => setAiLoading(false));
    }
  };

  const questions = response?.split("\n").filter((q) => q.trim() !== "") || [];

  const formattedQuestions = questions.map((question) =>
    question
      .replace(/the student/g, `${name}`)
      .replace(/\[Specific Date\]/g, `${moment(date).format("LL")}`)
  );

  const Label = ({ label }) => {
    return (
      <Box mb={1} lineHeight={0} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography component="label" variant="body1" fontWeight="500">
            {label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={8} lg={8}>
          <Label label="Select Category" />
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            options={options}
            size="small"
            isClearable={true}
            isSearchable={true}
            menuPosition="fixed"
            menuShouldBlockScroll={true}
          />
        </Grid>
        <Grid item mt={3.5}>       
          <BoxButton
            variant="contained"
            size="large"
            loading={aiLoading}
            onClick={generate}
            disabled={!(values.studentId && values.startTime && selectedCategory)}
            sx={{
              borderRadius: 5,
              backgroundColor: "#292727",
              "&:hover": {
                backgroundColor: grey[800],
              },
            }}
            startIcon={
              <AutoAwesomeIcon/>
            }
          >
           <Typography variant="body1" fontWeight={500} textTransform={'capitalize'}>Generate</Typography> 
          </BoxButton>
        </Grid>

        <Grid item xs={12}>
          <SCard>
            <Box mt={0}>
              {aiLoading && (
                <>
                  <Box mb={2}>
                    <Typography variant="body1">
                      Generating goals for <strong>{selectedCategory?.label}</strong>... Please
                      wait.
                    </Typography>
                  </Box>

                  <SkeletonListLoader count={5} />
                </>
              )}

              {!aiLoading && formattedQuestions?.length === 0 && (
                <SMessageCard
                  // icon={<TaskAltOutlined />}
                  imgUrl={Creative}
                  text="AI Goal Builder"
                  subText={
                    <>
                      <Typography variant="body1" component="div">       To generate goals, please ensure the following fields are selected:
                      </Typography>
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="body1" component="div">
                          <ul style={{ textAlign: 'left', padding: 0, listStylePosition: 'inside' }}>
                            <li>Select student</li>
                            <li>Start time</li>
                            <li>Select Category</li>
                          </ul>
                        </Typography>
                      </Box>
                    </>

                  }
                />
              )}

              <List disablePadding>
                {formattedQuestions &&
                  formattedQuestions.map((question, index) => {
                    const labelId = `checkbox-list-label-${index}`;

                    const formattedQuestion = question.replace(/\*\*(.*?)\*\*/g, (match, p1) =>
                      p1 === "the student" ? `**${name}**` : match
                    );
                    // Check if the question is a new row
                    const isNewRow = question.startsWith("By [Specific Date]");

                    return (
                      <ListItem
                        key={index}
                        disableGutters
                        style={{ display: "flex", alignItems: "center", marginBottom: "0.1rem" }}
                      >
                        <Checkbox
                          edge="start"
                          onChange={() => handleToggle(index, question)} // Pass index or a unique identifier
                          checked={goals?.findIndex((goal) => goal?.id === index) !== -1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                        <ListItemText
                          variant="body1"
                          primary={
                            <div
                              dangerouslySetInnerHTML={{ __html: formattedQuestion }}
                              style={{ flex: 1, fontWeight: 400 }}
                            />
                          }
                        />
                        {isNewRow && <br />} {/* Add a line break for new row */}
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
          </SCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const categoryList = [
  { id: uuidv1(), label: "Articulation" },
  { id: uuidv1(), label: "Phonology" },
  { id: uuidv1(), label: "Hearing" },
  { id: uuidv1(), label: "Fluency" },
  { id: uuidv1(), label: "Functional Life Skills" },
  { id: uuidv1(), label: "Expressive Language" },
  { id: uuidv1(), label: "Receptive Language" },
  { id: uuidv1(), label: "Auditory Discrimination" },
  { id: uuidv1(), label: "Phonological Awareness" },
  { id: uuidv1(), label: "Social Skills / Pragmatics" },
  { id: uuidv1(), label: "Augmentative Alternative Communication (AAC)" },
  { id: uuidv1(), label: "Figurative Language" },
  { id: uuidv1(), label: "Written Language" },
  { id: uuidv1(), label: "Intelligibility" },
];
