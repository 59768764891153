import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { grey } from "@mui/material/colors";
import {
  Grid,
  FormControlLabel,
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Stack,
  useMediaQuery,
  Checkbox
} from "@mui/material";
import FormField from "components/FormField";
import { searchStudent } from "redux/actions/studentActions";
import moment from "moment";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SMessageCard from "components/SMessageCard";
import { Add, AutoAwesome, Close } from "@mui/icons-material";
import GoalBuilder from "./GoalBuilder";
import debounce from "lodash/debounce";
import TaskList from "../../../assets/icons/task-list.png";
import { getCurrentSchoolYear } from "components/Common/getSchoolYearOptions";
const AddEvent = ({ formData }) => {
  const dispatch = useDispatch();
  const { searchedResult } = useSelector((state) => ({
    searchedResult: state.studentReducer.searchedResult,
  }));
  const [schoolYear, setSchoolYear] = useState(getCurrentSchoolYear()); // Function to get the current school year
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const {
    formField,
    values,
    errors,
    touched,
    setFieldValue,
    editInfo,
    setOpenGoalBuider,
    openGoalBuilder,
  } = formData;

  const {
    eventTitle,
    startTime,
    endTime,
    isRecurring,
    recurrencePattern,
    studentId,
    eventType,
    sendEmailAlert,
    isBillable,
    assignedAssistant,
    goals,
    duration,
  } = formField;
  const {
    eventTitle: eventTitleV,
    startTime: startTimeV,
    endTime: endTimeV,
    isRecurring: isRecurringV,
    recurrencePattern: recurrencePatternV,
    studentId: studentIdV,
    eventType: eventTypeV,
    sendEmailAlert: sendEmailAlertV,
    goals: goalsV,
    duration: durationV,
    isBillable: isBillableV,
  } = values;

  const pattern = [
    { id: "weekly", name: "Weekly" },
    { id: "daily", name: "Daily" },
    { id: "every2weeks", name: "Every 2 Weeks" },
  ];

  function handleDuration(event, newValue) {
    setFieldValue("duration", newValue);
  }

  const durationList = [
    { id: "15", name: "15 mins" },
    { id: "30", name: "30 mins" },
    { id: "45", name: "45 mins" },
    { id: "60", name: "1 hour" },
    { id: "120", name: "2 hours" },
  ];

  const eventTypeList = [
    { id: "therapy", name: "Therapy Session", color: "#00bcd4" }, // Light Blue
    { id: "iepAnnual", name: "IEP Meeting Annual", color: "#4caf50" }, // Green
    { id: "assessment", name: "Assessment", color: "#f44336" }, // Red
    { id: "iepTriennial", name: "IEP Meeting Triennial", color: "#673ab7" }, // Deep Purple
    { id: "screening", name: "Screening", color: "#ffc107" }, // Amber
    { id: "checkIn", name: "Check In", color: "#ff5722" }, // Deep Orange
    { id: "other", name: "Other", color: "#9e9e9e" }, // Grey
  ];

  function handlePattern(option) {
    setFieldValue("recurrencePattern", option);
  }
  const [loading, setLoading] = useState(false);

  const handleInputChange = debounce((inputValue) => {
    if (inputValue.length > 1 || inputValue === '') {
      setLoading(true);
      dispatch(searchStudent(inputValue, schoolYear)).then((response) => {
        setLoading(false);
      });
    }
  }, 500);

  useEffect(() => {
    return () => {
      handleInputChange.cancel();
    };
  }, []);

  useEffect(() => {
    dispatch(searchStudent("", schoolYear));
  }, []);

  function handleSelectStudent(option) {
    setFieldValue(studentId.name, option);
    setFieldValue(assignedAssistant.name, option.assignedAssistant);
    if(!option) {
      setFieldValue(assignedAssistant.name, []);
    }
  }
  
  function findStudent() {
    if (studentIdV > 0) {
      const value = studentIdV.find((f) => f._id === editInfo?._def?.extendedProps?.studentId);
      return value;
    }
  }

  useEffect(() => {
    if (editInfo?._def?.extendedProps?._id || editInfo?._def?.extendedProps?.id) {
      // Convert local time to UTC
      const utcStartTime = moment.utc(editInfo._instance.range.start);
      const utcEndTime = moment.utc(editInfo._instance.range.end);

      // Format UTC times to ISO 8601 format with local time zone offset
      const formattedUtcStartTime = utcStartTime.format("YYYY-MM-DDTHH:mm:ss");
      const formattedUtcEndTime = utcEndTime.format("YYYY-MM-DDTHH:mm:ss");

      // Set form field values
      setFieldValue(startTime.name, formattedUtcStartTime);
      setFieldValue(endTime.name, formattedUtcEndTime);
      setFieldValue(isRecurring.name, editInfo._def.extendedProps.isRecurring);
      setFieldValue(eventTitle.name, editInfo._def.extendedProps.eventTitle);
      setFieldValue(eventType.name, editInfo._def.extendedProps.eventType);
      setFieldValue(studentId.name, editInfo._def.extendedProps.studentId);
      setFieldValue(assignedAssistant.name, editInfo._def.extendedProps.assignedAssistant)
    }
  }, [editInfo]);

  useEffect(() => {
    setFieldValue(eventTitle.name, `${studentIdV && studentIdV.firstName + " " + studentIdV.lastName || ""} - ${eventTypeV && eventTypeV?.name || ""} `)
  }, [studentIdV, eventTypeV])


  const handleAddRow = () => {
    setFieldValue("goals", [...goalsV, { name: "" }]);
  };

  const handleDeleteRow = (index) => {
    const newGoals = [...goalsV];
    newGoals.splice(index, 1);
    setFieldValue("goals", newGoals);
  };

  const handleChange = (index, event) => {
    const newGoals = [...goalsV];
    newGoals[index].name = event.target.value;
    setFieldValue("goals", newGoals);
  };

  const handleGoalAssistance = () => {
    setOpenGoalBuider(!openGoalBuilder);
    // navigate("/assistanceAI");
  };

  function loadForm() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <FormField
            inputType="select"
            type={studentId.type}
            isClearable
            label={studentId.label}
            name={studentId.name}
            onInputChange={handleInputChange}
            options={searchedResult && searchedResult?.response}
            onChange={handleSelectStudent}
            getOptionLabel={(option) => option?.firstName + " " + option?.lastName}
            getOptionValue={(option) => option?._id}
            size="small"
            value={studentIdV?.length > 0 ? findStudent() : studentIdV}
            defaultValue={studentIdV?.length > 0 ? findStudent() : studentIdV}
            isRequired={true}
            isLoading={loading}
            placeholder={studentId.placeholder}
            error={errors.studentId && touched.studentId}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormField
            isClearable
            inputType="select"
            type={eventType.type}
            label={eventType.label}
            name={eventType.name}
            size="small"
            options={eventTypeList}
            onChange={(option) => {
              setFieldValue(eventType.name, option);
            }}
            getOptionLabel={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    marginRight: "8px",
                    backgroundColor: option.color,
                  }}
                ></div>
                {option.name}
              </div>
            )}
            getOptionValue={(option) => option.id}
            isRequired={true}
            value={eventTypeV}
            placeholder={eventType.placeholder}
            error={errors.eventType && touched.eventType}
          />
        </Grid>
        <Grid item xs={12} lg={12}>

          <FormField
            type={eventTitle.type}
            label={eventTitle.label}
            name={eventTitle.name}
            size="small"
            inputProps={{ maxLength: 80 }}
            isRequired={true}
            value={eventTitleV}
            placeholder={eventTitle.placeholder}
            error={errors.eventTitle && touched.eventTitle}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormField
            type={startTime.type}
            label={startTime.label}
            name={startTime.name}
            isRequired={true}
            size="small"
            disabled={goalsV.length > 0}
            value={startTimeV}
            placeholder={startTime.placeholder}
            error={errors.startTime && touched.startTime}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box mb={1} lineHeight={0}>
            <Typography component="label" fontWeight="500">
              {duration.label}
            </Typography>
          </Box>
          <ToggleButtonGroup
            value={durationV}
            name={duration.name}
            exclusive
            onChange={handleDuration}
            size="small"
            aria-label="duration"
          >
            {durationList.map((duration) => (
              <ToggleButton
                key={duration.id}
                value={duration.id}
                style={{
                  backgroundColor: durationV === duration.id ? "black" : "initial",
                  color: durationV === duration.id ? "white" : "grey",
                }}
              >
                {duration.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Box mt={0.75}>
            <Typography component="div" variant="body2" color="error">
              {errors.duration && touched.duration ? errors.duration : null}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Stack
            direction={isSmallScreen ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            // divider={<Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} flexItem />}
            spacing={2}
          >
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleAddRow}
                startIcon={<Add />}
              >
                Add Goal
              </Button>
            </Box>



            <Box>
              <Button
                size="medium"
                variant="outlined"
                sx={{
                  bgShadow: 0,
                  color: grey[900],
                  borderColor: grey[200],
                  "&:hover": {
                    background: '#fff',
                    color: openGoalBuilder ? '#f32f2f' : '#5163f4',
                    borderColor: openGoalBuilder ? grey[200] : '#5163f4',
                  },
                }}
                startIcon={
                  openGoalBuilder ? (
                    <Close style={{ color: "#f32f2f" }} />
                  ) : (
                    <AutoAwesome style={{ color: "#5163f4" }} />
                  )
                }
                onClick={handleGoalAssistance}
              >
                {openGoalBuilder ? "Close Goal Builder" : "AI Goal Builder"}
              </Button>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} lg={12}>
          <Paper variant="outlined" style={{ padding: "2rem", backgroundColor: "#fff" }}>
            {goalsV.length === 0 && (
              <SMessageCard
                imgUrl={TaskList}
                text="Click 'Add Goal'"
                subText="Utilize the AI Goal Builder to quickly generate and assign goals to students."
              />
            )}
            {goalsV.map((goal, index) => (
              <>
                <Box mb={1} lineHeight={0}>
                  <Typography
                    component="label"
                    // variant="subtitle2"
                    fontWeight="500"
                  // textTransform="capitalize"
                  >
                    Goal {index + 1}
                  </Typography>
                </Box>

                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
                >
                  <TextField
                    fullWidth
                    multiline
                    size="small"
                    variant="outlined"
                    value={goal.name}
                    onChange={(event) => handleChange(index, event)}
                  />

                  <IconButton 
                   sx={{
                    boxShadow: 0,
                    ml: 2,
                    color: grey[800],
                    borderColor: grey[200],
                    background: grey[200],
                    '&:hover': {
                        background: '#ffcdcd',
                        color: 'red',
                    }
                }}
                  onClick={() => handleDeleteRow(index)} >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isBillableV}
                  onChange={(e) => setFieldValue(isBillable.name, e.target.checked)}
                  name={isBillable.name}
                  color="primary"
                />
              }
              label={`${isBillable.label}`}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={sendEmailAlertV}
                  onChange={(e) => setFieldValue(sendEmailAlert.name, e.target.checked)}
                  name={sendEmailAlert.name}
                  color="primary"
                />
              }
              label={`${sendEmailAlert.label}`}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isRecurringV}
                  onChange={(e) => setFieldValue(isRecurring.name, e.target.checked)}
                  name={isRecurring.name}
                  color="primary"
                />
              }
              label={isRecurring.label}
            />
          </Box>
        </Grid>

        {isRecurringV && (
          <Grid item xs={12} lg={12}>
            <FormField
              inputType="select"
              type={recurrencePattern.type}
              label={recurrencePattern.label}
              name={recurrencePattern.name}
              options={pattern}
              onChange={handlePattern}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              size="small"
              autoFocus
              menuPlacement="top"
              isRequired={false}
              value={recurrencePatternV}
              placeholder={recurrencePattern.placeholder}
              error={errors.recurrencePattern && touched.recurrencePattern}
            />
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <React.Fragment>
      {openGoalBuilder ? (
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            {loadForm()}
          </Grid>
          <Grid item lg={6} xs={12}
          sx={{
            // height: "80vh",
            // margin: "auto",
            // overflowY: "auto",
          }}>
            <Paper
              variant="outlined"
              sx={{
                background: "#f3f3f3",
                borderRadius: "0.6rem",
                padding: "1.6rem",
                boxShadow: "rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem",
              }}
            >
              <GoalBuilder
                name={`${studentIdV?.firstName} ${studentIdV?.lastName}`}
                date={startTimeV}
                setFieldValue={setFieldValue}
                goalsV={goalsV}
                openGoalBuilder={openGoalBuilder}
                values={values}
              />
            </Paper>
          </Grid>
        </Grid>
      ) : (
        loadForm()
      )}
    </React.Fragment>
  );
};

export default AddEvent;
