import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, Chip } from "@mui/material";
import { fetchStudentById } from "redux/actions/studentActions";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { LogoutOutlined } from "@mui/icons-material";
import { Avatar, Box } from "@mui/material";
// import DarkModeIcon from "@mui/icons-material/DarkMode";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
// import LightModeIcon from "@mui/icons-material/LightMode";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useAuth0 } from '@auth0/auth0-react';
import FeedbackButton from "components/Common/FeedbackButton";

function Header(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  const { user, logout } = useAuth0();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDarMode = () => {
    setDarkMode(!darkMode);
  };

  const handleButtonClick = () => {
    navigate("/student");
    setStudentName("");
  };

  const handleLogOut = () => {
    // localStorage.clear();
    logout({ returnTo: window.location.origin });

  };

  const { onDrawerToggle } = props;
  const location = useLocation();
  const { id } = useParams();
  const [studentName, setStudentName] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    if (id !== undefined && id !== null) {
      if (location.pathname === `/student/${id}`) {
        setStudentName("Loading ....");
        dispatch(fetchStudentById(id))
          .then((response) => {
            setStudentName(
              (response.response.firstName || "") + " " + (response.response.lastName)
            );            
          })
          .catch((err) => {
            navigate(`/student`);
          });
      }
    }
  }, [location.pathname, id]);

  const getTitleFromPath = (pathname, id) => {
    switch (pathname) {
      case `/student/${id}`:
        return `${studentName}'s Profile`;
      case "/student":
        return "Caseload Management";
      case "/report":
        return "Evaluation Report";
      case "/scheduler":
        return "Scheduler";
      case "/goal":
        return "Goal Bank";
      case "/dashboard":
        return "Dashboard";
      case "/assistanceAI":
        return "Goal Builder";
      case "/imageAI":
        return "Generate Image AI";
      case "/scheduling-links":
        return "Scheduling Link";
      case "/data-tracking":
        return "Data Tracking";
      case "/payment-update":
        return "Payment";
      default:
        return "Default Title"; // Set a default title for unmatched routes
    }
  };

  const title = getTitleFromPath(location.pathname, id);

  let name = ""

  if(user.given_name) {
    name = user.given_name;
  } else if (user.nickname) {
    name = user.nickname
  } else {
    name = ''
  }


  const getFeedbackFeature = (pathname) => {
    switch (pathname) {
      case '/data-tracking':
        return 'data-tracking';
      case '/dashboard':
        return 'dashboard';
      case '/assistanceAI':
        return 'assistanceAI';
      default:
        return null;
    }
  };
  
  const feature = getFeedbackFeature(location.pathname);

  return (
    <React.Fragment>
      <AppBar variant="outlined" position="sticky" elevation={0} sx={{ padding: 0 }}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon sx={{ color: "black" }} />
              </IconButton>
            </Grid>

            <Grid item xs>
              <Typography variant="h3" fontSize={'1.25rem'} fontWeight={700}>{title}</Typography>
            </Grid>
            {matches ? (
              <>
                <Grid item>
                  {location.pathname === `/student/${id}` && (
                    <Button
                      color="secondary"
                      sx={{ bgShadow: 0 }}
                      size="medium"
                      onClick={handleButtonClick}
                      startIcon={<ArrowBackOutlinedIcon />}
                    >
                      Back to Main
                    </Button>
                  )}
                </Grid>

                <Grid item>
                {feature && <FeedbackButton feature={feature} />}
                </Grid>

                {/* <Grid item>
                  <Tooltip title="Alerts • No alerts">
                    <IconButton color="secondary">
                      <NotificationsIcon />
                    </IconButton>
                  </Tooltip>
                </Grid> */}
                <Grid item>
                  <Tooltip title="Profile">
                    <Chip
                      variant="outlined"
                      color="default"
                      label={
                        <Typography variant="subtitle2" color={"primary"} textTransform={'capitalize'}>
                          {name}
                        </Typography>
                      }
                      deleteIcon={<ExpandMoreOutlined />}
                      onDelete={handleClick}
                      avatar={<Avatar src={user.picture} />}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      style={{ cursor: "pointer" }} // Show pointer cursor
                      onClick={handleClick}
                    />
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    elevation={1}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    sx={{
                      "& .MuiMenu-list": {
                        backgroundColor: "#363740",
                      },
                      "& .MuiPaper-root": {
                        borderRadius: 1,
                        marginTop: theme.spacing(1.5),
                      },
                    }}
                  >
                    <Box sx={{ width: 220, maxWidth: "100%" }}>
                      <MenuItem onClick={handleClose} sx={{ color: "#fff" }}>
                        <ListItemIcon>
                          <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                      </MenuItem>
                      <MenuItem onClick={handleLogOut} sx={{ color: "#fff" }}>
                        <ListItemIcon>
                          <Logout fontSize="small" sx={{ color: "#fff" }} />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Box>
                  </Menu>
                </Grid>
              </>
            ) : (
              <Grid item>
                <IconButton onClick={handleLogOut}>
                  <LogoutOutlined />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
