import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Stack,
  Button,
  Avatar,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { ArrowBack } from "@mui/icons-material";
import moment from "moment";
import { green } from "@mui/material/colors";

export default function ViewInvite(props) {
  const { eventTitle, dateTimeSlots, invitedList, selectedSlots } = props.data;

  function handleBack() {
    props.setView(false);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h3" fontSize={24}>
              {eventTitle}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="standard"
              size="medium"
              color="secondary"
              startIcon={<ArrowBack />}
              onClick={handleBack}
            >
              Back
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{
            backgroundColor: "#ffff",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Invited</TableCell>
                {dateTimeSlots.map((slot) => (
                  <TableCell key={slot.id} style={{ textAlign: "center" }}>
                    {moment(slot.start).format("llll")}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invitedList.map((invited) => (
                <TableRow key={invited.id}>
                  <TableCell>
                    <Typography variant="subtitle1">{invited.email}</Typography>
                  </TableCell>
                  {dateTimeSlots.map((slot) => (
                    <TableCell key={slot.id} style={{ textAlign: "center" }}>
                      {selectedSlots.find(
                        (selectedSlot) =>
                          selectedSlot.email === invited.email &&
                          moment(selectedSlot.start).isSame(slot.start)
                      ) && (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <Tooltip title="Confirmed">
                            <Avatar
                              sx={{ bgcolor: green[400], width: 24, height: 24 }}
                              sizes="small"
                            >
                              <CheckIcon />
                            </Avatar>
                          </Tooltip>
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
