import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const infoBoxStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  box: {
    width: '50%',
    border: '0.5px',
    borderColor: 'grey',
    padding: 5,
    textAlign: 'left',
    marginBottom: -0.5, // Adjust spacing between boxes
    flexDirection: 'row', // Ensure label and value are in a row
    alignItems: 'center', // Center items vertically
  },
  label: {
    color: 'black',
    marginRight: 5, // Adjust space between label and value
    fontWeight: 'heavy',
    fontFamily: 'Lato Bold',
  },
  value: {
    flex: 1, // Allow value to fill remaining space
    fontWeight: 'light',
    fontFamily: 'Lato',
  },
});

const InfoBox = ({ data }) => {
  // Format testDates

  console.log('data', data)
  const formattedTestDates = data?.testDates && data?.testDates?.length > 0
    ? data?.testDates
      .map(date => moment(date).format('MM/DD/YY'))
      .join(', ')
    : '';

  // Calculate chronologicalAge if dateOfBirth is available
  const dateOfBirth = data?.dateOfBirth ? moment(data.dateOfBirth) : null;
  const currentDate = moment();
  const age = dateOfBirth ? currentDate.diff(dateOfBirth, 'years') : 'Date of birth not provided';

  const primaryLanguageName = data?.primaryLanguage?.name || 'Not specified';


  return (
    <View>
      <View style={infoBoxStyles.row}>
        <View style={infoBoxStyles.box}>
          <Text style={infoBoxStyles.label}>Name:</Text>
          <Text style={infoBoxStyles.value}>{data.firstName} {data.lastName}</Text>
        </View>
        <View style={infoBoxStyles.box}>
          <Text style={infoBoxStyles.label}>Date of Report:</Text>
          <Text style={infoBoxStyles.value}>{data.dateOfReport}</Text>
        </View>
      </View>

      <View style={infoBoxStyles.row}>
        <View style={infoBoxStyles.box}>
          <Text style={infoBoxStyles.label}>Date of Birth:</Text>
          <Text style={infoBoxStyles.value}>{data.dateOfBirth}</Text>
        </View>
        <View style={infoBoxStyles.box}>
          <Text style={infoBoxStyles.label}>Test Date(s):</Text>
          <Text style={infoBoxStyles.value}>{formattedTestDates}</Text>
        </View>
      </View>

      <View style={infoBoxStyles.row}>
        <View style={infoBoxStyles.box}>
          <Text style={infoBoxStyles.label}>Chronological Age:</Text>
          <Text style={infoBoxStyles.value}>{age}</Text>
        </View>
        <View style={infoBoxStyles.box}>
          <Text style={infoBoxStyles.label}>Grade:</Text>
          <Text style={infoBoxStyles.value}>{data?.grade?.label}</Text>
        </View>
      </View>

      <View style={infoBoxStyles.row}>
        <View style={infoBoxStyles.box}>
          <Text style={infoBoxStyles.label}>School:</Text>
          <Text style={infoBoxStyles.value}>{data.school}</Text>
        </View>
        <View style={infoBoxStyles.box}>
          <Text style={infoBoxStyles.label}>Evaluator Name:</Text>
          <Text style={infoBoxStyles.value}>{data.evaluatorName}</Text>
        </View>
      </View>

      <View style={infoBoxStyles.row}>
        <View style={infoBoxStyles.box}>
          <Text style={infoBoxStyles.label}>Case Manager:</Text>
          <Text style={infoBoxStyles.value}>{data.caseManager}</Text>
        </View>
        <View style={infoBoxStyles.box}>
          <Text style={infoBoxStyles.label}>Primary Language:</Text>
          <Text style={infoBoxStyles.value}>{primaryLanguageName}</Text>
        </View>
      </View>
    </View>
  );
};

export default InfoBox;
