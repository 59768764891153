import PropTypes from "prop-types";
import { Box, Typography, Tooltip } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import TextField from "@mui/material/TextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function FormField({ label, isRequired, inputType, toolTipText, name, error, nested, path, multiline, rows, ...rest }) {
  const inputComponent = () => {
    switch (inputType) {
      case "select":
        return (
          <Field
            {...rest}
            name={name}
            as={Select}
            menuPosition="fixed"
            menuShouldBlockScroll={true}
            styles={{
              control: (baseStyles, { isFocused, isDisabled }) => ({
                ...baseStyles,
                borderColor: error ? 'red' : isFocused ? '#171d26' : 'lightGrey',

                // borderColor: isFocused ? '#171d26' : 'lightGrey',
                borderRadius: '8px',
                backgroundColor: 'transparent',
                boxShadow: isFocused ? '0 0 0 1px #171d26' : 'none',
               '&:hover': {
                 borderColor: error ? 'red' : '#171d26',
                },
                cursor: isDisabled ? 'not-allowed' : 'default',
              }),
            }}
          />
        );
      case "createSelect":
        return (
          <Field
            {...rest}
            name={name}
            as={CreatableSelect}
            maxMenuHeight={150}
            menuPlacement="bottom"
            menuShouldBlockScroll={true}
          />
        );

      default:
        return (
          <Field
            {...rest}
            name={name}
            as={TextField}
            multiline={multiline}
            rows={rows}
            style={{ width: "100%", background: "#fff" }}
            error={error}
          />
        );
    }
  };

  return (
    <Box mb={1.5}>
      <Box mb={1} lineHeight={0} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography component="label" variant="body1" fontWeight="500">
            {label}
          </Typography>
          {isRequired && <span style={{ color: "red", marginLeft: "4px" }}> *</span>}
        </Box>
        {toolTipText && (
          <Tooltip title={toolTipText} arrow placement="bottom-start" style={{ cursor: "pointer" }}>
            <InfoOutlinedIcon fontSize="small" sx={{ color: "grey" }} />
          </Tooltip>
        )}
      </Box>
      {inputComponent()}
      <Box mt={0.75}>
        <Typography component="div" variant="body2" color="error">
          <ErrorMessage name={nested ? path : name} />
        </Typography>
      </Box>
    </Box>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  inputType: PropTypes.oneOf(["select", "createSelect", "datePicker", "textField"]),
  toolTipText: PropTypes.string,
  error: PropTypes.any,
  nested: PropTypes.bool,
  path: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

export default FormField;
