import checkout from "./form";

const {
  formField: {
    firstName,
    middleName,
    lastName,
    caseManager,
    dateOfBirth,
    gender,
    grade,
    eligibilityEvaluation,
    initialEvaluation,
    annualPlanReview,
    school,
    currentAcademicYear
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [middleName.name]: "",
  [lastName.name]: "",
  [caseManager.name]: "",
  [dateOfBirth.name]: "",
  [gender.name]: "",
  [grade.name]: "",
  [school.name]: "",
  [eligibilityEvaluation.name]: "",
  [initialEvaluation.name]: "",
  [annualPlanReview.name]: "",
  [currentAcademicYear.name]: "",
};

export default initialValues;
