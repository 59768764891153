// LogoContainer.js
import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import brandLogo from 'assets/loading_icon.svg';

const Container = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});

const Logo = styled('img')({
  position: 'absolute',
  width: 40, // adjust size as needed
  height: 40, // adjust size as needed
});

const BackdropLoading = ({ size = 100, thickness = 3.6 }) => (
    <Container>
    <CircularProgress size={size} sx={{
        color: '#111212',
        '& .MuiCircularProgress-circle': {
          strokeWidth: 2, // Adjust the thickness here
        },
      }} />
    <Logo src={brandLogo} alt="Loading" />
  </Container>
);

export default BackdropLoading;
