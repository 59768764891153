import React, { useState, useEffect, useCallback } from "react";
import { Box, Tooltip, Typography, DialogContent, DialogActions } from "@mui/material";
import GeneralDialog from "components/GeneralDialog";
import FieldMapping from "./FieldMapping";
//API
import { importStudents, fetchStudentsDataTable, deleteStudent } from "redux/actions/studentActions";
import { useDispatch } from "react-redux";
import DateAndTimeConvert from "components/Common/DateAndTimeConvert";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Preview } from "@mui/icons-material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import BoxTable from "components/BoxTable";
import BoxButton from "components/BoxButton";
import Papa from "papaparse";
import { getCurrentSchoolYear } from "components/Common/getSchoolYearOptions";
import { bulkDeleteStudents } from "redux/actions/studentActions";
import DeleteConfirm from "./DeleteConfirm";

export default function StudentTable(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uploadedData, setUploadedData] = useState([]); // Initialize data with the rows
  const [searchInput, setSearchInput] = useState(""); // search filter BTTable
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showBulkDeleteConfirm, setShowBulkDeleteConfirm] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [newData, setNewData] = useState([]); // Initialize data with the rows
  const file = props.uploadedFile; // Access the file directly from props

  const userStr = localStorage.getItem("user");
  const user = JSON.parse(userStr);
  const id = user?._id;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    searchValue: "",
  });

  const [queryOptions, setQueryOptions] = React.useState({});
  const [selectionModel, setSelectionModel] = useState([]);


  const handleSortModelChange = React.useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handleSelectionModelChange = (newSelectionModel) => {
    console.log('newSelectionModel', newSelectionModel);
    setSelectionModel(newSelectionModel);
  };

  const payload = {
    filter: {
      sortColumnName:
        queryOptions.sortModel && queryOptions?.sortModel?.length > 0
          ? queryOptions?.sortModel[0].field
          : "",
      sortOrder:
        queryOptions.sortModel && queryOptions?.sortModel?.length > 0
          ? queryOptions?.sortModel[0].sort
          : "",
      limit: pageState.pageSize,
      pageNumber: pageState.page,
      searchValue: searchInput,
      schoolYear: props.schoolYear,
    },
  };

  const fetchData = async () => {
    setPageState((old) => ({ ...old, isLoading: true }));

    dispatch(fetchStudentsDataTable(payload))
      .then((response) => {
        const json = response;
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: json?.response ? json.response : [],
          total: json?.totalCount,
        }));
      })
      .catch((err) => {
        setPageState((old) => ({ ...old, isLoading: false }));
      });
  };

  useEffect(() => {
    fetchData();
  }, [queryOptions, pageState.page, pageState.pageSize, searchInput, props.reload, props.schoolYear]);



  function readUploadedFile() {
    if (file) {
      setIsDialogOpen(true);

      const reader = new FileReader();
      reader.onload = () => {
        // Parse the CSV file
        const result = Papa.parse(reader.result, { header: true });

        // Filter out empty rows or rows where all values are null or empty
        const filteredData = result.data.filter(row =>
          row && Object.values(row).some(value => value && value.trim() !== '')
        );

        setUploadedData(filteredData);
        console.log('filteredData', filteredData)
      };
      reader.readAsText(file);
    } else {
      setIsDialogOpen(false);
    }
  }


  const onSubmit = (e) => {
    e.preventDefault();

    const currentSchoolYear = getCurrentSchoolYear(); // e.g., "2024-2025"

    const payload = newData.map((student) => ({
      ...student,
      tenantId: id,
      currentAcademicYear: {
        schoolYear: currentSchoolYear,
        grade: student.grade || 'Unknown', // Provide a default value if grade is not provided
        school: student.school || 'Unknown', // Provide a default value if school is not provided
      }
    }));

    setLoading(true);

    dispatch(importStudents(payload))
      .then((response) => {
        setIsDialogOpen(false);
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        console.error('Error importing students:', err); // Log the error for debugging
        setLoading(false);
        setIsDialogOpen(true); // Optionally open a dialog or show a UI element to inform the user
      });
  };



  useEffect(() => {
    if (props.uploadedFile) {
      readUploadedFile();
    }
  }, [props.uploadedFile]);

  const edit = useCallback(
    (rowData) => () => {
      console.log(rowData.row);
      navigate(`/student/${rowData.row._id}`);
      // props.handleEdit(true);
      // props.setStudentData(rowData);
    },
    []
  );


  const handleDeleteConfirm = useCallback(
    (rowData) => () => {
      setSelectedStudent(rowData.row);
      setDeleteConfirm(true);
    },
    []
  );


  const handleDelete = async () => {
    setLoading(true);
    const payload = {
      studentId: selectedStudent._id
    };

    try {
      const response = await dispatch(deleteStudent(payload));
      console.log('response', response);

      fetchData();
      setDeleteConfirm(false);
    } catch (err) {
      console.error('Failed to delete student:', err);
    } finally {
      setLoading(false);
    }
  };


  const handleBulkDeleteClick = () => {
    if (selectionModel.length === 0) {
      alert('No students selected');
      return;
    }
    setShowBulkDeleteConfirm(true);
  };

  const handleBulkDeleteConfirm = async () => {
    setLoading(true);
    const payload = { studentIds: selectionModel };

    try {
      const response = await dispatch(bulkDeleteStudents(payload));
      console.log('Delete response:', response);
      fetchData();
      setSelectionModel([]);
    } catch (err) {
      console.error('Failed to delete students:', err);
    } finally {
      setLoading(false);
      setShowBulkDeleteConfirm(false);
    }
  };

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography variant="body1">{params.row.firstName}</Typography>
          </>
        );
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      hide: !matches ? true : false,
    },
    {
      field: "dateOfBirth",
      headerName: "DOB",
      flex: 1,
      sortable: true,
      hide: !matches ? true : false,

      renderCell: (params) => {
        return (
          <>
            {params.row.dateOfBirth ? (
              <DateAndTimeConvert timestamp={params.row.dateOfBirth} />
            ) : null}
          </>
        );
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      flex: 1,
      hide: !matches ? true : false,
      renderCell: (params) => {
        return (
          <>
            {params.row.currentAcademicYear.grade}
          </>
        );
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      hide: !matches ? true : false,
    },
    {
      field: "school",
      headerName: "School",
      flex: 1,
      hide: !matches ? true : false,
      renderCell: (params) => {
        return (
          <>
            {params.row.currentAcademicYear.school}
          </>
        );
      },
    },
    {
      field: "caseManager",
      headerName: "Case Manager",
      flex: 1,
      hide: !matches ? true : false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      hide: selectionModel.length > 0 ? true: false,
      sortable: false,
      flex: 0.8,
      getActions: (params) => {
        return [
          <Tooltip title="View & Edit Student">
            <GridActionsCellItem
              icon={<Preview sx={{ fontSize: "1.3rem" }} />}
              label="View & Update Student"
              onClick={edit(params)}
            />
          </Tooltip>,
          <Tooltip title="Delete">
            <GridActionsCellItem
              icon={<DeleteOutlinedIcon sx={{ fontSize: "1.3rem" }} />}
              label="Delete"
              style={{ color: "#ff6969" }}
              onClick={handleDeleteConfirm(params)}

            />
          </Tooltip>,
        ];
      },
    },
  ];
  return (
    <Box mt={2}>
      <BoxTable
        columns={columns}
        autoHeight
        getRowId={(row) => row._id}
        data={pageState.data}
        rowCount={pageState.total}
        loading={pageState.isLoading}
        page={pageState.page - 1}
        checkboxSelection
        pageSize={pageState.pageSize}
        paginationMode="server"
        pagination
        setSearchInput={setSearchInput}
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1 }));
        }}
        onSortModelChange={handleSortModelChange}
        handleBulkDelete={handleBulkDeleteClick} // Use the new handler
        selectionModel={selectionModel} // Pass the selection model to the DataGrid
        onSelectionModelChange={handleSelectionModelChange}

      />
      <DeleteConfirm
        open={showBulkDeleteConfirm}
        title="Confirm Bulk Delete"
        onClose={() => setShowBulkDeleteConfirm(false)}
        count={selectionModel.length} // Pass the count of selected students
        onConfirm={handleBulkDeleteConfirm}
        loading={loading}
        text={`Are you sure you want to delete ${selectionModel.length} selected student${selectionModel.length > 1 ? 's' : ''}?`}
        subText="Deleting these records will permanently remove all related student data. This action cannot be undone."
      />

      <DeleteConfirm
        open={deleteConfirm}
        title="Deleted Confirmation"
        onClose={() => setDeleteConfirm(false)}
        onConfirm={handleDelete}
        loading={loading}
        text="Are you sure you want to proceed?"
        subText="Deleting this record will permanently remove all related student data. This action cannot be undone."
      />

      <GeneralDialog
        open={isDialogOpen}
        title="Caseload Import"
        fullScreen={true}
        fullwidth
        onClose={() => {
          setIsDialogOpen(false);
          props.setUploadedFile(null);
        }}
      >
        <DialogContent dividers={true} style={{ background: "white" }}>
          <FieldMapping uploadedData={uploadedData} dbHeaders={columns} setNewData={setNewData} />
        </DialogContent>
        <DialogActions>
          <Box mr={2}>
            <BoxButton
              loading={loading}
              onClick={onSubmit}
              color="success"
              variant="contained"
              size="large"
              autoFocus
            // disabled={Object.keys(errors).length !== 0}
            >
              Submit
            </BoxButton>
          </Box>
        </DialogActions>
      </GeneralDialog>
    </Box>
  );
}
