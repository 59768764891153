import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import FormField from "components/FormField";
import OptionButton from "../components/OptionButton";

const Pragmatics = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        pragmatics_Q_01,
        pragmatics_Q_02,
        pragmatics_Q_03,
        pragmatics_Q_04,
        pragmatics_Q_05,
        pragmatics_Q_06,
        pragmatics_Q_07,
        pragmatics_Q_08,
        prag_additional_concerns,
        prag_doc_description
    } = formField.pragmatics;


    const replaceStudentName = (label) => {
        const studentName = values?.generalInformation?.firstName || '[student info not-found]';
        return label.replace('STUDENT', studentName);
    };
    // Render the rich text for background information
    return (
        <Grid container spacing={2} p={2}>
            <OptionButton
                label={replaceStudentName(pragmatics_Q_01.label)}
                type="radio"
                row={false}
                name={pragmatics_Q_01.name}
                options={props.options?.pragmatics_Q_01}
                value={values.pragmatics.pragmatics_Q_01}
                onChange={(name, value) => setFieldValue(pragmatics_Q_01.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={pragmatics_Q_01.toolTipText}
                error={errors.pragmatics?.pragmatics_Q_01 && touched.pragmatics?.pragmatics_Q_01}
            />
            <OptionButton
                label={replaceStudentName(pragmatics_Q_02.label)}
                type="radio"
                row={false}
                name={pragmatics_Q_02.name}
                options={props.options?.pragmatics_Q_02}
                value={values.pragmatics.pragmatics_Q_02}
                onChange={(name, value) => setFieldValue(pragmatics_Q_02.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={pragmatics_Q_02.toolTipText}
                error={errors.pragmatics?.pragmatics_Q_02 && touched.pragmatics?.pragmatics_Q_02}
            />
            <OptionButton
                label={replaceStudentName(pragmatics_Q_03.label)}
                type="radio"
                row={false}
                name={pragmatics_Q_03.name}
                options={props.options?.pragmatics_Q_03}
                value={values.pragmatics.pragmatics_Q_03}
                onChange={(name, value) => setFieldValue(pragmatics_Q_03.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={pragmatics_Q_03.toolTipText}
                error={errors.pragmatics?.pragmatics_Q_03 && touched.pragmatics?.pragmatics_Q_03}
            />
            <OptionButton
                label={replaceStudentName(pragmatics_Q_04.label)}
                type="radio"
                row={false}
                name={pragmatics_Q_04.name}
                options={props.options?.pragmatics_Q_04}
                value={values.pragmatics.pragmatics_Q_04}
                onChange={(name, value) => setFieldValue(pragmatics_Q_04.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={pragmatics_Q_04.toolTipText}
                error={errors.pragmatics?.pragmatics_Q_04 && touched.pragmatics?.pragmatics_Q_04}
            />
            <OptionButton
                label={replaceStudentName(pragmatics_Q_05.label)}
                type="radio"
                row={false}
                name={pragmatics_Q_05.name}
                options={props.options?.pragmatics_Q_05}
                value={values.pragmatics.pragmatics_Q_05}
                onChange={(name, value) => setFieldValue(pragmatics_Q_05.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={pragmatics_Q_05.toolTipText}
                error={errors.pragmatics?.pragmatics_Q_05 && touched.pragmatics?.pragmatics_Q_05}
            />
            <OptionButton
                label={replaceStudentName(pragmatics_Q_06.label)}
                type="radio"
                row={false}
                name={pragmatics_Q_06.name}
                options={props.options?.pragmatics_Q_06}
                value={values.pragmatics.pragmatics_Q_06}
                onChange={(name, value) => setFieldValue(pragmatics_Q_06.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={pragmatics_Q_06.toolTipText}
                error={errors.pragmatics?.pragmatics_Q_06 && touched.pragmatics?.pragmatics_Q_06}
            />
            <OptionButton
                label={replaceStudentName(pragmatics_Q_07.label)}
                type="radio"
                row={false}
                name={pragmatics_Q_07.name}
                options={props.options?.pragmatics_Q_07}
                value={values.pragmatics.pragmatics_Q_07}
                onChange={(name, value) => setFieldValue(pragmatics_Q_07.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={pragmatics_Q_07.toolTipText}
                error={errors.pragmatics?.pragmatics_Q_07 && touched.pragmatics?.pragmatics_Q_07}
            />
            <OptionButton
                label={replaceStudentName(pragmatics_Q_08.label)}
                type="radio"
                row={true}
                name={pragmatics_Q_08.name}
                options={props.options?.pragmatics_Q_08}
                value={values.pragmatics.pragmatics_Q_08}
                onChange={(name, value) => setFieldValue(pragmatics_Q_08.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={pragmatics_Q_08.toolTipText}
                error={errors.pragmatics?.pragmatics_Q_08 && touched.pragmatics?.pragmatics_Q_08}
            />
            <Grid item xs={12}>
                <FormField
                    rows={2}
                    multiline
                    type={prag_additional_concerns.type}
                    label={prag_additional_concerns.label}
                    name={prag_additional_concerns.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(prag_additional_concerns.path, value)}
                    value={values.pragmatics.prag_additional_concerns}
                    placeholder={prag_additional_concerns.placeholder}
                />
            </Grid>



        </Grid>
    );
};

const HeaderComp = ({ title }) => {
    return (
        <Grid item md={12} sm={12} xs={12}>
            <Divider textAlign="left">
                <Typography variant="overline" color="text.secondary" fontWeight={800} fontSize={14}>
                    {title}
                </Typography>
            </Divider>
        </Grid>
    );
};

export default Pragmatics;
