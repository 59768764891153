import React, { useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchAllEvents } from "redux/actions/scheduleAction";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import timeGridPlugin from "@fullcalendar/timegrid";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import { getCurrentSchoolYear } from "components/Common/getSchoolYearOptions";

import { Box, Typography } from "@mui/material";

function AgendaCard() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const calendarRef = useRef(null); // Ref to access the FullCalendar component

  const [events, setEvents] = useState([]);
  const [currentRange, setCurrentRange] = useState({ start: '', end: '' });
  const [currentView, setCurrentView] = useState('timeGridDay');


  const handleDatesSet = (dateInfo) => {
    const { start, end, view } = dateInfo;
    const startISO = start.toISOString();
    const endISO = end.toISOString();
  
    // Compare with current range and view type to avoid unnecessary updates
    if (
      startISO !== currentRange.start || 
      endISO !== currentRange.end || 
      view.type !== currentView
    ) {
      setCurrentRange({ start: startISO, end: endISO });
      setCurrentView(view.type);
      const schoolYear =  getCurrentSchoolYear(); // Example school year
      reloadEvents(startISO, endISO, schoolYear);
    }
  };


  const reloadEvents = (start, end, schoolYear) => {
    setLoading(true);
    dispatch(fetchAllEvents(start, end, schoolYear)).then((response) => {
      if (response && response?.response?.length > 0) {
        const modifiedEvents = response?.response.map((event) => ({
          ...event,
          title: event.eventTitle,
          start: event.startTime,
          end: event.endTime,
        }));
        setLoading(false);
        setEvents(modifiedEvents);
      } else {
        setLoading(false);
        setEvents([]); // Clear events if none are returned
      }
      // const calendarApi = calendarRef.current.getApi();
      // if (calendarApi) {
      //   let time = moment().format('HH:mm:ss');
      //   console.log('time', time);
      //   console.log('calendarApi', calendarApi)
      //   calendarApi.scrollToTime(time); // Scroll to current time
      // }
    });
  };


  useLayoutEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `  
        .fc .fc-timegrid-slot-label {
          height: 2.5em;
          border-color: #f1f1f1;
            // vertical-align: middle;
            // padding: 9px 9px 28px 10px;
            // border: none;
            // text-transform: revert;
            // //letter-spacing: 0.7px;
            // font-weight: lighter;
        }
        thead {
            display: none;
        }
    
        .fc .fc-timegrid-col.fc-day-today {
            background-color: white;
             border-color: #f1f1f1;

        }
        .fc .fc-daygrid-day-frame {
            background-color: white;
        }
    
        .fc-scrollgrid {
            border: none !important;
        }
    
        .fc-scrollgrid td:last-of-type {
            border-right: none !important;
            border-bottom: none !important;
                      border-color: #f1f1f1 !important;

        }
        .fc-timegrid-body {
            scroll-behavior: smooth;
        }
        // .fc .fc-timegrid-now-indicator-arrow {
        //        background: rgb(234, 67, 53);
        //   }

      .fc .fc-timegrid-now-indicator-line {
          border-color: rgb(234, 67, 53);
          border-width: 2px 0px 0px;
        }
    
        `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
  return (
    <Box
      sx={{
        height: "85vh",
        boxShadow:
          "0 5px 6px -3px rgba(23,29,38,.06), 0 9px 12px 1px rgba(23,29,38,.03), 0 3px 16px 2px rgba(23,29,38,.05)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
        flex: "1 1 auto",
        background: "#fff",
        borderRadius: "12px",
        border: "1px solid rgba(24,29,37,.05)",
        position: "relative",
      }}
    >
      <Typography variant="h4" letterSpacing={0.3} p={2}>
        Today's Agenda
      </Typography>
      <FullCalendar
        ref={calendarRef} // Attach the ref
        timeZone="local"
        plugins={[listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView={currentView} // Change to listDay for the day view
        headerToolbar={true}
        weekends={true}
        eventColor="#c1c1c1"
        height={"100%"}
        slotDuration={"1:00"}
        slotLabelInterval={"1:00"}
        events={events}
        nowIndicator={true}
        scrollTimeReset={true}
        datesSet={handleDatesSet}
        // slotMinTime="00:00:00"
        // scrollTime="05:00:00" // Set initial scroll position to midnight or desired time

      />
    </Box>
  );
}

export default AgendaCard;
