import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS

const toolbarOptions = ['bold', 'italic', 'underline', 'list', 'bullet', 'ordered'];

const CustomQuillEditor = ({ value, onChange, placeholder, toolbarId }) => (
  <div>
    {/* Custom Toolbar with dynamic ID and label */}
    <div id={toolbarId || "toolbar"} style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '10px', fontWeight: 500, color: '#656565' }}>Notes</span>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {toolbarOptions.includes('bold') && <button className="ql-bold">Bold</button>}
        {toolbarOptions.includes('italic') && <button className="ql-italic">Italic</button>}
        {toolbarOptions.includes('underline') && <button className="ql-underline">Underline</button>}
        {toolbarOptions.includes('list') && <button className="ql-list" value="bullet">Bullet List</button>}
        {toolbarOptions.includes('ordered') && <button className="ql-list" value="ordered">Numbered List</button>}
      </div>
    </div>
    {/* Quill Editor */}
    <ReactQuill
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      modules={CustomQuillEditor.modules(toolbarId)}
      formats={toolbarOptions}
      theme="snow"
      style={{ minHeight: '50px' }} // Inline styles
    />
  </div>
);

CustomQuillEditor.modules = (toolbarId) => ({
  toolbar: {
    container: `#${toolbarId || "toolbar"}`,
  },
  clipboard: {
    matchVisual: false,
  }
});

CustomQuillEditor.formats = toolbarOptions;

export default CustomQuillEditor;
