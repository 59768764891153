import React, { useRef, useState, useEffect } from "react";
import { Grid, Button, Typography, Box } from "@mui/material";
import FormField from "components/FormField";
import { getIn } from "formik";
import SignatureCanvas from 'react-signature-canvas';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';

const Signature = (props) => {
  const { formField, values, errors, touched, setFieldValue } = props.formData;
  const { user } = useAuth0();

  const {
    sig_add_signature,
    sig_fullname,
    sig_position,
    sig_school_district_name,
    sig_date
  } = formField.signature;

  const sigCanvas = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [signatureError, setSignatureError] = useState(false);

  useEffect(() => {
    // Set default values when component mounts or user changes
    if (user) {
      setFieldValue(sig_fullname.path, user.name || '');
    }
    // Set today's date as default for sig_date
    setFieldValue(sig_date.path, moment().format('YYYY-MM-DD'));
  }, [user, setFieldValue, sig_fullname.path, sig_date.path]);

  console.log(user)
  const handleClear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setFieldValue(sig_add_signature.path, '');
      setIsSaved(false);
      setSignatureError(false);
    }
  };

  const handleDraw = () => {
    setIsDrawing(true);
    setIsSaved(false);
    setSignatureError(false);
  };

  const handleSave = () => {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL('image/png');
      if (dataUrl === 'data:image/png;base64,') {
        setSignatureError(true);
        return;
      }
      setFieldValue(sig_add_signature.path, dataUrl);
      setIsDrawing(false);
      setIsSaved(true);
      setSignatureError(false);
    }
  };

  const handleDateChange = (e) => {
    const formattedDate = moment(e.target.value).utc().toISOString();
    setFieldValue(sig_date.path, formattedDate);
  };

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={12} lg={12}>
        <Typography variant="h6" gutterBottom>
          Choose an option below:
        </Typography>
        {!isDrawing && !isSaved && !values.signature.sig_add_signature && (
          <Typography variant="body1" color="error" gutterBottom>
            No signature found. Please draw your signature.
          </Typography>
        )}
        {!isDrawing && !isSaved && (
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleDraw}
          >
            Draw Signature
          </Button>
        )}
        {isDrawing && (
          <Box mb={2}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSave}
            >
              Save Signature
            </Button>
            <Button 
              variant="outlined" 
              color="secondary" 
              onClick={handleClear}
              sx={{ ml: 2 }}
            >
              Clear
            </Button>
          </Box>
        )}
        {isSaved && (
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleDraw}
          >
            Draw New Signature
          </Button>
        )}
        {signatureError && (
          <Typography variant="body1" color="error" gutterBottom>
            Signature is required. Please draw your signature.
          </Typography>
        )}
      </Grid>
      {isDrawing && (
        <Grid item xs={12} sm={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            Draw your signature:
          </Typography>
          <Box 
            sx={{
              border: '2px dashed lightGrey', 
              borderRadius: '4px', 
              width: 500,
              padding: '8px', 
              position: 'relative'
            }}
          >
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{ 
                width: 500, 
                height: 200, 
                className: 'sigCanvas' 
              }}
              onEnd={() => {}}
            />
          </Box>
        </Grid>
      )}
      {values.signature.sig_add_signature && !isDrawing && !signatureError && (
        <Grid item xs={12} sm={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            Your signature:
          </Typography>
          <img 
            src={values.signature.sig_add_signature} 
            alt="signature" 
            style={{ maxWidth: '100%', height: 'auto' }} 
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} lg={6}>
        <FormField
          type={sig_fullname.type}
          label={sig_fullname.label}
          name={sig_fullname.name}
          size="small"
          isRequired={true}
          path={sig_fullname.path}
          onChange={(e) => setFieldValue(sig_fullname.path, e.target.value)}
          value={values.signature.sig_fullname}
          placeholder={sig_fullname.placeholder}
          error={getIn(errors, sig_fullname.path) && getIn(touched, sig_fullname.path)}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <FormField
          type={sig_position.type}
          label={sig_position.label}
          name={sig_position.name}
          size="small"
          isRequired={true}
          path={sig_position.path}
          onChange={(e) => setFieldValue(sig_position.path, e.target.value)}
          value={values.signature.sig_position}
          placeholder={sig_position.placeholder}
          error={getIn(errors, sig_position.path) && getIn(touched, sig_position.path)}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <FormField
          type={sig_school_district_name.type}
          label={sig_school_district_name.label}
          name={sig_school_district_name.name}
          size="small"
          isRequired={true}
          path={sig_school_district_name.path}
          onChange={(e) => setFieldValue(sig_school_district_name.path, e.target.value)}
          value={values.signature.sig_school_district_name}
          placeholder={sig_school_district_name.placeholder}
          error={getIn(errors, sig_school_district_name.path) && getIn(touched, sig_school_district_name.path)}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <FormField
          type={sig_date.type}
          label={sig_date.label}
          name={sig_date.name}
          size="small"
          isRequired={true}
          path={sig_date.path}
          onChange={handleDateChange}
          value={moment(values.signature.sig_date).format('YYYY-MM-DD')}
          placeholder={sig_date.placeholder}
          error={getIn(errors, sig_date.path) && getIn(touched, sig_date.path)}
        />
      </Grid>
    </Grid>
  );
};

export default Signature;
