import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Divider, Chip, Button} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FormField from "components/FormField";
import moment from "moment";
import debounce from "lodash/debounce";
import { searchStudent } from "redux/actions/studentActions";
import { getIn } from "formik";
import { grey } from '@mui/material/colors';
import { getCurrentSchoolYear } from "components/Common/getSchoolYearOptions";

const GeneralInfomation = (props) => {
  const dispatch = useDispatch();
  const { searchedResult } = useSelector((state) => ({
    searchedResult: state.studentReducer.searchedResult,
  }));
  const [schoolYear, setSchoolYear] = useState(getCurrentSchoolYear()); // Function to get the current school year

  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const { formField, values, errors, touched, setFieldValue, setTouched } = props.formData;
  const {
    firstName,
    middleName,
    lastName,
    caseManager,
    evaluatorName,
    dateOfBirth,
    school,
    schoolDistrict,
    primaryLanguage,
    gender,
    grade,
    currentProgram,
    primaryDisability,
    testDates,
    secondaryDisability,
    identificationDate,
    serviceMinutes,
    // reasonForTesting,
  } = formField.generalInformation;

  const [dateInput, setDateInput] = useState('');
  const [dateError, setDateError] = useState('');

  const handleDateChange = (newDate, setFieldValue, values) => {
    if (newDate) {
      const formattedDate = moment(newDate).utc().toISOString();
      if (!values.generalInformation.testDates.includes(formattedDate)) {
        setFieldValue("generalInformation.testDates", [...values.generalInformation.testDates, formattedDate]);
        setDateInput(''); 
        setDateError('');
      } else {
        setDateError('Date already exists');
      }
    }
  };

  const handleDeleteDate = (dateToDelete, setFieldValue, values) => {
    setFieldValue(
      "generalInformation.testDates",
      values.generalInformation.testDates.filter((date) => date !== dateToDelete)
    );
  };

  const handleChangeStudent = (option) => {

    if (option === null) {
      setSelectedStudent(option);
      setFieldValue("generalInformation.firstName", '');
      setFieldValue("generalInformation.lastName", '');
      setFieldValue("generalInformation.grade", "");
      setFieldValue("generalInformation.school", "");
      setFieldValue("generalInformation.caseManager", "");
      setFieldValue("generalInformation.dateOfBirth", "");
      setFieldValue("generalInformation.gender", "");
    }
if (option !== null) {
      setSelectedStudent(option);
      // Ensure option has all necessary fields
      setFieldValue("generalInformation.firstName", option?.firstName);
      setFieldValue("generalInformation.lastName", option?.lastName);
      setFieldValue("generalInformation.grade", option?.currentAcademicYear?.grade);
      setFieldValue("generalInformation.school", option?.currentAcademicYear?.school);
      setFieldValue("generalInformation.caseManager", option?.caseManager);
      setFieldValue("generalInformation.dateOfBirth", option?.dateOfBirth ? moment(option.dateOfBirth).format('YYYY-MM-DD') : "");
      // Handle gender with a fallback if not found in GenderList
      const genderValue = props.options?.GenderList.find((g) => g.label === option?.gender) || null;
      setFieldValue("generalInformation.gender", genderValue);

       // Mark fields as touched
       setTouched({
        generalInformation: {
          firstName: false,
          lastName: false,
          grade: false,
          school: false,
          caseManager: false,
          dateOfBirth: false,
          gender: false,
        },
      }, false);
    }
  };

  const handleInputChange = debounce((inputValue) => {
    if (inputValue.length > 1 || inputValue === '') {
      setLoading(true);
      dispatch(searchStudent(inputValue, schoolYear)).then((response) => {
        setLoading(false);
      });
    }
  }, 500);

  useEffect(() => {
    return () => {
      handleInputChange.cancel();
    };
  }, []);

  useEffect(() => {
    dispatch(searchStudent("", schoolYear));
  }, []);

  return (
    <Grid container spacing={2} p={0}>
      <Grid item xs={12}>
        <FormField
          isClearable
          inputType="select"
          label="Select Student"
          isRequired={false}
          name={"student"}
          size="small"
          options={searchedResult && searchedResult.response}
          onInputChange={handleInputChange}
          onChange={handleChangeStudent}
          value={selectedStudent}
          isLoading={loading}
          getOptionLabel={(option) => option.firstName + " " + option.lastName}
          getOptionValue={(option) => option._id}
          placeholder={"Select Student"}
        />
      </Grid>
      <HeaderComp title="(OR)" />
      <Grid item xs={12} sm={4} lg={4}>
        <FormField
          type={firstName.type}
          label={firstName.label}
          name={firstName.name}
          size="small"
          isRequired={true}
          nested={true}
          path={firstName.path}
          onChange={(e) => setFieldValue(firstName.path, e.target.value)}
          value={values.generalInformation.firstName}
          placeholder={firstName.placeholder}
          error={getIn(errors, firstName.path) && getIn(touched, firstName.path)}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={4}>
        <FormField
          type={middleName.type}
          label={middleName.label}
          name={middleName.name}
          size="small"
          isRequired={false}
          path={middleName.path}
          onChange={(e) => setFieldValue(middleName.path, e.target.value)}
          value={values.generalInformation.middleName}
          placeholder={middleName.placeholder}
          error={getIn(errors, middleName.path) && getIn(touched, middleName.path)}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={4}>
        <FormField
          type={lastName.type}
          label={lastName.label}
          name={lastName.name}
          size="small"
          isRequired={true}
          nested={true}
          path={lastName.path}
          onChange={(e) => setFieldValue(lastName.path, e.target.value)}
          value={values.generalInformation.lastName}
          placeholder={lastName.placeholder}
          error={getIn(errors, lastName.path) && getIn(touched, lastName.path)}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={4}>
        <FormField
          type={dateOfBirth.type}
          label={dateOfBirth.label}
          name={dateOfBirth.name}
          size="small"
          isRequired={true}
          nested={true}
          path={dateOfBirth.path}
          onChange={(e) => setFieldValue(dateOfBirth.path, e.target.value)}
          value={values.generalInformation.dateOfBirth}
          placeholder={dateOfBirth.placeholder}
          error={getIn(errors, dateOfBirth.path) && getIn(touched, dateOfBirth.path)}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={4}>
        <FormField
          inputType='select'
          isClearable
          label={gender.label}
          isRequired={true}
          name={gender.name}
          size="small"
          nested={true}
          path={gender.path}
          options={props.options?.GenderList}
          onChange={(option) => setFieldValue(gender.path, option)}
          value={values.generalInformation.gender}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={gender.placeholder}
          error={getIn(errors, gender.path) && getIn(touched, gender.path)}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          isClearable
          inputType={'select'}
          type={grade.type}
          label={grade.label}
          nested={true}
          path={grade.path}
          options={props.options?.GradeList}
          onChange={(option) => setFieldValue(grade.path, option)}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          size="small"
          isRequired={true}
          value={values.generalInformation.grade}
          placeholder={'Select ...'}
          error={getIn(errors, grade.path) && getIn(touched, grade.path)}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <FormField
          type={school.type}
          label={school.label}
          name={school.name}
          size="small"
          isRequired={true}
          path={school.path}
          nested={true}
          onChange={(e) => setFieldValue(school.path, e.target.value)}
          value={values.generalInformation.school}
          placeholder={school.placeholder}
          error={getIn(errors, school.path) && getIn(touched, school.path)}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <FormField
          type={schoolDistrict.type}
          label={schoolDistrict.label}
          name={schoolDistrict.name}
          size="small"
          isRequired={true}
          nested={true}
          path={schoolDistrict.path}
          onChange={(e) => setFieldValue(schoolDistrict.path, e.target.value)}
          value={values.generalInformation.schoolDistrict}
          placeholder={schoolDistrict.placeholder}
          error={getIn(errors, schoolDistrict.path) && getIn(touched, schoolDistrict.path)}
        />
      </Grid> 
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          type={evaluatorName.type}
          label={evaluatorName.label}
          name={evaluatorName.name}
          size="small"
          isRequired={true}
          nested={true}
          path={evaluatorName.path}
          onChange={(e) => setFieldValue(evaluatorName.path, e.target.value)}
          value={values.generalInformation.evaluatorName}
          placeholder={evaluatorName.placeholder}
          error={getIn(errors, evaluatorName.path) && getIn(touched, evaluatorName.path)}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          type={caseManager.type}
          label={caseManager.label}
          name={caseManager.name}
          size="small"
          isRequired={true}
          nested={true}
          path={caseManager.path}
          onChange={(e) => setFieldValue(caseManager.path, e.target.value)}
          value={values.generalInformation.caseManager}
          placeholder={caseManager.placeholder}
          error={getIn(errors, caseManager.path) && getIn(touched, caseManager.path)}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <FormField
          isClearable
          inputType="select"
          label={primaryLanguage.label}
          isRequired={true}
          nested={true}
          path={primaryLanguage.path}
          name={primaryLanguage.name}
          size="small"
          options={props.options?.Languages}
          onChange={(option) => setFieldValue(primaryLanguage.path, option)}
          value={values.generalInformation.primaryLanguage}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={'Select ...'}
          error={getIn(errors, primaryLanguage.path) && getIn(touched, primaryLanguage.path)}
        />
      </Grid>
      <HeaderComp title="Test Date(s)" />

      <Grid item xs={8} sm={6} lg={3}>
        <FormField
          type="date"
          label="Test Date(s)"
          name="testDateInput"
          size="small"
          variant="outlined"
          placeholder="Enter test date"
          value={dateInput}
          onChange={(e) => {
            setDateError('');
            setDateInput(e.target.value)
          }
        }
          error={Boolean(dateError)}
          fullWidth
          sx={{ marginBottom: -0.5 }}
        />
        <Typography component="div" variant="body2" color="error">
          {dateError}
        </Typography>
      </Grid>
      <Grid item xs={2} sm={2} lg={2}>
          <Button
            size="medium"
            variant="contained"
            sx={{ marginTop: 3.5, height: 38, width: "7rem", borderRadius: 10 }}
            onClick={() => handleDateChange(dateInput, setFieldValue, values)} 
            disabled={dateInput === ''}
          >
            ADD
          </Button>
        </Grid>



      {values.generalInformation.testDates.length > 0 && (
        <Grid item xs={12}>
            <Typography component="label" variant="body1" fontWeight="500" color='text.secondary'>
              Selected Test Date(s)
            </Typography>
          <Box component={'div'} mt={1}>
          {values.generalInformation.testDates.map((date) => (
            <Chip
              key={date}
              // variant="outlined"
              size="large"
              color="secondary"
              label={moment(date).format('LL')}
              onDelete={() => handleDeleteDate(date, setFieldValue, values)}
              sx={{
                marginRight: "1em",
                marginTop: 0.5,
                borderRadius: 1,
                letterSpacing: 1.4,
                backgroundColor: '#171d26', // Custom background color
                color: '#fff', // Custom text color
                '& .MuiChip-deleteIcon': {
                  color: grey[400], // Custom delete icon color
                },
              }}
            />
          ))}
          </Box>
        </Grid>
      )}
      <HeaderComp title="Additional Information" />
      <Grid item xs={12} sm={12} lg={6}>
        <FormField
          isClearable
          inputType="select"
          path={primaryDisability.path}
          label={primaryDisability.label}
          isRequired={false}
          name={primaryDisability.name}
          size="small"
          options={props.options?.PrimaryDisability}
          onChange={(option) => setFieldValue(primaryDisability.path, option)}
          value={values.generalInformation.primaryDisability}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={primaryDisability.placeholder}
          error={getIn(errors, primaryDisability.path) && getIn(touched, primaryDisability.path)}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <FormField
          isClearable
          inputType="select"
          label={secondaryDisability.label}
          isRequired={false}
          name={secondaryDisability.name}
          size="small"
          path={secondaryDisability.path}
          options={props.options?.PrimaryDisability}
          onChange={(option) => setFieldValue(secondaryDisability.path, option)}
          value={values.generalInformation.secondaryDisability}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={secondaryDisability.placeholder}
          error={getIn(errors, secondaryDisability.path) && getIn(touched, secondaryDisability.path)}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={3}>
        <FormField
          type={identificationDate.type}
          label={identificationDate.label}
          name={identificationDate.name}
          size="small"
          isRequired={false}
          path={identificationDate.path}
          onChange={(e) => {
            const formattedDate = moment(e.target.value).utc().toISOString();
            setFieldValue(identificationDate.path, formattedDate);
          }} 
          value={values.generalInformation.identificationDate
            ? moment(values.generalInformation.identificationDate).format('YYYY-MM-DD')
            : ""
          }         
          placeholder={identificationDate.placeholder}
          error={getIn(errors, identificationDate.path) && getIn(touched, identificationDate.path)}
          toolTipText={identificationDate.toolTipText}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={3}>
        <FormField
          type={serviceMinutes.type}
          label={serviceMinutes.label}
          name={serviceMinutes.name}
          size="small"
          isRequired={false}
          path={serviceMinutes.path}
          onChange={(e) => setFieldValue(serviceMinutes.path, e.target.value)}
          toolTipText={serviceMinutes.toolTipText}
          value={values.generalInformation.serviceMinutes}
          placeholder={serviceMinutes.placeholder}
          error={getIn(errors, serviceMinutes.path) && getIn(touched, serviceMinutes.path)}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <FormField
          isClearable
          inputType="select"
          label={currentProgram.label}
          isRequired={false}
          name={currentProgram.name}
          path={currentProgram.path}
          size="small"
          options={props.options?.EnrollmentProgram}
          onChange={(option) => setFieldValue(currentProgram.path, option)}
          value={values.generalInformation.currentProgram}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={currentProgram.placeholder}
          error={getIn(errors, currentProgram.path) && getIn(touched, currentProgram.path)}
        />
      </Grid>
     
    </Grid>
  );
};

const HeaderComp = ({ title }) => {
  return (
    <Grid item md={12} sm={12} xs={12}>
      <Divider textAlign="left">
        <Typography variant="overline" color="primary" fontWeight={800} fontSize={14}>
          {title}
        </Typography>
      </Divider>
    </Grid>
  );
};

export default GeneralInfomation;
