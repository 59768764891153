import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { email, password, firstName, lastName },
} = checkout;

const validations = [
  Yup.object().shape({
    [email.name]: Yup.string().required(email.errorMsg),
    [password.name]: Yup.string().required(password.errorMsg),
  }),
  Yup.object().shape({
    [email.name]: Yup.string().required(email.errorMsg),
    [password.name]: Yup.string().required(password.errorMsg),
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
  }),
  Yup.object().shape({
    [email.name]: Yup.string().required(email.errorMsg),
  }),
  Yup.object().shape({
    [password.name]: Yup.string().required(password.errorMsg),
  }),
];

export default validations;
