import checkout from "./form";

const {
  formField: {
    eventTitle,
    startTime,
    endTime,
    isRecurring,
    recurrencePattern,
    isBillable,
    studentId,
    eventType,
    sendEmailAlert,
    goals,
    duration,
    assignedAssistant,
  },
} = checkout;

const initialValues = {
  [eventTitle.name]: "",
  [startTime.name]: "",
  [endTime.name]: "",
  [isRecurring.name]: false,
  [isBillable.name]: false,
  [recurrencePattern.name]: "",
  [studentId.name]: null,
  [eventType.name]: "",
  [sendEmailAlert.name]: false,
  [goals.name]: [],
  [duration.name]: "",
  [assignedAssistant.name]: []
};

export default initialValues;
