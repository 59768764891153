import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBox from "components/Box/SearchBox";
import { getAllReports } from "redux/actions/reportAction";
import SLoadingIndicator from "components/SLoadingIndicator";
import SMessageCard from "components/SMessageCard";
import { Pagination, Box, Paper, Divider, Grid, Typography, Stack, Container } from "@mui/material";
import { CopyAll, MoreVert, HourglassEmpty, ConstructionOutlined } from "@mui/icons-material";
import { blue, grey, purple } from "@mui/material/colors";
import moment from "moment";
import { groupBy } from "lodash";
import UpgradePlanDialog from "global/UpgradePlan";
import { useNavigate } from "react-router";

import NoData from "../../../assets/icons/no-results.png";

const ReportAllList = ({ data, onItemClick }) => {
  const handleItemClick = (item) => {
    onItemClick && onItemClick(item);
  };
  const groupedData = groupBy(data, (item) => moment(item.createdAt).format("YYYY-MM-DD"));

  return (
    <div>
      {Object.keys(groupedData).map((date) => (
        <div key={date}>
          <Typography variant="subtitle1" color={"text.secondary"} mb={1}>
            {moment(date).format("LL")}
          </Typography>
          {groupedData[date].map((item) => (
            <Paper
              key={item._id}
              elevation={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderLeft: `2px solid ${blue[300]}`, // Replace with your desired color
                padding: "10px 12px 8px 16px",
                background: "#fff",
                marginBottom: 1,
                position: "relative",
                cursor: "pointer",
                borderRadius: "1px",
                backgroundColor: "#fff",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.01)",
                  boxShadow: "0px 0px 10px 0px rgb(13 183 242 / 9%)",
                  cursor: "pointer",
                  borderColor: (theme) => theme.palette.primary["main"],
                },
              }}
              onClick={() => handleItemClick(item)}
            >
              <Grid container spacing={2} sx={{ flex: 1 }}>
                <Grid item xs={9}>
                  <Stack
                    spacing={2}
                    useFlexGap
                    direction={{ xs: "column", sm: "row" }}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Box width={250} sx={{ maxWidth: 300, display: "flex" }}>
                      <Typography
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        fontWeight={500}
                        variant="body1"
                        textTransform={"capitalize"}
                      >
                        {item.generalInformation.firstName} {item.generalInformation.lastName}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs sx={{ justifyContent: "flex-end", display: "flex" }}>
                  <Stack spacing={2} direction={"row"}>
                    <CopyAll fontSize="medium" style={{ marginTop: 0, color: grey[500] }} />
                    <MoreVert fontSize="medium" style={{ color: grey[500] }} />
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </div>
      ))}
    </div>
  );
};

export default function ReportList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    navigate("/dashboard");
  };

  const { reportsList } = useSelector((state) => ({
    reportsList: state.reportReducer.reportsList,
  }));

  const response = reportsList?.response?.length > 0 ? reportsList?.response : [];

  const [page, setPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [inviteById, setInviteById] = useState(null);
  const [totalCount, setTotalCount] = useState(0);

  const perPage = 10; // Number of items per page

  const payload = {
    filter: {
      pageNumber: page,
      searchValue: searchFilter,
    },
  };
  const handleSearch = (searchText) => {
    setSearchFilter(searchText);
    // Your search logic here
  };

  const handleClear = () => {
    setSearchFilter("");
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleItemClick = (item) => {
    setInviteById(item);
  };

  const fetchData = async () => {
    setLoading(true);
    dispatch(getAllReports(payload))
      .then((response) => {
        setLoading(false);
        setTotalCount(response.totalCount);
        if (response.allowAccess === false) {
          setDialogOpen(true);
        }
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [page, searchFilter]); // Fetch data when the page changes

  return (
    <React.Fragment>
      {loading ? (
        <SLoadingIndicator height="30vh" />
      ) : response?.length > 0 ? (
        <Container maxWidth="md" sx={{ mb: 3, mt: 3 }}>
          <SearchBox
            onSearch={handleSearch}
            onClear={handleClear}
            placeholder="Search Reports by Student Name"
          />
          <ReportAllList data={response} onItemClick={handleItemClick} />
          <Box style={{ display: "flex", justifyContent: "flex-end" }} mt={3}>
            <Pagination
              count={Math.ceil(totalCount / perPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
              shape="circular"
              variant="outlined"
              size="medium"
              showFirstButton
              showLastButton
            />
          </Box>
        </Container>
      ) : (
        <Box height="70vh">
          <SMessageCard
            imgUrl={NoData}
            imgWidth="5rem"
            text="No Records Found"
            subText="Start creating reports by clicking on 'New Report'."
          />
        </Box>
      )}
      <UpgradePlanDialog open={dialogOpen} onClose={handleCloseDialog} />
    </React.Fragment>
  );
}
