import behavioralObservationForm from "./Forms/behavioralObservationForm";
import oralPeripheralMechForm from "./Forms/oralPeripheralMechForm";
import voiceObservationForm from "./Forms/voiceObservationForm";
import articulationIntellForm from "./Forms/articulationIntellForm";
import pragmaticsForm from "./Forms/pragmaticsForm";
import fluencyForm from "./Forms/fluencyForm";
import recommendationsForm from "./Forms/recommendationsForm";
import prevAssesmentForm from "./Forms/prevAssesmentForm";
import generalInformationForm from "./Forms/generalInformationForm";
import reportSummaryForm from "./Forms/reportSummaryForm";
import eligibilityForm from "./Forms/eligibilityForm";
import normativeChartForm from "./Forms/normativeChartForm";
import signatureForm from "./Forms/signatureForm";
import backgroundInfoForm from "./Forms/backgroundInfoForm";

const form = {
  formId: "report",
  formField: {
    generalInformation: generalInformationForm.generalInformation,
    purposeOfEvaluation: {
      evaluationType: {
        name: "evaluationType",
        label: "Evaluation Type",
        type: "text",
        path: "purposeOfEvaluation.evaluationType",
        placeholder: "Select ...",
        errorMsg: "evaluationType is required.",
        toolTipText: "What specific areas or needs are addressed during the speech and language services?",
        invalidMsg: "",
      },
      eval_type_referredBy: {
        name: "eval_type_referredBy",
        label: "Referred By",
        type: "text",
        path: "purposeOfEvaluation.eval_type_referredBy",
        placeholder: "Select ...",
        errorMsg: "Referred by is required.",
        toolTipText: "Who referred the student for evaluation?",
        invalidMsg: "",
      },
      
      eval_type_dateOfReferal: {
        name: "eval_type_dateOfReferal",
        label: "Date of Referral",
        type: "date",
        path: "purposeOfEvaluation.eval_type_dateOfReferal",
        placeholder: "eval_type_dateOfReferal",
        errorMsg: "Date of referral is required.",
        toolTipText: "When was the student referred for evaluation?",
        invalidMsg: "",
      },
      eval_type_content: {
        name: "eval_type_content",
        label: "Description",
        type: "text",
        path: "purposeOfEvaluation.eval_type_content",
        placeholder: "eval_type_content",
        errorMsg: "Description is required.",
        toolTipText: "Provide details of the evaluation.",
        invalidMsg: "",
      },
      eval_referred_title: {
        name: "eval_referred_title",
        label: "Title",
        type: "text",
        placeholder: "Select ...",
        path: "purposeOfEvaluation.eval_referred_title",
        errorMsg: "Invalid",
        toolTipText: "Any other relevant information.",
        invalidMsg: "",
      },
        eval_referred_name: {
          name: "eval_referred_name",
          label: "Name",
          type: "text",
          placeholder: "Name of the Person",
          path: "purposeOfEvaluation.eval_referred_name",
          errorMsg: "Invalid",
          toolTipText: "eval_referred_name",
          invalidMsg: "",
        },
        eval_referred_hospital_name: {
          name: "eval_referred_hospital_name",
          label: "Hospital Name",
          type: "text",
          placeholder: "Name of the Hospital",
          path: "purposeOfEvaluation.eval_referred_hospital_name",
          errorMsg: "Invalid",
          toolTipText: "Any other relevant information.",
          invalidMsg: "",
        },
        eval_type_referral_other: {
          name: "eval_type_referral_other",
          label: "If Other, Specify",
          type: "text",
          placeholder: "Other",
          path: "purposeOfEvaluation.eval_type_referral_other",
          errorMsg: "Invalid",
          toolTipText: "Any other relevant information.",
          invalidMsg: "",
        },

      eval_type_other: {
        name: "eval_type_other",
        label: "If Other, Specify",
        type: "text",
        placeholder: "Other",
        path: "purposeOfEvaluation.eval_type_other",
        errorMsg: "Invalid",
        toolTipText: "Any other relevant information.",
        invalidMsg: "",
      },

      eval_type_doc_content: {
        name: "eval_type_doc_content",
        label: "eval_type_doc_content",
        type: "text",
        path: "purposeOfEvaluation.eval_type_doc_content",

        placeholder: "eval_type_doc_content",
        errorMsg: "Invalid",
        toolTipText: "eval_type_doc_content",
        invalidMsg: "",
      },
      eval_type_doc_referral_content: {
        name: "eval_type_doc_referral_content",
        label: "eval_type_doc_referral_content",
        type: "text",
        path: "purposeOfEvaluation.eval_type_doc_referral_content",
        placeholder: "eval_type_doc_referral_content",
        errorMsg: "eval_type_doc_referral_content",
        toolTipText: "eval_type_doc_referral_content",
        invalidMsg: "",
      },
    },
    prevAssesment: prevAssesmentForm.prevAssesment,
    teacherInput: {
      teacher_input_Q_01: {
        name: "teacher_input_Q_01",
        label: "Teacher's Name",
        type: "text",
        path: "teacherInput.teacher_input_Q_01",
        placeholder: "Enter teacher's name",
        toolTipText: "",
        errorMsg: "Teacher's Name is required.",
        invalidMsg: "",
      },
      teacher_input_Q_02: {
        name: "teacher_input_Q_02",
        label: "Teacher's Position",
        type: "text",
        placeholder: "Enter teacher's position",
        errorMsg: "Teacher's Position is required.",
        path: "teacherInput.teacher_input_Q_02",
        invalidMsg: "",
      },
      teacher_input_Q_03: {
        name: "teacher_input_Q_03",
        label: "Information Collected",
        type: "text",
        path: "teacherInput.teacher_input_Q_03",
        placeholder: "Information collected by teacher",
        errorMsg: "Information Collected is required.",
        invalidMsg: "",
      },
      teacher_input_Q_03_other: {
        name: "teacher_input_Q_03_other",
        label: "Specify",
        type: "text",
        path: "teacherInput.teacher_input_Q_03_other",
        placeholder: "Specify",
        errorMsg: "teacher_input_Q_03_other",
        invalidMsg: "",
      },
      teacher_input_Q_04: {
        name: "teacher_input_Q_04",
        label: "Date and Time",
        type: "date",
        path: "teacherInput.teacher_input_Q_04",
        placeholder: "Date and time ",
        errorMsg: "Date and Time is required.",
        invalidMsg: "",
      },
      teacher_input_Q_05: {
        name: "teacher_input_Q_05",
        label: "Students Strength",
        type: "text",
        path: "teacherInput.teacher_input_Q_05",
        placeholder: "Students strength",
        errorMsg: "Perspective Strength is required.",
        invalidMsg: "",
      },
      teacher_input_Q_06: {
        name: "teacher_input_Q_06",
        label: "Receptive Language Concern",
        type: "text",
        path: "teacherInput.teacher_input_Q_06",
        placeholder: "Receptive language concerns",
        errorMsg: "Receptive Language Concern is required.",
        invalidMsg: "",
      },
      teacher_input_Q_06_other: {
        name: "teacher_input_Q_06_other",
        label: "If Other, Specify",
        type: "text",
        path: "teacherInput.teacher_input_Q_06_other",
        placeholder: "Specify",
        errorMsg: "teacher_input_Q_06_other",
        invalidMsg: "",
      },
      teacher_input_Q_07: {
        name: "teacher_input_Q_07",
        label: "Expressive Language Concern",
        type: "text",
        path: "teacherInput.teacher_input_Q_07",
        placeholder: "Expressive language concerns",
        errorMsg: "Expressive Language Concern is required.",
        invalidMsg: "",
      },
      teacher_input_Q_07_other: {
        name: "teacher_input_Q_07_other",
        label: "Specify",
        type: "text",
        path: "teacherInput.teacher_input_Q_07_other",
        placeholder: "Specify",
        errorMsg: "teacher_input_Q_07_other",
        invalidMsg: "",
      },
      teacher_input_Q_08: {
        name: "teacher_input_Q_08",
        label: "Articulation Concern",
        type: "text",
        path: "teacherInput.teacher_input_Q_08",
        placeholder: "Articulation concerns",
        errorMsg: "Articulation Concern is required.",
        invalidMsg: "",
      },
      teacher_input_Q_08_other: {
        name: "teacher_input_Q_08_other",
        label: "Specify",
        type: "text",
        path: "teacherInput.teacher_input_Q_08_other",
        placeholder: "Specify",
        errorMsg: "teacher_input_Q_08_other",
        invalidMsg: "",
      },
      teacher_input_Q_09: {
        name: "teacher_input_Q_09",
        label: "Fluency Concern",
        type: "text",
        path: "teacherInput.teacher_input_Q_09",
        placeholder: "Fluency concerns",
        errorMsg: "Fluency Concern is required.",
        invalidMsg: "",
      },
      teacher_input_Q_10: {
        name: "teacher_input_Q_10",
        label: "Social Interaction Concern",
        type: "text",
        path: "teacherInput.teacher_input_Q_10",
        placeholder: "Social interaction concerns",
        errorMsg: "Social Interaction Concern is required.",
        invalidMsg: "",
      },
      teacher_input_Q_11: {
        name: "teacher_input_Q_11",
        label: "How does STUDENT perform in reading comprehension?",
        type: "text",
        path: "teacherInput.teacher_input_Q_11",
        placeholder: "Reading skills evaluation",
        errorMsg: "Reading Skills is required.",
        invalidMsg: "",
      },
      teacher_input_Q_12: {
        name: "teacher_input_Q_12",
        label: "How does STUDENT perform in mathematical problem-solving?",
        type: "text",
        path: "teacherInput.teacher_input_Q_12",
        placeholder: "Math skills evaluation",
        errorMsg: "Math Skills is required.",
        invalidMsg: "",
      },
      teacher_input_Q_13: {
        name: "teacher_input_Q_13",
        label: "How does STUDENT  perform in written expression?",
        type: "text",
        path: "teacherInput.teacher_input_Q_13",
        placeholder: "Writing skills evaluation",
        errorMsg: "Writing Skills is required.",
        invalidMsg: "",
      },
      teacher_input_Q_14: {
        name: "teacher_input_Q_14",
        label: "How does STUDENT behave and participate in class?",
        type: "text",
        path: "teacherInput.teacher_input_Q_14",
        placeholder: "Class performance evaluation",
        errorMsg: "Class Performance is required.",
        invalidMsg: "",
      },
      teacher_input_Q_15: {
        name: "teacher_input_Q_15",
        label: "How does STUDENT interact with peers and manage emotions?",
        type: "text",
        path: "teacherInput.teacher_input_Q_15",
        placeholder: "Social-emotional development evaluation",
        errorMsg: "Social-Emotional Development is required.",
        invalidMsg: "",
      },
      teacher_input_Q_16: {
        name: "teacher_input_Q_16",
        label: "Attendance",
        type: "text",
        path: "teacherInput.teacher_input_Q_16",
        placeholder: "Attendance evaluation",
        errorMsg: "Attendance is required.",
        invalidMsg: "",
      },
      teacher_input_Q_17_A: {
        name: "teacher_input_Q_17_A",
        label: "How does STUDENT perform in gross motor skills such as running and jumping?",
        type: "text",
        path: "teacherInput.teacher_input_Q_17_A",
        errorMsg: "teacher_input_Q_17_A",
        invalidMsg: "",
      },
      teacher_input_Q_17_B: {
        name: "teacher_input_Q_17_B",
        label: "How does STUDENT perform in fine motor skills such as using scissors or tying shoelaces?",
        type: "text",
        path: "teacherInput.teacher_input_Q_17_B",
        errorMsg: "teacher_input_Q_17_B",
        invalidMsg: "",
      },
      teacher_input_Q_17_C: {
        name: "teacher_input_Q_17_C",
        label: "How does STUDENT's handwriting or penmanship compare to peers?",
        type: "text",
        path: "teacherInput.teacher_input_Q_17_C",
        errorMsg: "teacher_input_Q_17_C",
        invalidMsg: "",
      },
      teacher_input_Q_18: {
        name: "teacher_input_Q_18",
        label: "Overall Performance",
        type: "text",
        path: "teacherInput.teacher_input_Q_18",
        placeholder: "Overall performance evaluation",
        errorMsg: "Overall Performance is required.",
        invalidMsg: "",
      },
      teacher_input_Q_19: {
        name: "teacher_input_Q_19",
        label: "Has any instructional modifications have been implemented to support STUDENT?",
        type: "text",
        path: "teacherInput.teacher_input_Q_19",
        placeholder: "Instruction modifications",
        errorMsg: "Instruction Modifications is required.",
        invalidMsg: "",
      },
      teacher_input_Q_19_other: {
        name: "teacher_input_Q_19_other",
        label: "If Yes, Specify",
        type: "text",
        path: "teacherInput.teacher_input_Q_19_other",
        placeholder: "Specify other services",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      teacher_input_Q_20: {
        name: "teacher_input_Q_20",
        label: "Have you implemented alternative teaching methods to address the STUDENT's needs?",
        type: "text",
        path: "teacherInput.teacher_input_Q_20",
        placeholder: "Alternative teaching strategies",
        errorMsg: "Alternative Teaching Strategies is required.",
        invalidMsg: "",
      },
      teacher_input_Q_20_other: {
        name: "teacher_input_Q_20_other",
        label: "If Yes, Specify",
        type: "text",
        path: "teacherInput.teacher_input_Q_20_other",
        placeholder: "Specify other services",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      teacher_input_Q_21: {
        name: "teacher_input_Q_21",
        label: "Has any  accommodations  been provided to assist the STUDENT's learning?",
        type: "text",
        path: "teacherInput.teacher_input_Q_21",
        placeholder: "Accommodations provided",
        errorMsg: "Accommodations is required.",
        invalidMsg: "",
      },
      teacher_input_Q_21_other: {
        name: "teacher_input_Q_21_other",
        label: "If Yes, Specify",
        type: "text",
        path: "teacherInput.teacher_input_Q_21_other",
        placeholder: "Specify other services",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      teacher_input_Q_22: {
        name: "teacher_input_Q_22",
        label: "Have you implemented specialized resources or materials been incorporated into the STUDENT's educational plan ?",
        type: "text",
        path: "teacherInput.teacher_input_Q_22",
        placeholder: "Resource materials used",
        errorMsg: "Resource Materials is required.",
        invalidMsg: "",
      },
      teacher_input_Q_22_other: {
        name: "teacher_input_Q_22_other",
        label: "If Yes, Specify",
        type: "text",
        path: "teacherInput.teacher_input_Q_22_other",
        placeholder: "Specify other services",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      teacher_additional_info: {
        name: "teacher_additional_info",
        label: "Any other additional information",
        type: "text",
        path: "teacherInput.teacher_additional_info",
        placeholder: "Please type here ...",
        errorMsg: "Additional Information is required.",
        invalidMsg: "",
      },
      teacher_doc_description: {
        name: "teacher_doc_description",
        label: "Teacher's Documentation Description",
        type: "text",
        placeholder: "Description of teacher's documentation",
        errorMsg: "Teacher's Documentation Description is required.",
        invalidMsg: "",
      },
    },
    //parent input

    parentInput: {
      parent_input_Q_01: {
        name: "parent_input_Q_01",
        label: "Name of the Person",
        path: "parentInput.parent_input_Q_01",
        type: "text",
        toolTipText: "TODO",
        placeholder: "Please type the selected relationship person name",
        errorMsg: "Parent/Guardian name is required.",
        invalidMsg: "Invalid input for parent/guardian name.",
      },
      parent_input_Q_02: {
        name: "parent_input_Q_02",
        label: "Relationship to Student",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_02",
        placeholder: "Enter relationship to student",
        errorMsg: "Relationship to student is required.",
        invalidMsg: "Invalid input for relationship to student.",
      },
      parent_input_Q_02_other: {
        name: "parent_input_Q_02_other",
        label: "If Other, Specify",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_02_other",
        placeholder: "Please type here ...",
        errorMsg: "Relationship to student other is required.",
        invalidMsg: "Invalid input for other to student.",
      },
      parent_input_Q_03: {
        name: "parent_input_Q_03",
        label: "Information Collected Date",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_03",
        placeholder: "",
        errorMsg: "Information collected date is required.",
        invalidMsg: "Invalid date format.",
      },
      parent_input_Q_03_other: {
        name: "parent_input_Q_03_other",
        label: "If Other, Specify",
        type: "text",
        path: "parentInput.parent_input_Q_03_other",
        placeholder: "Specify other services",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      parent_input_Q_04: {
        name: "parent_input_Q_04",
        label: "Interview Date",
        type: "date",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_04",
        placeholder: "",
        errorMsg: "Information timestamp is required.",
        invalidMsg: "Invalid timestamp format.",
      },
      parent_input_Q_05: {
        name: "parent_input_Q_05",
        label: "STUDENT's Strengths",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_05",
        placeholder: "Enter student's strengths",
        errorMsg: "Student's strengths are required.",
        invalidMsg: "Invalid input for student's strengths.",
      },
      parent_input_Q_06: {
        name: "parent_input_Q_06",
        label: "STUDENT's Hobbies",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_06",
        placeholder: "Enter student's hobbies",
        errorMsg: "Student's hobbies are required.",
        invalidMsg: "Invalid input for student's hobbies.",
      },
      parent_input_Q_07: {
        name: "parent_input_Q_07",
        label: "STUDENT's Least Favorite Activities",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_07",
        placeholder: "Enter student's least favorite activities",
        errorMsg: "Student's least favorite activities are required.",
        invalidMsg: "Invalid input for student's least favorite activities.",
      },
      parent_input_Q_08: {
        name: "parent_input_Q_08",
        label: "Does any of the family members have or had an IEP ?",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_08",
        placeholder: "Enter family's IEP concerns",
        errorMsg: "Family's IEP concerns are required.",
        invalidMsg: "Invalid input for family's IEP concerns.",
      },
      parent_input_Q_08_other: {
        name: "parent_input_Q_08_other",
        label: "If Other, Specify",
        type: "text",
        path: "parentInput.parent_input_Q_08_other",
        placeholder: "Specify other services",
        errorMsg: "parent_input_Q_08_other",
        toolTipText: 'TODO'
      },
      parent_input_Q_09: {
        name: "parent_input_Q_09",
        label: "Parent's Concerns on Receptive Language Development at Home",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_09",
        placeholder: "Enter receptive language skills",
        errorMsg: "Receptive language skills are required.",
        invalidMsg: "Invalid input for receptive language skills.",
      },
      parent_input_Q_09_other: {
        name: "parent_input_Q_09_other",
        label: "If Other, Specify",
        type: "text",
        path: "parentInput.parent_input_Q_09_other",
        placeholder: "Specify",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      parent_input_Q_10: {
        name: "parent_input_Q_10",
        label: "Parent's Concerns on Expressive Language Development at Home",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_10",
        placeholder: "Enter expressive language skills",
        errorMsg: "Expressive language skills are required.",
        invalidMsg: "Invalid input for expressive language skills.",
      },
      parent_input_Q_10_other: {
        name: "parent_input_Q_10_other",
        label: "If Other, Specify",
        type: "text",
        path: "parentInput.parent_input_Q_10_other",
        placeholder: "Specify",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      parent_input_Q_11: {
        name: "parent_input_Q_11",
        label: "Parent's Concerns on Social Pragmatic langauge Development at Home",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_11",
        placeholder: "Enter social pragmatic language skills",
        errorMsg: "Social pragmatic language skills are required.",
        invalidMsg: "Invalid input for social pragmatic language skills.",
      },
      parent_input_Q_11_other: {
        name: "parent_input_Q_11_other",
        label: "If Other, Specify",
        type: "text",
        path: "parentInput.parent_input_Q_11_other",
        placeholder: "Specify",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      parent_input_Q_12: {
        name: "parent_input_Q_12",
        label: "Parent's Concerns on Fluency at Home",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_12",
        placeholder: "Enter student's fluency",
        errorMsg: "Student's fluency is required.",
        invalidMsg: "Invalid input for student's fluency.",
      },
      parent_input_Q_12_other: {
        name: "parent_input_Q_12_other",
        label: "If Other, Specify",
        type: "text",
        path: "parentInput.parent_input_Q_12_other",
        placeholder: "Specify",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      parent_input_Q_13: {
        name: "parent_input_Q_13",
        label: "Parent's Concerns on Voice and Loudness at home",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_13",
        placeholder: "Enter student's voice loudness",
        errorMsg: "Student's voice loudness is required.",
        invalidMsg: "Invalid input for student's voice loudness.",
      },
      parent_input_Q_13_other: {
        name: "parent_input_Q_13_other",
        label: "If Other, Specify",
        type: "text",
        path: "parentInput.parent_input_Q_13_other",
        placeholder: "Specify",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      parent_input_Q_14: {
        name: "parent_input_Q_14",
        label: "Parent's Concerns on Articulation/ Phonology at home",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_14",
        placeholder: "Enter student's articulation",
        errorMsg: "Student's articulation is required.",
        invalidMsg: "Invalid input for student's articulation.",
      },
      parent_input_Q_14_other: {
        name: "parent_input_Q_14_other",
        label: "If Other, Specify",
        type: "text",
        path: "parentInput.parent_input_Q_14_other",
        placeholder: "Specify",
        errorMsg: "Please specify.",
        toolTipText: 'TODO'
      },
      parent_input_Q_15: {
        name: "parent_input_Q_15",
        label: "What specific goals or improvements would you like your child to achieve through speech and language therapy ?",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_input_Q_15",
        placeholder: "Enter areas for improvement",
        errorMsg: "Areas for improvement are required.",
        invalidMsg: "Invalid input for areas for improvement.",
      },
      parent_additional_info: {
        name: "parent_additional_info",
        label: "Any other additional information",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_additional_info",
        placeholder: "Enter any additional information",
        errorMsg: "Additional information is required.",
        invalidMsg: "Invalid input for additional information.",
      },
      parent_doc_description: {
        name: "parent_doc_description",
        label: "Parent's Documentation Description",
        type: "text",
        toolTipText: "TODO",
        path: "parentInput.parent_doc_description",
        placeholder: "Description of parent's documentation",
        errorMsg: "Parent's documentation description is required.",
        invalidMsg: "Invalid input for parent's documentation description.",
      },
    },
    classroomObservation: {
      classroom_obs_date_time: {
        name: "classroom_obs_date_time",
        label: "Observation Date and Time",
        type: "datetime-local",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_date_time",
        placeholder: "Enter date and time of observation",
        errorMsg: "Date and time of observation are required.",
        invalidMsg: "Invalid date or time format.",
      },
      classroom_obs_teacher_input_Q_01: {
        name: "classroom_obs_teacher_input_Q_01",
        label: "Teacher's Name",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_teacher_input_Q_01",
        placeholder: "Enter teacher's name",
        errorMsg: "Teacher's name is required.",
        invalidMsg: "Invalid input for teacher's name.",
      },
      classroom_obs_assistants_count: {
        name: "classroom_obs_assistants_count",
        label: "Number of Classroom Assistants / Instructional Aides",
        type: "number",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_assistants_count",
        placeholder: "Enter number of assistants",
        errorMsg: "Number of assistants is required.",
        invalidMsg: "Invalid input for number of assistants.",
      },
      classroom_obs_Q_04: {
        name: "classroom_obs_Q_04",
        label: "Classroom Setting",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_04",
        placeholder: "Enter the activity observed",
        errorMsg: "Activity observed is required.",
        invalidMsg: "Invalid input for activity observed.",
      },
      classroom_obs_Q_04_other: {
        name: "classroom_obs_Q_04_other",
        label: "If Other, Specify",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_04_other",
        placeholder: "Enter the activity observed",
        errorMsg: "Activity observed is required.",
        invalidMsg: "Invalid input for activity observed.",
      },
      classroom_obs_Q_05: {
        name: "classroom_obs_Q_05",
        label: "Activity Observed",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_05",
        placeholder: "Enter the activity observed",
        errorMsg: "Activity observed is required.",
        invalidMsg: "Invalid input for activity observed.",
      },
      classroom_obs_Q_06: {
        name: "classroom_obs_Q_06",
        label: "Student's Positioning",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_06",
        placeholder: "Enter how the student was positioned",
        errorMsg: "Student's positioning is required.",
        invalidMsg: "Invalid input for student's positioning.",
      },
      classroom_obs_Q_07: {
        name: "classroom_obs_Q_07",
        label: "Speech Clarity",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_07",
        placeholder: "Enter information about speech clarity",
        errorMsg: "Speech clarity information is required.",
        invalidMsg: "Invalid input for speech clarity.",
      },
      classroom_obs_Q_08: {
        name: "classroom_obs_Q_08",
        label: "Language Skills",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_08",
        placeholder: "Enter information about language skills",
        errorMsg: "Language skills information is required.",
        invalidMsg: "Invalid input for language skills.",
      },
      classroom_obs_Q_09: {
        name: "classroom_obs_Q_09",
        label: "Social Skills",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_09",
        placeholder: "Enter information about social skills",
        errorMsg: "Social skills information is required.",
        invalidMsg: "Invalid input for social skills.",
      },
      classroom_obs_Q_10: {
        name: "classroom_obs_Q_10",
        label: "Pragmatic Skills",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_10",
        placeholder: "Enter information about pragmatic skills",
        errorMsg: "Pragmatic skills information is required.",
        invalidMsg: "Invalid input for pragmatic skills.",
      },
      classroom_obs_Q_11: {
        name: "classroom_obs_Q_11",
        label: "Fluency",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_11",
        placeholder: "Enter information about fluency",
        errorMsg: "Fluency information is required.",
        invalidMsg: "Invalid input for fluency.",
      },
      classroom_obs_Q_12: {
        name: "classroom_obs_Q_12",
        label: "Voice Loudness",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_12",
        placeholder: "Enter information about voice loudness",
        errorMsg: "Voice loudness information is required.",
        invalidMsg: "Invalid input for voice loudness.",
      },
      classroom_obs_Q_13: {
        name: "classroom_obs_Q_13",
        label: "Articulation",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_13",
        placeholder: "Enter information about articulation",
        errorMsg: "Articulation information is required.",
        invalidMsg: "Invalid input for articulation.",
      },
      classroom_obs_Q_14: {
        name: "classroom_obs_Q_14",
        label: "Ability to attend to tasks and instructions",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_14",
        placeholder: "Describe first behavioral observation",
        errorMsg: "First behavioral observation is required.",
        invalidMsg: "Invalid input for first behavioral observation.",
      },
      classroom_obs_Q_15: {
        name: "classroom_obs_Q_15",
        label: "Engagement in classroom activities and discussions",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_15",
        placeholder: "Describe second behavioral observation",
        errorMsg: "Second behavioral observation is required.",
        invalidMsg: "Invalid input for second behavioral observation.",
      },
      classroom_obs_Q_16: {
        name: "classroom_obs_Q_16",
        label: "Collaboration with peers and following group instructions",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_16",
        placeholder: "Describe third behavioral observation",
        errorMsg: "Third behavioral observation is required.",
        invalidMsg: "Invalid input for third behavioral observation.",
      },
      classroom_obs_Q_17: {
        name: "classroom_obs_Q_17",
        label: "STUDENT was able to:",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_17",
        placeholder: "Describe fourth behavioral observation",
        errorMsg: "Fourth behavioral observation is required.",
        invalidMsg: "Invalid input for fourth behavioral observation.",
      },
      classroom_obs_Q_18: {
        name: "classroom_obs_Q_18",
        label: "Any extra support provided by the teacher",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_18",
        placeholder: "Enter information about support needed",
        errorMsg: "Support needed information is required.",
        invalidMsg: "Invalid input for support needed.",
      },
      classroom_obs_Q_19: {
        name: "classroom_obs_Q_19",
        label: "How does the STUDENT feel",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_19",
        placeholder: "Enter information about student's feelings",
        errorMsg: "Student's feelings information is required.",
        invalidMsg: "Invalid input for student's feelings.",
      },
      classroom_obs_Q_19_other: {
        name: "classroom_obs_Q_19_other",
        label: "If Other, Specify",
        type: "text",
        path: "classroomObservation.classroom_obs_Q_19_other",
        errorMsg: "classroom_obs_Q_19_other",
        invalidMsg: "classroom_obs_Q_19_other",
      },
      classroom_obs_Q_20: {
        name: "classroom_obs_Q_20",
        label: "Transition Between Activities",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_20",
        placeholder: "Enter information about transition between activities",
        errorMsg: "Transition between activities information is required.",
        invalidMsg: "Invalid input for transition between activities.",
      },
      classroom_obs_Q_20_other: {
        name: "classroom_obs_Q_20_other",
        label: "If Other, Specify",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_20_other",
        errorMsg: "classroom_obs_Q_20_other",
        invalidMsg: "classroom_obs_Q_20_other",
      },
      classroom_obs_Q_21: {
        name: "classroom_obs_Q_21",
        label: "Listening Skills",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_21",
        placeholder: "Enter information about listening skills",
        errorMsg: "Listening skills information is required.",
        invalidMsg: "Invalid input for listening skills.",
      },
      classroom_obs_Q_21_other: {
        name: "classroom_obs_Q_21_other",
        label: "Listening Skills",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_21_other",
        errorMsg: "classroom_obs_Q_21_other",
        invalidMsg: "classroom_obs_Q_21_other",
      },
      classroom_obs_Q_22: {
        name: "classroom_obs_Q_22",
        label: "Follows Directions",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_22",
        placeholder: "Enter information about following directions",
        errorMsg: "Following directions information is required.",
        invalidMsg: "Invalid input for following directions.",
      },
      classroom_obs_Q_22_other: {
        name: "classroom_obs_Q_22_other",
        label: "Follows Directions",
        type: "text",
        toolTipText: "TODO",
        path: "classroomObservation.classroom_obs_Q_22_other",
        errorMsg: "classroom_obs_Q_22_other",
        invalidMsg: "classroom_obs_Q_22_other",
      },
      classroom_additional_info: {
        name: "classroom_additional_info",
        label: "Any other additional information",
        type: "text",
        placeholder: "Enter additional information",
        path: "classroomObservation.classroom_additional_info",
        errorMsg: "Additional information is required.",
        invalidMsg: "Invalid input for additional information.",
      },
      classroom_doc_description: {
        name: "classroom_doc_description",
        label: "Document Description",
        type: "text",
        placeholder: "Enter document description",
        path: "classroomObservation.classroom_doc_description",
        errorMsg: "Document description is required.",
        invalidMsg: "Invalid input for document description.",
      },
    },
    evaluationObservQuestionnaire: {
      eval_obv_Q_01: {
        name: "eval_obv_Q_01",
        label: "Eye Contact",
        type: "text",
        toolTipText: "TODO",
        path: "evaluationObservQuestionnaire.eval_obv_Q_01",
        placeholder: "Select Eye Contact",
        errorMsg: "Eye contact is required.",
        invalidMsg: "Invalid input for student engagement.",
      },
      eval_obv_Q_02: {
        name: "eval_obv_Q_02",
        label: "Facial Expressions",
        type: "text",
        toolTipText: "TODO",
        path: "evaluationObservQuestionnaire.eval_obv_Q_02",
        placeholder: "Facial Expressions",
        errorMsg: "Facial Expressions is required",
        invalidMsg: "Invalid input for facial expressions",
      },
      eval_obv_Q_03: {
        name: "eval_obv_Q_03",
        label: "Verbal Communication",
        type: "text",
        toolTipText: "TODO",
        path: "evaluationObservQuestionnaire.eval_obv_Q_03",
        placeholder: "Describe verbal communication",
        errorMsg: "Verbal communication description is required.",
        invalidMsg: "Invalid input for verbal communication.",
      },
      eval_obv_Q_04: {
        name: "eval_obv_Q_04",
        label: "Gestures",
        type: "text",
        toolTipText: "TODO",
        path: "evaluationObservQuestionnaire.eval_obv_Q_04",
        placeholder: "Describe non-verbal communication",
        errorMsg: "Non-verbal communication description is required.",
        invalidMsg: "Invalid input for non-verbal communication.",
      },
      eval_obv_Q_04_other: {
        name: "eval_obv_Q_04_other",
        label: "If Other, Specify",
        path: "evaluationObservQuestionnaire.eval_obv_Q_04_other",
        type: "text",
        placeholder: "Please Specify",
        errorMsg: "eval_obv_Q_04_other",
        invalidMsg: "eval_obv_Q_04_other",
      },
      eval_obv_Q_05: {
        name: "eval_obv_Q_05",
        label: "Posture and Body Orientation",
        type: "text",
        toolTipText: "TODO",
        path: "evaluationObservQuestionnaire.eval_obv_Q_05",
        placeholder: "Posture and Body Orientation",
        errorMsg: "Posture and Body Orientation is required.",
        invalidMsg: "Invalid input Posture and Body Orientation",
      },
      eval_obv_Q_06: {
        name: "eval_obv_Q_06",
        label: "Response to Instruction",
        type: "text",
        toolTipText: "TODO",
        path: "evaluationObservQuestionnaire.eval_obv_Q_06",
        placeholder: "Describe response to instruction",
        errorMsg: "Response to instruction description is required.",
        invalidMsg: "Invalid input for response to instruction.",
      },
      eval_obv_Q_07: {
        name: "eval_obv_Q_07",
        label: "Vocabulary",
        type: "text",
        toolTipText: "TODO",
        path: "evaluationObservQuestionnaire.eval_obv_Q_07",
        placeholder: "Describe vocabulary",
        errorMsg: "Vocabulary description is required.",
        invalidMsg: "Invalid input for vocabulary.",
      },

      eval_obv_Q_08: {
        name: "eval_obv_Q_08",
        label: "Articulation",
        type: "text",
        toolTipText: "TODO",
        path: "evaluationObservQuestionnaire.eval_obv_Q_08",
        placeholder: "Describe articulation",
        errorMsg: "Articulation description is required.",
        invalidMsg: "Invalid input for articulation.",
      },
      eval_obv_Q_09: {
        name: "eval_obv_Q_09",
        label: "Overall Communication Skills",
        type: "text",
        toolTipText: "TODO",
        path: "evaluationObservQuestionnaire.eval_obv_Q_09",
        placeholder: "Describe overall communication skills",
        errorMsg: "Overall communication skills description is required.",
        invalidMsg: "Invalid input for overall communication skills.",
      },
      eval_doc_description: {
        name: "eval_doc_description",
        type: "text",
        errorMsg: "eval_doc_description",
        invalidMsg: "eval_doc_description",
      }

    },
    backgroundInformation: backgroundInfoForm.backgroundInformation,
    behavioralObservation: behavioralObservationForm.behavioralObservation,
    oralPeripheralMechanism: oralPeripheralMechForm.oralPeripheralMechanism,
    fluency: fluencyForm.fluency,
    voiceObservation: voiceObservationForm.voiceObservation,
    articulation_Intelligibility: articulationIntellForm.articulation_Intelligibility,
    pragmatics: pragmaticsForm.pragmatics,
    reportSummary: reportSummaryForm.reportSummary,
    eligibility: eligibilityForm.eligibility,
    recommendations: recommendationsForm.recommendations,
    signature: signatureForm.signature,
    normative_chart: normativeChartForm.normative_chart,
    goals: {
      goals: {
        name: "goals",
        label: "Goals",
        placeholder: "Goals",
        errorMsg: "Goals name is required.",
        invalidMsg: "",
      }
    },
  },
};

export default form;
