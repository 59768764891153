import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { grey } from "@mui/material/colors";

import { Box, Grid, Stack, Button, IconButton, Chip, useTheme, Menu } from "@mui/material";
import _ from "lodash"; // Import lodash

import { FilterList, Close } from "@mui/icons-material";

import { useDispatch } from "react-redux";
import ChartData from "../ChartData";
import SCard from "components/SCard";
import SInfoCard from "components/SInfoCard";
import {
  AttachMoneyOutlined,
  AssessmentOutlined,
  PersonOffOutlined,
  EventRepeatOutlined,
} from "@mui/icons-material";
import SLoadingIndicator from "components/SLoadingIndicator";
import { getGlobalDropdowns } from "redux/actions/configAction";

import DateRangePickerValues from "../DateRangePickerValues";
import { getDataTrackingHistory } from "redux/actions/scheduleAction";

function DateChip({ startDate, endDate, onDelete }) {
  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };

  return (
    <Chip
      label={`${formatDateString(startDate)} - ${formatDateString(endDate)}`}
      onDelete={onDelete}
      color="primary"
      size="large"
    />
  );
}

export default function Overview(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data } = props;
  const [activeButton, setActiveButton] = useState("Last 7 days");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const filterOpen = Boolean(anchorEl);
  const [filteredDates, setFilteredDates] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  const handleDateRange = (range) => {
    let startDate, endDate;

    switch (range) {
      case 'Last 60 days':
        startDate = moment().subtract(60, 'days').startOf('day');
        endDate = moment().subtract(1, 'day').endOf('day');
        break;
      case 'Last 30 days':
        startDate = moment().subtract(1, 'month').startOf('day');
        endDate = moment().subtract(1, 'day').endOf('day');
        break;
      case 'Last 7 days':
        startDate = moment().subtract(7, 'days').startOf('day');
        endDate = moment().subtract(1, 'day').endOf('day');
        break;
      default:
        return;
    }

    const payload = {
      filter: {
        pageNumber: page,
        eventType: [],
        searchValue: "",
        startDate: startDate.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        endDate: endDate.utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
      }
    };

    fetchData(payload);
    setActiveButton(range);
  };

  const fetchData = async (payload) => {
    if (data && data?.response?._id) {
      setChartData([]);
      setLoading(true);

      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const response = await dispatch(getDataTrackingHistory(data?.response._id, payload));
        const chartData = response?.response?.map((item) => ({
          date: new Date(item.startTime).toLocaleDateString(),
          average: calculateAverage([item]),
        }));

        setChartData(chartData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const calculateAverage = (data) => {
    const results = data.flatMap((item) =>
      item.goals.flatMap((goal) => goal.results.filter((result) => result !== null))
    );

    const correctCount = results.filter((result) => result === true).length;
    const wrongCount = results.filter((result) => result === false).length;

    const totalCount = correctCount + wrongCount;
    return totalCount > 0 ? Math.round((correctCount / totalCount) * 100) : 0;
  };

  const handleDelete = () => {
    setSelectedDates({});
    setFilteredDates(null);
    handleDateRange(activeButton);
  };


  const handleDateRangeChange = (dates) => {
    setSelectedDates(dates);
  };

  const handleApplyFilters = () => {
    const startDateLocal = moment(selectedDates.startDate).startOf('day');
    const endDateLocal = moment(selectedDates.endDate).endOf('day');

    if (startDateLocal && endDateLocal) {
      const payload = {
        filter: {
          pageNumber: page,
          eventType: [],
          searchValue: "",
          startDate: startDateLocal.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endDate: endDateLocal.utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
        }
      };
      setFilteredDates(payload);
      fetchData(payload);
    }
    setAnchorEl(null);
    setActiveButton(null);
  };

  useEffect(() => {
    handleDateRange('Last 7 days');
    dispatch(getGlobalDropdowns());
  }, [data?.response?._id]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={3}>
          <SInfoCard loading={loading} icon={<AttachMoneyOutlined />} text="0" subText="Total Billing (Hours)" />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SInfoCard loading={loading} icon={<AssessmentOutlined />} text="0" subText="Last Session (%)" />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SInfoCard loading={loading} icon={<PersonOffOutlined />} text="0" subText="Absent Sessions" />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SInfoCard loading={loading} icon={<EventRepeatOutlined />} text="0" subText="Comped Session(s)" />
        </Grid>
        <Grid item xs={12}></Grid>

        <Grid item xs={12} md={6} lg={12}>
          <SCard
            title="Assessment Progress"
            renderActions={() => (
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <div>
                  {filteredDates?.filter?.startDate && filteredDates?.filter?.endDate && (
                    <DateChip
                      startDate={filteredDates?.filter?.startDate}
                      endDate={filteredDates?.filter?.endDate}
                      onDelete={handleDelete}
                    />
                  )}
                </div>

                <div>
                  {['Last 60 days','Last 30 days', 'Last 7 days', 'Custom Range'].map((label, index) => (
                    <Button
                      key={label}
                      variant="outlined"
                      sx={{
                        bgShadow: 0,
                        mr: 1,
                        color: activeButton === label ? '#5263f3' : grey[500],
                        borderColor: grey[200],
                        background: activeButton === label ? '#eeeffe' : null,

                        '&:hover': {
                          color: grey[600],
                          borderColor: grey[200],
                        },
                      }}
                      size="small"
                      style={{ cursor: 'pointer' }}
                      onClick={label === 'Custom Range' ? handleClick : () => handleDateRange(label)}
                      startIcon={label === 'Custom Range' && <FilterList />}
                    >
                      {label}
                    </Button>
                  ))}
                  <Menu
                    anchorEl={anchorEl}
                    id="filter-menu"
                    open={filterOpen}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    sx={{
                      '& .MuiMenu-list': {
                        backgroundColor: '#f7f8fc',
                      },
                      '& .MuiPaper-root': {
                        borderRadius: 1,
                        marginTop: theme.spacing(1.5),
                      },
                    }}
                  >
                    <Box style={{ padding: 20, backgroundColor: '#f7f8fc' }}>
                      <Box mb={3}>
                        <IconButton style={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseFilter}>
                          <Close />
                        </IconButton>
                      </Box>
                      <Box mb={2}>
                        <DateRangePickerValues onChange={handleDateRangeChange} />
                      </Box>
                      <Button variant="outlined" onClick={handleApplyFilters} fullWidth color="primary">
                        Search
                      </Button>
                    </Box>
                  </Menu>
                </div>
              </Stack>
            )}
          >
            {loading ? <SLoadingIndicator height="20.25rem" /> : <ChartData data={chartData} />}
          </SCard>

        </Grid>
      </Grid>
    </React.Fragment>
  );
}
