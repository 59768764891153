import React, { useEffect, useState } from "react";
import { Grid, Checkbox, Box, Typography, FormControlLabel, FormHelperText } from "@mui/material";
import FormField from "components/FormField";
import moment from "moment";
import HeaderComp from "components/Common/HeaderComp";
import { useSelector } from "react-redux";
import { getSchoolYearOptions } from "components/Common/getSchoolYearOptions";

const EditStudentDetails = ({ formData }) => {
  const today = moment().format('YYYY-MM-DD');

  const { global_dropdowns } = useSelector((state) => ({
    global_dropdowns: state.configReducer.global_dropdown_list,
  }));

  const [modifyYear, setModifyYear] = useState(false);
  const { formField, values, errors, touched, setFieldValue, data, edit } = formData;

  const {
    firstName,
    middleName,
    lastName,
    caseManager,
    dateOfBirth,
    gender,
    school,
    grade, //string
    primaryLanguage, //string
    disability,
    ethnicity,
    secondaryLanguage, //string
    eligibilityEvaluation, //date
    initialEvaluation, //date
    annualPlanReview, //date
    eligibility, // pending or eligible
    initialEvaluationReviewCompleted, //boolean
    nextEligibilityReviewCompleted, //boolean
    annualPlanReviewCompleted, //boolean
    currentAcademicYear,
  } = formField;

  console.log('errors', errors)
  const {
    firstName: firstNameV,
    middleName: middleNameV,
    lastName: lastNameV,
    caseManager: caseManagerV,
    gender: genderV,
    dateOfBirth: dateOfBirthV,
    school: schoolV,
    grade: gradeV, // Add missing fields
    disability: disabilityV,
    currentAcademicYear: currentAcademicYearV,
    ethnicity: ethnicityV,
    primaryLanguage: primaryLanguageV, // Add missing fields
    secondaryLanguage: secondaryLanguageV, // Add missing fields
    eligibilityEvaluation: eligibilityEvaluationV, // Add missing fields
    initialEvaluation: initialEvaluationV, // Add missing fields
    annualPlanReview: annualPlanReviewV, // Add missing fields
    eligibility: eligibilityV, // Add missing fields
    initialEvaluationReviewCompleted: initialEvaluationReviewCompletedV, // Add missing fields
    nextEligibilityReviewCompleted: nextEligibilityReviewCompletedV, // Add missing fields
    annualPlanReviewCompleted: annualPlanReviewCompletedV, // Add missing fields
  } = values;

 
  function findGenderValue() {
    if (global_dropdowns.response?.GenderList.length > 0) {
      const value = global_dropdowns.response?.GenderList.find((f) => f.label.toLowerCase() === data.response.gender.toLowerCase());
      return value || null; // Return null if no match is found
    }
    return null; // Return null if GenderList is empty or undefined
  }

  function findethnicityValue() {
    if (global_dropdowns.response?.Ethnicity.length > 0) {
      const value = global_dropdowns.response?.Ethnicity.find((f) => f.label.toLowerCase() === data.response.ethnicity.toLowerCase());
      return value || null; // Return null if no match is found
    }
    return null; // Return null if GenderList is empty or undefined
  }
  
  function findSchoolYear() {
    if (getSchoolYearOptions().length > 0) {
      const value = getSchoolYearOptions().find((f) => f.id.toLowerCase() === data.response.currentAcademicYear.schoolYear);
      return value || null; // Return null if no match is found
    }
    return null; // Return null if GenderList is empty or undefined

  }
  function findGradeValue() {
    if (global_dropdowns.response?.GradeList.length > 0) {
      let gradeLabel = data.response.currentAcademicYear.grade;
  
      // Extract the numeric part from "5th Grade" or similar
      const match = gradeLabel.match(/(\d+)(?:st|nd|rd|th)/);
      if (match) {
        const gradeNumber = parseInt(match[1], 10);
        const gradeValue = global_dropdowns.response?.GradeList.find((f) => parseInt(f.label) === gradeNumber);
        return gradeValue || null; // Return null if no match is found
      }
      // If no numeric part is found, try to find by exact match
      return global_dropdowns.response?.GradeList.find((f) => f.label.toLowerCase() === gradeLabel.toLowerCase()) || null;
    }
    return null; // Return null if GradeList is empty or undefined
  }


   
  function findGenderValue() {
    if (global_dropdowns.response?.GenderList.length > 0) {
      const value = global_dropdowns.response?.GenderList.find((f) => f.label.toLowerCase() === data.response.gender.toLowerCase());
      return value || null; // Return null if no match is found
    }
    return null; // Return null if GenderList is empty or undefined
  }

  function findEligibleValue() {
    if (global_dropdowns.response?.Eligibility.length > 0) {
      const value = global_dropdowns.response?.Eligibility.find((f) => f.label.toLowerCase() === data.response.eligibility.toLowerCase());
      return value || null;
    }
    return null;
  }

  function findLanguage() {
    if (global_dropdowns.response?.Languages.length > 0) {
      const value = global_dropdowns.response?.Languages.find((f) => f.label.toLowerCase() === data.response.primaryLanguage.toLowerCase());
      return value || null;
    }
    return null;
  }

  useEffect(() => {
    if (edit) {
      setFieldValue("firstName", data.response.firstName);
      setFieldValue("middleName", data.response.middleName);
      setFieldValue("lastName", data.response.lastName);
      setFieldValue("dateOfBirth", data.response.dateOfBirth)
      setFieldValue("school", data.response?.currentAcademicYear?.school);
      setFieldValue('disability', data.response.disability);
      setFieldValue("eligibility", data.response.eligibility ? findEligibleValue() : null);
      setFieldValue("ethnicity", data.response.ethnicity ? findethnicityValue() : null);
      setFieldValue("gender", data.response.gender ? findGenderValue() : null);
      setFieldValue("grade", data.response?.currentAcademicYear?.grade ? findGradeValue() : null);
      setFieldValue("eligibilityEvaluation",data.response.eligibilityEvaluation || "");
      setFieldValue("initialEvaluation", data.response.initialEvaluation || "");
      setFieldValue("annualPlanReview", data.response.annualPlanReview || "");
      setFieldValue("caseManager", data.response.caseManager);
      setFieldValue("primaryLanguage",  data.response.primaryLanguage ? findLanguage() : null);
      setFieldValue("secondaryLanguage", data.response.secondaryLanguage ? findLanguage() : null);
      setFieldValue("annualPlanReviewCompleted", data.response.annualPlanReviewCompleted);
      setFieldValue("nextEligibilityReviewCompleted", data.response.nextEligibilityReviewCompleted);
      setFieldValue("currentAcademicYear",  data.response.currentAcademicYear.schoolYear ? findSchoolYear() : null);

      setFieldValue(
        "initialEvaluationReviewCompleted",
        data.response.initialEvaluationReviewCompleted
      );
    }
  }, [edit, setFieldValue]);


  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFieldValue(name, checked);
  };

  const ScrollToTop = ({  }) => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  };

  return (
    <Grid container spacing={2}>
      <HeaderComp title="Acadamic Year" />        
        <Grid item xs={12} sm={4} lg={4}>
          <FormField
            type={currentAcademicYear.type}
            inputType="select"
            label={currentAcademicYear.label}
            isRequired={true}
            name={currentAcademicYear.name}
            size="small"
            options={getSchoolYearOptions()} // Ensure this returns an array of objects
            onChange={(option) => setFieldValue('currentAcademicYear', option)}
            value={currentAcademicYearV}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.id}
            placeholder={currentAcademicYear.placeholder}
            error={errors.currentAcademicYear && touched.currentAcademicYear}
            isDisabled={!modifyYear} // Disable field if modifyYear is false
          />
           <FormControlLabel
          control={
            <Checkbox
              checked={modifyYear}
              onChange={() => setModifyYear(!modifyYear)}
              color="primary"
            />
          }
          label="Allow Editing Academic Year"
        />
        </Grid>
        
        <Grid item xs={12} sm={4} lg={8}>
          {/* Other content here */}
        </Grid>
      <HeaderComp title="General Information"/>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          type={firstName.type}
          label={firstName.label}
          name={firstName.name}
          size="small"
          isRequired={true}
          value={firstNameV}
          placeholder={firstName.placeholder}
          error={errors.firstName && touched.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          type={middleName.type}
          label={middleName.label}
          name={middleName.name}
          size="small"
          isRequired={false}
          value={middleNameV}
          placeholder={middleName.placeholder}
          error={errors.middleName && touched.middleName}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          type={lastName.type}
          label={lastName.label}
          name={lastName.name}
          size="small"
          isRequired={true}
          value={lastNameV}
          placeholder={lastName.placeholder}
          error={errors.lastName && touched.lastName}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          type={dateOfBirth.type}
          label={dateOfBirth.label}
          name={dateOfBirth.name}
          size="small"
          isRequired={true}
          onChange={(e) => {
            const date = moment(e.target.value).startOf('day').utc().toISOString();
            setFieldValue('dateOfBirth', date);
          }}
          inputProps={{
            max: today,
          }}
          value={moment(dateOfBirthV).format('YYYY-MM-DD')}
          placeholder={dateOfBirth.placeholder}
          error={errors.dateOfBirth && touched.dateOfBirth}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          isClearable
          type={grade.type}
          inputType="select"
          label={grade.label}
          isRequired={false}
          name={grade.name}
          size="small"
          options={global_dropdowns.response?.GradeList}
          onChange={(selectedOption) => {
            setFieldValue('grade', selectedOption ? selectedOption : null); // Sets only the value or null if cleared
          }}
          defaultValue={gradeV}
          value={gradeV}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={grade.placeholder}
          error={errors.grade && touched.grade}
          // success={locationV.length > 0 && !errors.location}
        />
      </Grid>
      <Grid item xs={4}>
        <FormField
          isClearable
          type={gender.type}
          inputType="select"
          label={gender.label}
          isRequired={true}
          name={gender.name}
          size="small"
          options={global_dropdowns.response?.GenderList}
          onChange={(selectedOption) => {
            setFieldValue('gender', selectedOption ? selectedOption : null); // Sets only the value or null if cleared
          }}         
          value={genderV}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={gender.placeholder}
          error={errors.gender && touched.gender}
          // success={locationV.length > 0 && !errors.location}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
         isClearable
         type={primaryLanguage.type}
         inputType="select"
         label={primaryLanguage.label}
         isRequired={true}
         name={primaryLanguage.name}
         size="small"
         options={global_dropdowns.response?.Languages}
         onChange={(selectedOption) => {
           setFieldValue('primaryLanguage', selectedOption ? selectedOption : null); // Sets only the value or null if cleared
         }}         
         defaultValue={primaryLanguageV}
         value={primaryLanguageV}
         getOptionLabel={(option) => option.label}
         getOptionValue={(option) => option.id}
         placeholder={primaryLanguage.placeholder}
         error={errors.primaryLanguage && touched.primaryLanguage}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          isClearable
         type={secondaryLanguage.type}
         inputType="select"
         label={secondaryLanguage.label}
         isRequired={true}
         name={secondaryLanguage.name}
         size="small"
         options={global_dropdowns.response?.Languages}
         onChange={(selectedOption) => {
           setFieldValue('secondaryLanguage', selectedOption ? selectedOption : null); // Sets only the value or null if cleared
         }}         
         defaultValue={secondaryLanguageV}
         value={secondaryLanguageV}
         getOptionLabel={(option) => option.label}
         getOptionValue={(option) => option.id}
         placeholder={secondaryLanguage.placeholder}
         error={errors.secondaryLanguage && touched.secondaryLanguage}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          isClearable
          type={ethnicity.type}
          inputType="select"
          label={ethnicity.label}
          isRequired={false}
          name={ethnicity.name}
          size="small"
          options={global_dropdowns.response?.Ethnicity}
          onChange={(selectedOption) => {
            setFieldValue('ethnicity', selectedOption ? selectedOption : null); // Sets only the value or null if cleared
          }}
          value={ethnicityV}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={ethnicity.placeholder}
          error={errors.ethnicity && touched.ethnicity}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          type={disability.type}
          label={disability.label}
          name={disability.name}
          size="small"
          isRequired={false}
          value={disabilityV}
          placeholder={disability.placeholder}
          error={errors.disability && touched.disability}
        />
      </Grid>
     
      <HeaderComp title="School Details"/>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          type={caseManager.type}
          label={caseManager.label}
          name={caseManager.name}
          size="small"
          isRequired={false}
          value={caseManagerV}
          placeholder={caseManager.placeholder}
          error={errors.caseManager && touched.caseManager}
        />
      </Grid>
      <Grid item xs={4}>
        <FormField
          isClearable
          type={eligibility.type}
          inputType="select"
          label={eligibility.label}
          isRequired={false}
          name={eligibility.name}
          size="small"
          options={global_dropdowns.response?.Eligibility}
          onChange={(selectedOption) => {
            setFieldValue('eligibility', selectedOption ? selectedOption : null); // Sets only the value or null if cleared
          }}          
          value={eligibilityV}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={eligibility.placeholder}
          error={errors.eligibility && touched.eligibility}
          // success={locationV.length > 0 && !errors.location}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          type={school.type}
          label={school.label}
          name={school.name}
          size="small"
          isRequired={false}
          value={schoolV}
          placeholder={school.placeholder}
          error={errors.school && touched.school}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <FormField
          type={annualPlanReview.type}
          label={annualPlanReview.label}
          name={annualPlanReview.name}
          size="small"
          isRequired={false}
          onChange={(e) => {
            const date = moment(e.target.value).startOf('day').utc().toISOString();
            setFieldValue('annualPlanReview', date);
        }}
          value={moment(annualPlanReviewV).format('YYYY-MM-DD')}
          placeholder={annualPlanReview.placeholder}
          error={errors.annualPlanReview && touched.annualPlanReview}
        />
        <Box p={0} mr={5} mb={2}>
          <Checkbox
            size="small"
            checked={annualPlanReviewCompletedV || false}
            onChange={handleChange}
            name={annualPlanReviewCompleted.name}
            color="primary"
          />
          <Typography color="text.secondary" variant="subtitle2" component={"span"}>
            {annualPlanReviewCompleted.label} ?
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <FormField
          type={eligibilityEvaluation.type}
          label={eligibilityEvaluation.label}
          name={eligibilityEvaluation.name}
          size="small"
          isRequired={false}
          onChange={(e) => {
            const date = moment(e.target.value).startOf('day').utc().toISOString();
            setFieldValue('eligibilityEvaluation', date);
           }}
           value={moment(eligibilityEvaluationV).format('YYYY-MM-DD')}
           placeholder={eligibilityEvaluation.placeholder}
          error={errors.eligibilityEvaluation && touched.eligibilityEvaluation}
        />
        <Box p={0} mr={5} mb={2}>
          <Checkbox
            size="small"
            checked={nextEligibilityReviewCompletedV || false}
            onChange={handleChange}
            name={nextEligibilityReviewCompleted.name}
            color="primary"
          />
          <Typography color="text.secondary" variant="subtitle2" component={"span"}>
            {nextEligibilityReviewCompleted.label} ?
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <FormField
          type={initialEvaluation.type}
          label={initialEvaluation.label}
          name={initialEvaluation.name}
          size="small"
          isRequired={false}
          value={moment(initialEvaluationV).format('YYYY-MM-DD')}
          onChange={(e) => {
            const date = moment(e.target.value).startOf('day').utc().toISOString();
            setFieldValue('initialEvaluation', date);
           }}
          placeholder={initialEvaluation.placeholder}
          error={errors.initialEvaluation && touched.initialEvaluation}
        />
        <Box p={0} mr={5} mb={2}>
          <Checkbox
            size="small"
            checked={initialEvaluationReviewCompletedV || false}
            onChange={handleChange}
            name={initialEvaluationReviewCompleted.name}
            color="primary"
          />
          <Typography color="text.secondary" variant="subtitle2" component={"span"}>
            {initialEvaluationReviewCompleted.label} ?
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditStudentDetails;
