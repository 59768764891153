import store from "../store";
import { OPEN_NOTIFICATION, CLOSE_NOTIFICATION } from "redux/constants/constants";

export function showNotification(data) {
  store.dispatch({
    type: OPEN_NOTIFICATION,
    payload: data,
  });
}

export function closeNotification() {
  store.dispatch({
    type: CLOSE_NOTIFICATION,
  });
}
