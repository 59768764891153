// Notification

export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

// AUTHENICATION

export const AUTH_USER_LOGIN = "AUTH_USER_LOGIN";
export const AUTH_USER_REGISTER = "AUTH_USER_REGISTER";
export const AUTH_USER_VERIFY = "AUTH_USER_VERIFY";
export const AUTH_USER_LOGOUT = "AUTH_USER_LOGOUT";
export const AUTH_USER_EMAIL_VERIFY = "AUTH_USER_EMAIL_VERIFY";

// STUDENT

export const ADD_STUDENT = "ADD_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const IMPORT_STUDENTS = "IMPORT_STUDENTS";
export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const FETCH_STUDENT_BY_ID = "FETCH_STUDENT_BY_ID";
export const FETCH_STUDENTS_COUNT = "FETCH_STUDENTS_COUNT";
export const SEARCH_STUDENT = "SEARCH_STUDENT";
// GOAL BANK

export const CREATE_GOAL_BANK = "CREATE_GOAL_BANK";
export const FETCH_GOALS_BY_CATEGORY = "FETCH_GOALS_BY_CATEGORY";
export const FETCH_DATA_TRACKING_HISTORY = "FETCH_DATA_TRACKING_HISTORY";

export const SYNC_GOOGLE_CALENDAR = "SYNC_GOOGLE_CALENDAR";

export const AUTH_GOOGLE = "AUTH_GOOGLE";
export const AUTH_GOOGLE_CALLBACK = "AUTH_GOOGLE_AUTH_GOOGLE_CALLBACK";

// MEETING INVITE

export const SEND_MEETING_INVITE = "SEND_MEETING_INVITE";
export const FETCH_INVITED_LIST = "FETCH_INVITED_LIST";
export const FETCH_SLOTS_LIST = "FETCH_SLOTS_LIST";
export const FETCH_EVENTS_BY_DATE = "FETCH_EVENTS_BY_DATE";

//SUBSCRIPTION

export const USER_CREATE_SUBSCRIPTION = "USER_CREATE_SUBSCRIPTION";
export const USER_CHECK_SUBSCRIPTION = "USER_CHECK_SUBSCRIPTION";
export const USER_CANCEL_SUBSCRIPTION = "USER_CANCEL_SUBSCRIPTION";
export const USER_SUBSCRIPTION_SUCCESS = "USER_SUBSCRIPTION_SUCCESS";

//User

export const USER_DETAILS = "USER_DETAILS";
export const USER_MENU = "USER_MENU";
export const UPDATE_USER = "UPDATE_USER";

//IEP Reports

export const CREATE_REPORT = "CREATE_REPORT";
export const GET_ALL_REPORTS = "GET_ALL_REPORTS";
export const REPORTS_IEP_DROPDOWN = "REPORTS_IEP_DROPDOWN";


// Configuration

export const GLOBAL_DROPDOWNS = 'GLOBAL_DROPDOWNS';


// Data Tracking

export const UPDATE_DATA_TRACKING = "UPDATE_DATA_TRACKING";

export const ASSISTANT_CREATE ="ASSISTANT_CREATE"
export const ASSISTANT_DELETE ="ASSISTANT_DELETE"
export const ASSISTANT_PASSWORD ="ASSISTANT_PASSWORD"
export const ASSISTANTS_LIST ="ASSISTANTS_LIST"
export const ASSISTANT_UPDATE = "ASSISTANT_UPDATE"