import * as Actions from "../constants/constants";

const initialState = {
  assistants_list: [],

};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.ASSISTANT_CREATE:
    case Actions.ASSISTANT_DELETE:
    case Actions.ASSISTANT_PASSWORD:
    case Actions.ASSISTANTS_LIST:
      return { ...state, assistants_list: action.payload };

    default:
      return state;
  }
};
