import React, { useEffect, useState } from "react";
import { Grid, Paper, Chip, Typography, Box, Stack, Divider } from "@mui/material";
import Select from "react-select";
import { red, grey } from "@mui/material/colors";
import { Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { List } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import logo from "../../../assets/slpbox-logo-full-white.svg";
import SMessageCard from "components/SMessageCard";
import { getCurrentSchoolYear } from "components/Common/getSchoolYearOptions";
const FieldMapping = (props) => {
  const uploadedData = props.uploadedData || [];
  const dbHeaders = [
    {
      field: "firstName",
      headerName: "First Name",
    },
    {
      field: "middleName",
      headerName: "Middle Name",
    },
    {
      field: "lastName",
      headerName: "Last Name",
    },
    {
      field: "fullName",
      headerName: "Full Name",
    },
    {
      field: "dateOfBirth",
      headerName: "Date Of Birth",
    },
    {
      field: "grade",
      headerName: "Grade",
    },
    {
      field: "gender",
      headerName: "Gender",
    },
    {
      field: "disability",
      headerName: "Disability",
    },
    {
      field: "school",
      headerName: "School",
    },
    {
      field: "caseManager",
      headerName: "Case Manager",
    },
    {
      field: "primaryLanguage",
      headerName: "Primary Language",
    },
    {
      field: "secondaryLanguage",
      headerName: "Secondary Language",
    },
    {
      field: "eligibility",
      headerName: "Eligibility - (Pending or Eligible)",
    },
    {
      field: "initialEvaluation",
      headerName: "Initial Evaluation Date",
    },
    {
      field: "annualPlanReview",
      headerName: "Next Annual Evaluation Date",
    },
    {
      field: "eligibilityEvaluation",
      headerName: "Next Eligibility Evaludation Date",
    },
  ];
  const headers = Object.keys(uploadedData[0] || {});

  const [fieldMappings, setFieldMappings] = useState({});

  // Function to handle field name selection
  const handleFieldNameSelection = (fieldName, newHeader) => {
    setFieldMappings({
      ...fieldMappings,
      [fieldName]: newHeader,
    });
  };
  // Function to apply field name mappings to the uploaded data
  const applyFieldMappings = (data, mappings) => {
    return data.map((item) => {
      const newItem = {};
      for (const key in item) {
        const newKey = mappings[key] || key;
        newItem[newKey] = item[key];
      }
      return newItem;
    });
  };

  const handleDelete = (key) => {
    const updatedFieldMappings = { ...fieldMappings };
    delete updatedFieldMappings[key];
    setFieldMappings(updatedFieldMappings);
  };

  // Process the data with field name mappings applied
  const processedData = applyFieldMappings(uploadedData, fieldMappings);

  const mappedValues = Object.values(fieldMappings);

  /// THIS filteredProcessedData is to be sent as payload to add this records in DB for students

  // Filter keys in each object of processedData based on mappedValues
  const filteredProcessedData = processedData.map((item) => {
    const newItem = {};
    for (const key of mappedValues) {
      if (item.hasOwnProperty(key)) {
        newItem[key] = item[key];
      }
    }

    return newItem;
  });

  const filteredDbHeaders = dbHeaders.filter((option) => !mappedValues.includes(option.field));

  useEffect(() => {
    // Send processedData to the parent component using the callback function
    props.setNewData(filteredProcessedData);
  }, [fieldMappings]);

  function showValue(value) {
    const matchedHeader = dbHeaders.find((header) => header.field === value);
    return matchedHeader ? matchedHeader.headerName : "";
  }

  const selectedFields = Object.values(fieldMappings);
  const unselectedHeaders = dbHeaders.filter((header) => !selectedFields.includes(header.field));
  const currentSchoolYear = getCurrentSchoolYear();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={6}>
        <Paper variant="outlined" sx={{ background: "#fff" }}>
          <Typography variant="h3" align="center" gutterBottom sx={{ background: "#f2f2f2" }}>
            Field Mapping
          </Typography>
          <List>
            <TableContainer component={Paper} elevation={0} sx={{ border: 0, background: "#fff" }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {/*  */}
                    <TableCell sx={{ fontSize: "14px", textTransform: "uppercase" }}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img
                          src={"https://cdn-icons-png.flaticon.com/512/8242/8242984.png"}
                          style={{ width: "2rem", marginRight: 10 }}
                        />

                        <Typography variant="h5" mr={0} fontWeight={500}>
                          CSV Column Headers
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ fontSize: "14px", textTransform: "uppercase" }}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={logo} style={{ width: "6rem", marginRight: 5 }} />
                        <Typography variant="h5" mr={0} fontWeight={500}>
                          Column Headers
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {headers.map((key, index) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Typography
                          // fullwidth
                          color="text.secondary"
                          variant="body1"
                          fontWeight={600}
                        >
                          {key}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Select
                          name={key}
                          // fullWidth
                          size="small"
                          menuShouldBlockScroll={true}
                          isRequired={true}
                          menuPosition="fixed"
                          options={filteredDbHeaders}
                          onChange={(selectedOption) =>
                            handleFieldNameSelection(key, selectedOption?.field)
                          }
                          value={
                            dbHeaders.find((option) => option.field === fieldMappings[key]) || null
                          }
                          getOptionLabel={(option) => option.headerName}
                          getOptionValue={(option) => option.field}
                          placeholder="Choose Header ..."
                          styles={{
                            singleValue: (base) => ({
                              ...base,
                              fontSize: "14px",
                              fontWeight: 600,
                            }),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper variant="outlined" style={{ position: "sticky", top: 0, background: "#fff" }}>
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ background: "#7373f6", color: "#fff" }}
          >
             School Year ({currentSchoolYear})
      </Typography>
          <Box mt={5}>
            <Divider textAlign="left">
              <Typography variant="h4" align="left" ml={1} gutterBottom>
                Assigned
              </Typography>
              {/* <Typography variant="caption" component={'div'} ml={1} mb={2} color='text.secondary'>First Name, Last Name fields are required. Other columns are optional.
            </Typography> */}
            </Divider>
          </Box>
          {/* <Alert color="info" severity="info" sx={{ borderRadius: 0 }}>
            <Typography variant="body1" component="div">
              Please select the slpbox headers that relates to each of your CSV columns.
              <ul>
                <li>First Name, Last Name fields are required. Other columns are optional.</li>
              </ul>
            </Typography>
        </Alert> */}
          <List>
            {Object.entries(fieldMappings).map(([key, value], index) => (
              <Paper
                key={index}
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 12px 8px 16px",
                  background: grey[100],
                  alignItems: "center",
                  marginBottom: 1,
                  position: "relative",
                  cursor: "pointer",
                  borderRadius: "1px",

                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: grey[300],
                    // color: '#fff',
                    borderColor: (theme) => theme.palette.primary["main"],
                  },
                }}
              >
                <Grid container spacing={2} sx={{ flex: 1 }}>
                  <Grid item xs={12} lg={9}>
                    <Stack
                      spacing={2}
                      useFlexGap
                      direction={{ xs: "column", sm: "row" }}
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Box>
                        {/* <Avatar sx={{ bgcolor: grey[500], width: 24, height: 24 }}> */}
                        <RadioButtonCheckedIcon sx={{ color: grey[700] }} />
                        {/* </Avatar> */}
                      </Box>
                      <Box width={250} sx={{ maxWidth: 300, display: "flex" }}>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          fontWeight={400}
                          variant="body1"
                          textTransform={"capitalize"}
                        >
                          {key}
                        </Typography>
                      </Box>
                      <Box>
                        <ArrowForwardIcon sx={{ fontSize: 18, marginBottom: "-4px" }} />
                      </Box>
                      <Box>
                        <Typography
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          fontWeight={500}
                          variant="body1"
                          textTransform={"capitalize"}
                        >
                          {showValue(value)}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs sx={{ justifyContent: "flex-end", display: "flex" }}>
                    <Stack spacing={2} direction={"row"}>
                      <DeleteIcon
                        fontSize="medium"
                        style={{ marginTop: 0, color: red[300] }}
                        onClick={() => handleDelete(key)}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </List>
          {Object.keys(fieldMappings).length === 0 && (
            <Box p={1}>
              <SMessageCard
                // icon={<SyncAltOutlined />}
                imgWidth="10rem"
                imgUrl="https://app-cdn.clickup.com/empty-lineup.6af62efc720232f88f943a58cdfef20d.svg"
                text="No Columns Assigned !"
                subText="Please select the slpbox headers that relates to each of your CSV columns.                "
              />
            </Box>
          )}
          <Box mt={5}>
            <Divider textAlign="left">
              <Typography variant="h4" align="left" ml={1} gutterBottom>
                Not Assigned
              </Typography>
            </Divider>
          </Box>
          <Box p={1}>
            <Typography variant="body2" component={"div"} ml={1} mb={2} color="text.secondary">
              <span style={{ color: "red" }}>*</span> Please note that the First Name, Last Name and
              School columns are required, while the other columns are optional.
            </Typography>
            {unselectedHeaders.map((header) => (
              <Chip
                // icon={<WarningAmberOutlined style={{color: '#d53f3f'}} />}
                key={header.field}
                variant="outlined"
                size="small"
                // color={header.headerName === 'firstName' ? 'error' : 'warning'}
                sx={{ borderRadius: 5 }}
                label={`${header.headerName}`}
                style={{ margin: "4px" }}
              />
            ))}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default FieldMapping;
