const articulationIntellForm = {
  articulation_Intelligibility: {
    arti_skills: {
      name: "arti_skills",
      label: "How would you describe the articulation skills of STUDENT",
      type: "text",
      placeholder: "Enter articulation skills",
      errorMsg: "Articulation skills are required.",
      invalidMsg: "Invalid input for articulation skills.",
      toolTipText: '',
      path: "articulation_Intelligibility.arti_skills",
    },
    arti_sound_production: {
      name: "arti_sound_production",
      label: "Does STUDENT demonstrate any of the following articulation issues?",
      type: "text",
      placeholder: "Enter sound production details",
      errorMsg: "Sound production details are required.",
      invalidMsg: "Invalid input for sound production details.",
      toolTipText: '',
      path: "articulation_Intelligibility.arti_sound_production",
    },
    arti_intelligibility: {
      name: "arti_intelligibility",
      label: "How easy is it for unfamiliar listeners to understand STUDENT when speaking?",
      type: "text",
      placeholder: "Enter intelligibility details",
      toolTipText: '',
      path: "articulation_Intelligibility.arti_intelligibility",
      errorMsg: "Intelligibility details are required.",
      invalidMsg: "Invalid input for intelligibility details.",
    },
    arti_behav_observations: {
      name: "arti_behav_observations",
      label: "During speech tasks, STUDENT demonstrates",
      type: "text",
      placeholder: "Enter behavioral observations",
      errorMsg: "Behavioral observations are required.",
      invalidMsg: "Invalid input for behavioral observations.",
      toolTipText: '',
      path: "articulation_Intelligibility.arti_behav_observations",
    },
    arti_impact_communication: {
      name: "arti_impact_communication",
      label: "How does STUDENT's articulation affect their communication abilities?",
      type: "text",
      placeholder: "Enter impact on communication",
      errorMsg: "Impact on communication details are required.",
      invalidMsg: "Invalid input for impact on communication details.",
      toolTipText: '',
      path: "articulation_Intelligibility.arti_impact_communication",
    },
    arti_additional_concerns: {
      name: "arti_additional_concerns",
      label: "Additional Concerns",
      type: "text",
      placeholder: "Enter additional concerns",
      errorMsg: "Additional concerns are required.",
      path: "articulation_Intelligibility.arti_additional_concerns",
      invalidMsg: "Invalid input for additional concerns.",
    },
    arti_doc_description: {
      name: "arti_doc_description",
      label: "Additional Description",
      type: "text",
      placeholder: "Enter additional description",
      errorMsg: "Additional description is required.",
      invalidMsg: "Invalid input for additional description.",
    },
  },
};

export default articulationIntellForm;
