import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    generalInformation: {
      firstName,
      middleName,
      lastName,
      caseManager,
      evaluatorName,
      dateOfBirth,
      school,
      schoolDistrict,
      gender,
      primaryLanguage,
      grade,
      testDates,
      dateOfReport,
      currentProgram,
      primaryDisability,
      secondaryDisability,
      identificationDate,
      serviceMinutes,
    },
    purposeOfEvaluation: {
      evaluationType,
      eval_type_referredBy,
      eval_type_dateOfReferal,
      eval_referred_title,
        eval_referred_name,
        eval_referred_hospital_name,
        eval_type_referral_other,
      eval_type_other,
      eval_type_doc_content,
      eval_type_doc_referral_content,
    },
    backgroundInformation: {
      // Educational History
      background_edu_Q_01,
      background_edu_Q_01_other,
      background_edu_Q_02,
      background_edu_Q_03,
      background_edu_Q_03_other,
      // Medical History
      background_med_Q_01,
      background_med_Q_02,
      background_med_Q_03,
      background_med_Q_03_other,
      background_med_Q_04,
      background_med_Q_04_other,
      background_med_Q_05,
      background_med_Q_05_other,
      // Developmental History
      background_development_Q_01,
      background_development_Q_02,
      background_development_Q_03,
      background_development_Q_04,
      background_development_Q_05,
      background_development_Q_05_other,
      // Medical Findings
      relevant_med_Q_01,
      relevant_med_Q_02,
      relevant_med_Q_03,
      relevant_med_Q_03_other,
      //Family History
      background_family_Q_01,
      background_family_Q_01_other,
      background_doc_description,
      background_edu_history_output,
      background_medical_history_output,
      background_development_history_output,
      background_medical_findings_output,
      background_family_history_output
    },
    prevAssesment: {
      prev_assesment_history,
      prev_assesment_history_other,
      prev_assesment_services,
    },
    teacherInput: {
      teacher_input_Q_01,
      teacher_input_Q_02,
      teacher_input_Q_03,
      teacher_input_Q_03_other,
      teacher_input_Q_04,
      teacher_input_Q_05,
      teacher_input_Q_06,
      teacher_input_Q_06_other,
      teacher_input_Q_07,
      teacher_input_Q_07_other,
      teacher_input_Q_08,
      teacher_input_Q_08_other,
      teacher_input_Q_09,
      teacher_input_Q_10,
      teacher_input_Q_11,
      teacher_input_Q_12,
      teacher_input_Q_13,
      teacher_input_Q_14,
      teacher_input_Q_15,
      teacher_input_Q_16,
      teacher_input_Q_17_A,
      teacher_input_Q_17_B,
      teacher_input_Q_17_C,
      teacher_input_Q_18,
      teacher_input_Q_19,
      teacher_input_Q_19_other,
      teacher_input_Q_20,
      teacher_input_Q_20_other,
      teacher_input_Q_21,
      teacher_input_Q_21_other,
      teacher_input_Q_22,
      teacher_input_Q_22_other,
      teacher_additional_info,
      teacher_doc_description,
    },
    parentInput: {
      parent_input_Q_01,
      parent_input_Q_02,
      parent_input_Q_02_other,
      parent_input_Q_03,
      parent_input_Q_03_other,
      parent_input_Q_04,
      parent_input_Q_05,
      parent_input_Q_06,
      parent_input_Q_07,
      parent_input_Q_08,
      parent_input_Q_08_other,
      parent_input_Q_09,
      parent_input_Q_09_other,
      parent_input_Q_10,
      parent_input_Q_10_other,
      parent_input_Q_11,
      parent_input_Q_11_other,
      parent_input_Q_12,
      parent_input_Q_12_other,
      parent_input_Q_13,
      parent_input_Q_13_other,
      parent_input_Q_14,
      parent_input_Q_14_other,
      parent_input_Q_15,
      parent_additional_info,
      parent_doc_description
    },
    classroomObservation: {
      classroom_obs_date_time,
      classroom_obs_teacher_input_Q_01,
      classroom_obs_assistants_count,
      classroom_obs_Q_04,
      classroom_obs_Q_04_other,
      classroom_obs_Q_05,
      classroom_obs_Q_06,
      classroom_obs_Q_07,
      classroom_obs_Q_08,
      classroom_obs_Q_09,
      classroom_obs_Q_10,
      classroom_obs_Q_11,
      classroom_obs_Q_12,
      classroom_obs_Q_13,
      classroom_obs_Q_14,
      classroom_obs_Q_15,
      classroom_obs_Q_16,
      classroom_obs_Q_17,
      classroom_obs_Q_18,
      classroom_obs_Q_19,
      classroom_obs_Q_19_other,
      classroom_obs_Q_20,
      classroom_obs_Q_20_other,
      classroom_obs_Q_21,
      classroom_obs_Q_21_other,
      classroom_obs_Q_22,
      classroom_obs_Q_22_other,
      classroom_additional_info,
      classroom_doc_description
    },
    evaluationObservQuestionnaire: {
      eval_obv_Q_01,
      eval_obv_Q_02,
      eval_obv_Q_03,
      eval_obv_Q_04,
      eval_obv_Q_04_other,
      eval_obv_Q_05,
      eval_obv_Q_06,
      eval_obv_Q_07,
      eval_obv_Q_08,
      eval_obv_Q_09,
      eval_doc_description
    },
    behavioralObservation: {
      behavioral_Q_01,
      behavioral_Q_02,
      behavioral_Q_03,
      behavioral_Q_04,
      behavioral_Q_05,
      behav_student_doc_description,
    },
    oralPeripheralMechanism: {
      opme_lips,
      opme_tongue,
      opme_jaw,
      opme_teeth,
      opme_additional_info,
      opme_doc_description
    },
    fluency: {
      flu_student_describe,
      flu_student_experience,
      flu_student_report,
      flu_doc_description
    },
    voiceObservation: {
      voice_obs_method,
      voice_obs_pitch,
      voice_obs_loudness,
      voice_obs_quality,
      voice_obs_resonance,
      voice_obs_overall_voice,
      voice_doc_description
    },
    articulation_Intelligibility: {
      arti_skills,
      arti_sound_production,
      arti_intelligibility,
      arti_behav_observations,
      arti_impact_communication,
      arti_additional_concerns,
      arti_doc_description
    },
    pragmatics: {
      pragmatics_Q_01,
      pragmatics_Q_02,
      pragmatics_Q_03,
      pragmatics_Q_04,
      pragmatics_Q_05,
      pragmatics_Q_06,
      pragmatics_Q_07,
      pragmatics_Q_08,
      prag_additional_concerns,
      prag_doc_description
    },
    reportSummary: {
      report_summary_Q_01,
      report_summary_Q_01_other,
      report_summary_Q_02,
      report_summary_Q_02_other,
      report_summary_Q_03,
      report_summary_Q_03_other,
      report_summary_Q_04,
      report_summary_Q_04_other,
      report_summary_Q_05,
      report_summary_Q_06,
      report_summary_doc_description
    },
    eligibility: {
      eligibility_Q_01,
      eligibility_Q_02,
      eligibility_Q_02_other,
      eligibility_Q_03,
      eligibility_Q_04,
      eligibility_Q_05,
      eligibility_Q_06,
      eligibility_Q_07,
      eligibility_Q_07_doc_description
    },
    recommendations: {
      recomm_Q_01,
      recomm_Q_01_other,
      recomm_Q_02,
      recomm_doc_description
    },
    goals: {
      goals
    },
    normative_chart: {
      normative_chart_default,
      normative_chart_uploaded
    },
    signature: {
      sig_add_signature,
      sig_fullname,
      sig_position,
      sig_school_district_name,
      sig_date
    },
  },
} = checkout;

// Validation schema for Basic Information
const generalInformationSchema = Yup.object().shape({
  [firstName.name]: Yup.string().required(firstName.errorMsg),
  [middleName.name]: Yup.string(),
  [lastName.name]: Yup.string().required(lastName.errorMsg),
  [caseManager.name]: Yup.string(),
  [evaluatorName.name]: Yup.string().required(evaluatorName.errorMsg),
  [dateOfBirth.name]: Yup.date().required(dateOfBirth.errorMsg),
  [school.name]: Yup.string().required(school.errorMsg),
  [schoolDistrict.name]: Yup.string().required(schoolDistrict.errorMsg),
  [primaryLanguage.name]: Yup.object().required(primaryLanguage.errorMsg),
  [gender.name]: Yup.object().required(gender.errorMsg),
  [grade.name]: Yup.object().required(grade.errorMsg),
  [testDates.name]: Yup.array(),
  [dateOfReport.name]: Yup.date().required(dateOfReport.errorMsg),
  [currentProgram.name]: Yup.object().nullable(),
  [primaryDisability.name]: Yup.object().nullable(),
  [secondaryDisability.name]: Yup.object().nullable(),
  [identificationDate.name]: Yup.date(),
  [serviceMinutes.name]: Yup.number(),
});


// Validation schema for Purpose of Evaluation
const purposeOfEvaluationSchema = Yup.object().shape({
  [evaluationType.name]: Yup.object().nullable(),
  [eval_type_referredBy.name]: Yup.object().nullable(),
  [eval_type_dateOfReferal.name]: Yup.date(),
  [eval_type_other.name]: Yup.string(),
  [eval_referred_title.name]: Yup.object().nullable(),
  [eval_referred_name.name]: Yup.string(),
  [eval_referred_hospital_name.name]: Yup.string(),
  [eval_type_referral_other.name]: Yup.string(),
  //DOCUMENT CONTENT
  [eval_type_doc_content.name]: Yup.string(),
  [eval_type_doc_referral_content.name]: Yup.string()
});

// Validation schema for Education History
const backgroundInfoSchema = Yup.object().shape({
  // Educational History
  [background_edu_Q_01.name]: Yup.string(),
  [background_edu_Q_01_other.name]: Yup.string(),
  [background_edu_Q_02.name]: Yup.string(),
  [background_edu_Q_03.name]: Yup.array(),
  [background_edu_Q_03_other.name]: Yup.string(),
  // Medical History
  [background_med_Q_01.name]: Yup.string(),
  [background_med_Q_02.name]: Yup.string(),
  [background_med_Q_03.name]: Yup.string(),
  [background_med_Q_03_other.name]: Yup.string(),
  [background_med_Q_04.name]: Yup.string(),
  [background_med_Q_04_other.name]: Yup.string(),
  [background_med_Q_05.name]: Yup.string(),
  [background_med_Q_05_other.name]: Yup.string(),
  // Developmental History
  [background_development_Q_01.name]: Yup.string(),
  [background_development_Q_02.name]: Yup.string(),
  [background_development_Q_03.name]: Yup.string(),
  [background_development_Q_04.name]: Yup.string(),
  [background_development_Q_05.name]: Yup.string(),
  [background_development_Q_05_other.name]: Yup.string(),
  // Medical Findings
  [relevant_med_Q_01.name]: Yup.string(),
  [relevant_med_Q_02.name]: Yup.string(),
  [relevant_med_Q_03.name]: Yup.string(),
  [relevant_med_Q_03_other.name]: Yup.string(),
  // Family History
  [background_family_Q_01.name]: Yup.string(),
  [background_family_Q_01_other.name]: Yup.string(),
  [background_doc_description.name]: Yup.string(),
  [background_edu_history_output.name]: Yup.string(),
  [background_medical_history_output.name]: Yup.string(),
  [background_development_history_output.name]: Yup.string(),
  [background_medical_findings_output.name]: Yup.string(),
  [background_family_history_output.name]: Yup.string()

});

const prevAssesmentSchema = Yup.object().shape({
  [prev_assesment_history.name]: Yup.string(),
  [prev_assesment_history_other.name]: Yup.string(),
  [prev_assesment_services.name]: Yup.array().of(
    Yup.object().shape({
      dateOfReport: Yup.date(),
      examinerName: Yup.string(),
      prev_assesment_type: Yup.object().nullable(),
      serviceType: Yup.object().nullable(),
      serviceType_other: Yup.string(),
      serviceFrequency: Yup.object().nullable(),
      serviceFrequency_other: Yup.string(),
      diagnosis: Yup.string(),
      serviceMinutes: Yup.number().integer(),
    })
  )
});
// Validation schema for Teacher Input
const teacherInputSchema = Yup.object().shape({
  [teacher_input_Q_01.name]: Yup.string().required(teacher_input_Q_01.errorMsg),
  [teacher_input_Q_02.name]: Yup.string(),
  [teacher_input_Q_03.name]: Yup.string(),
  [teacher_input_Q_03_other.name]: Yup.string(),
  [teacher_input_Q_04.name]: Yup.date().required(teacher_input_Q_04.errorMsg),
  [teacher_input_Q_05.name]: Yup.string(),
  [teacher_input_Q_06.name]: Yup.string(),
  [teacher_input_Q_06_other.name]: Yup.string(),
  [teacher_input_Q_07.name]: Yup.string(),
  [teacher_input_Q_07_other.name]: Yup.string(),
  [teacher_input_Q_08.name]: Yup.string(),
  [teacher_input_Q_08_other.name]: Yup.string(),
  [teacher_input_Q_09.name]: Yup.string(),
  [teacher_input_Q_10.name]: Yup.string(),
  [teacher_input_Q_11.name]: Yup.string(),
  [teacher_input_Q_12.name]: Yup.string(),
  [teacher_input_Q_13.name]: Yup.string(),
  [teacher_input_Q_14.name]: Yup.string(),
  [teacher_input_Q_15.name]: Yup.string(),
  [teacher_input_Q_16.name]: Yup.string(),
  [teacher_input_Q_17_A.name]: Yup.string(),
  [teacher_input_Q_17_B.name]: Yup.string(),
  [teacher_input_Q_17_C.name]: Yup.string(),
  [teacher_input_Q_18.name]: Yup.string(),
  [teacher_input_Q_19.name]: Yup.string(),
  [teacher_input_Q_19_other.name]: Yup.string(),
  [teacher_input_Q_20.name]: Yup.string(),
  [teacher_input_Q_20_other.name]: Yup.string(),
  [teacher_input_Q_21.name]: Yup.string(),
  [teacher_input_Q_21_other.name]: Yup.string(),
  [teacher_input_Q_22.name]: Yup.string(),
  [teacher_input_Q_22_other.name]: Yup.string(),
  [teacher_additional_info.name]: Yup.string(),
  [teacher_doc_description.name]: Yup.string(),
});


// Validation schema for Teacher Input
const parentInputSchema = Yup.object().shape({
  [parent_input_Q_01.name]: Yup.string(),
  [parent_input_Q_02.name]: Yup.array(),
  [parent_input_Q_02_other.name]: Yup.string(),
  [parent_input_Q_03.name]: Yup.string(),
  [parent_input_Q_03_other.name]: Yup.string(),
  [parent_input_Q_04.name]: Yup.date(),
  [parent_input_Q_05.name]: Yup.string(),
  [parent_input_Q_06.name]: Yup.string(),
  [parent_input_Q_07.name]: Yup.string(),
  [parent_input_Q_08.name]: Yup.string(),
  [parent_input_Q_08_other.name]: Yup.string(),
  [parent_input_Q_09.name]: Yup.array(),
  [parent_input_Q_09_other.name]: Yup.string(),
  [parent_input_Q_10.name]: Yup.array(),
  [parent_input_Q_10_other.name]: Yup.string(),
  [parent_input_Q_11.name]: Yup.array(),
  [parent_input_Q_11_other.name]: Yup.string(),
  [parent_input_Q_12.name]: Yup.array(),
  [parent_input_Q_12_other.name]: Yup.string(),
  [parent_input_Q_13.name]: Yup.array(),
  [parent_input_Q_13_other.name]: Yup.string(),
  [parent_input_Q_14.name]: Yup.array(),
  [parent_input_Q_14_other.name]: Yup.string(),
  [parent_input_Q_15.name]: Yup.string(),
  [parent_additional_info.name]: Yup.string(),
  [parent_doc_description.name]: Yup.string(),
});

const classroomObservationSchema = Yup.object().shape({
  [classroom_obs_date_time.name]: Yup.date(),
  [classroom_obs_teacher_input_Q_01.name]: Yup.string(),
  [classroom_obs_assistants_count.name]: Yup.number().integer(),
  [classroom_obs_Q_04.name]: Yup.string(),
  [classroom_obs_Q_04_other.name]: Yup.string(),
  [classroom_obs_Q_05.name]: Yup.string(),
  [classroom_obs_Q_06.name]: Yup.string(),
  [classroom_obs_Q_07.name]: Yup.array(),
  [classroom_obs_Q_08.name]: Yup.array(),
  [classroom_obs_Q_09.name]: Yup.array(),
  [classroom_obs_Q_10.name]: Yup.array(),
  [classroom_obs_Q_11.name]: Yup.array(),
  [classroom_obs_Q_12.name]: Yup.array(),
  [classroom_obs_Q_13.name]: Yup.array(),
  [classroom_obs_Q_14.name]: Yup.string(),
  [classroom_obs_Q_15.name]: Yup.string(),
  [classroom_obs_Q_16.name]: Yup.string(),
  [classroom_obs_Q_17.name]: Yup.array(),
  [classroom_obs_Q_18.name]: Yup.array(),
  [classroom_obs_Q_19.name]: Yup.array(),
  [classroom_obs_Q_19_other.name]: Yup.string(),

  [classroom_obs_Q_20.name]: Yup.array(),
  [classroom_obs_Q_20_other.name]: Yup.string(),

  [classroom_obs_Q_21.name]: Yup.array(),
  [classroom_obs_Q_21_other.name]: Yup.string(),

  [classroom_obs_Q_22.name]: Yup.array(),
  [classroom_obs_Q_22_other.name]: Yup.string(),

  [classroom_additional_info.name]: Yup.string(),
  [classroom_doc_description.name]: Yup.string(),
});

const evaluationObservQuestionnaireSchema = Yup.object().shape({
  [eval_obv_Q_01.name]: Yup.string(),
  [eval_obv_Q_02.name]: Yup.string(),
  [eval_obv_Q_03.name]: Yup.string(),
  [eval_obv_Q_04.name]: Yup.string(),
  [eval_obv_Q_04_other.name]: Yup.string(),
  [eval_obv_Q_05.name]: Yup.string(),
  [eval_obv_Q_06.name]: Yup.string(),
  [eval_obv_Q_07.name]: Yup.string(),
  [eval_obv_Q_08.name]: Yup.string(),
  [eval_obv_Q_09.name]: Yup.string(),
  [eval_doc_description.name]: Yup.string()
});
const behavioralObservationSchema = Yup.object().shape({
  [behavioral_Q_01.name]: Yup.string(),
  [behavioral_Q_02.name]: Yup.string(),
  [behavioral_Q_03.name]: Yup.string(),
  [behavioral_Q_04.name]: Yup.string(),
  [behavioral_Q_05.name]: Yup.string(),
  [behav_student_doc_description.name]: Yup.string(),
});

const oralPeripheralMechanismSchema = Yup.object().shape({
  [opme_lips.name]: Yup.string(),
  [opme_tongue.name]: Yup.string(),
  [opme_jaw.name]: Yup.string(),
  [opme_teeth.name]: Yup.string(),
  [opme_additional_info.name]: Yup.string(),
  [opme_doc_description.name]: Yup.string(),
});

const fluencySchema = Yup.object().shape({
  [flu_student_describe.name]: Yup.string(),
  [flu_student_experience.name]: Yup.string(),
  [flu_student_report.name]: Yup.string(),
  [flu_doc_description.name]: Yup.string(),
});

const voiceObservationSchema = Yup.object().shape({
  [voice_obs_method.name]: Yup.string(),
  [voice_obs_pitch.name]: Yup.string(),
  [voice_obs_loudness.name]: Yup.string(),
  [voice_obs_quality.name]: Yup.string(),
  [voice_obs_resonance.name]: Yup.string(),
  [voice_obs_overall_voice.name]: Yup.string(),
  [voice_doc_description.name]: Yup.string(),
});

const articulation_IntelligibilitySchema = Yup.object().shape({
  [arti_skills.name]: Yup.string(),
  [arti_sound_production.name]: Yup.string(),
  [arti_intelligibility.name]: Yup.string(),
  [arti_behav_observations.name]: Yup.string(),
  [arti_impact_communication.name]: Yup.string(),
  [arti_additional_concerns.name]: Yup.string(),
  [arti_doc_description.name]: Yup.string(),
});

const pragmaticsSchema = Yup.object().shape({
  [pragmatics_Q_01.name]: Yup.string(),
  [pragmatics_Q_02.name]: Yup.string(),
  [pragmatics_Q_03.name]: Yup.string(),
  [pragmatics_Q_04.name]: Yup.string(),
  [pragmatics_Q_05.name]: Yup.string(),
  [pragmatics_Q_06.name]: Yup.string(),
  [pragmatics_Q_07.name]: Yup.string(),
  [pragmatics_Q_08.name]: Yup.string(),
  [prag_additional_concerns.name]: Yup.string(),
  [prag_doc_description.name]: Yup.string(),
});

const reportSummarySchema = Yup.object().shape({
  [report_summary_Q_01.name]: Yup.string(),
  [report_summary_Q_01_other.name]: Yup.string(),
  [report_summary_Q_02.name]: Yup.string(),
  [report_summary_Q_02_other.name]: Yup.string(),
  [report_summary_Q_03.name]: Yup.string(),
  [report_summary_Q_03_other.name]: Yup.string(),
  [report_summary_Q_04.name]: Yup.string(),
  [report_summary_Q_04_other.name]: Yup.string(),
  [report_summary_Q_05.name]: Yup.string(),
  [report_summary_Q_06.name]: Yup.string(),
  [report_summary_doc_description.name]: Yup.string()
});

const eligibilitySchema = Yup.object().shape({
  [eligibility_Q_01.name]: Yup.string(),
  [eligibility_Q_02.name]: Yup.array(),
  [eligibility_Q_02_other.name]: Yup.string(),
  [eligibility_Q_03.name]: Yup.string(),
  [eligibility_Q_04.name]: Yup.string(),
  [eligibility_Q_05.name]: Yup.string(),
  [eligibility_Q_06.name]: Yup.string(),
  [eligibility_Q_07.name]: Yup.string(),
  [eligibility_Q_07_doc_description.name]: Yup.string()
});

const recommendationsSchema = Yup.object().shape({
  [recomm_Q_01.name]: Yup.array(),
  [recomm_Q_01_other.name]: Yup.string(),
  [recomm_Q_02.name]: Yup.string(),
  [recomm_doc_description.name]: Yup.string()
});
const goalsSchema = Yup.object().shape({
  [goals.name]: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
    })
  )
});

const signatureSchema = Yup.object().shape({
  [sig_add_signature.name]: Yup.string(),
  [sig_fullname.name]: Yup.string(),
  [sig_position.name]: Yup.string(),
  [sig_school_district_name.name]: Yup.string(),
  [sig_date.name]: Yup.date()
});

const normative_chart_schema = Yup.object().shape({
  [normative_chart_default.name]: Yup.string(),
  [normative_chart_uploaded.name]: Yup.string(),
});

// Combine all schemas into one main validation schema
const validations = [
  Yup.object().shape({
    generalInformation: generalInformationSchema,
    purposeOfEvaluation: purposeOfEvaluationSchema,
    backgroundInformation: backgroundInfoSchema,
    prevAssesment: prevAssesmentSchema,
    teacherInput: teacherInputSchema,
    parentInput: parentInputSchema,
    classroomObservation: classroomObservationSchema,
    evaluationObservQuestionnaire: evaluationObservQuestionnaireSchema,
    behavioralObservation: behavioralObservationSchema,
    oralPeripheralMechanism: oralPeripheralMechanismSchema,
    fluency: fluencySchema,
    voiceObservation: voiceObservationSchema,
    articulation_Intelligibility: articulation_IntelligibilitySchema,
    pragmatics: pragmaticsSchema,
    reportSummary: reportSummarySchema,
    eligibility: eligibilitySchema,
    recommendations: recommendationsSchema,
    goals: goalsSchema,
    signature: signatureSchema,
    normative_chart: normative_chart_schema
  })];

export default validations;
