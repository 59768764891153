const form = {
  formId: "collaboration",
  formField: {
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      placeholder: "Assistant Email Address",
      errorMsg: "Email is required.",
      invalidMsg: "",
    },
    name: {
      name: "name",
      label: "Full Name",
      type: "text",
      placeholder: "Enter Fullname",
      errorMsg: "Fullname is required.",
      invalidMsg: "",
    },
    password: {
      name: "password",
      label: "Temporary Password",
      type: "password",
      placeholder: "Enter Temporary Password",
      errorMsg: "Temporary password is required.",
      invalidMsg: "",
    },
  },
};

export default form;
