/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../constants/constants";

const initialState = {
  studentList: [],
  studentById: {},
  studentCount: [],
  getDataTrackingHistory: [],
  searchedResult: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.FETCH_STUDENTS:
      return { ...state, studentList: action.payload };
    case Actions.DELETE_STUDENT:
    case Actions.FETCH_STUDENT_BY_ID:
      return { ...state, studentById: action.payload };
    case Actions.IMPORT_STUDENTS:
      return { ...state, studentList: action.payload };
    case Actions.FETCH_STUDENTS_COUNT:
      return { ...state, studentCount: action.payload };
    case Actions.FETCH_DATA_TRACKING_HISTORY:
      return { ...state, getDataTrackingHistory: action.payload };
    case Actions.SEARCH_STUDENT:
      return { ...state, searchedResult: action.payload };
    default:
      return state;
  }
};
