import React from "react";
import { Grid } from "@mui/material";
import OptionButton from "../components/OptionButton";

const Recommendations = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        recomm_Q_01,
        recomm_Q_01_other,
        recomm_Q_02,
        recomm_doc_description
    } = formField.recommendations;

    return (
        <Grid container spacing={2} p={2}>
            <OptionButton
                label={recomm_Q_01.label}
                type="checkbox"
                row={false}
                name={recomm_Q_01.name}
                options={props.options?.recomm_Q_01}
                value={values.recommendations.recomm_Q_01}
                onChange={(name, value) => setFieldValue(recomm_Q_01.path, value)}
                additionalInfoLabel={recomm_Q_01_other.label}
                additionalInfoName={recomm_Q_01_other.path}
                additionalInfoValue={values.recommendations.recomm_Q_01_other}
                setFieldValue={setFieldValue}
                toolTipText={recomm_Q_01.toolTipText}
                triggerValues={[8]} // Values that should trigger the additional input field
                error={errors.recommendations?.recomm_Q_01 && touched.recommendations?.recomm_Q_01}
            />
            <OptionButton
                label={recomm_Q_02.label}
                type="radio"
                row={true}
                name={recomm_Q_02.name}
                options={recomm_Q_02.options}
                value={values.recommendations.recomm_Q_02}
                onChange={(name, value) => setFieldValue(recomm_Q_02.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={recomm_Q_02.toolTipText}
                error={errors.recommendations?.recomm_Q_02 && touched.recommendations?.recomm_Q_02}
            />
        </Grid>
    );
};

export default Recommendations;
