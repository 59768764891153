import { authRequest } from "./request";
import * as Actions from "../constants/constants";
import { showNotification } from "./notificationAction";
import { SUCCESS } from "redux/constants/notificationType";

export function reportAllDropdowns() {
  return async (dispatch) => {
    const response = await authRequest("report/dropdownList", "GET");
    dispatch({
      type: Actions.REPORTS_IEP_DROPDOWN,
      payload: response.response,
    });
    return response?.response;
  };
};

export function createReport(payload) {
  return async (dispatch) => {
    try {
      const response = await authRequest("report/create-report", "POST", payload);
      dispatch({
        type: Actions.CREATE_REPORT,
        payload: response,
      });
      showNotification({
        message: "Added Succesfully !",
        type: SUCCESS,
      });
      return response;
    } catch (error) {
      return error;
    }
  };
}

export function getAllReports(payload) {
  const queryParams = new URLSearchParams(payload.filter).toString();
  return async (dispatch) => {
    try {
      const response = await authRequest(`report/getAllReports?${queryParams}`, "GET");
      dispatch({
        type: Actions.GET_ALL_REPORTS,
        payload: response,
      });
      return response;
    } catch (error) {
      return error;
    }
  };
}
