const form = {
  formId: "auth",
  formField: {
    email: {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "Email address",
      errorMsg: "Email is required.",
      invalidMsg: "",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Password",
      errorMsg: "Password is required.",
      invalidMsg: "",
    },
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      placeholder: "First Name",
      errorMsg: "First name is required.",
      invalidMsg: "",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      placeholder: "Last Name",
      errorMsg: "Last Name is required.",
      invalidMsg: "",
    },
  },
};

export default form;
