import React, { useState } from "react";
import { Box } from "@mui/material";
import { initialValues, form, validations } from "../components/schemas";
import { Formik, Form } from "formik";
import SignUpForm from "../SignUpForm";
import { useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { register } from "redux/actions/authAction";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import AuthBox from "../../../components/Box/AuthBox";
import AgreementText from "../AgreementText";
const FullWidthButton = styled(LoadingButton)({
  width: "100%",
  // marginTop: 15,
});

const SignUpPage = () => {
  const { formId, formField } = form;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitForm = (values, actions) => {
    let payload = {
      email: values.email.toLowerCase(),
      ...values,
    };
    setLoading(true);
    dispatch(register(payload))
      .then((response) => {
        if (response && !response.success) setLoading(false);

        if (response && response.success) {
          setLoading(false);
          navigate("/login");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <AuthBox showSS0={true}>
      <Formik initialValues={initialValues} validationSchema={validations[1]} onSubmit={submitForm}>
        {({ values, errors, touched, setFieldValue, isSubmitting, resetForm }) => (
          <Form id={formId} autoComplete="off">
            <SignUpForm
              values={values}
              touched={touched}
              formField={formField}
              setFieldValue={setFieldValue}
              resetForm={resetForm}
              errors={errors}
            />
            <Box align="center" mt={2}>
              <FullWidthButton
                size="large"
                variant="contained"
                type="submit"
                loading={loading}
                // endIcon={<EastIcon />}
                sx={{
                  background: "#6565ec",
                  color: "white",
                  "&:hover": {
                    background: "#5d5dd0",
                    borderColor: "#6565ec",
                  },
                }}
              >
                {"Sign up"}
              </FullWidthButton>
              <AgreementText Text={'signing up'}/>

            </Box>
          </Form>
        )}
      </Formik>
    </AuthBox>
  );
};

export default SignUpPage;
