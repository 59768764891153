import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Paper, Box, Stack, Pagination, Divider } from "@mui/material";
import SMessageCard from "components/SMessageCard";
import { CopyAll, MoreVert } from "@mui/icons-material";
import { blue, grey, purple } from "@mui/material/colors";
import { fetchAllSentInvites } from "redux/actions/meetingInviteAction";
import ViewInvite from "../ViewInvite";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import moment from "moment";
import { groupBy } from "lodash";
import SearchBox from "components/Box/SearchBox";
import { Send } from "@mui/icons-material";
import SLoadingIndicator from "components/SLoadingIndicator";
import BlockDate from '../../../../assets/icons/connection.png'
const AssessmentList = ({ data, onItemClick }) => {
  const handleItemClick = (item) => {
    onItemClick && onItemClick(item);
  };
  const groupedData = groupBy(data, (item) => moment(item.createdAt).format("YYYY-MM-DD"));

  return (
    <div>
      {Object.keys(groupedData).map((date) => (
        <div key={date}>
          <Typography variant="subtitle1" color={"text.secondary"} mb={1}>
            {moment(date).format("LL")}
          </Typography>
          {groupedData[date].map((item) => (
            <Paper
              key={item._id}
              elevation={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderLeft: `2px solid ${blue[300]}`, // Replace with your desired color
                padding: "10px 12px 8px 16px",
                background: "#faf9f9",
                marginBottom: 1,
                position: "relative",
                cursor: "pointer",
                borderRadius: "1px",
                backgroundColor: "#faf9f9",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.01)",
                  boxShadow: "0px 0px 10px 0px rgb(13 183 242 / 9%)",
                  cursor: "pointer",
                  borderColor: (theme) => theme.palette.primary["main"],
                },
              }}
              onClick={() => handleItemClick(item)}
            >
              <Grid container spacing={2} sx={{ flex: 1 }}>
                <Grid item xs={9}>
                  <Stack
                    spacing={2}
                    useFlexGap
                    direction={{ xs: "column", sm: "row" }}
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Box width={150} sx={{ maxWidth: 200, display: "flex" }}>
                      <Typography
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        fontWeight={500}
                        variant="body1"
                        textTransform={"capitalize"}
                      >
                        {item.eventTitle}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs sx={{ justifyContent: "flex-end", display: "flex" }}>
                  <Stack spacing={2} direction={"row"}>
                    <CopyAll fontSize="medium" style={{ marginTop: 0, color: grey[500] }} />
                    <MoreVert fontSize="medium" style={{ color: grey[500] }} />
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </div>
      ))}
    </div>
  );
};

export default function InvitedList() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { invitedList } = useSelector((state) => ({
    invitedList: state.meetingInviteReducer.invitedList,
  }));

  const response = invitedList?.response?.length > 0 ? invitedList?.response : [];

  const [view, setView] = useState(false);
  const [inviteById, setInviteById] = useState(null);

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [searchFilter, setSearchFilter] = useState("");
  const perPage = 10; // Number of items per page

  const payload = {
    filter: {
      pageNumber: page,
      searchValue: searchFilter,
      startDate: "",
      endDate: "",
    },
  };

  const fetchData = async () => {
    setLoading(true);
    dispatch(fetchAllSentInvites(payload))
      .then((response) => {
        setLoading(false);
        setTotalCount(response.totalCount);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [page, searchFilter]); // Fetch data when the page changes

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleItemClick = (item) => {
    setView(true);
    setInviteById(item);
  };

  const handleSearch = (searchText) => {
    setSearchFilter(searchText);
    // Your search logic here
  };

  const handleClear = () => {
    setSearchFilter("");
  };

  return (
    <React.Fragment>
      {!view && (
        <SearchBox
          onSearch={handleSearch}
          onClear={handleClear}
          placeholder="Search by meeting name ..."
        />
      )}

      {loading ? (
        <SLoadingIndicator height="60vh" />
      ) : response?.length > 0 ? (
        view ? (
          <ViewInvite data={inviteById} setView={setView} />
        ) : (
          <>
            <Box>
              <AssessmentList data={response} onItemClick={handleItemClick} />
            </Box>
            <Box style={{ display: "flex", justifyContent: "flex-end" }} mt={5}>
              <Pagination
                count={Math.ceil(totalCount / perPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
                shape="circular"
                variant="outlined"
                size="medium"
                showFirstButton
                showLastButton
              />
            </Box>
          </>
        )
      ) : (
        <Box height="60vh">

        <SMessageCard
        text="No meeting links found!"
        imgWidth="5rem"
        imgUrl={BlockDate}
        subText="Click on 'New Link' to create new scheduling link."
        />
        </Box>
      )}
    </React.Fragment>
  );
}
