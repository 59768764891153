import React, { useEffect } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router";
import { ThemeProvider } from "@mui/material";
import theme from "theme";
import MainLayout from "./components/MainLayout";
import Dashboard from "./pages/Dashboard";
import Student from "pages/Student";
import ViewStudent from "pages/Student/ViewStudent";
import Report from "pages/Report";
import Scheduler from "pages/Scheduler";
import "./index.css";
import Login from "pages/AuthPage";
import GlobalNotification from "components/GlobalNotification";
import AIGenerated from "pages/AIGenerated";
import ImageAI from "pages/AIGenerated/ImageAI";
import SchedulingLinks from "pages/SchedulingLinks";
import DataTracking from "pages/DataCollection";
import axios from "axios";
// import OnBoardingPage from "global/OnBoardingPage";
import SignUp from "./pages/AuthPage/SignUpPage";
import ResetPasswordPage from "./pages/AuthPage/ResetPasswordPage";
import ForgotPasswordPage from "./pages/AuthPage/ForgotPasswordPage";
import VerifyEmailPage from "./pages/AuthPage/VerifyEmailPage";
import OnBoardingPage from "pages/OnBoardingPage";
import SubscriptionPage from "pages/OnBoardingPage/SubscriptionPage";
import InvitedPagePublic from "pages/InvitedPagePublic";
import { useAuth0 } from "@auth0/auth0-react";
import SLoadingIndicator from 'components/SLoadingIndicator';
import { Backdrop, Container } from '@mui/material';
import BackdropLoading from "components/Common/BackdropLoading";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, logout, error, isLoading } = useAuth0();
 
  // if(!user.email_verified) {
  //   navigate('/verification')
  // }
  useEffect(() => {
    if (error) {
      logout({ returnTo: window.location.origin });
    } else if (!isAuthenticated && !isLoading) {
      // Redirect to login if the user is not authenticated
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, error, isLoading]);

  // If the authentication check is still in progress, show a loading spinner or nothing
  if (isLoading || !isAuthenticated) {
    return null; // Or a loading spinner or message
  }

  return children;
};

function App() {
  const { getAccessTokenSilently, isAuthenticated, isLoading, user, logout } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const setupAxiosInterceptors = async () => {
      axios.interceptors.request.use(async (config) => {
        try {
          const token = await getAccessTokenSilently();
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        } catch (error) {
          console.error('Error fetching the access token', error);
        }
        return config;
      }, (error) => {
        return Promise.reject(error);
      });
    };

    setupAxiosInterceptors();
  }, [getAccessTokenSilently, logout]);

  useEffect(() => {
    if (!isAuthenticated) return;

    const navigateUser = () => {
      if (user) {
        console.log('user', user.assigned_role);

        if (user.assigned_role === 'assistant') {
          // Navigate to /data-tracking if the user is an assistant
          // navigate('/data-tracking');
        } else {
          // Handle subscription status and redirection for other roles
          if (!user.subscription_status || user.subscription_status === 'canceled') {
            navigate('/subscription');
          } else {
            if (window.location.pathname === '/') {
              navigate('/dashboard');
            }
          }
        }
      } else {
        // Handle case where user object is not present
        logout({ returnTo: window.location.origin }); // Log out if user object is not present
      }
    };

    navigateUser();
  }, [isAuthenticated, user, navigate, logout]);

  if (isLoading) {
    return (
      <Backdrop sx={{ color: '#fff', backgroundColor: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <BackdropLoading />
      </Backdrop>
    );
  }

  return (
    <ThemeProvider theme={theme}>
    <Routes>
      <Route path="/meeting/:meetingId" element={<InvitedPagePublic />} />
      <Route path="/subscription" element={<ProtectedRoute><SubscriptionPage /></ProtectedRoute>} />
      <Route path="/" element={<ProtectedRoute><MainLayout /></ProtectedRoute>}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/data-tracking" element={<DataTracking />} />
        <Route path="/student" element={<Student />} />
        <Route path="/student/:id" element={<ViewStudent />} />
        <Route path="/report" element={<Report />} />
        <Route path="/scheduler" element={<Scheduler />} />
        <Route path="/assistanceAI" element={<AIGenerated />} />
        <Route path="/imageAI" element={<ImageAI />} />
        <Route path="/scheduling-links" element={<SchedulingLinks />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
    <GlobalNotification />
  </ThemeProvider>
  );
}

export default App;
