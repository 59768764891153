/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../constants/constants";

const initialState = {
  subscriptionData: [],
  subscriptionSuccess: {},
  userDetails: {},
  userMenu: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.USER_CREATE_SUBSCRIPTION:
      return { ...state, subscriptionData: action.payload };
    case Actions.USER_SUBSCRIPTION_SUCCESS:
      return { ...state, subscriptionSuccess: action.payload };
    case Actions.USER_CANCEL_SUBSCRIPTION:
      return { ...state, subscriptionData: action.payload };
    case Actions.USER_CHECK_SUBSCRIPTION:
      return { ...state, subscriptionData: action.payload }; // Update as needed
    case Actions.USER_DETAILS:
      return { ...state, userDetails: action.payload };
    case Actions.USER_MENU:
      return { ...state, userMenu: action.payload };
    case Actions.UPDATE_USER:
    default:
      return state;
  }
};
