import { authRequest } from "./request";
import * as Actions from "../constants/constants";

export function subscriptionCheckoutSession(payload) {
  return (dispatch) => {
    return authRequest(`users/v1/create-subscription-checkout-session`, "POST", payload)
      .then((response) => {
        dispatch({
          type: Actions.USER_CREATE_SUBSCRIPTION,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function subscriptionPaymentSuccess() {
  return (dispatch) => {
    return authRequest(`users/v1/payment-success`, "POST")
      .then((response) => {
        dispatch({
          type: Actions.USER_CHECK_SUBSCRIPTION,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function subscriptionBillingPortal() {
  return (dispatch) => {
    return authRequest(`users/v1/billing-portal`, "POST")
      .then((response) => {
        dispatch({
          type: Actions.USER_CREATE_SUBSCRIPTION,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}
// export function checkIfActive() {
//     return (dispatch) => {
//         return authRequest(`users/checkSubscriptionStatus`, 'POST').then((response) => {
//             dispatch({
//                 type: Actions.USER_SUBSCRIPTION_SUCCESS,
//                 payload: response,
//             });
//             return response;
//         }).catch((error) => {
//             return error;
//         })
//     };
// }

export function cancelSubscription() {
  return (dispatch) => {
    return authRequest(`users/cancelSubscription`, "POST", {})
      .then((response) => {
        dispatch({
          type: Actions.USER_CANCEL_SUBSCRIPTION,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getUserById() {
  return async (dispatch) => {
    try {
      const response = await authRequest(`config/getUserInfo`, "GET");
      dispatch({
        type: Actions.USER_DETAILS,
        payload: response,
      });
      return response;
    } catch (error) {
      return error;
    }
  };
}

export function getUserMenu() {
  return (dispatch) => {
    return authRequest(`config/menu`, "GET")
      .then((response) => {
        dispatch({
          type: Actions.USER_MENU,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

// USER MANAGEMENT - AUTH0

export function updateUser(payload) {
  const user_metadata = {
    ...payload.user_metadata,
    on_boarding: true // Static value
  };

  return (dispatch) => {
    return authRequest(`config/update`, "PUT", user_metadata)
      .then((response) => {
        dispatch({
          type: Actions.UPDATE_USER,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}
