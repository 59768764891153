import axios from 'axios';
import { showNotification } from './notificationAction';
import { ERROR } from '../constants/notificationType';
import { PROD_BASE_URL } from 'components/Common/ServerURL';

export const authRequest = (url, method, payload, additionalHeaders = {}) => {
  const config = {
    url: `${PROD_BASE_URL}${url}`,
    method,
    data: payload,
    withCredentials: true, // Ensure credentials are included
    headers: {
      'Content-Type': 'application/json',
      ...additionalHeaders,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        let errorMessage = 'An unexpected error occurred';

        if (error.response) {

          const statusCode = error.response.status;

          // Customize error message based on status code
          switch (statusCode) {
            case 400:
              errorMessage = error.response.data.errorMessage || 'Bad Request';
              break;
            case 404:
              errorMessage = error.response.data.message || 'Resource not found';
              break;
            case 500:
              errorMessage = error.response.data.errorMessage || 'Internal Server Error';
              break;
            default:
              errorMessage = error.response.data.errorMessage || 'Something went wrong!';
          }
          
          showNotification({
            message: errorMessage,
            type: ERROR,
          });
          reject(new Error(errorMessage)); // Reject the promise with the specific error message
        }
      });
  });
};
