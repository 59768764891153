
const signatureForm = {
  signature: {
    sig_add_signature: {
      name: "sig_add_signature",
      label: "Add Signature",
      type: "text",
      placeholder: "Add signature",
      errorMsg: "Signature is required.",
      invalidMsg: "Invalid signature input.",
      path: "signature.sig_add_signature",
    },
    sig_fullname: {
      name: "sig_fullname",
      label: "Full Name",
      type: "text",
      placeholder: "Enter full name",
      errorMsg: "Full name is required.",
      invalidMsg: "Invalid full name input.",
      path: "signature.sig_fullname",
    },
    sig_position: {
      name: "sig_position",
      label: "Position",
      type: "text",
      placeholder: "Enter position",
      errorMsg: "Position is required.",
      invalidMsg: "Invalid position input.",
      path: "signature.sig_position",
    },
    sig_school_district_name: {
      name: "sig_school_district_name",
      label: "School District Name",
      type: "text",
      placeholder: "Enter school district name",
      errorMsg: "School district name is required.",
      invalidMsg: "Invalid school district name input.",
      path: "signature.sig_school_district_name",
    },
    sig_date: {
      name: "sig_date",
      label: "Date",
      type: "date",
      placeholder: "Select date",
      errorMsg: "Date is required.",
      invalidMsg: "Invalid date input.",
      path: "signature.sig_date",
    },
  }
};

export default signatureForm;
