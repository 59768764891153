import React, { useState, useCallback } from 'react';
import { DialogContent, DialogActions, Box } from '@mui/material';
import BoxButton from 'components/BoxButton';
import SMessageCard from 'components/SMessageCard';
import GeneralDialog from 'components/GeneralDialog';

const DeleteConfirm = ({ title, open, onClose, onConfirm, loading, text, subText, count }) => {
  return (
    <GeneralDialog
      open={open}
      title={title}
      fullScreen={false}
      fullwidth
      onClose={onClose}
    >
      <DialogContent dividers={true} style={{ background: "white" }}>
        <SMessageCard
          text={text || `Are you sure you want to delete ${count} selected student${count > 1 ? 's' : ''}?`}
          subText={subText || "Deleting these records will permanently remove all related student data. This action cannot be undone."}
        />
      </DialogContent>
      <DialogActions>
        <Box mr={2}>
          <BoxButton
            loading={loading}
            onClick={onConfirm}
            variant="contained"
            color="error"
            size="large"
            autoFocus
          >
            Delete
          </BoxButton>
        </Box>
      </DialogActions>
    </GeneralDialog>
  );
};

export default DeleteConfirm;
