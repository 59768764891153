import React, { useState } from "react";
import { Grid, Box, Container, TextField, Stack, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { generateImage } from "redux/actions/studentActions";
import BoxButton from "components/BoxButton";
import SCard from "components/SCard";
import { v1 as uuidv1 } from "uuid";
import { Typography } from "@mui/material";
import { List, ListItem, ListItemText, Checkbox } from "@mui/material";
import UpgradePlanDialog from "global/UpgradePlan";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import SMessageCard from "components/SMessageCard";
import Select from "react-select";
import { useNavigate } from "react-router";
import TaskList from "../../assets/icons/task-list.png";
import SkeletonListLoader from "components/SkeletonListLoader";

const categoryList = [
  { id: uuidv1(), label: "Articulation" },
  { id: uuidv1(), label: "Phonology" },
  { id: uuidv1(), label: "Hearing" },
  { id: uuidv1(), label: "Fluency" },
  { id: uuidv1(), label: "Functional Life Skills" },
  { id: uuidv1(), label: "Expressive Language" },
  { id: uuidv1(), label: "Receptive Language" },
  { id: uuidv1(), label: "Auditory Discrimination" },
  { id: uuidv1(), label: "Phonological Awareness" },
  { id: uuidv1(), label: "Social Skills / Pragmatics" },
  { id: uuidv1(), label: "Augmentative Alternative Communication (AAC)" },
  { id: uuidv1(), label: "Figurative Language" },
  { id: uuidv1(), label: "Written Language" },
  { id: uuidv1(), label: "Intelligibility" },
];

const type = [{ id: uuidv1(), label: "Adult" }, { id: uuidv1(), label: "Child" }, ,];

const gradeList = [
  { id: uuidv1(), label: "Pre School" },
  { id: uuidv1(), label: "TK" },
  { id: uuidv1(), label: "K" },
  { id: uuidv1(), label: "1st" },
  { id: uuidv1(), label: "2nd" },
  { id: uuidv1(), label: "3rd" },
  { id: uuidv1(), label: "4th" },
  { id: uuidv1(), label: "5th" },
  { id: uuidv1(), label: "6th" },
  { id: uuidv1(), label: "7th" },
  { id: uuidv1(), label: "8th" },
  { id: uuidv1(), label: "9th" },
  { id: uuidv1(), label: "10th" },
  { id: uuidv1(), label: "11th" },
  { id: uuidv1(), label: "12th" },
];

export default function AIGenerated() {
  const dispatch = useDispatch();
  const [promptValue, setPromptValue] = useState("");
  const [response, setResponse] = useState(null);
  const [aiLoading, setAiLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);

  const [typeValue, setTypeValue] = useState("");
  const [inputText, setInputText] = useState("");
  const [inputAge, setInputAge] = useState("");

  const [goals, setGoals] = useState([]);

  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    navigate("/dashboard");
  };

  const handleToggle = (value, label) => {
    const currentIndex = goals.findIndex((goal) => goal.name === value);
    const newGoals = [...goals];

    if (currentIndex === -1) {
      // If the goal is not in the list, add it.
      newGoals.push({ name: value, label: label });
    } else {
      // If the goal is already in the list, remove it.
      newGoals.splice(currentIndex, 1);
    }

    setGoals(newGoals);
  };

  function handleClearAll() {
    setGoals([]);
  }

  const maxWords = 50;

  const handleTextChange = (event) => {
    setInputText(event.target.value);
  };

  const handleAgeChange = (event) => {
    setInputAge(event.target.value);
  };
  const wordCount = inputText.trim() ? inputText.match(/\S+/g).length : 0;

  const handleGradeChange = (selectedOption) => {
    setSelectedGrade(selectedOption);
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const options = categoryList.map((category) => ({
    value: category.label, // Assuming you want to use the label as the value
    label: category.label,
  }));

  const gradeOptions = gradeList.map((category) => ({
    value: category.label, // Assuming you want to use the label as the value
    label: category.label,
  }));

  const generate = async () => {
    setAiLoading(true);
    setResponse("");

    let payload = {
      category: selectedCategory.label,
      grade: selectedGrade.label,
      age: inputAge,
      additionalPrompt: inputText,
    };

    let payloadString = JSON.stringify(payload);
    dispatch(generateImage(payloadString))
      .then((response) => {
        if (response.allowAccess === false) {
          setDialogOpen(true);
        }
        setResponse(response.data.content);
        setAiLoading(false);
      })
      .catch((err) => setAiLoading(false));
  };

  const questions = response && response.split("\n").filter((q) => q.trim() !== "");

  const Label = ({ label }) => {
    return (
      <Box mb={1} lineHeight={0} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography component="label" variant="body1" fontWeight="500">
            {label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" sx={{ marginTop: 2, marginBottom: 5 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={3}>
            <SCard title="">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Label label="Select Category" />
                  <Select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    options={options}
                    isClearable={true}
                    isSearchable={true}
                    menuPosition="fixed"
                    menuShouldBlockScroll={true}
                  />
                </Grid>

                <Grid item xs={8}>
                  <Label label="Select Grade" />
                  <Select
                    value={selectedGrade}
                    onChange={handleGradeChange}
                    options={gradeOptions}
                    isClearable={true}
                    isSearchable={true}
                    menuPosition="fixed"
                    menuShouldBlockScroll={true}
                  />
                  {/* <RadioGroup value={typeValue} onChange={handleTypeChange} row>
                    {type.map((category) => (
                      <FormControlLabel
                        key={category.id}
                        value={category.label}
                        control={<Radio />}
                        label={category.label}
                      />
                    ))}
                  </RadioGroup> */}
                </Grid>

                <Grid item xs={4}>
                  <Label label="Age" />
                  <TextField
                    fullWidth
                    type="number"
                    value={inputAge}
                    size="small"
                    onChange={handleAgeChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Label label="Additional Prompt" />
                  <TextField
                    multiline
                    fullWidth
                    value={inputText}
                    onChange={handleTextChange}
                    helperText={`${wordCount}/${maxWords} words`}
                    // Additional props like error handling can be based on the word count
                    error={wordCount > maxWords}
                    // Optionally, you can provide a more detailed error message
                    FormHelperTextProps={{
                      style: { textAlign: "right" }, // Right align the helper text
                      error: wordCount > maxWords, // Pass error state
                    }}
                  />
                </Grid>
              </Grid>
              {/* This Box will be pushed to the bottom */}
              <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <BoxButton
                  variant="contained"
                  size="medium"
                  onClick={generate}
                  loading={aiLoading}
                  startIcon={<AutoAwesomeIcon sx={{ color: "#ff9800" }} />}
                  // disabled={!(selectedCategory && typeValue)}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "#171d26",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: "#292727",
                    },
                  }}
                >
                  Generate
                </BoxButton>
              </Box>
            </SCard>
          </Grid>
          <Grid item xs={12} lg={9}>
            <SCard
              page="aiGoal"
              title={questions?.length > 0 && "AI Generated Goals"}
              renderActions={() => (
                <Stack direction="row" spacing={2}>
                  {!!goals?.length && (
                    <Button variant="outlined" color="primary" onClick={handleClearAll}>
                      Clear All
                    </Button>
                  )}
                  {!!goals?.length && (
                    <Button variant="contained" color="primary">
                      Assign to Student
                    </Button>
                  )}
                </Stack>
              )}
            >
              <Box mt={0}>
                {aiLoading && (
                  <>
                    <Box mb={2}>
                      <Typography variant="body1">
                        Generating goals for <strong>{selectedCategory?.label}</strong>... Please
                        wait.
                      </Typography>
                    </Box>

                    <SkeletonListLoader count={5} />
                  </>
                )}
                {questions === null && (
                                                          <Box height="70vh">

                  <SMessageCard
                    imgUrl={TaskList}
                    imgWidth="5rem"
                    text="AI Goal Builder"
                    subText="Select your preferences and then click Generate."
                  />
                  </Box>
                )}

                <List disablePadding>
                  {questions &&
                    questions.map((question, index) => {
                      const labelId = `checkbox-list-label-${index}`;

                      const formattedQuestion = question.replace(/\*\*(.*?)\*\*/g);

                      // Check if the question is a new row
                      const isNewRow = question.startsWith("By [Specific Date]");

                      return (
                        <ListItem
                          key={index}
                          disableGutters
                          style={{ display: "flex", alignItems: "center", marginBottom: "0.1rem" }}
                        >
                          <Checkbox
                            edge="start"
                            onChange={() => handleToggle(index, question)} // Pass index or a unique identifier
                            checked={goals?.findIndex((goal) => goal?.name === index) !== -1}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                          <ListItemText
                            variant="body1"
                            primary={
                              <div
                                dangerouslySetInnerHTML={{ __html: formattedQuestion }}
                                style={{ flex: 1, fontWeight: 400 }}
                              />
                            }
                          />
                          {isNewRow && <br />} {/* Add a line break for new row */}
                        </ListItem>
                      );
                    })}
                </List>
              </Box>
            </SCard>
          </Grid>
        </Grid>
      </Container>
      <UpgradePlanDialog open={dialogOpen} onClose={handleCloseDialog} />
    </React.Fragment>
  );
}
