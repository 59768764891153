import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    eventTitle,
    dateSlots,
    invitedList,
    description,
    duration,
    guestEmail,
    dateTimeSlots,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [eventTitle.name]: Yup.string().required(eventTitle.errorMsg),
    [dateSlots.name]: Yup.date(),
    [invitedList.name]: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string().required("email is required"),
        })
      )
      .required(invitedList.errorMsg),
    [dateTimeSlots.name]: Yup.array()
      .of(
        Yup.object().shape({
          start: Yup.date().required("start is required"),
          end: Yup.date().required("end is required"),
        })
      )
      .required(dateTimeSlots.errorMsg),
    [description.name]: Yup.string().required(dateSlots.errorMsg),
    [duration.name]: Yup.string(),
    [guestEmail.name]: Yup.string().email(guestEmail.invalidMsg),
  }),
];

export default validations;
