import checkout from "./form";

const {
  formField: {
    email,
    name,
    password,
  },
} = checkout;

const initialValues = {
  [email.name]: "",
  [name.name]: "",
  [password.name]: "",
};

export default initialValues;
