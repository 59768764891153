import React, { useState } from "react";
import { Grid, Typography, Divider, Radio, RadioGroup, Box, FormGroup } from "@mui/material";
import OptionSelector from "../components/OptionSelectior";
import FormField from "components/FormField";
import OptionButton from "../components/OptionButton";

const BehavioralObservation = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        behavioral_Q_01,
        behavioral_Q_02,
        behavioral_Q_03,
        behavioral_Q_04,
        behavioral_Q_05,
        behav_student_doc_description,
    } = formField.behavioralObservation;


    const replaceStudentName = (label) => {
        const studentName = values?.generalInformation?.firstName || '[student info not-found]';
        return label.replace('STUDENT', studentName);
    };
    return (
        <Grid container spacing={2} p={2}>
            <HeaderComp title="Engagement" />
            <OptionButton
                label={replaceStudentName(behavioral_Q_01.label)}
                type="radio"
                name={behavioral_Q_01.name}
                options={props.options?.behavioral_Q_01}
                value={values.behavioralObservation.behavioral_Q_01}
                onChange={(name, value) => setFieldValue(behavioral_Q_01.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={behavioral_Q_01.toolTipText}
                error={errors.behavioralObservation?.behavioral_Q_01 && touched.behavioralObservation?.behavioral_Q_01}
            />
            <HeaderComp title="Cooperation" />

            <OptionButton
                label={replaceStudentName(behavioral_Q_02.label)}
                type="radio"
                name={behavioral_Q_02.name}
                options={props.options?.behavioral_Q_02}
                value={values.behavioralObservation.behavioral_Q_02}
                onChange={(name, value) => setFieldValue(behavioral_Q_02.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={behavioral_Q_02.toolTipText}
                error={errors.behavioralObservation?.behavioral_Q_02 && touched.behavioralObservation?.behavioral_Q_02}
            />
            <HeaderComp title="Focus" />

            <OptionButton
                label={replaceStudentName(behavioral_Q_03.label)}
                type="radio"
                name={behavioral_Q_03.name}
                options={props.options?.behavioral_Q_03}
                value={values.behavioralObservation.behavioral_Q_03}
                onChange={(name, value) => setFieldValue(behavioral_Q_03.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={behavioral_Q_03.toolTipText}
                error={errors.behavioralObservation?.behavioral_Q_03 && touched.behavioralObservation?.behavioral_Q_03}
            />
            <HeaderComp title="Response to Instructions" />

            <OptionButton
                label={replaceStudentName(behavioral_Q_04.label)}
                type="radio"
                name={behavioral_Q_04.name}
                options={props.options?.behavioral_Q_04}
                value={values.behavioralObservation.behavioral_Q_04}
                onChange={(name, value) => setFieldValue(behavioral_Q_04.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={behavioral_Q_04.toolTipText}
                error={errors.behavioralObservation?.behavioral_Q_04 && touched.behavioralObservation?.behavioral_Q_04}
            />
            <HeaderComp title="Interaction with Examiner" />

            <OptionButton
                label={replaceStudentName(behavioral_Q_05.label)}
                type="radio"
                name={behavioral_Q_05.name}
                options={props.options?.behavioral_Q_05}
                value={values.behavioralObservation.behavioral_Q_05}
                onChange={(name, value) => setFieldValue(behavioral_Q_05.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={behavioral_Q_05.toolTipText}
                error={errors.behavioralObservation?.behavioral_Q_05 && touched.behavioralObservation?.behavioral_Q_05}
            />
        </Grid>
    );
};

const HeaderComp = ({ title }) => {
    return (
        <Grid item md={12} sm={12} xs={12}>
            <Divider textAlign="left">
                <Typography variant="overline" color="text.secondary" fontWeight={800} fontSize={14}>
                    {title}
                </Typography>
            </Divider>
        </Grid>
    );
};
export default BehavioralObservation;
