import React from "react";
import { Box, LinearProgress } from "@mui/material";

const SLoadingIndicator = ({ text = "Please wait, loading...", height = "80vh", ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: height,
      }}
      {...props}
    >
      <Box sx={{ width: "100%", textAlign: "center", marginBottom: 2 }}>{text}</Box>

      <Box sx={{ width: "50%" }}>
        <LinearProgress />
      </Box>
    </Box>
  );
};

export default SLoadingIndicator;
