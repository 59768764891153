import React from "react";
import { Paper, Container, Stack } from "@mui/material";

function ActionHeader({ children }) {
  return (
    <Paper sx={{ width: "100%", borderRadius: 0, borderTop: "none", background: '#fff' }} variant="outlined">
      <Container maxWidth="xl" sx={{ padding: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          {children}
        </Stack>
      </Container>
    </Paper>
  );
}

export default ActionHeader;
