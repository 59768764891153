import { OPEN_NOTIFICATION, CLOSE_NOTIFICATION } from "../constants/constants";

const initialState = {
  open: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_NOTIFICATION:
      return {
        ...state,
        open: true,
        ...action.payload,
      };
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
