import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import moment from 'moment';
import commonStyles from '../../commonStyles';

// SignatureBox component
const SignatureBox = ({ data }) => {
    const base64Image = data?.sig_add_signature?.split(',')[1];
    const formattedDate = data?.sig_date ? moment(data.sig_date).format('MM/DD/YYYY') : '';

    return (
        <View style={commonStyles.box} wrap={false}> {/* Ensure it starts on a new page */}
            <Text style={commonStyles.footerText}>This document is electronically signed.</Text>

            {base64Image && (  // Check if base64 image data exists
                <Image
                    src={`data:${data.sig_mime_type};base64,${base64Image}`} // Include MIME type and base64 data
                    style={commonStyles.signatureImage}
                />
            )}
            <Text style={commonStyles.signatureText}>Name: {data?.sig_fullname}</Text>
            <Text style={commonStyles.signatureText}>Title: Speech-Language Pathologist</Text>
            <Text style={commonStyles.signatureText}>School District: Lammersville Unified School District</Text>
            {formattedDate && <Text style={commonStyles.signatureText}>Date: {formattedDate}</Text>}
        </View>
    );
};

export default SignatureBox;
