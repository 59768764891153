import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import NoData from "../../assets/icons/no-results.png";

const SMessageCard = ({ icon, text, fontSize, subText, imgUrl, imgWidth = "3rem", ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
      {...props}
    >
      <Box mb={2}>
        <img
          src={imgUrl ? imgUrl : NoData}
          style={{ width: imgWidth, marginTop: "2rem", filter: "grayscale(100%) brightness(150%)" }}
        />
      </Box>
      <Box sx={{ width: "100%", textAlign: "center", marginBottom: 2 }}>
        <Typography variant="h4" fontSize={fontSize} lineHeight={2.0}>
          {text}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {subText}
        </Typography>
      </Box>
    </Box>
  );
};

export default SMessageCard;
