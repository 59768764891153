import React from "react";
import { Box, Paper, Typography, Dialog, DialogContent, Stack } from "@mui/material";
import { List, ListItemIcon, ListItemText, ListItem, Button, Radio, Slide } from "@mui/material";
import { Check } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={{ enter: 500, exit: 300 }} />;
});

const generate = {
  standard: [
    {
      primary: "Unlimited Caseload",
      secondary: "Import Caseload csv or excel included.",
    },
    {
      primary: "Unlimited Scheduler",
      secondary: "Sync with Google Calendar included",
    },
    {
      primary: "Unlimited Data Tracking",
    },
    {
      primary: "Scheduling Link",
      secondary: "Limited - 20 Scheduling Per month",
    },
    {
      primary: "Reports",
      secondary: "Limited - 3 Reports Per Month",
    },
    {
      primary: "Support",
      secondary: "Raise a ticket if facing issues. You will get repsonse with in 48hrs",
    },
  ],
  premium: [
    {
      primary: "Create Unlimited Evaluation Reports",
    },
    {
      primary: "Unlimited IEP Goal Builder by AI",
      secondary: "IEP Goals are generated as per the selected category just in seconds by AI.",
    },
    {
      primary: "Unlimited Scheduling Link",
      secondary:
        "Simplified solution to send group meeting invites to parents / teachers to booked timeslot.",
    },
    {
      primary: "Custom Document Header",
      secondary: "You can upload custom brand logo for each of your IEP Report Document",
    },
    {
      primary: "Support Feature",
      secondary: "Raise a ticket if facing issues. You will get repsonse with in 24hrs",
    },
    {
      primary: "Everything from Standard Plan",
    },
  ],
};

function RadioOption({ value, label, description, price, current, selectedValue, onChange }) {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Radio
          value={value}
          color="secondary"
          name="radio-buttons"
          slotProps={{ input: { "aria-label": value } }}
          checked={selectedValue === value}
          onChange={onChange}
          sx={{
            color: "white",
            "&:hover": {
              color: "white",
              borderColor: "#5163f3",
            },
            "&:checked": {
              color: "white",
              borderColor: "#5163f3",
            },
            "&:not(:checked)": {
              color: "white",
              borderColor: "grey",
            },
          }}
        />
        <Box>
          <Typography variant="h6" color="white" fontWeight="medium">
            {label}
            {/* {current && <Chip variant="outlined" size="small" label="current" sx={{ color: 'white' }} />} */}
          </Typography>
          <Typography variant="caption" color="grey" fontWeight="small">
            {description}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4" color="white" fontWeight="medium">
          ${price}
        </Typography>
        <Typography variant="caption" color="grey" fontWeight="small">
          Per Month
        </Typography>
      </Box>
    </>
  );
}

const UpgradePlanDialog = ({ open, onClose, stripeBilling }) => {
  const [selectedValue, setSelectedValue] = React.useState("premium");

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(5px)",
          backgroundColor: "#00000069",
        },
      }}
    >
      <DialogContent
        sx={{
          backgroundColor: "#0e1218",
        }}
      >
        <>
          <Paper
            variant="outlined"
            elevation={0}
            style={{
              width: 500,
              padding: 10,
              marginTop: 10,
              borderColor: selectedValue === "standard" ? "#5163f3" : "#232323",
              background: selectedValue === "standard" ? "rgb(81 99 243 / 15%)" : "none",
              cursor: "pointer",
            }}
            onClick={() => handleRadioChange("standard")}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0.5}>
              <RadioOption
                value="standard"
                label="Standard"
                description="Billed Monthly"
                price="12"
                selectedValue={selectedValue}
                onChange={() => handleRadioChange("standard")}
              />
            </Stack>
          </Paper>
          <Paper
            variant="outlined"
            elevation={0}
            style={{
              width: 500,
              padding: 10,
              marginTop: 10,
              background: selectedValue === "premium" ? "rgb(81 99 243 / 15%)" : "none",
              borderColor: selectedValue === "premium" ? "#5163f3" : "#232323",
              cursor: "pointer",
            }}
            onClick={() => handleRadioChange("premium")}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0.5}>
              <RadioOption
                value="premium"
                label="Premium"
                description="Billed Monthly"
                price="19"
                current={selectedValue === "premium"}
                selectedValue={selectedValue}
                onChange={() => handleRadioChange("premium")}
              />
            </Stack>
          </Paper>
        </>

        <Box>
          <List dense>
            <ListItem sx={{ py: 1, px: 2 }}>
              <ListItemText sx={{ color: "#fff" }}>
                <Typography variant="overline" color="grey" fontWeight="small">
                  Features
                </Typography>
              </ListItemText>
            </ListItem>

            <List dense>
              {generate[selectedValue].map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <Check style={{ color: "#5163f3" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2" color="white" fontWeight="medium" lineHeight={1}>
                        {item.primary}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption" color="grey" fontWeight="medium">
                        {item.secondary}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </List>
        </Box>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Box>
            <Button
              // component={Link}
              variant="outlined"
              size="medium"
              color="secondary"
              fullWidth
              onClick={onClose}
              sx={{
                color: "#808080",
                borderColor: "#232323",
                "&:hover": {
                  color: "grey",
                  borderColor: "lightGrey",
                },
                // boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, .1), 0px 1px 0px 0px rgba(255, 255, 255, .3) inset'
              }}
            >
              May be later
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={stripeBilling}
              fullWidth
              sx={{
                boxShadow:
                  "0px 2px 8px 0px rgba(0, 0, 0, .1), 0px 1px 0px 0px rgba(255, 255, 255, .3) inset",
              }}
            >
              Continue with Premium
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradePlanDialog;
