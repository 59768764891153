const behavioralObservationForm = {
  behavioralObservation: {
    behavioral_Q_01: {
      name: "behavioral_Q_01",
      label: "How engaged was STUDENT during the evaluation?",
      type: "text",
      toolTipText: "TODO",
      path: "behavioralObservation.behavioral_Q_01",
      placeholder: "Enter student engagement details",
      errorMsg: "Student engagement details are required.",
      invalidMsg: "Invalid input for student engagement.",
    },
    behavioral_Q_02: {
      name: "behavioral_Q_02",
      label: "How cooperative was STUDENT during the evaluation?",
      type: "text",
      toolTipText: "TODO",
      path: "behavioralObservation.behavioral_Q_02",
      placeholder: "Enter student cooperation details",
      errorMsg: "Student cooperation details are required.",
      invalidMsg: "Invalid input for student cooperation.",
    },
    behavioral_Q_03: {
      name: "behavioral_Q_03",
      label: "How well does STUDENT maintain focus during the evaluation?",
      type: "text",
      toolTipText: "TODO",
      path: "behavioralObservation.behavioral_Q_03",
      placeholder: "Enter student focus details",
      errorMsg: "Student focus details are required.",
      invalidMsg: "Invalid input for student focus.",
    },
    behavioral_Q_04: {
      name: "behavioral_Q_04",
      label: "How does STUDENT respond to instructions given during the evaluation?",
      type: "text",
      toolTipText: "TODO",
      path: "behavioralObservation.behavioral_Q_04",
      placeholder: "Enter response to instruction details",
      errorMsg: "Response to instruction details are required.",
      invalidMsg: "Invalid input for response to instruction.",
    },
    behavioral_Q_05: {
      name: "behavioral_Q_05",
      label: "How does STUDENT interact with the examiner?",
      type: "text",
      toolTipText: "TODO",
      path: "behavioralObservation.behavioral_Q_05",
      placeholder: "Enter student interaction details",
      errorMsg: "Student interaction details are required.",
      invalidMsg: "Invalid input for student interaction.",
    },
    behav_student_doc_description: {
      name: "behav_student_doc_description",
      label: "Additional Description",
      type: "text",
      placeholder: "Enter additional description",
      errorMsg: "Additional description is required.",
      invalidMsg: "Invalid input for additional description.",
    },
  },
};

export default behavioralObservationForm;
