const eligibilityForm = {
    eligibility: {
      eligibility_Q_01: {
        name: "eligibility_Q_01",
        label: "Eligibility Statement Per State",
        type: "text",
        path: "eligibility.eligibility_Q_01",
        placeholder: "Enter the eligibility statement per state",
        errorMsg: "Please fill in the eligibility statement per state.",
        invalidMsg: "Invalid eligibility statement."
      },
      eligibility_Q_02: {
        name: "eligibility_Q_02",
        label: "Student’s Eligibility",
        type: "multiSelect",
        path: "eligibility.eligibility_Q_02",
        placeholder: "Select the student's eligibility",
        errorMsg: "Please select the student's eligibility.",
        invalidMsg: "Invalid selection for the student's eligibility."
      },
      eligibility_Q_02_other: {
        name: "eligibility_Q_02_other",
        label: "Specify Other Eligibility",
        type: "text",
        path: "eligibility.eligibility_Q_02_other",
        placeholder: "Specify other eligibility",
        errorMsg: "Please specify other eligibility.",
        invalidMsg: "Invalid input for other eligibility.",
      },
      eligibility_Q_03: {
        name: "eligibility_Q_03",
        label: "Environmental or economic factors",
        type: "radio",
        path: "eligibility.eligibility_Q_03",
        options: [
          { id: 1, label: "Yes" },
          { id: 2, label: "No" }
        ],
        placeholder: "Select an option",
        errorMsg: "Please select an option.",
        invalidMsg: "Invalid selection."
      },
      eligibility_Q_04: {
        name: "eligibility_Q_04",
        label: "Cultural differences",
        type: "radio",
        path: "eligibility.eligibility_Q_04",
        options: [
          { id: 1, label: "Yes" },
          { id: 2, label: "No" }
        ],
        placeholder: "Select an option",
        errorMsg: "Please select an option.",
        invalidMsg: "Invalid selection."
      },
      eligibility_Q_05: {
        name: "eligibility_Q_05",
        label: "School attendance",
        type: "radio",
        path: "eligibility.eligibility_Q_05",
        options: [
          { id: 1, label: "Yes" },
          { id: 2, label: "No" }
        ],
        placeholder: "Select an option",
        errorMsg: "Please select an option.",
        invalidMsg: "Invalid selection."
      },
      eligibility_Q_06: {
        name: "eligibility_Q_06",
        label: "Health, developmental, and/or medical problem",
        type: "radio",
        path: "eligibility.eligibility_Q_06",
        options: [
          { id: 1, label: "Yes" },
          { id: 2, label: "No" }
        ],
        placeholder: "Select an option",
        errorMsg: "Please select an option.",
        invalidMsg: "Invalid selection."
      },
      eligibility_Q_07: {
        name: "eligibility_Q_07",
        label: "Does the assessment results, evaluation scores affect and impact student's educational performance and progress?",
        type: "radio",
        path: "eligibility.eligibility_Q_07",
        options: [
          { id: 1, label: "Yes" },
          { id: 2, label: "No" },
          { id: 3, label: "Not Applicable" }
        ],
        placeholder: "Select an option",
        errorMsg: "Please select an option.",
        invalidMsg: "Invalid selection."
      },
      eligibility_Q_07_doc_description: {
        name: "eligibility_Q_07_doc_description",
        label: "Other Eligibility Statement",
        type: "text",
        path: "eligibility.eligibility_Q_07_doc_description",
        placeholder: "Enter the other eligibility statement",
        errorMsg: "Please fill in the other eligibility statement.",
        invalidMsg: "Invalid eligibility statement."
      }
    }
  };
  
  export default eligibilityForm;