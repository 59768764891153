import { authRequest } from "./request";
import {
    ASSISTANT_CREATE,
    ASSISTANT_DELETE,
    ASSISTANT_PASSWORD,
    ASSISTANTS_LIST,
    ASSISTANT_UPDATE
} from "../constants/constants";
import { showNotification } from "./notificationAction";
import { SUCCESS, ERROR } from "../constants/notificationType";
import { fetchStudentById } from "./studentActions";

export function createAssistant(payload) {
    return async (dispatch) => {
        try {
            const response = await authRequest(`assistant/create-assistant`, "POST", payload);
            dispatch({
                type: ASSISTANT_CREATE,
                payload: response,
            });
            showNotification({
                message: response.message,
                type: SUCCESS,
            });
            dispatch(getAllAssistants());
            return response;
        } catch (error) {
            return error;
        }
    };
}


export function deleteAssistant(payload) {
    return async (dispatch) => {
        try {
            const response = await authRequest(`assistant/delete-assistant`, "DELETE", payload);
            dispatch({
                type: ASSISTANT_DELETE,
                payload: response,
            });
            showNotification({
                message: response.message,
                type: SUCCESS,
            });
            return response;
        } catch (error) {
            return error;
        }
    };
}


export function setAssistantPassword(payload) {
    return async (dispatch) => {
        try {
            const response = await authRequest(`assistant/set-assistant-password`, "POST", payload);
            dispatch({
                type: ASSISTANT_PASSWORD,
                payload: response,
            });
            return response;
        } catch (error) {
            return error;
        }
    };
}



export function getAllAssistants() {
    return async (dispatch) => {
        try {
            const response = await authRequest(`assistant/get-all-assistants`, "GET");
            dispatch({
                type: ASSISTANTS_LIST,
                payload: response,
            });
            return response;
        } catch (error) {
            return error;
        }
    };
}


export function updateAssistantsForStudent(payload) {
    return async (dispatch) => {
        try {
            const response = await authRequest(`student/update-assistants`, "POST", payload);
            dispatch({
                type: ASSISTANT_UPDATE,
                payload: response,
            });

            await dispatch(fetchStudentById(response?.response._id))
            return response;
        } catch (error) {
            return error;
        }
    };
}

