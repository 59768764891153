import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, Chip, Box, Typography } from "@mui/material";
import FormField from "components/FormField";
import moment from "moment";
import { v1 as uuidv1 } from "uuid";
import { showNotification } from "redux/actions/notificationAction";
import { ERROR } from "../../../../redux/constants/notificationType";
import SMessageCard from "components/SMessageCard";
import { GroupAdd, Today } from "@mui/icons-material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Dateslot from '../../../../assets/icons/add-event.png'
import GuestListImage from '../../../../assets/icons/add-group.png'


const InviteForm = ({ formData }) => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // const [visitorsList, setVisitorsList] = useState([]);
  // const [dateTimeSlotsList, setDateTimeSlotsList] = useState([]);
  const [isAddDateDisabled, setIsAddDateDisabled] = useState(true);

  const { formField, values, errors, touched, setFieldValue, setFieldTouched } = formData;

  const {
    eventTitle,
    dateSlots,
    // invitedList,
    description,
    // dateTimeSlots,
    duration,
    guestEmail,
  } = formField;

  const {
    eventTitle: eventTitleV,
    dateSlots: dateSlotsV,
    dateTimeSlots: dateTimeSlotsV,
    invitedList: invitedListV,
    description: descriptionV,
    duration: durationV,
    guestEmail: guestEmailV,
  } = values;

  const durationList = [
    { id: "15", name: "15 mins" },
    { id: "30", name: "30 mins" },
    { id: "45", name: "45 mins" },
    { id: "60", name: "1 hour" },
    { id: "120", name: "2 hours" },
  ];

  function handleDuration(event, newValue) {
    setFieldValue("duration", newValue);
  }

  const handleAddDateTime = () => {
    const newDateTime = values.dateSlots;
    const newDuration = values.duration;
    const checkIfExist = dateTimeSlotsV?.some((d) => d.start === newDateTime);

    if (checkIfExist) {
      showNotification({
        message: "Date already exists!",
        type: ERROR,
      });
    } else if (newDateTime && newDuration) {
      const startMoment = moment(newDateTime);
      const endMoment = startMoment.clone().add(Number(newDuration), "minutes");

      const newSlots = {
        id: uuidv1(),
        start: startMoment.format("YYYY-MM-DDTHH:mm"), // Format start time
        end: endMoment.format("YYYY-MM-DDTHH:mm"), // Format end time
      };

      // setDateTimeSlotsList([...dateTimeSlotsList, newSlots]);
      setFieldValue("dateTimeSlots", [...dateTimeSlotsV, newSlots]);

      setFieldValue("dateSlots", "");
      setFieldTouched("dateSlots", false);
      setFieldValue("duration", "");
      setFieldTouched("duration", false);
      setIsAddDateDisabled(true);
    }
  };

  const handleAddVisitor = () => {
    const newVisitorEmail = values.guestEmail;

    const visitorExists = invitedListV?.some((visitor) => visitor.email === newVisitorEmail);

    if (visitorExists) {
      showNotification({
        message: "Visitor with the same email already exists!",
        type: ERROR,
      });
    } else if (newVisitorEmail) {
      const newVisitor = {
        id: uuidv1(),
        email: newVisitorEmail,
      };

      // setVisitorsList([...visitorsList, newVisitor]);
      setFieldValue("invitedList", [...invitedListV, newVisitor]);

      setFieldValue("guestEmail", "");
      setFieldTouched("guestEmail", false);

      setIsButtonDisabled(true);
    }
  };

  const handleDeleteSlots = (id) => {
    const updatedSlots = dateTimeSlotsV?.filter((visitor) => visitor.id !== id);
    // setDateTimeSlotsList(updatedSlots);
    setFieldValue("dateTimeSlots", updatedSlots.length === 0 ? null : updatedSlots);
  };

  const handleDeleteVisitor = (id) => {
    const updatedVisitors = invitedListV?.filter((visitor) => visitor.id !== id);
    // setVisitorsList(updatedVisitors);
    setFieldValue("invitedList", updatedVisitors.length === 0 ? null : updatedVisitors);
  };

  useEffect(() => {
    setIsButtonDisabled(!(values.guestEmail && !errors.guestEmail));
  }, [values.guestEmail, errors.guestEmail]);

  useEffect(() => {
    setIsAddDateDisabled(
      !(values.dateSlots && !errors.dateSlots && values.duration && !errors.duration)
    );
  }, [values.dateSlots, errors.dateSlots, values.duration, errors.duration]);

  const formatDateTimeRange = (start, end) => {
    const startMoment = moment(start);
    const endMoment = moment(end);

    const formattedStart = startMoment.format("llll");
    const formattedEnd = endMoment.format("LT");

    return `${formattedStart} to ${formattedEnd}`;
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <FormField
            type={eventTitle.type}
            //   label={eventTitle.label}
            name={eventTitle.name}
            variant={"standard"}
            size="small"
            //   isRequired={true}
            InputProps={{
              style: { fontSize: 30, padding: 10 },
            }}
            value={eventTitleV}
            placeholder={eventTitle.placeholder}
            error={errors.eventTitle && touched.eventTitle}
          />
        </Grid>

        <Grid item xs={12} lg={5}>
          <FormField
            type={dateSlots.type}
            label={dateSlots.label}
            name={dateSlots.name}
            size="small"
            isRequired={true}
            InputProps={{
              inputProps: {
                min: moment().format("YYYY-MM-DDTHH:mm"),
                step: 900,
              },
            }}
            value={dateSlotsV}
            placeholder={dateSlots.placeholder}
            error={errors.dateSlots && touched.dateSlots}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <Box mb={1} lineHeight={0}>
            <Typography component="label" fontWeight="500">
              {duration.label}
            </Typography>
          </Box>
          <ToggleButtonGroup
            value={durationV}
            name={duration.name}
            exclusive
            onChange={handleDuration}
            size="small"
            aria-label="duration"
          >
            {durationList.map((duration) => (
              <ToggleButton
                key={duration.id}
                value={duration.id}
                style={{
                  backgroundColor: durationV === duration.id ? "black" : "white",
                  color: durationV === duration.id ? "white" : "grey",
                }}
              >
                {duration.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={12} lg={2}>
          <Button
            size="medium"
            variant="contained"
            sx={{ marginTop: 3.5, height: 38, width: "7rem", borderRadius: 10 }}
            onClick={handleAddDateTime}
            disabled={isAddDateDisabled}
          >
            ADD
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          {dateTimeSlotsV?.length === 0 && (
            <SMessageCard
            imgUrl={Dateslot}
            text="No date slots added"
              subText="You can add a maximum of 5 date and time slots."
            />
          )}
          {dateTimeSlotsV?.map((visitor) => (
            <Chip
              key={visitor.id}
              label={`${formatDateTimeRange(visitor.start, visitor.end)}`}
              onDelete={() => handleDeleteSlots(visitor.id)}
              variant="filled"
              // color="success"
              style={{
                marginRight: "4px",
                fontSize: 14,
                borderRadius: "5px",
                marginBottom: "8px",
              }}
            />
          ))}
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <FormField
            type={guestEmail.type}
            label={guestEmail.label}
            name={guestEmail.name}
            req="true"
            size="small"
            value={guestEmailV}
            placeholder={guestEmail.placeholder}
            error={errors.guestEmail && touched.guestEmail}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Button
            size="medium"
            variant="contained"
            sx={{ marginTop: 3.5, height: 38, width: "7rem", borderRadius: 10 }}
            onClick={handleAddVisitor}
            disabled={isButtonDisabled}
          >
            ADD
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} lg={12}>
          {invitedListV?.length === 0 && (
            <SMessageCard
              imgUrl={GuestListImage}
              text="No guests have been added"
              subText="Add multiple guest emails by clicking 'ADD'"
            />
          )}
          {invitedListV?.map((visitor) => (
            <Chip
              key={visitor.id}
              label={`${visitor.email}`}
              onDelete={() => handleDeleteVisitor(visitor.id)}
              variant="filled"
              color="primary"
              style={{
                marginRight: "4px",
                fontSize: 14,
                borderRadius: "5px",
                marginBottom: "8px",
              }}
            />
          ))}
        </Grid>
        <Grid item xs={12} lg={12}>
          <FormField
            type={description.type}
            label={description.label}
            name={description.name}
            size="small"
            isRequired={true}
            value={descriptionV}
            multiline
            rows={4}
            placeholder={description.placeholder}
            error={errors.description && touched.description}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default InviteForm;
