import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip, Button, Menu, TextField, Typography, Box, CircularProgress } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const isMac = /Mac|iPad|iPhone|iPod/.test(navigator.userAgent);

const FeedbackButton = ({ feature }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        if (open) {
            handleClose();
        } else {
            setAnchorEl(event.currentTarget);
            setFeedback(''); // Clear feedback content when opening the menu
            setSubmitted(false); // Reset submitted state when opening the menu
            setError(''); // Reset error state when opening the menu
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSubmit = () => {
        if (!feedback.trim()) {
            setError('Please provide some feedback before submitting.');
            return;
        }

        setLoading(true);
        // Use the `feature` here when sending feedback to your API
        setTimeout(() => {
            setLoading(false);
            setSubmitted(true);
            setTimeout(() => {
                setSubmitted(false);
                handleClose();
                console.log('Feedback submitted:', { feedback, feature }); // Include feature in feedback
            }, 2000); // Close menu after 2 seconds
        }, 3000); // Simulate network request for 3 seconds
    };

    const handleKeyPress = (event) => {
        if ((isMac && event.metaKey && event.key === 'Enter') || (!isMac && event.ctrlKey && event.key === 'Enter')) {
            handleSubmit();
        }
    };

    const handleChange = (event) => {
        setFeedback(event.target.value);
        if (error) {
            setError(''); // Clear error message when user starts typing
        }
    };

    return (
        <>
            <Tooltip title="Share Feedback">
                <Button
                    onClick={handleClick}
                    startIcon={<HelpOutlineIcon />}
                    // sx={{ backgroundColor: '#171d26', color: '#fff', '&:hover': { backgroundColor: '#484c54' } }}
                >
                    Report Issue
                </Button>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                elevation={1}
                id="feedback-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={{
                    '& .MuiMenu-list': {
                        backgroundColor: '#171d26',
                    },
                    '& .MuiPaper-root': {
                        borderRadius: 1,
                        marginTop: 1.5,
                        width: '400px',
                    },
                }}
            >
                <Box p={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Help us improve this page."
                        placeholder="Help us improve this page"
                        multiline
                        rows={4}
                        value={feedback}
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#2a2f38',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#7f7f7f',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#7f7f7f',
                                },
                            },
                        }}
                    />
                    {error && (
                        <Typography variant="body2" color="error" mt={2} align="center">
                            {error}
                        </Typography>
                    )}
                    {!loading && !error && (
                        <Typography variant="body2" color="white" mt={2} align="center">
                            {isMac ? 'Press ⌘ + Enter to submit' : 'Press Ctrl + Enter to submit'}
                        </Typography>
                    )}
                    {loading && (
                        <Box display="flex" justifyContent="center" mt={2}>
                            <CircularProgress sx={{ color: '#fff' }} />
                        </Box>
                    )}
                    {submitted && !loading && (
                        <Typography variant="body2" color="#a5ff9f" mt={2} align="center">
                            Your request has been submitted.
                        </Typography>
                    )}
                </Box>
            </Menu>
        </>
    );
};

FeedbackButton.propTypes = {
    feature: PropTypes.string.isRequired, // Update prop type
};

export default FeedbackButton;
