import checkout from "./form";

const {
  formField: {
    assessmentName,
    caseManager,
    assessmentDate,
    assessmentTemplate,
    startTime,
    endTime,
    repeats,
    occurrences,
  },
} = checkout;

const initialValues = {
  [assessmentName.name]: "",
  [caseManager.name]: "",
  [assessmentDate.name]: "",
  [startTime.name]: "",
  [endTime.name]: "",
  [assessmentTemplate.name]: null,
  [repeats.name]: null,
  [occurrences.name]: null,
};

export default initialValues;
