const oralPeripheralMechForm = {
  oralPeripheralMechanism: {
    opme_lips: {
      name: "opme_lips",
      label: "Coordination and range of motion during speech and non-speech tasks",
      type: "text",
      toolTipText: 'TODO',
      path: "oralPeripheralMechanism.opme_lips",
      placeholder: "Enter lips details",
      errorMsg: "Lips details are required.",
      invalidMsg: "Invalid input for lips details.",
    },
    opme_tongue: {
      name: "opme_tongue",
      label: "Coordination and range of motion during speech and non-speech tasks",
      type: "text",
      toolTipText: 'TODO',
      path: "oralPeripheralMechanism.opme_tongue",
      placeholder: "Enter tongue details",
      errorMsg: "Tongue details are required.",
      invalidMsg: "Invalid input for tongue details.",
    },
    opme_jaw: {
      name: "opme_jaw",
      label: "Strength, isolation, coordination, and range of motion",
      type: "text",
      toolTipText: 'TODO',
      path: "oralPeripheralMechanism.opme_jaw",
      placeholder: "Enter jaw details",
      errorMsg: "Jaw details are required.",
      invalidMsg: "Invalid input for jaw details.",
    },
    opme_teeth: {
      name: "opme_teeth",
      label: "Teeth",
      type: "text",
      toolTipText: 'TODO',
      path: "oralPeripheralMechanism.opme_teeth",
      placeholder: "Enter teeth details",
      errorMsg: "Teeth details are required.",
      invalidMsg: "Invalid input for teeth details.",
    },
    opme_additional_info: {
      name: "opme_additional_info",
      label: "Any other additional information",
      type: "text",
      path: "oralPeripheralMechanism.opme_additional_info",
      placeholder: "Additional Information",
      errorMsg: "Additional information is required.",
      invalidMsg: "Invalid input for additional information.",
    },
    opme_doc_description: {
      name: "opme_doc_description",
      label: "Document Description",
      type: "text",
      path: "oralPeripheralMechanism.opme_doc_description",
      placeholder: "Enter additional description",
      errorMsg: "Additional description is required.",
      invalidMsg: "Invalid input for additional description.",
    },
  },
};

export default oralPeripheralMechForm;
