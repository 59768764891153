import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch } from "react-redux";
import { verifyUserEmail } from "redux/actions/authAction";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import AuthBox from "../../../components/Box/AuthBox";
import SMessageCard from "components/SMessageCard";
import { Security } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import Verification from "../../../assets/icons/verification.png";

const FullWidthButton = styled(LoadingButton)({
  width: "100%",
});

const VerifyEmailPage = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { token } = useParams();
  const handleVerifyEmail = () => {
    setLoading(true);
    dispatch(verifyUserEmail(token)).then((response) => {
      if (response && !response.success) setLoading(false);
      if (response.success) {
        setLoading(false);
        navigate("/login");
      }
    });
  };

  return (
    <AuthBox showSS0={false}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Typography variant="h1" sx={{ marginY: 2 }}>
            Verification
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <SMessageCard
            imgUrl={Verification}
            imgWidth="5rem"
            fontSize={16}
            height="22vh"
            subText={"To verify your email address. Please click on Verify."}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box mt={2}>
            <FullWidthButton
              size="large"
              loading={loading}
              onClick={() => handleVerifyEmail(token)}
              variant="contained"
              disabled={loading}
              sx={{
                background: "#6565ec",
                color: "white",
                "&:hover": {
                  background: "#5d5dd0",
                  borderColor: "#6565ec",
                },
              }}
            >
              Verify
            </FullWidthButton>
          </Box>
        </Grid>
      </Grid>
    </AuthBox>
  );
};

export default VerifyEmailPage;
