import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Paper, Box, Stack, Divider, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LockResetIcon from "@mui/icons-material/LockReset";
import EmailIcon from "@mui/icons-material/Email";
import { blue, grey } from "@mui/material/colors";
import { getAllAssistants, deleteAssistant } from "redux/actions/assistantAction";
import HeaderComp from "components/Common/HeaderComp";
const CollaborationList = ({ data, onDelete, onResetPassword, onSendVerification }) => {
    return (
      <div>
        <Grid container spacing={2}>
        <HeaderComp title="Current Assistants" />
        </Grid>
        {data && data?.map((item) => (
          <Paper
            key={item._id}
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderLeft: `2px solid #5263f4`,
              padding: "10px 12px",
              background: "#faf9f9",
              marginBottom: 1,
              position: "relative",
              cursor: "pointer",
              borderRadius: "2px",
            //   backgroundColor: "#faf9f9",
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.01)",
                // boxShadow: "0px 0px 10px 0px rgb(13 183 242 / 9%)",
                cursor: "pointer",
                // borderColor: (theme) => theme.palette.primary["main"],
              },
            }}
          >
            <Grid container spacing={2} alignItems="center">
           
              <Grid item xs={9} container alignItems="center">
                <Typography
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  width={150}
                  fontWeight={500}
                  variant="body1"
                  textTransform={"capitalize"}
                >
                  {item.name}
                </Typography>
                <Divider orientation="vertical" flexItem sx={{ height: '24px', marginLeft: 2, marginRight: 2 }} />
                <Typography
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  width={200}

                  whiteSpace={"nowrap"}
                  variant="body2"
                  color="text.secondary"
                >
                  {item.email}
                </Typography>
                <Divider orientation="vertical" flexItem sx={{ height: '24px', marginLeft: 2, marginRight: 2 }} />
                <Typography
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  variant="body2"
                  color="text.secondary"
                >
                  {item.assigned_role}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Stack spacing={1} direction={"row"}>
                  <IconButton 
                    sx={{
                        boxShadow: 0,
                        mr: 1,
                        color: grey[800],
                        borderColor: grey[200],
                        background: grey[200],
                        '&:hover': {
                            background: '#eeeffe',
                            color: '#5263f3',
                        }
                    }}
                    color="error" onClick={() => onDelete(item.assistantId)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    sx={{
                        boxShadow: 0,
                        mr: 1,
                        color: grey[800],
                        borderColor: grey[200],
                        background: grey[200],
                        '&:hover': {
                            background: '#eeeffe',
                            color: '#5263f3',
                        }
                    }}
                     onClick={() => onResetPassword(item.assistantId)}>
                    <LockResetIcon />
                  </IconButton>
                  <IconButton 
                    sx={{
                        boxShadow: 0,
                        mr: 1,
                        color: grey[800],
                        borderColor: grey[200],
                        background: grey[200],
                        '&:hover': {
                            background: '#eeeffe',
                            color: '#5263f3',
                        }
                    }}
                    onClick={() => onSendVerification(item.assistantId)}>
                    <EmailIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </div>
    );
  };

export default function ListOfCollaboration() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { assistants } = useSelector((state) => ({
    assistants: state.inviteAssistantReducer.assistants_list,
  }));

  console.log('assistants', assistants)

  useEffect(() => {
    const fetchAssistants = async () => {
      setLoading(true);
      const response = await dispatch(getAllAssistants());
      console.log('response?.data', response.response)
      setLoading(false);
    };

    fetchAssistants();
  }, [dispatch]);

  const handleDelete = async (assistantId) => {
    console.log(assistantId);
    await dispatch(deleteAssistant({ assistantId })); // Pass the assistantId in the body
  };

  const handleResetPassword = async (assistantId) => {
    // await dispatch(resetAssistantPassword(assistantId));
    // Additional handling if needed
  };

  const handleSendVerification = async (assistantId) => {
    // await dispatch(sendVerificationEmail(assistantId));
    // Additional handling if needed
  };

  return (
    <React.Fragment>
      {!loading && (
        <CollaborationList 
          data={assistants?.response && assistants?.response} 
          onDelete={handleDelete} 
          onResetPassword={handleResetPassword} 
          onSendVerification={handleSendVerification} 
        />
      )}
    </React.Fragment>
  );
}
