const reportSummaryForm = {
  reportSummary: {
    report_summary_Q_01: {
      name: "report_summary_Q_01",
      label: "How would you summarize the student's language skills?",
      type: "select",
      path: "reportSummary.report_summary_Q_01",
 
      placeholder: "Select the level of difficulty",
      errorMsg: "Please select a summary for the student's language skills.",
      invalidMsg: "Invalid selection for the student's language skills.",
    },
    report_summary_Q_01_other: {
      name: "report_summary_Q_01_other",
      label: "Specify",
      type: "text",
      path: "reportSummary.report_summary_Q_01_other",
      placeholder: "Please specify",
      errorMsg: "Please provide details for the selected difficulty.",
      invalidMsg: "Invalid details provided.",
    },
    report_summary_Q_02: {
      name: "report_summary_Q_02",
      label: "How would you summarize the student's pragmatic language skills (social communication)?",
      type: "select",
      path: "reportSummary.report_summary_Q_02",
   
      placeholder: "Select the level of difficulty",
      errorMsg: "Please select a summary for the student's pragmatic language skills.",
      invalidMsg: "Invalid selection for the student's pragmatic language skills.",
    },
    report_summary_Q_02_other: {
      name: "report_summary_Q_02_other",
      label: "Specify",
      type: "text",
      path: "reportSummary.report_summary_Q_02_other",
      placeholder: "Please specify",
      errorMsg: "Please provide details for the selected difficulty.",
      invalidMsg: "Invalid details provided.",
    },
    report_summary_Q_03: {
      name: "report_summary_Q_03",
      label: "How would you summarize the student's articulation skills?",
      type: "select",
      path: "reportSummary.report_summary_Q_03",
  
      placeholder: "Select the level of difficulty",
      errorMsg: "Please select a summary for the student's articulation skills.",
      invalidMsg: "Invalid selection for the student's articulation skills.",
    },
    report_summary_Q_03_other: {
      name: "report_summary_Q_03_other",
      label: "Specify",
      type: "text",
      path: "reportSummary.report_summary_Q_03_other",
      placeholder: "Please specify",
      errorMsg: "Please provide details for the selected difficulty.",
      invalidMsg: "Invalid details provided.",
    },
    report_summary_Q_04: {
      name: "report_summary_Q_04",
      label: "How would you summarize the student's voice?",
      type: "select",
      path: "reportSummary.report_summary_Q_04",
    
      placeholder: "Select the level of difficulty",
      errorMsg: "Please select a summary for the student's voice.",
      invalidMsg: "Invalid selection for the student's voice.",
    },
    report_summary_Q_04_other: {
      name: "report_summary_Q_04_other",
      label: "Specify",
      type: "text",
      path: "reportSummary.report_summary_Q_04_other",
      placeholder: "Please specify",
      errorMsg: "Please provide details for the selected difficulty.",
      invalidMsg: "Invalid details provided.",
    },
    report_summary_Q_05: {
      name: "report_summary_Q_05",
      label: "How would you summarize the student's fluency?",
      type: "select",
      path: "reportSummary.report_summary_Q_05",
     
      placeholder: "Select the level of difficulty",
      errorMsg: "Please select a summary for the student's fluency.",
      invalidMsg: "Invalid selection for the student's fluency.",
    },
    report_summary_Q_06: {
      name: "report_summary_Q_06",
      label: "Additional Information",
      type: "text",
      path: "reportSummary.report_summary_Q_06",
      placeholder: "Provide any additional information",
      errorMsg: "Please provide additional information.",
      invalidMsg: "Invalid additional information provided.",
    },
    report_summary_doc_description: {
      name: "report_summary_doc_description",
      path: "reportSummary.report_summary_doc_description"
    }
  }
};

export default reportSummaryForm;
