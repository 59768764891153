import { useMemo } from "react";
import { Box, Skeleton } from "@mui/material";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import moment from "moment-timezone";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { min } from "lodash";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function ChartData(props) {
  const options = {
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        from:1,
        to: 0,
        loop: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: true,
      mode: "index",
    },
    scales: {
      y: {
        beginAtZero: false,
        min: 0, // Sets the minimum value of the y-axis
        max: 100, // Sets the maximum value of the y-axis  // Ensures that the y-axis starts at 0
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
        ticks: {
          display: true,
          stepSize: 20, // Sets the interval between tick marks
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          display: true,
        },
      },
    
    },
  };

  const chartData = useMemo(() => {
    if (!props.data) return null;

    return {
      labels: props.data.map((item) => moment(item.date).format("MMM Do")),
      datasets: [
        {
          label: "Session Score",
          data: props.data.map((item) => item.average),
          fill: false,
          borderColor: "#5163f3",
        },
      ],
    };
  }, [props.data]);

  return (
    <Box sx={{ height: "20.25rem" }}>
      {chartData && <Line data={chartData} options={options} />}
    </Box>
  );
}

ChartData.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      average: PropTypes.number.isRequired,
    })
  ),
};

export default ChartData;
