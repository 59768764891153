import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../../assets/slpbox-logo-full-black.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Button, Typography, Card, CardContent, Tooltip } from "@mui/material";
import {
  DashboardOutlined,
  RocketLaunch,
  GroupOutlined,
  InsertInvitationOutlined,
  ScheduleSendOutlined,
  SummarizeOutlined,
} from "@mui/icons-material";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import InsightsIcon from "@mui/icons-material/Insights";
import { subscriptionBillingPortal } from "redux/actions/subscriptionAction";
import { getUserMenu } from "redux/actions/subscriptionAction";
// const { School } = icons;
import { useAuth0 } from '@auth0/auth0-react';
import { grey, blue, green, amber } from '@mui/material/colors';

const item = {
  py: "4px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.2,
  px: 3,
};

const getIconComponent = (iconName, iconProps = {}) => {
  switch (iconName) {
    case "DonutLargeIcon":
      return <DashboardOutlined {...iconProps} />;
    case "Person2Outlined":
      return <GroupOutlined {...iconProps} />;
    case "CalendarTodayRounded":
      return <InsertInvitationOutlined {...iconProps} />;
    case "InsightsIcon":
      return <InsightsIcon {...iconProps} />;
    case "QueryStatsOutlined":
      return <SummarizeOutlined {...iconProps} />;
    case "LinkOutlined":
      return <ScheduleSendOutlined {...iconProps} />;
    case "AutoAwesomeIcon":
      return <AutoAwesomeIcon {...iconProps} />;
    default:
      return null; // Return null if the icon name doesn't match any known icons
  }
};

export default function SideMenu(props) {
  const { ...other } = props;

  const location = useLocation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(location.pathname ? location.pathname : "/dashboard");

  const listItemStyle = {
    mx: "auto", // Horizontal centering
    my: "auto", // Vertical centering
    // other styles...
  };

  const [loading, setLoading] = useState(false);

  const handleBillingPortal = async () => {
    setLoading(true); // Set loading state to true

    dispatch(subscriptionBillingPortal()).then((response) => {
      const checkoutSessionId = response.session.url;
      window.location.href = checkoutSessionId;
    }).catch((e) => {
      setLoading(false); // Set loading state to true

    })

  };




  const userMenu = useSelector((state) => state.subscriptionReducer.userMenu);

  const memoizedUserMenu = useMemo(() => userMenu, [userMenu]);

  useEffect(() => {
    dispatch(getUserMenu());
  }, []);

  const { user } = useAuth0();

  const planType = user?.plan_type || '';


  const renderFeatureCards = () => {
    switch (true) {
      case planType.startsWith('basic'):
        return (
          <>
            <FeatureCard
              icon={<WorkspacePremiumIcon sx={{ color: "#bdbdbd" }} />}
              title="Basic"
              description="Upgrade to unlock AI features and more!"
              buttonText="Upgrade"
              buttonAction={handleBillingPortal}
              planType="basic"
              loading={loading}
            />
          </>
        );
      case planType.startsWith('standard'):
        return (
          <>
            <FeatureCard
              icon={<WorkspacePremiumIcon sx={{ color: "#46c686" }} />}
              title="Standard"
              description="Upgrade to unlock all features"
              buttonText="Upgrade"
              buttonAction={handleBillingPortal}
              planType='standard'
              loading={loading}

            />
          </>
        );
      case planType.startsWith('premium'):
        return (
          <>
            <FeatureCard
              icon={<WorkspacePremiumIcon sx={{ color: "#c6a146" }} />}
              title="Premium"
              description="Help us improve"
              buttonText="Submit Feedback"
              planType='premium'
              loading={loading}

            />
          </>
        );
      default:
        return null
    }
  };


  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory }}>
          <Box
            sx={listItemStyle}
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <img src={Logo} alt="brandlogo" style={{ width: "8rem" }} />
          </Box>
        </ListItem>

        {memoizedUserMenu.length > 0 &&
          memoizedUserMenu.map(({ id, children }) => (
            <Box key={id} sx={{ bgcolor: "#171d26" }}>
              <ListItem sx={{ py: 1, px: 2 }}>
                <ListItemText sx={{ color: "#fff" }}>
                  <Typography variant="overline" color="grey" fontWeight="small">
                    {id}
                  </Typography>
                </ListItemText>
              </ListItem>
              {children.map(({ id: childId, icon, to }) => (
                <Link to={to} key={to} style={{ textDecoration: "none", color: "inherit" }}>
                  <ListItem disablePadding key={childId}>
                    <ListItemButton
                      selected={selected === to}
                      sx={{
                        ...item,
                        borderTopRightRadius: "5rem",
                        borderBottomRightRadius: "5rem",
                      }}
                      onClick={() => setSelected(to)}
                    >
                      <ListItemIcon sx={{ color: selected === to ? "#7a87f3" : "grey" }}>
                        {getIconComponent(icon)}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle1" fontWeight="medium">
                          {childId}
                        </Typography>
                      </ListItemText>
                      {(planType.startsWith('basic') && ['Scheduler', 'Goal Builder', 'Evaluation Report'].includes(childId)) ||
                      (planType.startsWith('standard') && childId === 'Evaluation Report') ? (
                        <Tooltip title="Upgrade Plan" placement="top">
                          <WorkspacePremiumIcon sx={{ marginLeft: 'auto', color: '#8686867d' }} />
                        </Tooltip>
                      ) : null}
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </Box>
          ))}
      </List>
      <List style={{ marginTop: "auto" }}>
        {renderFeatureCards()}
      </List>
    </Drawer>
  );
}

function FeatureCard({ icon, title, description, buttonText, buttonAction, planType, loading }) {

  return (
    <Box pt={2} my={2} mx={2}>
      <Card elevation={0}>
        <CardContent sx={{ background: "#0e1218" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center", // Center vertically
              width: "fit-content",
              p: 0, // Adjust padding
              borderRadius: "md",
              boxShadow: "md",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 28, // Fixed size for the icon container
                height: 48,
                borderRadius: "50%",
                bgcolor: "transparent", // No background color for the icon container
                mr: 2, // Margin right for spacing
              }}
            >
              {icon}
            </Box>
            <Box>
              <Typography variant="overline" color={grey[600]} fontWeight="normal" fontSize={10}>
                Current Plan
              </Typography>
              <Typography variant="h4" color={grey[400]} fontWeight="bold" textTransform="uppercase" fontSize={14}>
                {title}
              </Typography>
              {description && (
                <Typography variant="caption" color={grey[500]} fontWeight="small">
                  {description}
                </Typography>
              )}
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={buttonAction}
              fullWidth
              sx={{
                background: planType === 'premium' ? '#c6a147' : planType === 'standard' ? '#46c686' : '',
                textTransform: "uppercase",
                boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, .1), 0px 1px 0px 0px rgba(255, 255, 255, .3) inset",
              }}
            >
              {loading ? 'Please Wait ...' : buttonText}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}