import React, { useState, useCallback } from "react";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import ClearIcon from '@mui/icons-material/Clear'; // Import Clear icon from MUI icons

const NormativeChart = (props) => {
    const { formField, values, errors, touched, setFieldValue } = props.formData;

    const {
        normative_chart_uploaded
    } = formField.normative_chart;

    const [uploadedImage, setUploadedImage] = useState(values.normative_chart ? values.normative_chart.normative_chart_uploaded : null);
    const [errorMessage, setErrorMessage] = useState('');

    // Function to handle file drop
    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64Image = reader.result;
            setUploadedImage(base64Image);
            setFieldValue('normative_chart.normative_chart_uploaded', base64Image);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
        setErrorMessage('');
    }, [setFieldValue]);

    // Function to handle file drop rejection
    const onDropRejected = useCallback((rejectedFiles) => {
        const file = rejectedFiles[0];
        if (file) {
            if (file.errors[0].code === 'file-too-large') {
                setErrorMessage('File is too large. Maximum size is 5MB.');
            } else if (file.errors[0].code === 'file-invalid-type') {
                setErrorMessage('Invalid file type. Only JPG, PNG, and JPEG formats are allowed.');
            }
        }
    }, []);

    // Initialize dropzone
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropRejected,
        accept: 'image/jpeg, image/png, image/jpg',
        multiple: false,
        maxSize: 5000000 // Limit file size to 5MB (adjust as needed)
    });

    // Function to handle removing image
    const handleRemoveImage = (event) => {
        event.stopPropagation(); // Prevent the event from propagating to the dropzone
        setUploadedImage(null);
        setFieldValue('normative_chart.normative_chart_uploaded', null);
    };

    return (
        <Grid container spacing={2} p={2}>
            <Grid item xs={12} sm={12} lg={12}>
                <Typography variant="h6" gutterBottom>
                    Upload Normative Chart
                </Typography>
                <Box
                    sx={{
                        border: '2px dashed lightGrey',
                        borderRadius: '4px',
                        width: '100%', // Fixed width
                        height: '300px', // Fixed height
                        padding: '16px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        position: 'relative',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    {/* Display uploaded image or default message */}
                    {uploadedImage ? (
                        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                            <img
                                src={uploadedImage}
                                alt="Normative Chart"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }}
                            />
                            <IconButton
                                onClick={handleRemoveImage}
                                sx={{
                                    position: 'absolute',
                                    top: '8px',
                                    right: '8px',
                                    color: 'white',
                                    backgroundColor: 'rgba(0,0,0,0.5)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0,0,0,0.7)'
                                    }
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="body1" color="textSecondary">
                                Click or drag and drop an image here
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                Only JPG, PNG, or JPEG formats are allowed. Maximum file size is 5MB.
                            </Typography>
                            {errorMessage && (
                                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                    {errorMessage}
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default NormativeChart;
