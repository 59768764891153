import React, { useEffect, useState } from "react";
import {
  Grid,
  Chip,
  Avatar,
  Button,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SCard from "components/SCard";
import HeaderComp from "components/Common/HeaderComp";
import { getAllAssistants, updateAssistantsForStudent } from "redux/actions/assistantAction";
import Select from "react-select";
import BoxButton from "components/BoxButton";
import SMessageCard from "components/SMessageCard";
import { DialogActions, DialogContent } from "@mui/material";
import GeneralDialog from "components/GeneralDialog";
import { green} from "@mui/material/colors";

export default function CollebrationSLPA(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [assistantToDelete, setAssistantToDelete] = useState(null);
  const { assistants } = useSelector((state) => ({
    assistants: state.inviteAssistantReducer.assistants_list,
  }));

  useEffect(() => {
    const fetchAssistants = async () => {
      setLoading(true);
      await dispatch(getAllAssistants());
      setLoading(false);
    };

    fetchAssistants();
  }, [dispatch]);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedAssistant(selectedOption);
    } else {
      setSelectedAssistant(null);
    }
  };

  const handleAddAssistant = async () => {
    if (selectedAssistant && !props.data.response.assignedAssistant.includes(selectedAssistant.assistantId)) {
      const updatedAssistants = [...props.data.response.assignedAssistant, selectedAssistant.assistantId];
      await dispatch(
        updateAssistantsForStudent({
          studentId: props.data.response._id,
          tenantId: props.data.response.tenantId,
          assistantIds: updatedAssistants,
        })
      );
      setSelectedAssistant(null);
    }
  };

  const handleDeleteAssistant = (assistantId) => {
    setDeleteConfirm(true);
    setAssistantToDelete(assistantId);
  };

  const handleDeleteConfirm = async () => {
    const updatedAssistants = props.data.response.assignedAssistant.filter((id) => id !== assistantToDelete);
    await dispatch(
      updateAssistantsForStudent({
        studentId: props.data.response._id,
        tenantId: props.data.response.tenantId,
        assistantIds: updatedAssistants,
      })
    );
    setDeleteConfirm(false);
    setAssistantToDelete(null);
  };

  return (
    <React.Fragment>
      <SCard title="Assessment Progress">
        <Grid container spacing={1}>
          <HeaderComp title="Select Assistant" />

          <Grid item xs={12} md={12} lg={6}>
            <Select
              inputType="select"
              isClearable
              label={'Select Assistant'}
              name={'selectAssistant'}
              options={assistants?.response}
              onChange={handleSelectChange}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.assistantId}
              size="small"
              value={selectedAssistant}
              isRequired={true}
              isLoading={loading}
              placeholder="Select Assistant"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddAssistant}
              disabled={!selectedAssistant}
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : "Add Assistant"}
            </Button>
          </Grid>

          <HeaderComp title="Currently Active" />

          <Grid item xs={12} md={12} lg={12}>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {props?.data?.response.assignedAssistant.length > 0 && props?.data?.response?.assignedAssistant?.map((assistantId) => {
                const assistant = assistants?.response?.find((a) => a?.assistantId === assistantId);
                return (
                  <Chip
                    key={assistantId}
                    // variant="outlined"
                    color="success"
                    label={<Typography variant="body2" fontWeight={500}>{assistant ? assistant.name : assistantId}</Typography>}
                    onDelete={() => handleDeleteAssistant(assistantId)}
                    avatar={
                      <Avatar
                       sx={{ bgcolor: green[100], fontWeight: 700, color: 'white' }} variant="circle">
                        {assistant ? assistant.name[0].toUpperCase() : "A"}
                      </Avatar>
                    }
                  />
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </SCard>

      <GeneralDialog
        open={deleteConfirm}
        title={`Delete Assistant`}
        fullScreen={false}
        fullwidth
        onClose={() => setDeleteConfirm(false)}
      >
        <DialogContent dividers={true} style={{ background: "white" }}>
          <SMessageCard
            text="Are you sure you want to proceed?"
            subText="Deleting this assistant will remove them from the list. This action cannot be undone."
          />
        </DialogContent>
        <DialogActions>
          <Box mr={2}>
            <BoxButton
              loading={loading}
              onClick={handleDeleteConfirm}
              variant="contained"
              color="error"
              size="large"
              autoFocus
            >
              Delete
            </BoxButton>
          </Box>
        </DialogActions>
      </GeneralDialog>
    </React.Fragment>
  );
}
