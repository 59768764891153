import React from 'react';
import { Text, View} from '@react-pdf/renderer';
import commonStyles from '../../commonStyles';
import HtmlToPdfText from '../../HtmlToPdfText';

const BackgroundInfo = ({ data }) => (
  <View style={commonStyles.box}>
    <Text style={commonStyles.subTitle}>Background Information</Text>
    <HtmlToPdfText html={data?.background_edu_history_output} />
  </View>
);

export default BackgroundInfo;
