// Helper function to get gender pronoun
export const genderPronoun = (gender) => {
    switch (gender?.toLowerCase()) {
      case 'male': return 'his';
      case 'female': return 'her';
      default: return 'their';
    }
  };
  
  // Helper function to replace placeholders in the template
  export const replacePlaceholders = (template, values, props) => {
    if (!template) return '';
  
    const gender = values?.generalInformation?.gender?.label?.toLowerCase();
    let pronounSubject = '';
    let pronounPossessive = '';
  
    if (gender === 'male') {
      pronounSubject = 'He';
      pronounPossessive = 'His';
    } else if (gender === 'female') {
      pronounSubject = 'she';
      pronounPossessive = 'her';
    } else if (gender === 'non binary') {
      pronounSubject = 'they';
      pronounPossessive = 'their';
    } else {
      pronounSubject = "<b style='color:red'>NO GENDER SELECTED</b>";
      pronounPossessive = "<b style='color:red'>NO GENDER SELECTED</b>";
    }
  
    const attendanceId = values.backgroundInformation?.background_edu_Q_02 || '';
    const prevSchool = values.backgroundInformation?.background_edu_Q_01 || '';
  
    return template
      .replace(/{pronoun_subject}/g, pronounSubject || "<b style='color:red'>SELECT GENDER</b>")
      .replace(/{pronoun_possessive}/g, pronounPossessive || "<b style='color:red'>SELECT GENDER</b>")
      .replace(/{genderPronouns}/g, genderPronoun(values?.generalInformation?.gender?.label) || "<b style='color:red'>SELECT GENDER</b>")
      .replace(/{firstName}/g, values?.generalInformation?.firstName || "<b style='color:red'>STUDENT NAME</b>")
      .replace(
        /{attendanceRecord}/g,
        prevSchool.toString() === "2" ? "No" : props.options?.background_edu_Q_02?.find(l => l?.id.toString() === attendanceId)?.label || "<b style='color:red'>Attendance Record</b>"
      )
      .replace(/{previousSchool}/g, values?.backgroundInformation?.background_edu_Q_01.toString() === '2' ? props.options?.background_edu_Q_01?.find(l => l?.id.toString() === prevSchool)?.label?.toLowerCase() :
        values?.backgroundInformation?.background_edu_Q_01_other || '')
      .replace(/{background_edu_Q_03}/g,
        values?.backgroundInformation?.background_edu_Q_03?.length > 0
          ? values.backgroundInformation.background_edu_Q_03
            .map(id => props.options.background_edu_Q_03.find(option => option.id.toString() === id)?.label)
            .filter(label => label && label !== "Other")
            .join(", ")
          : "<b style='color:red'>Special Education Services</b>"
      )
      .replace(/{background_edu_Q_03_other}/g, values.backgroundInformation?.background_edu_Q_03_other || '');
  };
  