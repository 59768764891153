import React from "react";
import { Box, CssBaseline, Typography } from "@mui/material";
import SideMenu from "components/SideMenu";
import Header from "components/Header";
import theme from "theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Outlet } from "react-router-dom";

const drawerWidth = 256;

const MainLayout = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        {isSmUp ? null : (
          <SideMenu
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        )}
        <SideMenu
          PaperProps={{ style: { width: drawerWidth } }}
          sx={{ display: { sm: "block", xs: "none" } }}
        />
      </Box>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <Outlet />
        {/* <Box
          component="footer"
          sx={{
            mt: 'auto', // Push footer to the bottom
            p: 2,
            bgcolor: '#eaeff1',
            width: '100%',
            bottom: 0,
          }}
        >
          <Typography>Footer</Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default MainLayout;
