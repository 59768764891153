import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import OptionButton from "../components/OptionButton";
import contentTemplate from './content.json';
import HeaderComp from "components/Common/HeaderComp";
import { genderPronoun, replacePlaceholders } from './document_output';

const BackgroundInformation = (props) => {

  const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

  const {
    // Educational History
    background_edu_Q_01,
    background_edu_Q_01_other,
    background_edu_Q_02,
    background_edu_Q_03,
    background_edu_Q_03_other,
    // Medical History
    background_med_Q_01,
    background_med_Q_02,
    background_med_Q_03,
    background_med_Q_03_other,
    background_med_Q_04,
    background_med_Q_04_other,
    background_med_Q_05,
    background_med_Q_05_other,
    // Developmental History
    background_development_Q_01,
    background_development_Q_02,
    background_development_Q_03,
    background_development_Q_04,
    // Medical Findings
    relevant_med_Q_01,
    relevant_med_Q_02,
    relevant_med_Q_03,
    relevant_med_Q_03_other,
    //Family History
    background_family_Q_01,
    background_family_Q_01_other,
    background_doc_description,
    background_edu_history_output,
    background_medical_history_output,
    background_development_history_output,
    background_medical_findings_output,
    background_family_history_output

  } = formField.backgroundInformation;

 
  useEffect(() => {
    const bg_value = Number(values.backgroundInformation?.background_edu_Q_01);
    let edu_history_output = '';
    let specialEducation = '';
    let attendanceRecord = '';

    const output_background_edu_Q_01_yes = "{firstName} has attended {previousSchool} previous schools.";
    const output_background_edu_Q_01_no = "{firstName} has attended no previous schools.";
    const output_background_edu_Q_02 = "{pronoun_possessive} attendance record is mentioned as {attendanceRecord}.";
    const output_background_edu_Q_03_none = "Currently, {firstName} does not receive any special education services.";
    const output_background_edu_Q_03_array = "Currently, {firstName} receives special education services, including {background_edu_Q_03}.";

    // Determine edu_history_output based on bg_value
    switch (bg_value) {
      case 1:
        edu_history_output = output_background_edu_Q_01_yes;
        break;
      case 2:
        edu_history_output = output_background_edu_Q_01_no;
        break;
      default:
        console.log('No matching value for bg_value:', bg_value);
    }

    // Determine specialEducation based on values.backgroundInformation.background_edu_Q_03 (array)
    const background_edu_Q_03 = values.backgroundInformation?.background_edu_Q_03 || [];
    if (background_edu_Q_03.length > 0) {
      if (background_edu_Q_03.includes('1')) {
        specialEducation = output_background_edu_Q_03_none;
      } else {
        specialEducation = output_background_edu_Q_03_array;
      }
    }

    const att_record = Number(values.backgroundInformation?.background_edu_Q_02);

    if (att_record) {
      attendanceRecord = output_background_edu_Q_02;
    }

    const output1 = replacePlaceholders(edu_history_output, values, props);
    const output2 = replacePlaceholders(attendanceRecord, values, props);
    const output3 = replacePlaceholders(specialEducation, values, props);

    setFieldValue(
      background_edu_history_output.path,
      `<p>${output1 || ''} ${output2 || ''} ${output3 || ''} ${values.backgroundInformation.background_edu_Q_03_other || ''}</p>`
      
    );
      }, [values.backgroundInformation, values.generalInformation]);

  
  return (
    <Grid container spacing={2} p={2}>
      <HeaderComp title="Education History" />
      <OptionButton
        type="radio"
        label={background_edu_Q_01.label}
        name={background_edu_Q_01.name}
        options={props.options?.background_edu_Q_01}
        value={values.backgroundInformation.background_edu_Q_01}
        onChange={(name, value) => setFieldValue(background_edu_Q_01.path, value)}
        additionalInfoLabel={background_edu_Q_01_other.label}
        additionalInfoName={background_edu_Q_01_other.path}
        additionalInfoValue={values.backgroundInformation.background_edu_Q_01_other}
        additionalInfoPath={background_edu_Q_01_other.path}
        additionalInfoType={background_edu_Q_01_other.type}
        setFieldValue={setFieldValue}
        toolTipText={background_edu_Q_01.toolTipText}
        error={errors.backgroundInformation?.background_edu_Q_01 && touched.backgroundInformation?.background_edu_Q_01}
        triggerValues={[1]} // Values that should trigger the additional input field
      />
      {values?.backgroundInformation?.background_edu_Q_01.toString() !== '2' &&
        <OptionButton
          type="radio"
          label={background_edu_Q_02.label}
          name={background_edu_Q_02.name}
          options={props.options?.background_edu_Q_02}
          value={values.backgroundInformation.background_edu_Q_02}
          onChange={(name, value) => setFieldValue(background_edu_Q_02.path, value)}
          setFieldValue={setFieldValue}
          toolTipText={background_edu_Q_02.toolTipText}
          error={errors.backgroundInformation?.background_edu_Q_02 && touched.backgroundInformation?.background_edu_Q_02}
        />
      }


      <OptionButton
        type="checkbox"
        label={background_edu_Q_03.label}
        name={background_edu_Q_03.name}
        options={props.options?.background_edu_Q_03}
        value={values.backgroundInformation.background_edu_Q_03}
        onChange={(name, value) => {
          try {
            if (!Array.isArray(value)) {
              console.error('Expected an array value:', value);
              return;
            }
      
            let newValue = [...value];
      
            if (newValue.includes('1')) {
              if (newValue.length > 1) {
                newValue = ['1']; // If 'None' is selected with other values, keep only 'None'
              } else {
                newValue = ['1']; // If 'None' is the only selected value, keep it as is
              }
            } else {
              newValue = newValue.filter(v => v !== '1'); // Remove 'None' from the values if other options are selected
            }
      
            console.log('Updated value:', newValue);
            setFieldValue(background_edu_Q_03.path, newValue);
          } catch (error) {
            console.error('Error handling value change:', error);
          }
        }}  
        additionalInfoLabel={background_edu_Q_03_other.label}
        additionalInfoName={background_edu_Q_03_other.path}
        additionalInfoValue={values.backgroundInformation.background_edu_Q_03_other}
        setFieldValue={setFieldValue}
        toolTipText={background_edu_Q_03.toolTipText}
        error={errors.backgroundInformation?.background_edu_Q_03 && touched.backgroundInformation?.background_edu_Q_03}
        triggerValues={[10]} // Single trigger value
        placeholder={background_edu_Q_03.placeholder}
      />
      <Grid item xs={12}>
        <Typography variant="h5" mb={2}>
          Education History Output
        </Typography>
        <Typography variant="subtitle1">
          <div dangerouslySetInnerHTML={{ __html: values?.backgroundInformation?.background_edu_history_output }} />
        </Typography>
      </Grid>
      <HeaderComp title="Medical History" />

      <OptionButton
        type="radio"
        label={background_med_Q_01.label}
        name={background_med_Q_01.name}
        options={props.options?.background_med_Q_01}
        value={values.backgroundInformation.background_med_Q_01}
        onChange={(name, value) => setFieldValue(background_med_Q_01.path, value)}
        setFieldValue={setFieldValue}
        toolTipText={background_med_Q_01.toolTipText}
        error={errors.backgroundInformation?.background_med_Q_01 && touched.backgroundInformation?.background_med_Q_01}
      />
      <OptionButton
        type="radio"
        label={background_med_Q_02.label}
        name={background_med_Q_02.name}
        options={props.options?.background_med_Q_02}
        value={values.backgroundInformation.background_med_Q_02}
        onChange={(name, value) => setFieldValue(background_med_Q_02.path, value)}
        setFieldValue={setFieldValue}
        toolTipText={background_med_Q_02.toolTipText}
        error={errors.backgroundInformation?.background_med_Q_02 && touched.backgroundInformation?.background_med_Q_02}
      />
      <OptionButton
        type="radio"
        label={background_med_Q_03.label}
        name={background_med_Q_03.name}
        options={props.options?.background_med_Q_03}
        value={values.backgroundInformation.background_med_Q_03}
        onChange={(name, value) => setFieldValue(background_med_Q_03.path, value)}
        additionalInfoLabel={background_med_Q_03_other.label}
        additionalInfoName={background_med_Q_03_other.path}
        additionalInfoValue={values.backgroundInformation.background_med_Q_03_other}
        setFieldValue={setFieldValue}
        toolTipText={background_med_Q_03.toolTipText}
        error={errors.backgroundInformation?.background_med_Q_03 && touched.backgroundInformation?.background_med_Q_03}
        triggerValues={[5]} // Values that should trigger the additional input field
      />
      <OptionButton
        type="radio"
        label={background_med_Q_04.label}
        name={background_med_Q_04.name}
        options={props.options?.background_med_Q_04}
        value={values.backgroundInformation.background_med_Q_04}
        onChange={(name, value) => setFieldValue(background_med_Q_04.path, value)}
        additionalInfoLabel={background_med_Q_04_other.label}
        additionalInfoName={background_med_Q_04_other.path}
        additionalInfoValue={values.backgroundInformation.background_med_Q_04_other}
        setFieldValue={setFieldValue}
        toolTipText={background_med_Q_04.toolTipText}
        error={errors.backgroundInformation?.background_med_Q_04 && touched.backgroundInformation?.background_med_Q_04}
        triggerValues={[2]} // Values that should trigger the additional input field
      />

      <OptionButton
        type="radio"
        label={background_med_Q_05.label}
        name={background_med_Q_05.name}
        options={props.options?.background_med_Q_05}
        value={values.backgroundInformation.background_med_Q_05}
        onChange={(name, value) => setFieldValue(background_med_Q_05.path, value)}
        additionalInfoLabel={background_med_Q_05_other.label}
        additionalInfoName={background_med_Q_05_other.path}
        additionalInfoValue={values.backgroundInformation.background_med_Q_05_other}
        setFieldValue={setFieldValue}
        toolTipText={background_med_Q_05.toolTipText}
        error={errors.backgroundInformation?.background_med_Q_05 && touched.backgroundInformation?.background_med_Q_05}
        triggerValues={[2]} // Values that should trigger the additional input field
      />

      <OptionButton
        type="radio"
        label={relevant_med_Q_01.label}
        name={relevant_med_Q_01.name}
        options={props.options?.relevant_med_Q_01}
        value={values.backgroundInformation.relevant_med_Q_01}
        onChange={(name, value) => setFieldValue(relevant_med_Q_01.path, value)}
        setFieldValue={setFieldValue}
        toolTipText={relevant_med_Q_01.toolTipText}
        error={errors.backgroundInformation?.relevant_med_Q_01 && touched.backgroundInformation?.relevant_med_Q_01}
      />
      <OptionButton
        type="radio"
        label={relevant_med_Q_02.label}
        name={relevant_med_Q_02.name}
        options={props.options?.relevant_med_Q_02}
        value={values.backgroundInformation.relevant_med_Q_02}
        onChange={(name, value) => setFieldValue(relevant_med_Q_02.path, value)}
        setFieldValue={setFieldValue}
        toolTipText={relevant_med_Q_02.toolTipText}
        error={errors.backgroundInformation?.relevant_med_Q_02 && touched.backgroundInformation?.relevant_med_Q_02}
      />
      <OptionButton
        label={relevant_med_Q_03.label}
        type="radio"
        row={true}
        name={relevant_med_Q_03.name}
        options={props.options?.relevant_med_Q_03}
        value={values.backgroundInformation.relevant_med_Q_03}
        onChange={(name, value) => setFieldValue(relevant_med_Q_03.path, value)}
        additionalInfoLabel={relevant_med_Q_03_other.label}
        additionalInfoName={relevant_med_Q_03_other.path}
        additionalInfoValue={values.backgroundInformation.relevant_med_Q_03_other}
        setFieldValue={setFieldValue}
        toolTipText={relevant_med_Q_03.toolTipText}
        error={errors.backgroundInformation?.relevant_med_Q_03 && touched.backgroundInformation?.relevant_med_Q_03}
        triggerValues={[2]}
      />
      <Grid item xs={12}>
        <Typography variant="h5" mb={2}>
          Medical History Output
        </Typography>
        <Typography variant="subtitle1">
          <div dangerouslySetInnerHTML={{ __html: values?.backgroundInformation?.background_medical_history_output }} />
        </Typography>
      </Grid>

      <HeaderComp title="Development History" />

      <OptionButton
        type="radio"
        label={background_development_Q_01.label}
        name={background_development_Q_01.name}
        options={props.options?.background_development_Q_01}
        value={values.backgroundInformation.background_development_Q_01}
        onChange={(name, value) => setFieldValue(background_development_Q_01.path, value)}
        setFieldValue={setFieldValue}
        toolTipText={background_development_Q_01.toolTipText}
        error={errors.backgroundInformation?.background_development_Q_01 && touched.backgroundInformation?.background_development_Q_01}
      />
      <OptionButton
        type="radio"
        label={background_development_Q_02.label}
        name={background_development_Q_02.name}
        options={props.options?.background_development_Q_02}
        value={values.backgroundInformation.background_development_Q_02}
        onChange={(name, value) => setFieldValue(background_development_Q_02.path, value)}
        setFieldValue={setFieldValue}
        toolTipText={background_development_Q_02.toolTipText}
        error={errors.backgroundInformation?.background_development_Q_02 && touched.backgroundInformation?.background_development_Q_02}
      />
      <OptionButton
        type="radio"
        label={background_development_Q_03.label}
        name={background_development_Q_03.name}
        options={props.options?.background_development_Q_03}
        value={values.backgroundInformation.background_development_Q_03}
        onChange={(name, value) => setFieldValue(background_development_Q_03.path, value)}
        setFieldValue={setFieldValue}
        toolTipText={background_development_Q_03.toolTipText}
        error={errors.backgroundInformation?.background_development_Q_03 && touched.backgroundInformation?.background_development_Q_03}
      />
      <OptionButton
        type="radio"
        label={background_development_Q_04.label}
        name={background_development_Q_04.name}
        options={props.options?.background_development_Q_04}
        value={values.backgroundInformation.background_development_Q_04}
        onChange={(name, value) => setFieldValue(background_development_Q_04.path, value)}
        setFieldValue={setFieldValue}
        toolTipText={background_development_Q_04.toolTipText}
        error={errors.backgroundInformation?.background_development_Q_04 && touched.backgroundInformation?.background_development_Q_04}
      />
      <OptionButton
        label={background_family_Q_01.label}
        type="radio"
        row={true}
        name={background_family_Q_01.name}
        options={props.options?.background_family_Q_01}
        value={values.backgroundInformation.background_family_Q_01}
        onChange={(name, value) => setFieldValue(background_family_Q_01.path, value)}
        additionalInfoLabel={background_family_Q_01_other.label}
        additionalInfoName={background_family_Q_01_other.path}
        additionalInfoValue={values.backgroundInformation.background_family_Q_01_other}
        setFieldValue={setFieldValue}
        triggerValues={[1]} // Shows additional info for id 2
        toolTipText={background_family_Q_01.toolTipText}
        error={errors.backgroundInformation?.background_family_Q_01 && touched.backgroundInformation?.background_family_Q_01}
      />

      <Grid item xs={12}>
        <Typography variant="h5" mb={2}>
          Development History Output
        </Typography>
        <Typography variant="subtitle1">
          <div dangerouslySetInnerHTML={{ __html: values?.backgroundInformation?.background_development_history_output }} />
        </Typography>
      </Grid>


      {/* <HeaderComp title="Medical Findings" /> */}


      {/* <Grid item xs={12}>
        <Typography variant="h5" mb={2}>
          Medical Findings
        </Typography>
        <Typography variant="subtitle1">
          <div dangerouslySetInnerHTML={{ __html: values?.backgroundInformation?.background_medical_findings_output }} />
        </Typography>
      </Grid> */}

      {/* <HeaderComp title="Family History" /> */}


      {/* <Grid item xs={12}>
        <Typography variant="h5" mb={2}>
          Family History
        </Typography>
        <Typography variant="subtitle1">
          <div dangerouslySetInnerHTML={{ __html: values?.backgroundInformation?.background_family_history_output }} />
        </Typography>
      </Grid> */}
    </Grid>
  );
};

export default BackgroundInformation;
