/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../constants/constants";

const initialState = {
  reportsList: [],
  reportAllDropdowns: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CREATE_REPORT:
    case Actions.GET_ALL_REPORTS:
      return { ...state, reportsList: action.payload };
    case Actions.REPORTS_IEP_DROPDOWN:
      return { ...state, reportAllDropdowns: action.payload };
    default:
      return state;
  }
};
