import React from "react";
import { Box, Skeleton } from "@mui/material";

function SkeletonListLoader({ count }) {
  return (
    <div>
      {Array.from({ length: count }, (_, index) => (
        <Box key={index} sx={{ marginBottom: 2 }}>
          <Skeleton animation="wave" variant="text" width="100%" height={20} />
          <Skeleton animation="wave" variant="text" width="60%" height={20} />
          <Skeleton animation="wave" variant="text" width="30%" height={20} />
        </Box>
      ))}
    </div>
  );
}

export default SkeletonListLoader;
