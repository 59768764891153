import React, { useEffect } from "react";
import PDFPreview from "..";
import { PDFViewer } from "@react-pdf/renderer";

export default function ShowPDF({ formData }) {
    
    useEffect(() => {

    }, [formData.values]); // Add formData.values as a dependency


    return (
        <React.Fragment>
        <PDFViewer style={{ height: '100vh', width: '100%' }}>
            <PDFPreview values={formData?.values} />
        </PDFViewer>
    </React.Fragment>
    );
}

{/* <PDFDownloadLink document={<PDFPreview values={values} />} fileName="somename.pdf">
{({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
</PDFDownloadLink> */}