import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import { color } from "framer-motion";

export default function BoxButton({ variant, size, color, children, loading, ...rest }) {
  const theme = createTheme({});
  return (
    <ThemeProvider theme={theme}>
      <LoadingButton
        sx={{ boxShadow: 2, textTransform: "none" }}
        {...rest}
        variant={variant}
        size={size}
        color={color}
        loading={loading}
      >
        {children}
      </LoadingButton>
    </ThemeProvider>
  );
}

BoxButton.defaultProps = {
  size: "small",
  variant: "contained",
  color: "success"
};

BoxButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  children: PropTypes.node.isRequired,
};
