import { authRequest } from "./request";
import * as Actions from "../constants/constants";

export function getGlobalDropdowns() {
    return (dispatch) => {
      return authRequest(`config/commonDropdowns`, "GET")
        .then((response) => {
          dispatch({
            type: Actions.GLOBAL_DROPDOWNS,
            payload: response,
          });
          return response?.response;
        })
        .catch((error) => {
          return error;
        });
    };
  }
  