import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography, Dialog, DialogTitle, Slide, Box } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={{ enter: 300, exit: 300 }} />;
});

const GeneralDialog = ({
  title,
  open,
  onClose,
  children,
  maxWidth,
  fullWidth,
  onSubmit,
  fullScreen,
}) => {
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      maxWidth={maxWidth}
      fullScreen={!fullScreen ? responsive : fullScreen}
      fullWidth={true}
      // scroll='paper'
      // TransitionComponent={Transition}
      sx={{
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(5px)",
          backgroundColor: "#00000069",
        },
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">

        {/* Fixed title at the top */}
        <DialogTitle style={{ position: 'sticky', top: 0, backgroundColor: '#f5f5f5', zIndex: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <Typography variant="h3" color="secondary">
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {children}
      </Box>
    </Dialog>
  );
};

GeneralDialog.propTypes = {
  title: PropTypes.string.isRequired, // Define PropTypes for title
  open: PropTypes.bool.isRequired, // Define PropTypes for open
  onClose: PropTypes.func.isRequired, // Define PropTypes for onClose
  children: PropTypes.node.isRequired, // Define PropTypes for children
};

export default GeneralDialog;
