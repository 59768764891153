const backgroundInfoForm = {
    backgroundInformation: {
        background_edu_Q_01: {
            name: "background_edu_Q_01",
            label: "Previous Schools Attended",
            type: "text",
            path: "backgroundInformation.background_edu_Q_01",
            placeholder: "Select whether the student attended previous schools",
            errorMsg: "Please select if the student attended previous schools.",
            toolTipText: 'TODO'
        },
        background_edu_Q_01_other: {
            name: "background_edu_Q_01_other",
            label: "If Yes, How many schools?",
            type: "number",
            path: "backgroundInformation.background_edu_Q_01_other",
            placeholder: "Enter the number of schools attended",
            errorMsg: "Please enter the number of schools.",
            toolTipText: 'TODO'
        },
        background_edu_Q_02: {
            name: "background_edu_Q_02",
            label: "Attendance Record",
            type: "text",
            path: "backgroundInformation.background_edu_Q_02",
            placeholder: "Select the attendance record",
            errorMsg: "Please select the attendance record.",
            toolTipText: 'TODO'
        },
        background_edu_Q_03: {
            name: "background_edu_Q_03",
            label: "Special Education Services",
            type: "dropdown",
            path: "backgroundInformation.background_edu_Q_03",
            placeholder: "Select special education services",
            errorMsg: "Please select special education services.",
            toolTipText: 'TODO'
        },
        background_edu_Q_03_other: {
            name: "background_edu_Q_03_other",
            label: "If Other, Specify",
            type: "text",
            path: "backgroundInformation.background_edu_Q_03_other",
            placeholder: "Specify other services",
            errorMsg: "Please specify the other services.",
            toolTipText: 'TODO'
        },
        // Medical History
        background_med_Q_01: {
            name: "background_med_Q_01",
            label: "Hearing Screening",
            type: "text",
            path: "backgroundInformation.background_med_Q_01",
            placeholder: "Select hearing screening result",
            errorMsg: "Please select the hearing screening result.",
            toolTipText: 'TODO'
        },
        background_med_Q_02: {
            name: "background_med_Q_02",
            label: "Vision Screening",
            type: "dropdown",
            path: "backgroundInformation.background_med_Q_02",
            placeholder: "Select vision screening result",
            errorMsg: "Please select the vision screening result.",
            toolTipText: 'TODO'
        },
        background_med_Q_03: {
            name: "background_med_Q_03",
            label: "Chronic Illnesses",
            type: "text",
            path: "backgroundInformation.background_med_Q_03",
            placeholder: "Select chronic illnesses",
            errorMsg: "Please select any chronic illnesses.",
            toolTipText: 'TODO'
        },
        background_med_Q_03_other: {
            name: "background_med_Q_03_other",
            label: "If Other, Specify",
            type: "text",
            path: "backgroundInformation.background_med_Q_03_other",
            placeholder: "Specify other chronic illnesses",
            errorMsg: "Please specify other chronic illnesses.",
            toolTipText: 'TODO'
        },
        background_med_Q_04: {
            name: "background_med_Q_04",
            label: "Medication",
            type: "dropdown",
            path: "backgroundInformation.background_med_Q_04",
            placeholder: "Select medication status",
            errorMsg: "Please select if the student is on medication.",
            toolTipText: 'TODO'
        },
        background_med_Q_04_other: {
            name: "background_med_Q_04_other",
            label: "If Yes, Specify",
            type: "text",
            path: "backgroundInformation.background_med_Q_04_other",
            placeholder: "Specify medication",
            errorMsg: "Please specify the medication.",
            toolTipText: 'TODO'
        },
        background_med_Q_05: {
            name: "background_med_Q_05",
            label: "Previous Surgeries",
            type: "text",
            path: "backgroundInformation.background_med_Q_05",
            placeholder: "Select previous surgeries",
            errorMsg: "Please select if the student had previous surgeries.",
            toolTipText: 'TODO'
        },
        background_med_Q_05_other: {
            name: "background_med_Q_05_other",
            label: "If Yes, Specify",
            type: "text",
            path: "backgroundInformation.background_med_Q_05_other",
            placeholder: "Specify previous surgeries",
            errorMsg: "Please specify the previous surgeries.",
            toolTipText: 'TODO'
        },
        // Developmental History

        background_development_Q_01: {
            name: "background_development_Q_01",
            label: "Speech Development Milestones",
            type: "dropdown",
            path: "backgroundInformation.background_development_Q_01",
            placeholder: "Select speech development milestones",
            errorMsg: "Please select the speech development milestones.",
            toolTipText: 'TODO'
        },
        background_development_Q_02: {
            name: "background_development_Q_02",
            label: "Motor Skills Development",
            type: "dropdown",
            path: "backgroundInformation.background_development_Q_02",
            placeholder: "Select motor skills development",
            errorMsg: "Please select the motor skills development.",
            toolTipText: 'TODO'
        },
        background_development_Q_03: {
            name: "background_development_Q_03",
            label: "Social Development",
            type: "dropdown",
            path: "backgroundInformation.background_development_Q_03",
            placeholder: "Select social development",
            errorMsg: "Please select the social development.",
            toolTipText: 'TODO'
        },
        background_development_Q_04: {
            name: "background_development_Q_04",
            label: "Cognitive Development",
            type: "dropdown",
            path: "backgroundInformation.background_development_Q_04",
            placeholder: "Select cognitive development",
            errorMsg: "Please select the cognitive development.",
            toolTipText: 'TODO'
        },
        background_development_Q_05: {
            name: "background_development_Q_05",
            label: "Relevant Medical Findings",
            type: "dropdown",
            path: "backgroundInformation.background_development_Q_05",
            placeholder: "Select relevant medical findings for hearing loss",
            errorMsg: "Please select relevant medical findings for hearing loss.",
            toolTipText: 'TODO'
        },
        // relevant_med_Q_01
        // relevant_med_Q_02
        // relevant_med_Q_03
        // relevant_med_Q_03_other
        // Medical Findings
        relevant_med_Q_01: {
            name: "relevant_med_Q_01",
            label: "Hearing Loss",
            type: "text",
            path: "backgroundInformation.relevant_med_Q_01",
            errorMsg: "relevant_med_Q_01",
            toolTipText: 'TODO'
        },
        relevant_med_Q_02: {
            name: "relevant_med_Q_02",
            label: "Speech Impairment",
            type: "text",
            path: "backgroundInformation.relevant_med_Q_02",
            errorMsg: "relevant_med_Q_02",
            toolTipText: 'TODO'
        },
        relevant_med_Q_03: {
            name: "relevant_med_Q_03",
            label: "Neurological Conditions",
            type: "text",
            path: "backgroundInformation.relevant_med_Q_03",
            errorMsg: "relevant_med_Q_03",
            toolTipText: 'TODO'
        },
        relevant_med_Q_03_other: {
            name: "relevant_med_Q_03_other",
            label: "If Yes, Specify",
            type: "text",
            path: "backgroundInformation.relevant_med_Q_03_other",
            errorMsg: "relevant_med_Q_03_other",
            toolTipText: 'TODO'
        },

        background_development_Q_05_other: {
            name: "background_development_Q_05_other",
            label: "If Other, Specify",
            type: "text",
            path: "backgroundInformation.background_development_Q_05_other",
            placeholder: "Specify other medical findings",
            errorMsg: "Please specify other medical findings.",
            toolTipText: 'TODO'
        },
        //Family History

        background_family_Q_01: {
            name: "background_family_Q_01",
            label: "Speech/Language Disorders in Family",
            type: "dropdown",
            path: "backgroundInformation.background_family_Q_01",
            placeholder: "Please specify disorders in Family.",
            errorMsg: "Please specify disorders in Family.",
            toolTipText: 'TODO'
        },
        background_family_Q_01_other: {
            name: "background_family_Q_01_other",
            label: "If Yes, Specify",
            type: "text",
            path: "backgroundInformation.background_family_Q_01_other",
            placeholder: "Specify known conditions",
            errorMsg: "Please specify known conditions.",
            toolTipText: ''
        },
        background_doc_description: {
            name: "background_doc_description",
            type: "text",
            path: "backgroundInformation.background_doc_description",
            placeholder: "Specify known conditions",
            errorMsg: "background_doc_description",
            toolTipText: ''
        },
        background_edu_history_output : {
            name: "background_edu_history_output",
            type: "text",
            path: "backgroundInformation.background_edu_history_output",
            placeholder: "Specify background_edu_history_output",
            errorMsg: "background_edu_history_output",
            toolTipText: ''
        },
      background_medical_history_output: {
        name: "background_medical_history_output",
        type: "text",
        path: "backgroundInformation.background_medical_history_output",
        placeholder: "background_medical_history_output",
        errorMsg: "background_medical_history_output",
        toolTipText: ''
      },
      background_development_history_output: {
        name: "background_development_history_output",
        type: "text",
        path: "backgroundInformation.background_development_history_output",
        placeholder: "background_development_history_output",
        errorMsg: "background_development_history_output",
        toolTipText: ''
      },
      background_medical_findings_output: {
        name: "background_medical_findings_output",
        type: "text",
        path: "backgroundInformation.background_medical_findings_output",
        placeholder: "background_medical_findings_output",
        errorMsg: "background_medical_findings_output",
        toolTipText: ''
      },
      background_family_history_output: {
        name: "background_family_history_output",
        type: "text",
        path: "backgroundInformation.background_family_history_output",
        placeholder: "background_family_history_output",
        errorMsg: "background_family_history_output",
        toolTipText: ''
      }
    }
};

export default backgroundInfoForm;