import checkout from "./form";

const {
  formField: {
    firstName,
    lastName,
    middleName,
    caseManager,
    dateOfBirth,
    gender,
    school,
    disability,
    ethnicity,
    // New
    grade,
    primaryLanguage,
    secondaryLanguage,
    eligibilityEvaluation,
    currentAcademicYear,
    initialEvaluation,
    annualPlanReview,
    eligibility,
    initialEvaluationReviewCompleted,
    nextEligibilityReviewCompleted,
    annualPlanReviewCompleted,
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [middleName.name]: "",
  [lastName.name]: "",
  [caseManager.name]: "",
  [dateOfBirth.name]: "",
  [gender.name]: null,
  [school.name]: "",
  [disability.name]: "",
  [ethnicity.name]: null,
  // New
  [grade.name]: null,
  [primaryLanguage.name]: "",
  [secondaryLanguage.name]: "",
  [eligibilityEvaluation.name]: "",
  [initialEvaluation.name]: "",
  [annualPlanReview.name]: "",
  [eligibility.name]: null,
  [initialEvaluationReviewCompleted.name]: false,
  [nextEligibilityReviewCompleted.name]: false,
  [annualPlanReviewCompleted.name]: false,
  [currentAcademicYear.name]: ""
};

export default initialValues;
