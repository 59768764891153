import React, { useState } from "react";
import {
  Box,
  Chip,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import SCard from "components/SCard";
import DateAndTimeConvert from "components/Common/DateAndTimeConvert";
import EditIcon from "@mui/icons-material/Edit";
import EditStudentDetails from "./EditStudentDetails";
import { Formik, Form } from "formik";
import { initialValues, form, validations } from "./schemas";
import { CloseOutlined } from "@mui/icons-material";
import BoxButton from "components/BoxButton";
import { updateStudent } from "../../../../../redux/actions/studentActions";
import { useDispatch } from "react-redux";
import moment from "moment";
import HeaderComp from "components/Common/HeaderComp";

export default function PersonalInformation(props) {
  const { data } = props;
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function newForm(formData) {
    return <EditStudentDetails formData={formData} />;
  }

  function handleEdit() {
    setEdit(true);
  }

  function handleCancel() {
    setEdit(false);
  }

  const submitForm = (values, actions) => {

    // Clone values object and transform fields
  const payload = { ...values };

  // Transform fields
   payload.ethnicity =  values?.ethnicity?.label;

  if (values.gender?.label) {
    payload.gender = values.gender.label;
  }
  if (values.eligibility?.label) {
    payload.eligibility = values.eligibility.label;
  }

  if (values.primaryLanguage?.label) {
    payload.primaryLanguage = values.primaryLanguage.label;
  }
  if (values.secondaryLanguage?.label) {
    payload.secondaryLanguage = values.secondaryLanguage.label;
  }

  payload.currentAcademicYear = {
    schoolYear: values.currentAcademicYear.id,
    grade: values.grade.label,
    school: values.school,
  };
  
  payload.studentId = data.response._id;   
  setLoading(true);

    dispatch(updateStudent(payload))
      .then((response) => {
        console.log('response', response);
        setLoading(false);
        actions.setTouched({});
        actions.setSubmitting(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  return (
    <React.Fragment>
      <SCard
        title={!edit ? "Student Information" : "Edit Student"}
        renderActions={() =>
          !edit ? (
            <Tooltip title="Full Screen" arrow>
              <IconButton onClick={() => handleEdit()}>
                <EditIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Close Form" arrow>
              <IconButton onClick={() => handleCancel()}>
                <CloseOutlined fontSize="medium" />
              </IconButton>
            </Tooltip>
          )
        }
      >
        {edit ? (
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={submitForm}
          >
            {({ values, errors, touched, setFieldValue, isSubmitting, resetForm }) => (
              <Form id={formId} autoComplete="off">
                {newForm({
                  values,
                  touched,
                  formField,
                  setFieldValue,
                  resetForm,
                  errors,
                  data,
                  edit,
                })}
                <Box display="flex" justifyContent="flex-end">
                  <BoxButton
                    loading={loading}
                    type="submit"
                    color="success"
                    size="large"
                    variant="contained"
                    autoFocus
                  // disabled={Object.keys(errors).length !== 0}
                  >
                    Update
                  </BoxButton>
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <Grid container spacing={2}>
              <HeaderComp title="General Information" />
              <Grid item xs={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  First Name
                </Typography>
                <Typography variant="h4" fontWeight={500}>
                  {data.response.firstName}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Middle Name
                </Typography>
                {data.response.middleName ?
                  <Typography variant="h4" fontWeight={500}>
                    {data.response.middleName}
                  </Typography>
                  : '-'}
              </Grid>
              <Grid item xs={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Last Name
                </Typography>
                <Typography variant="h4" fontWeight={500}>
                  {data.response.lastName}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Date Of Birth
                </Typography>
                <Typography variant="h4" fontWeight={500}>
                  {<DateAndTimeConvert timestamp={data?.response?.dateOfBirth} />}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Grade
                </Typography>
                {data.response.currentAcademicYear.grade ?
                  <Typography variant="h4" fontWeight={500}>
                    {data.response.currentAcademicYear.grade}
                  </Typography>
                  : '-'
                }
              </Grid>
              <Grid item xs={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Gender
                </Typography>
                {data.response.gender ?
                  <Typography variant="h4" fontWeight={500}>
                    {data.response.gender}
                  </Typography>
                  : '-'
                }

              </Grid>
              <Grid item xs={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Primary Language
                </Typography>
                {data.response.primaryLanguage ?
                  <Typography variant="h4" fontWeight={500}>
                    {data.response.primaryLanguage}
                  </Typography>
                  : '-'
                }

              </Grid>
              <Grid item xs={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Secondary Language
                </Typography>
                {data.response.secondaryLanguage ?
                  <Typography variant="h4" fontWeight={500}>
                    {data.response.secondaryLanguage}
                  </Typography>
                  : "-"
                }
              </Grid>
              <Grid item xs={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Ethnicity
                </Typography>
                {data.response.ethnicity ?
                  <Typography variant="h4" fontWeight={500}>
                    {data.response.ethnicity}
                  </Typography>
                  : "-"
                }

              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Disability
                </Typography>
                {data.response.disability ?
                  <Typography variant="h4" fontWeight={500}>
                    {data.response.disability}
                  </Typography>
                  : "-"
                }

              </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
              <HeaderComp title="School Details" />
              <Grid item xs={12} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Case Manager
                </Typography>
                {data.response.caseManager ?
                  <Typography variant="h4" fontWeight={500}>
                    {data.response.caseManager}
                  </Typography>
                  : "-"
                }

              </Grid>
              <Grid item xs={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Eligibility
                </Typography>
                {data.response.eligibility ?
                  <Typography variant="h4" fontWeight={500}>
                    {data.response.eligibility}
                  </Typography> : "-"
                }

              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  School
                </Typography>
                <Typography variant="h4" fontWeight={500}>
                  {data.response.currentAcademicYear.school || '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Next Annual Plan Review
                </Typography>
                {data.response.annualPlanReview ? (
                  <Typography variant="h4" fontWeight={500}>
                    {moment(data.response.annualPlanReview).format("LL")}{" "}
                    <Chip
                      sx={{ height: "18px" }}
                      color={data.response.annualPlanReview ? "success" : "warning"}
                      variant="outlined"
                      label={
                        data.response.annualPlanReviewCompleted
                          ? "Review Completed"
                          : "Review Pending"
                      }
                      size="small"
                    />
                  </Typography>
                ) : '-'
                }

              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Next Eligibility Evaluation
                </Typography>
                {data.response.eligibilityEvaluation ? (
                  <Typography variant="h4" fontWeight={500}>
                    {moment(data.response.eligibilityEvaluation).format("LL")}{" "}
                    <Chip
                      sx={{ height: "18px" }}
                      color="warning"
                      variant="outlined"
                      label={
                        data.response.nextEligibilityReviewCompleted
                          ? "Review Completed"
                          : "Review Pending"
                      }
                      size="small"
                    />
                  </Typography>
                ) : '-'}

              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  Initial Evaluation
                </Typography>
                {data.response.initialEvaluation ? (
                  <Typography variant="h4" fontWeight={500}>
                    {moment(data.response.initialEvaluation).format("LL")}{" "}
                    <Chip
                      sx={{ height: "18px" }}
                      color="warning"
                      variant="outlined"
                      label={
                        data.response.initialEvaluationReviewCompleted
                          ? "Review Completed"
                          : "Review Pending"
                      }
                      size="small"
                    />
                  </Typography>
                ) : (
                  '-'
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color={"text.secondary"}>
                  School Year
                </Typography>
                <Chip
                  label={
                    <Typography variant="body1" fontWeight={500} color={"inherit"}>
                     {data.response?.currentAcademicYear?.schoolYear}
                    </Typography>
                  }
                  color="warning"
                  size="small"
                  sx={{ borderRadius: 0.5 }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </SCard>
    </React.Fragment>
  );
}
