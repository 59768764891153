import { authRequest } from "./request";
import {
  FETCH_STUDENTS,
  SEARCH_STUDENT,
  IMPORT_STUDENTS,
  FETCH_STUDENT_BY_ID,
  FETCH_STUDENTS_COUNT,
  ADD_STUDENT,
  DELETE_STUDENT
} from "../constants/constants";

import { showNotification } from "./notificationAction";
import { SUCCESS, ERROR } from "../constants/notificationType";

export function fetchAllStudents(schoolYear) {
  return (dispatch) => {
    return authRequest(`student/getAllStudents?schoolYear=${encodeURIComponent(schoolYear)}`, "GET")
      .then((response) => {
        dispatch({
          type: FETCH_STUDENTS,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function searchStudent(searchString, schoolYear) {
  return (dispatch) => {
    return authRequest(`student/searchStudent?searchString=${encodeURIComponent(searchString)}&schoolYear=${encodeURIComponent(schoolYear)}`, "GET")
      .then((response) => {
        dispatch({
          type: SEARCH_STUDENT,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        console.error("Error searching for students:", error);
        return error;
      });
  };
}


export function createStudent(payload) {
  return (dispatch) => {
    return authRequest(`student/create`, "POST", payload).then((response) => {
      dispatch({
        type: IMPORT_STUDENTS,
        payload: response,
      });
      showNotification({
        message: "New Student Added Succesfully!",
        type: SUCCESS,
      });

      return response;
    });
  };
}

export function fetchStudentsDataTable(payload) {
  const queryParams = new URLSearchParams(payload.filter).toString();
  return (dispatch) => {
    return authRequest(`student/students?${queryParams}`, "GET")
      .then((response) => {
        dispatch({
          type: FETCH_STUDENTS,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function fetchEvaluationCount() {
  return (dispatch) => {
    return authRequest(`student/getNextEligibilityCounts`, "GET")
      .then((response) => {
        dispatch({
          type: FETCH_STUDENTS_COUNT,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function generateImage(payload) {
  return (dispatch) => {
    return authRequest(`openai/generateImage`, "POST", payload).then((response) => {
      dispatch({
        type: FETCH_STUDENTS,
        payload: response, // Assuming the imageUrl is in the response.data object
      });
      return response; // Return the imageUrl if needed
    });
  };
}

export function aiAssistance(payload) {
  return (dispatch) => {
    return authRequest(`openai/aiAssistance`, "POST", payload).then((response) => {
      dispatch({
        type: FETCH_STUDENTS,
        payload: response, // Assuming the imageUrl is in the response.data object
      });
      return response; // Return the imageUrl if needed
    });
  };
}

export function fetchStudentById(studentId) {
  return (dispatch) => {
    return authRequest(`student/getStudentById/${studentId}`, "GET").then((response) => {
      dispatch({
        type: FETCH_STUDENT_BY_ID,
        payload: response,
      });
      return response;
    });
  };
}


export function updateStudent(payload) {
  return async (dispatch) => {
    try {
      const response = await authRequest('student/update', 'PUT', payload);
      dispatch({
        type: ADD_STUDENT,
        payload: response,
      });

      const { studentId } = payload;
      if (studentId) {
        await dispatch(fetchStudentById(studentId));
      }
      showNotification({
        message: 'Updated Successfully!',
        type: SUCCESS,
      });
      return response;
    } catch (error) {
      console.error('Delete failed:', error);
      showNotification({
        message: 'Delete failed. Please try again.',
        type: ERROR,
      });
      throw error;
    }
  };
}





export function importStudents(payload) {
  return (dispatch) => {
    return authRequest(`student/addImportStudents`, "POST", payload).then((response) => {
      dispatch({
        type: IMPORT_STUDENTS,
        payload: response,
      });
      showNotification({
        message: response.response  || 'Updated Succesfully!',
        type: SUCCESS,
      });

      return response;
    });
  };
}

export function deleteStudent(payload) {
  return (dispatch) => {
    return authRequest(`student/deleteStudent`, "DELETE", payload).then((response) => {
      dispatch({
        type: DELETE_STUDENT,
        payload: response,
      });
      showNotification({
        message: response.response  || 'Updated Succesfully!',
        type: SUCCESS,
      });

      return response;
    });
  };
}

export function bulkDeleteStudents(payload) {
  return (dispatch) => {
    return authRequest(`student/bulkStudentDelete`, "POST", payload).then((response) => {
      dispatch({
        type: DELETE_STUDENT,
        payload: response,
      });
      showNotification({
        message: response.response  || 'Deleted Succesfully!',
        type: SUCCESS,
      });

      return response;
    });
  };
}



export function importGoals(payload) {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("pdfFile", payload);
    console.log(formData);

    return authRequest("upload", "POST", formData, {
      // Set the correct headers for form data
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        dispatch({
          type: IMPORT_STUDENTS,
          payload: response.data, // Assuming the response contains the data you want
        });
        return response.data;
      })
      .catch((error) => {
        console.error("Error importing students:", error);
        // Handle the error or dispatch an error action
        // Example: dispatch({ type: IMPORT_STUDENTS_ERROR, payload: error });
        throw error;
      });
  };
}
