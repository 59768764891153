const normativeChartForm = {
  normative_chart: {
    normative_chart_default: {
      name: "normative_chart_default",
      label: "Normative Chart Default",
      type: "array",
      placeholder: "Enter default normative chart data",
      errorMsg: "Normative chart default data is required.",
      invalidMsg: "Invalid input for normative chart default data.",
      path: "normative_chart.normative_chart_default",
    },
    normative_chart_uploaded: {
      name: "normative_chart_uploaded",
      label: "Normative Chart Uploaded",
      type: "text",
      placeholder: "Upload normative chart",
      errorMsg: "Normative chart upload is required.",
      invalidMsg: "Invalid input for normative chart upload.",
      path: "normative_chart.normative_chart_uploaded",
    },
  }
};

export default normativeChartForm;
