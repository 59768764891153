import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Paper, Container, TextField, CircularProgress, Alert } from '@mui/material';
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { createAssistant } from 'redux/actions/assistantAction.js';

const InviteAssistant = () => {
    const dispatch = useDispatch();
    const { user } = useAuth0();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        const payload = {
            email,
            name,
            password
        };

        dispatch(createAssistant(payload)).then(() => setLoading(false));
        // try {
        //     const response = await dispatch(createAssistant(payload));
        //     if (response && response.message) {
        //         setSuccess(response.message);
        //     }
        // } catch (err) {
        //     setError(err.message);
        // } finally {
        //     setLoading(false);
        // }
    };

    return (
        <Container>
            <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Invite Assistant
                </Typography>
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Name"
                                variant="outlined"
                                fullWidth
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                fullWidth
                                required
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Password"
                                variant="outlined"
                                fullWidth
                                required
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {loading && <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />}
                    {success && <Alert severity="success" sx={{ marginTop: 2 }}>{success}</Alert>}
                    {error && <Alert severity="error" sx={{ marginTop: 2 }}>{error}</Alert>}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: 2 }}
                    >
                        Invite Assistant
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default InviteAssistant;
