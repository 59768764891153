import checkout from "./form";

const {
  formField: {
    generalInformation: {
      firstName,
      middleName,
      lastName,
      caseManager,
      evaluatorName,
      dateOfBirth,
      school,
      schoolDistrict,
      gender,
      grade,
      primaryLanguage,
      testDates,
      dateOfReport,
      currentProgram,
      primaryDisability,
      secondaryDisability,
      identificationDate,
      serviceMinutes,
    },
    purposeOfEvaluation: {
      evaluationType,
      eval_type_referredBy,
      eval_referred_title,
      eval_referred_name,
      eval_referred_hospital_name,
      eval_type_referral_other,
      eval_type_dateOfReferal,
      eval_type_other,
      eval_type_doc_content,
      eval_type_doc_referral_content
    },
    backgroundInformation: {
      // Educational History
      background_edu_Q_01,
      background_edu_Q_01_other,
      background_edu_Q_02,
      background_edu_Q_03,
      background_edu_Q_03_other,
      // Medical History
      background_med_Q_01,
      background_med_Q_02,
      background_med_Q_03,
      background_med_Q_03_other,
      background_med_Q_04,
      background_med_Q_04_other,
      background_med_Q_05,
      background_med_Q_05_other,
      // Developmental History
      background_development_Q_01,
      background_development_Q_02,
      background_development_Q_03,
      background_development_Q_04,
      background_development_Q_05,
      background_development_Q_05_other,
      // Medical Findings
      relevant_med_Q_01,
      relevant_med_Q_02,
      relevant_med_Q_03,
      relevant_med_Q_03_other,
      //Family History
      background_family_Q_01,
      background_family_Q_01_other,
      background_doc_description,
      background_edu_history_output,
      background_medical_history_output,
      background_development_history_output,
      background_medical_findings_output,
      background_family_history_output
    },
    prevAssesment: {
      prev_assesment_history,
      prev_assesment_history_other,
      prev_assesment_services
    },
    teacherInput: {
      teacher_input_Q_01,
      teacher_input_Q_02,
      teacher_input_Q_03,
      teacher_input_Q_03_other,
      teacher_input_Q_04,
      teacher_input_Q_05,
      teacher_input_Q_06,
      teacher_input_Q_06_other,
      teacher_input_Q_07,
      teacher_input_Q_07_other,
      teacher_input_Q_08,
      teacher_input_Q_08_other,
      teacher_input_Q_09,
      teacher_input_Q_10,
      teacher_input_Q_11,
      teacher_input_Q_12,
      teacher_input_Q_13,
      teacher_input_Q_14,
      teacher_input_Q_15,
      teacher_input_Q_16,
      teacher_input_Q_17_A,
      teacher_input_Q_17_B,
      teacher_input_Q_17_C,
      teacher_input_Q_18,
      teacher_input_Q_19,
      teacher_input_Q_19_other,
      teacher_input_Q_20,
      teacher_input_Q_20_other,
      teacher_input_Q_21,
      teacher_input_Q_21_other,
      teacher_input_Q_22,
      teacher_input_Q_22_other,
      teacher_additional_info,
      teacher_doc_description
    },
    parentInput: {
      parent_input_Q_01,
      parent_input_Q_02,
      parent_input_Q_02_other,
      parent_input_Q_03,
      parent_input_Q_03_other,
      parent_input_Q_04,
      parent_input_Q_05,
      parent_input_Q_06,
      parent_input_Q_07,
      parent_input_Q_08,
      parent_input_Q_08_other,
      parent_input_Q_09,
      parent_input_Q_09_other,
      parent_input_Q_10,
      parent_input_Q_10_other,
      parent_input_Q_11,
      parent_input_Q_11_other,
      parent_input_Q_12,
      parent_input_Q_12_other,
      parent_input_Q_13,
      parent_input_Q_13_other,
      parent_input_Q_14,
      parent_input_Q_14_other,
      parent_input_Q_15,
      parent_additional_info,
      parent_doc_description
    },
    classroomObservation: {
      classroom_obs_date_time,
      classroom_obs_teacher_input_Q_01,
      classroom_obs_assistants_count,
      classroom_obs_Q_04,
      classroom_obs_Q_04_other,
      classroom_obs_Q_05,
      classroom_obs_Q_06,
      classroom_obs_Q_07,
      classroom_obs_Q_08,
      classroom_obs_Q_09,
      classroom_obs_Q_10,
      classroom_obs_Q_11,
      classroom_obs_Q_12,
      classroom_obs_Q_13,
      classroom_obs_Q_14,
      classroom_obs_Q_15,
      classroom_obs_Q_16,
      classroom_obs_Q_17,
      classroom_obs_Q_18,
      classroom_obs_Q_19,
      classroom_obs_Q_19_other,
      classroom_obs_Q_20,
      classroom_obs_Q_20_other,
      classroom_obs_Q_21,
      classroom_obs_Q_21_other,
      classroom_obs_Q_22,
      classroom_obs_Q_22_other,
      classroom_additional_info,
      classroom_doc_description
    },
    evaluationObservQuestionnaire: {
      eval_obv_Q_01,
      eval_obv_Q_02,
      eval_obv_Q_03,
      eval_obv_Q_04,
      eval_obv_Q_04_other,
      eval_obv_Q_05,
      eval_obv_Q_06,
      eval_obv_Q_07,
      eval_obv_Q_08,
      eval_obv_Q_09,
      eval_doc_description
    },
    behavioralObservation: {
      behavioral_Q_01,
      behavioral_Q_02,
      behavioral_Q_03,
      behavioral_Q_04,
      behavioral_Q_05,
      behav_student_doc_description,
    },
    oralPeripheralMechanism: {
      opme_lips,
      opme_tongue,
      opme_jaw,
      opme_teeth,
      opme_additional_info,
      opme_doc_description
    },
    fluency: {
      flu_student_describe,
      flu_student_experience,
      flu_student_report,
      flu_doc_description
    },
    voiceObservation: {
      voice_obs_method,
      voice_obs_pitch,
      voice_obs_loudness,
      voice_obs_quality,
      voice_obs_resonance,
      voice_obs_overall_voice,
      voice_doc_description
    },
    articulation_Intelligibility: {
      arti_skills,
      arti_sound_production,
      arti_intelligibility,
      arti_behav_observations,
      arti_impact_communication,
      arti_additional_concerns,
      arti_doc_description
    },
    pragmatics: {
      pragmatics_Q_01,
      pragmatics_Q_02,
      pragmatics_Q_03,
      pragmatics_Q_04,
      pragmatics_Q_05,
      pragmatics_Q_06,
      pragmatics_Q_07,
      pragmatics_Q_08,
      prag_additional_concerns,
      prag_doc_description
    },
    reportSummary: {
      report_summary_Q_01,
      report_summary_Q_01_other,
      report_summary_Q_02,
      report_summary_Q_02_other,
      report_summary_Q_03,
      report_summary_Q_03_other,
      report_summary_Q_04,
      report_summary_Q_04_other,
      report_summary_Q_05,
      report_summary_Q_06,
      report_summary_doc_description
    },
    eligibility: {
      eligibility_Q_01,
      eligibility_Q_02,
      eligibility_Q_02_other,
      eligibility_Q_03,
      eligibility_Q_04,
      eligibility_Q_05,
      eligibility_Q_06,
      eligibility_Q_07,
      eligibility_Q_07_doc_description
    },
    recommendations: {
      recomm_Q_01,
      recomm_Q_01_other,
      recomm_Q_02,
      recomm_doc_description
    },
    goals: {
      goals,
    },
    normative_chart: {
      normative_chart_default,
      normative_chart_uploaded
    },
    signature: {
      sig_add_signature,
      sig_fullname,
      sig_position,
      sig_school_district_name,
      sig_date
    },
  },
} = checkout;

const initialValues = {
  generalInformation: {
    [firstName.name]: "",
    [middleName.name]: "",
    [lastName.name]: "",
    [caseManager.name]: "",
    [evaluatorName.name]: "",
    [dateOfBirth.name]: "",
    [school.name]: "",
    [schoolDistrict.name]: "",
    [gender.name]: null,
    [grade.name]: null,
    [primaryLanguage.name]: null,
    [testDates.name]: [],
    [dateOfReport.name]: "",
    // [reasonForTesting.name]: "",
    [currentProgram.name]: "",
    [primaryDisability.name]: "",
    [secondaryDisability.name]: "",
    [identificationDate.name]: "",
    [serviceMinutes.name]: "",
  },
  purposeOfEvaluation: {
    [evaluationType.name]: null,
    [eval_type_referredBy.name]: null,
    [eval_type_dateOfReferal.name]: "",
    [eval_referred_title.name]: "",
    [eval_referred_name.name]: "",
    [eval_referred_hospital_name.name]: "",
    [eval_type_referral_other.name]: "",
    [eval_type_other.name]: "",
    [eval_type_doc_content.name]: "",
    [eval_type_doc_referral_content.name]: ""
  },
  backgroundInformation: {
    // Educational History
    [background_edu_Q_01.name]: "",
    [background_edu_Q_01_other.name]: "",
    [background_edu_Q_02.name]: "",
    [background_edu_Q_03.name]: [],
    [background_edu_Q_03_other.name]: "",
    // Medical History
    [background_med_Q_01.name]: "",
    [background_med_Q_02.name]: "",
    [background_med_Q_03.name]: "",
    [background_med_Q_03_other.name]: "",
    [background_med_Q_04.name]: "",
    [background_med_Q_04_other.name]: "",
    [background_med_Q_05.name]: "",
    [background_med_Q_05_other.name]: "",
    // Developmental History
    [background_development_Q_01.name]: "",
    [background_development_Q_02.name]: "",
    [background_development_Q_03.name]: "",
    [background_development_Q_04.name]: "",
    [background_development_Q_05.name]: "",
    [background_development_Q_05_other.name]: "",
    // Medical Findings
    [relevant_med_Q_01.name]: "",
    [relevant_med_Q_02.name]: "",
    [relevant_med_Q_03.name]: "",
    [relevant_med_Q_03_other.name]: "",
    // Background family
    [background_family_Q_01.name]: "",
    [background_family_Q_01_other.name]: "",
    [background_doc_description.name]: "",
    [background_edu_history_output.name]: "",
    [background_medical_history_output.name]: "",
    [background_development_history_output.name]: "",
    [background_medical_findings_output.name]: "",
    [background_family_history_output.name]: ""

  },
  teacherInput: {
    [teacher_input_Q_01.name]: "",
    [teacher_input_Q_02.name]: "",
    [teacher_input_Q_03.name]: "",
    [teacher_input_Q_03_other.name]: "",
    [teacher_input_Q_04.name]: "",
    [teacher_input_Q_05.name]: "",
    [teacher_input_Q_06.name]: "",
    [teacher_input_Q_06_other.name]: "",
    [teacher_input_Q_07.name]: "",
    [teacher_input_Q_07_other.name]: "",
    [teacher_input_Q_08.name]: "",
    [teacher_input_Q_08_other.name]: "",
    [teacher_input_Q_09.name]: "",
    [teacher_input_Q_10.name]: "",
    [teacher_input_Q_11.name]: "",
    [teacher_input_Q_12.name]: "",
    [teacher_input_Q_13.name]: "",
    [teacher_input_Q_14.name]: "",
    [teacher_input_Q_15.name]: "",
    [teacher_input_Q_16.name]: "",
    [teacher_input_Q_17_A.name]: "",
    [teacher_input_Q_17_B.name]: "",
    [teacher_input_Q_17_C.name]: "",
    [teacher_input_Q_18.name]: "",
    [teacher_input_Q_19.name]: "",
    [teacher_input_Q_19_other.name]: "",
    [teacher_input_Q_20.name]: "",
    [teacher_input_Q_20_other.name]: "",
    [teacher_input_Q_21.name]: "",
    [teacher_input_Q_21_other.name]: "",
    [teacher_input_Q_22.name]: "",
    [teacher_input_Q_22_other.name]: "",
    [teacher_additional_info.name]: "",
    [teacher_doc_description.name]: "",
  },
  parentInput: {
    [parent_input_Q_01.name]: "",
    [parent_input_Q_02.name]: "",
    [parent_input_Q_02_other.name]: "",
    [parent_input_Q_03.name]: "",
    [parent_input_Q_03_other.name]: "",

    [parent_input_Q_04.name]: "",
    [parent_input_Q_05.name]: "",
    [parent_input_Q_06.name]: "",
    [parent_input_Q_07.name]: "",
    [parent_input_Q_08.name]: "",
    [parent_input_Q_08_other.name]: "",
    [parent_input_Q_09.name]: "",
    [parent_input_Q_09_other.name]: "",
    [parent_input_Q_10.name]: "",
    [parent_input_Q_10_other.name]: "",
    [parent_input_Q_11.name]: "",
    [parent_input_Q_11_other.name]: "",
    [parent_input_Q_12.name]: "",
    [parent_input_Q_12_other.name]: "",
    [parent_input_Q_13.name]: "",
    [parent_input_Q_13_other.name]: "",
    [parent_input_Q_14.name]: "",
    [parent_input_Q_14_other.name]: "",
    [parent_input_Q_15.name]: "",
    [parent_additional_info.name]: "",
    [parent_doc_description.name]: ""
  },
  classroomObservation: {
    [classroom_obs_date_time.name]: "",
    [classroom_obs_teacher_input_Q_01.name]: "",
    [classroom_obs_assistants_count.name]: "",
    [classroom_obs_Q_04.name]: "",
    [classroom_obs_Q_04_other.name]: "",
    [classroom_obs_Q_05.name]: "",
    [classroom_obs_Q_06.name]: "",
    [classroom_obs_Q_07.name]: "",
    [classroom_obs_Q_08.name]: "",
    [classroom_obs_Q_09.name]: "",
    [classroom_obs_Q_10.name]: "",
    [classroom_obs_Q_11.name]: "",
    [classroom_obs_Q_12.name]: "",
    [classroom_obs_Q_13.name]: "",
    [classroom_obs_Q_14.name]: "",
    [classroom_obs_Q_15.name]: "",
    [classroom_obs_Q_16.name]: "",
    [classroom_obs_Q_17.name]: "",
    [classroom_obs_Q_18.name]: "",
    [classroom_obs_Q_19.name]: "",
    [classroom_obs_Q_19_other.name]: "",
    [classroom_obs_Q_20.name]: "",
    [classroom_obs_Q_20_other.name]: "",
    [classroom_obs_Q_21.name]: "",
    [classroom_obs_Q_21_other.name]: "",
    [classroom_obs_Q_22.name]: "",
    [classroom_obs_Q_22_other.name]: "",
    [classroom_additional_info.name]: "",
    [classroom_doc_description.name]: ""
  },
  evaluationObservQuestionnaire: {
    [eval_obv_Q_01.name]: "",
    [eval_obv_Q_02.name]: "",
    [eval_obv_Q_03.name]: "",
    [eval_obv_Q_04.name]: "",
    [eval_obv_Q_04_other.name]: "",
    [eval_obv_Q_05.name]: "",
    [eval_obv_Q_06.name]: "",
    [eval_obv_Q_07.name]: "",
    [eval_obv_Q_08.name]: "",
    [eval_obv_Q_09.name]: "",
    [eval_doc_description.name]: ""
  },
  behavioralObservation: {
    [behavioral_Q_01.name]: "",
    [behavioral_Q_02.name]: "",
    [behavioral_Q_03.name]: "",
    [behavioral_Q_04.name]: "",
    [behavioral_Q_05.name]: "",
    [behav_student_doc_description.name]: ""
  },
  oralPeripheralMechanism: {
    [opme_lips.name]: "",
    [opme_tongue.name]: "",
    [opme_jaw.name]: "",
    [opme_teeth.name]: "",
    [opme_additional_info.name]: "",
    [opme_doc_description.name]: ""
  },
  fluency: {
    [flu_student_describe.name]: "",
    [flu_student_experience.name]: "",
    [flu_student_report.name]: "",
    [flu_doc_description.name]: ""
  },
  voiceObservation: {
    [voice_obs_method]: "",
    [voice_obs_pitch]: "",
    [voice_obs_loudness]: "",
    [voice_obs_quality]: "",
    [voice_obs_resonance]: "",
    [voice_obs_overall_voice]: "",
    [voice_doc_description]: ""
  },
  articulation_Intelligibility: {
    [arti_skills]: "",
    [arti_sound_production]: "",
    [arti_intelligibility]: "",
    [arti_behav_observations]: "",
    [arti_impact_communication]: "",
    [arti_additional_concerns]: "",
    [arti_doc_description]: ""
  },
  pragmatics: {
    [pragmatics_Q_01]: "",
    [pragmatics_Q_02]: "",
    [pragmatics_Q_03]: "",
    [pragmatics_Q_04]: "",
    [pragmatics_Q_05]: "",
    [pragmatics_Q_06]: "",
    [pragmatics_Q_07]: "",
    [pragmatics_Q_08]: "",
    [prag_additional_concerns]: "",
    [prag_doc_description]: ""
  },
  reportSummary: {
    [report_summary_Q_01.name]: "",
    [report_summary_Q_01_other.name]: "",
    [report_summary_Q_02.name]: "",
    [report_summary_Q_02_other.name]: "",
    [report_summary_Q_03.name]: "",
    [report_summary_Q_03_other.name]: "",
    [report_summary_Q_04.name]: "",
    [report_summary_Q_04_other.name]: "",
    [report_summary_Q_05.name]: "",
    [report_summary_Q_06.name]: "",
    [report_summary_doc_description.name]: ""
  },
  eligibility: {
    [eligibility_Q_01.name]: "",
    [eligibility_Q_02.name]: [],
    [eligibility_Q_02_other.name]: '',
    [eligibility_Q_03.name]: "",
    [eligibility_Q_04.name]: "",
    [eligibility_Q_05.name]: "",
    [eligibility_Q_06.name]: "",
    [eligibility_Q_07.name]: "",
    [eligibility_Q_07_doc_description.name]: ""
  },
  recommendations: {
    [recomm_Q_01]: "",
    [recomm_Q_01_other]: "",
    [recomm_Q_02]: "",
    [recomm_doc_description]: ""
  },
  goals: {
    [goals.name]: [],
  },
  prevAssesment: {
    [prev_assesment_history.name]: "",
    [prev_assesment_history_other]: "",
    [prev_assesment_services.name]: []
  },
  normative_chart: {
    [normative_chart_default.name]: "",
    [normative_chart_uploaded.name]: ""
  },
  signature: {
    [sig_add_signature.name]: "",
    [sig_fullname.name]: "",
    [sig_position.name]: "",
    [sig_school_district_name.name]: "",
    [sig_date.name]: ""
  },
};

export default initialValues;
