import React, {useEffect} from "react";
import { Grid } from "@mui/material";
import FormField from "components/FormField";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalDropdowns } from "redux/actions/configAction";
import { getSchoolYearOptions } from "components/Common/getSchoolYearOptions";

const AddStudent = ({ formData }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const dispatch = useDispatch();

  const { global_dropdowns } = useSelector((state) => ({
    global_dropdowns: state.configReducer.global_dropdown_list,
  }));


  const {
    firstName,
    middleName,
    lastName,
    caseManager,
    dateOfBirth,
    gender,
    school,
    grade, //string
    eligibilityEvaluation, //date
    initialEvaluation, //date
    annualPlanReview, //date
    currentAcademicYear
  } = formField;
  const {
    firstName: firstNameV,
    middleName: middleNameV,
    lastName: lastNameV,
    caseManager: caseManagerV,
    gender: genderV,
    dateOfBirth: dateOfBirthV,
    currentAcademicYear: currentAcademicYearV,
    school: schoolV,
    grade: gradeV, // Add missing fields
    eligibilityEvaluation: eligibilityEvaluationV, // Add missing fields
    initialEvaluation: initialEvaluationV, // Add missing fields
    annualPlanReview: annualPlanReviewV, // Add missing fields

  } = values;

  const today = moment().format('YYYY-MM-DD');

  useEffect(() => {
    dispatch(getGlobalDropdowns());
  }, []); // Run only once on initial load


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} lg={4}>
       <FormField
          type={currentAcademicYear.type}
          inputType="select"
          label={currentAcademicYear.label}
          isRequired={true}
          name={currentAcademicYear.name}
          size="small"
          options={getSchoolYearOptions()} // Ensure this returns an array of objects
          onChange={(option) => setFieldValue('currentAcademicYear', option)}
          value={currentAcademicYearV}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={currentAcademicYear.placeholder}
          error={errors.currentAcademicYear && touched.currentAcademicYear}
          // success={locationV.length > 0 && !errors.location}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={8}>
      </Grid>
      <Grid item xs={12} sm={4} lg={4}>
        <FormField
          type={firstName.type}
          label={firstName.label}
          name={firstName.name}
          size="small"
          isRequired={true}
          value={firstNameV}
          placeholder={firstName.placeholder}
          error={errors.firstName && touched.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={4}>
        <FormField
          type={middleName.type}
          label={middleName.label}
          name={middleName.name}
          size="small"
          isRequired={false}
          value={middleNameV}
          placeholder={middleName.placeholder}
          error={errors.middleName && touched.middleName}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={4}>
        <FormField
          type={lastName.type}
          label={lastName.label}
          name={lastName.name}
          size="small"
          isRequired={true}
          value={lastNameV}
          placeholder={lastName.placeholder}
          error={errors.lastName && touched.lastName}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
      <FormField
          type={dateOfBirth.type}
          label={dateOfBirth.label}
          name={dateOfBirth.name}
          size="small"
          isRequired={false}
          onChange={(e) => {
            const date = moment(e.target.value).startOf('day').utc().toISOString();
            setFieldValue('dateOfBirth', date);
        }}
        inputProps={{
          max: today,
        }}
          value={moment(dateOfBirthV).format('YYYY-MM-DD')}
          placeholder={dateOfBirth.placeholder}
          error={errors.dateOfBirth && touched.dateOfBirth}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
      <FormField
          isClearable
          type={grade.type}
          inputType="select"
          label={grade.label}
          isRequired={true}
          name={grade.name}
          size="small"
          options={global_dropdowns.response?.GradeList}
          onChange={(option) => setFieldValue('grade', option)}
          value={gradeV}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={grade.placeholder}
          error={errors.grade && touched.grade}
          // success={locationV.length > 0 && !errors.location}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          isClearable
          type={gender.type}
          inputType="select"
          label={gender.label}
          isRequired={true}
          name={gender.name}
          size="small"
          options={global_dropdowns.response?.GenderList}
          onChange={(option) => setFieldValue('gender', option)}
          value={genderV}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          placeholder={gender.placeholder}
          error={errors.gender && touched.gender}
          // success={locationV.length > 0 && !errors.location}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <FormField
          type={caseManager.type}
          label={caseManager.label}
          name={caseManager.name}
          size="small"
          isRequired={true}
          value={caseManagerV}
          placeholder={caseManager.placeholder}
          error={errors.caseManager && touched.caseManager}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <FormField
          type={school.type}
          label={school.label}
          name={school.name}
          size="small"
          isRequired={true}
          value={schoolV}
          placeholder={school.placeholder}
          error={errors.school && touched.school}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <FormField
          type={annualPlanReview.type}
          label={annualPlanReview.label}
          name={annualPlanReview.name}
          size="small"
          isRequired={false}
          onChange={(e) => {
            const date = moment(e.target.value).startOf('day').utc().toISOString();
            setFieldValue('annualPlanReview', date);
        }}
          value={moment(annualPlanReviewV).format('YYYY-MM-DD')}
          placeholder={annualPlanReview.placeholder}
          error={errors.annualPlanReview && touched.annualPlanReview}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <FormField
          type={eligibilityEvaluation.type}
          label={eligibilityEvaluation.label}
          name={eligibilityEvaluation.name}
          size="small"
          isRequired={false}
          onChange={(e) => {
            const date = moment(e.target.value).startOf('day').utc().toISOString();
            setFieldValue('eligibilityEvaluation', date);
           }}
          value={moment(eligibilityEvaluationV).format('YYYY-MM-DD')}
          placeholder={eligibilityEvaluation.placeholder}
          error={errors.eligibilityEvaluation && touched.eligibilityEvaluation}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <FormField
          type={initialEvaluation.type}
          label={initialEvaluation.label}
          name={initialEvaluation.name}
          size="small"
          isRequired={false}
          value={moment(initialEvaluationV).format('YYYY-MM-DD')}
          onChange={(e) => {
            const date = moment(e.target.value).startOf('day').utc().toISOString();
            setFieldValue('initialEvaluation', date);
           }}
          placeholder={initialEvaluation.placeholder}
          error={errors.initialEvaluation && touched.initialEvaluation}
        />
      </Grid>
    </Grid>
  );
};

export default AddStudent;
