const voiceObservationForm = {
  voiceObservation: {
    voice_obs_method: {
      name: "voice_obs_method",
      label: "Observation Method",
      type: "text",
      placeholder: "Enter observation method",
      errorMsg: "Observation method is required.",
      invalidMsg: "Invalid input for observation method.",
      toolTipText: '',
      path: "voiceObservation.voice_obs_method",
    },
    voice_obs_pitch: {
      name: "voice_obs_pitch",
      label: "Pitch",
      type: "text",
      toolTipText: '',
      path: "voiceObservation.voice_obs_pitch",
      placeholder: "Enter pitch details",
      errorMsg: "Pitch details are required.",
      invalidMsg: "Invalid input for pitch details.",
    },
    voice_obs_loudness: {
      name: "voice_obs_loudness",
      label: "Loudness",
      type: "text",
      toolTipText: '',
      path: "voiceObservation.voice_obs_loudness",
      placeholder: "Enter loudness details",
      errorMsg: "Loudness details are required.",
      invalidMsg: "Invalid input for loudness details.",
    },
    voice_obs_quality: {
      name: "voice_obs_quality",
      label: "Quality",
      type: "text",
      toolTipText: '',
      path: "voiceObservation.voice_obs_quality",
      placeholder: "Enter quality details",
      errorMsg: "Quality details are required.",
      invalidMsg: "Invalid input for quality details.",
    },
    voice_obs_resonance: {
      name: "voice_obs_resonance",
      label: "Resonance",
      type: "text",
      toolTipText: '',
      path: "voiceObservation.voice_obs_resonance",
      placeholder: "Enter resonance details",
      errorMsg: "Resonance details are required.",
      invalidMsg: "Invalid input for resonance details.",
    },
    voice_obs_overall_voice: {
      name: "voice_obs_overall_voice",
      label: "Overall Voice",
      type: "text",
      toolTipText: '',
      path: "voiceObservation.voice_obs_overall_voice",
      placeholder: "Enter overall voice details",
      errorMsg: "Overall voice details are required.",
      invalidMsg: "Invalid input for overall voice details.",
    },
    voice_doc_description: {
      name: "voice_doc_description",
      label: "Additional Description",
      type: "text",
      path: "voiceObservation.voice_doc_description",
      placeholder: "Enter additional description",
      errorMsg: "Additional description is required.",
      invalidMsg: "Invalid input for additional description.",
    },
  },
};

export default voiceObservationForm;
