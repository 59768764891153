import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import FormField from "components/FormField";
import OptionButton from "../components/OptionButton";
import moment from "moment";

const ClassroomObservation = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        classroom_obs_date_time,
        classroom_obs_teacher_input_Q_01,
        classroom_obs_assistants_count,
        classroom_obs_Q_04,
        classroom_obs_Q_04_other,
        classroom_obs_Q_05,
        classroom_obs_Q_06,
        classroom_obs_Q_07,
        classroom_obs_Q_08,
        classroom_obs_Q_09,
        classroom_obs_Q_10,
        classroom_obs_Q_11,
        classroom_obs_Q_12,
        classroom_obs_Q_13,
        classroom_obs_Q_14,
        classroom_obs_Q_15,
        classroom_obs_Q_16,
        classroom_obs_Q_17,
        classroom_obs_Q_18,
        classroom_obs_Q_19,
        classroom_obs_Q_19_other,
        classroom_obs_Q_20,
        classroom_obs_Q_20_other,
        classroom_obs_Q_21,
        classroom_obs_Q_21_other,
        classroom_obs_Q_22,
        classroom_obs_Q_22_other,
        classroom_additional_info,
        classroom_doc_description
    } = formField.classroomObservation;

    const replaceStudentName = (label) => {
        const studentName = values?.generalInformation?.firstName || '[student info not-found]';
        return label.replace('STUDENT', studentName);
    };
    // Render the rich text for background information
    return (
        <Grid container spacing={2} p={2}>
            {/* <HeaderComp title="Education History" /> */}
            <Grid item xs={12} sm={6} lg={6}>
                <FormField
                    type={classroom_obs_date_time.type}
                    label={classroom_obs_date_time.label}
                    name={classroom_obs_date_time.name}
                    size="small"
                    onChange={(e) => {
                        const formattedDate = moment(e.target.value).utc().toISOString();
                        setFieldValue(classroom_obs_date_time.path, formattedDate);
                    }}
                    value={values.classroomObservation.classroom_obs_date_time
                        ? moment(values.classroomObservation.classroom_obs_date_time).format('YYYY-MM-DDTHH:mm')
                        : ""
                    }
                    placeholder={classroom_obs_date_time.placeholder}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField
                    type={classroom_obs_teacher_input_Q_01.type}
                    label={classroom_obs_teacher_input_Q_01.label}
                    name={classroom_obs_teacher_input_Q_01.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(classroom_obs_teacher_input_Q_01.path, value)}
                    value={values.classroomObservation.classroom_obs_teacher_input_Q_01}
                    placeholder={classroom_obs_teacher_input_Q_01.placeholder}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField
                    type={classroom_obs_assistants_count.type}
                    label={classroom_obs_assistants_count.label}
                    name={classroom_obs_assistants_count.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(classroom_obs_assistants_count.path, value)}
                    value={values.classroomObservation.classroom_obs_assistants_count}
                    placeholder={classroom_obs_assistants_count.placeholder}
                />
            </Grid>
            <OptionButton
                label={classroom_obs_Q_04.label}
                type="radio"
                row={false}
                name={classroom_obs_Q_04.name}
                options={props.options?.classroom_obs_Q_04}
                value={values.classroomObservation.classroom_obs_Q_04}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_04.path, value)}
                additionalInfoLabel={classroom_obs_Q_04_other.label}
                additionalInfoName={classroom_obs_Q_04_other.path}
                additionalInfoValue={values.classroomObservation.classroom_obs_Q_04_other}
                triggerValues={[6]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_04.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_04 && touched.classroomObservation?.classroom_obs_Q_04}
            />

            <Grid item xs={12} sm={6} lg={12}>
                <FormField
                    type={classroom_obs_Q_05.type}
                    label={classroom_obs_Q_05.label}
                    name={classroom_obs_Q_05.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(classroom_obs_Q_05.path, value)}
                    value={values.classroomObservation.classroom_obs_Q_05}
                    placeholder={classroom_obs_Q_05.placeholder}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField
                    type={classroom_obs_Q_06.type}
                    label={classroom_obs_Q_06.label}
                    name={classroom_obs_Q_06.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(classroom_obs_Q_06.path, value)}
                    value={values.classroomObservation.classroom_obs_Q_06}
                    placeholder={classroom_obs_Q_06.placeholder}
                />
            </Grid>
            <HeaderComp title={'Speech and Language Skills Observation'} />
            <OptionButton
                label={classroom_obs_Q_07.label}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_07.name}
                options={props.options?.classroom_obs_Q_07}
                value={values.classroomObservation.classroom_obs_Q_07}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_07.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_07.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_07 && touched.classroomObservation?.classroom_obs_Q_07}
            />
            <OptionButton
                label={classroom_obs_Q_08.label}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_08.name}
                options={props.options?.classroom_obs_Q_08}
                value={values.classroomObservation.classroom_obs_Q_08}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_08.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_08.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_08 && touched.classroomObservation?.classroom_obs_Q_08}
            />

            <OptionButton
                label={classroom_obs_Q_09.label}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_09.name}
                options={props.options?.classroom_obs_Q_09}
                value={values.classroomObservation.classroom_obs_Q_09}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_09.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_09.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_09 && touched.classroomObservation?.classroom_obs_Q_09}
            />

            <OptionButton
                label={classroom_obs_Q_10.label}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_10.name}
                options={props.options?.classroom_obs_Q_10}
                value={values.classroomObservation.classroom_obs_Q_10}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_10.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_10.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_10 && touched.classroomObservation?.classroom_obs_Q_10}
            />
            <OptionButton
                label={classroom_obs_Q_11.label}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_11.name}
                options={props.options?.classroom_obs_Q_11}
                value={values.classroomObservation.classroom_obs_Q_11}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_11.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_11.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_11 && touched.classroomObservation?.classroom_obs_Q_11}
            />
            <OptionButton
                label={classroom_obs_Q_12.label}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_12.name}
                options={props.options?.classroom_obs_Q_12}
                value={values.classroomObservation.classroom_obs_Q_12}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_12.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_12.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_12 && touched.classroomObservation?.classroom_obs_Q_12}
            />

            <OptionButton
                label={classroom_obs_Q_13.label}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_13.name}
                options={props.options?.classroom_obs_Q_13}
                value={values.classroomObservation.classroom_obs_Q_13}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_13.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_13.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_13 && touched.classroomObservation?.classroom_obs_Q_13}
            />
            <HeaderComp title={'Behavioral Observations'} />
            <OptionButton
                label={classroom_obs_Q_14.label}
                type="radio"
                row={false}
                name={classroom_obs_Q_14.name}
                options={props.options?.classroom_obs_default}
                value={values.classroomObservation.classroom_obs_Q_14}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_14.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_14.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_14 && touched.classroomObservation?.classroom_obs_Q_14}
            />
            <OptionButton
                label={classroom_obs_Q_15.label}
                type="radio"
                row={false}
                name={classroom_obs_Q_15.name}
                options={props.options?.classroom_obs_default}
                value={values.classroomObservation.classroom_obs_Q_15}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_15.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_15.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_15 && touched.classroomObservation?.classroom_obs_Q_15}
            />
            <OptionButton
                label={classroom_obs_Q_16.label}
                type="radio"
                row={false}
                name={classroom_obs_Q_16.name}
                options={props.options?.classroom_obs_default}
                value={values.classroomObservation.classroom_obs_Q_16}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_16.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_16.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_16 && touched.classroomObservation?.classroom_obs_Q_16}
            />
            <OptionButton
                label={replaceStudentName(classroom_obs_Q_17.label)}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_17.name}
                options={props.options?.classroom_obs_Q_17}
                value={values.classroomObservation.classroom_obs_Q_17}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_17.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_17.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_17 && touched.classroomObservation?.classroom_obs_Q_17}
            />
            <OptionButton
                label={classroom_obs_Q_18.label}
                type="checkbox"
                row={true}
                name={classroom_obs_Q_18.name}
                options={props.options?.classroom_obs_Q_18}
                value={values.classroomObservation.classroom_obs_Q_18}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_18.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_18.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_18 && touched.classroomObservation?.classroom_obs_Q_18}
            />
            <OptionButton
                label={replaceStudentName(classroom_obs_Q_19.label)}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_19.name}
                options={props.options?.classroom_obs_Q_19}
                value={values.classroomObservation.classroom_obs_Q_19}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_19.path, value)}
                additionalInfoLabel={classroom_obs_Q_19_other.label}
                additionalInfoName={classroom_obs_Q_19_other.path}
                additionalInfoValue={values.classroomObservation.classroom_obs_Q_19_other}
                triggerValues={[8]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_19.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_19 && touched.classroomObservation?.classroom_obs_Q_19}
            />
            <OptionButton
                label={classroom_obs_Q_20.label}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_20.name}
                options={props.options?.classroom_obs_Q_20}
                value={values.classroomObservation.classroom_obs_Q_20}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_20.path, value)}
                additionalInfoLabel={classroom_obs_Q_20_other.label}
                additionalInfoName={classroom_obs_Q_20_other.path}
                additionalInfoValue={values.classroomObservation.classroom_obs_Q_20_other}
                triggerValues={[6]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_20.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_20 && touched.classroomObservation?.classroom_obs_Q_20}
            />
            <OptionButton
                label={classroom_obs_Q_21.label}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_21.name}
                options={props.options?.classroom_obs_Q_21}
                value={values.classroomObservation.classroom_obs_Q_21}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_21.path, value)}
                additionalInfoLabel={classroom_obs_Q_21_other.label}
                additionalInfoName={classroom_obs_Q_21_other.path}
                additionalInfoValue={values.classroomObservation.classroom_obs_Q_21_other}
                triggerValues={[6]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_21.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_21 && touched.classroomObservation?.classroom_obs_Q_21}
            />
            <OptionButton
                label={classroom_obs_Q_22.label}
                type="checkbox"
                row={false}
                name={classroom_obs_Q_22.name}
                options={props.options?.classroom_obs_Q_22}
                value={values.classroomObservation.classroom_obs_Q_22}
                onChange={(name, value) => setFieldValue(classroom_obs_Q_22.path, value)}
                additionalInfoLabel={classroom_obs_Q_22_other.label}
                additionalInfoName={classroom_obs_Q_22_other.path}
                additionalInfoValue={values.classroomObservation.classroom_obs_Q_22_other}
                triggerValues={[6]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={classroom_obs_Q_22.toolTipText}
                error={errors.classroomObservation?.classroom_obs_Q_22 && touched.classroomObservation?.classroom_obs_Q_22}
            />
            <Grid item xs={12} sm={6} lg={12}>
                <FormField
                    type={classroom_additional_info.type}
                    label={classroom_additional_info.label}
                    name={classroom_additional_info.name}
                    size="small"
                    multiline
                    rows={2}
                    onChange={(name, value) => setFieldValue(classroom_additional_info.path, value)}
                    value={values.classroomObservation.classroom_additional_info}
                    placeholder={classroom_additional_info.placeholder}
                />
            </Grid>
        </Grid>
    );
};

const HeaderComp = ({ title }) => {
    return (
        <Grid item md={12} sm={12} xs={12}>
            <Divider textAlign="left">
                <Typography variant="overline" color="text.secondary" fontWeight={800} fontSize={14}>
                    {title}
                </Typography>
            </Divider>
        </Grid>
    );
};
export default ClassroomObservation;
