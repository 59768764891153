import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    firstName,
    middleName,
    lastName,
    caseManager,
    dateOfBirth,
    gender,
    eligibilityEvaluation,
    initialEvaluation,
    annualPlanReview,
    grade,
    school,
    currentAcademicYear,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [middleName.name]: Yup.string(),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [caseManager.name]: Yup.string().required(caseManager.errorMsg),
    [gender.name]: Yup.object().required(gender.errorMsg),
    [grade.name]: Yup.object().required(grade.errorMsg),
    [caseManager.name]: Yup.string().required(caseManager.errorMsg),
    [dateOfBirth.name]: Yup.date().required(dateOfBirth.errorMsg),
    [eligibilityEvaluation.name]: Yup.date(),
    [initialEvaluation.name]: Yup.date(),
    [annualPlanReview.name]: Yup.date(),
    [school.name]: Yup.string().required(school.errorMsg),
    [currentAcademicYear.name]: Yup.object().required(currentAcademicYear.errorMsg)
  }),
];

export default validations;
