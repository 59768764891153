const prevAssesmentForm = {
    prevAssesment: {
      prev_assesment_history: {
        name: "prev_assesment_history",
        path: "prevAssesment.prev_assesment_history",
        label: "Does STUDENT have any previous evaluations and services?",
        type: "text",
        placeholder: "Enter communication skills",
        errorMsg: "Communication skills are required.",
        invalidMsg: "Invalid input for communication skills.",
        toolTipText: 'TODO'
      },
      prev_assesment_history_other: {
        name: "prev_assesment_history_other",
        label: "If Other, Specify",
        type: "text",
        placeholder: "Please specify",
        path: "prevAssesment.prev_assesment_history_other",
        errorMsg: "Specification details are required.",
        invalidMsg: "Invalid input for specification details.",
        toolTipText: 'TODO'
      },
      prev_assesment_services: {
        name: "prev_assesment_services",
        label: "Services provided",
        type: "text",
        placeholder: "Enter services details",
        errorMsg: "Services details are required.",
        path: "prevAssesment.prev_assesment_services",
        invalidMsg: "Invalid input for services details.",
      }
    }
  };
  
  export default prevAssesmentForm;
  