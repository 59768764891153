// commonStyles.js
import { StyleSheet, Font } from '@react-pdf/renderer';

// Register fonts
Font.register({
  family: 'Open Sans',
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: 'Lato',
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: 'Lato Italic',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};


Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

  // timesRomanText: {
  //   fontWeight: 'heavy',
  //   marginTop: 10,
  //   fontSize: 16,

  // },
  // regular: {
  //   fontFamily: 'Lato Bold',
  //   fontWeight: 400,
  // },
  // medium: {
  //   fontFamily: 'Lato',
  //   fontWeight: 500,
  // },
  // bold: {
  //   fontWeight: 600,
  //   fontFamily: 'Helvetica-Oblique',
  // },
  // black: {
  //   fontWeight: 900,
  //   fontFamily: 'Helvetica',
  // }
// Define common styles
const commonStyles = StyleSheet.create({
  page: {
    fontSize: 12,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    flexDirection: 'column',
    fontFamily: 'Lato',
    // lineHeight: 1.9,
  },
  headerContainer: {
    flexDirection: 'row',
    marginTop: 24,
    alignItems: 'center',
    marginBottom: 30
  },
  headerBox: {
    flexDirection: 'column',
    textAlign: 'right', // Align text to the right
    flex: 1, // Take up the remaining space
  },
  header: {
    letterSpacing: 0,
    fontSize: 14,
    fontWeight: 'heavy',
    fontFamily: 'Lato Bold',
    textTransform: 'uppercase',
},
  title: {
    fontFamily: 'Open Sans',
    fontSize: 24,
    marginBottom: 10,
  },
  subTitle: {
    letterSpacing: 0,
    marginTop: 5,
    marginBottom: 7,
    textDecoration: 'underline',
    fontSize: 12,
    fontWeight: 'heavy',
    fontFamily: 'Lato Bold',
    textTransform: 'uppercase',
  },
  body: {
    fontWeight: 'light',
    fontFamily: 'Lato',
    lineHeight: 1.7,
  },
  list: {
    fontWeight: 'light',
    fontFamily: 'Lato',
    lineHeight: 1.7,
    marginLeft: 10
  },
  box: {
    marginTop: 10,
    flexDirection: 'column',
    textAlign: 'left',
  },
  signatureImage: {
    marginTop: 1,
    width: 150,
    height: 100,
    objectFit: 'contain',
  },
  signatureText: {
    marginTop: 1,
    fontSize: 12,
    fontFamily: 'Lato',
},
footerText: {
    marginTop: 5,
    fontSize: 10,
    fontFamily: 'Lato Italic',
    textAlign: 'left',
    color: '#888',
},
logo: {
  width: 54,
  height: 'auto',
  marginRight: 10, // Add some space between the logo and the text
}
});

export default commonStyles;
