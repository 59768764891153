import { combineReducers } from "redux";
import studentReducer from "./studentReducer";
import notificationReducer from "./notificationReducer";
import authReducer from "./authReducer";
import schedulerReducer from "./schedulerReducer";
import meetingInviteReducer from "./meetingInviteReducer";
import subscriptionReducer from "./subscriptionReducer";
import reportReducer from "./reportReducer";
import configReducer from "./configReducer";
import dataTrackingReducer from "./dataTrackingReducer";
import inviteAssistantReducer from "./inviteAssistantReducer";
export default combineReducers({
  authReducer,
  notificationReducer,
  studentReducer,
  schedulerReducer,
  meetingInviteReducer,
  subscriptionReducer,
  reportReducer,
  configReducer,
  dataTrackingReducer,
  inviteAssistantReducer
});
