import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { subscriptionCheckoutSession } from 'redux/actions/subscriptionAction';
import Box from '@mui/material/Box';
import { Button, ButtonGroup, Container, Grid, Card, CardContent, CardActions, Chip, Divider, Typography } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import theme from "theme";
import { ThemeProvider } from "@mui/material";
import logo from "../../../assets/logo-box.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";


const SubscriptionPage = () => {
    const [activeButton, setActiveButton] = useState(2); // Default to "12 Months"
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleButtonClick = (index) => {
        console.log('index', index)
        setActiveButton(index);
    };
    const { user } = useAuth0();

    const tiers = [
        {
            title: 'Basic',
            price: activeButton === 0 ? 15 : activeButton === 1 ? 13 : 10,
            description: [
                'Caseload - Limited',
                'Schedule Sessions',
                'Data Tracking',
                'Single Sign-On (SSO)',
                "Safety & Privacy",
            ],
            buttonText: activeButton === 0 ? '7 Days Free' : activeButton === 1 ? '7 Days Free' : '7 Days Free',
            buttonVariant: 'outlined',
        },
        {
            title: 'Standard',
            subheader: 'Recommended',
            price: activeButton === 0 ? 30 : activeButton === 1 ? 28 : 25,
            description: [
                "Caseload - Unlimited, Import Caseload",
                "Data Tracking - Generate Report",
                "AI Goal Builder",
                "AI Session Activity",
                'Scheduling Links',
                'Sync Calendar - Google & Outlook',
                "Everything from Basic",
            ],
            buttonText: activeButton === 0 ? '14 Days Free' : activeButton === 1 ? '14 Days Free' : '14 Days Free',
            buttonVariant: 'contained',
        },
        {
            title: 'Premium',
            price: activeButton === 0 ? 40 : activeButton === 1 ? 38 : 35,
            description: [
                "Speech & Language Evaluation Report",
                "Custom Document Header",
                "Electronic signature",
                "Library of test templates",
                "Everything from Standard",
            ],
            buttonText: activeButton === 0 ? '30 Days Free' : activeButton === 1 ? '30 Days Free' : '30 Days Free',
            buttonVariant: 'outlined',
        },
    ];


    async function handleOpen(plan) {
        const selectedDuration = ['3', '6', '12'][activeButton];
        const selectedPlan = `${plan.toLowerCase()}_${selectedDuration}_plan`;
        console.log('selectedPlan', selectedPlan)
        const payload = { subscriptionType: selectedPlan, email: user.email, customer_id: user.stripe_customer_id };
        const response = await dispatch(subscriptionCheckoutSession(payload));
        const checkoutSessionId = response.session.url;
        window.location.href = checkoutSessionId;
    }


    // useEffect(() => {
    //     const navigateUser = () => {
    //         if (user) {
    //             if (user.subscription_status || user.subscription_status !== 'canceled') {
    //                 navigate('/dashboard');
    //             }
    //         }
    //     };

    //     navigateUser();
    // }, [user, navigate]);



    return (
        <ThemeProvider theme={theme}>
            <Container
                id="pricing"
                sx={{
                    pt: { xs: 4, sm: 4 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 3 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'center', md: 'center', xs: 'center' },
                    }}
                >
                    <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
                        <img src={logo} alt="Logo" style={{ width: "5rem" }} />
                    </Typography>
                    <Typography component="h2" variant="h2" color="text.secondary" mb={1}>
                        Choose Your Plan
                    </Typography>
                    <ButtonGroup variant='outlined' aria-label="Basic button group"
                    >
                        {['3 Months', '6 Months', '12 Months'].map((label, index) => (
                            <Button
                                key={label}
                                variant={activeButton === index ? 'contained' : 'outlined'}
                                sx={{
                                    padding: '5px 15px',
                                    height: '40px',
                                    borderRadius: '10px',
                                    border: '1px solid rgb(162, 155, 245)',
                                    color: activeButton === index ? '' : 'rgb(115, 115, 246)',
                                }}
                                style={{ border: "1px solid rgb(162, 155, 245)", background: activeButton === index ? '#7373f6' : "rgba(162, 155, 245, 0.1)" }}
                                onClick={() => handleButtonClick(index)}
                            >
                                {label}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Box>
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                    {tiers.map((tier) => (
                        <Grid
                            item
                            key={tier.title}
                            xs={12}
                            sm={6}
                            md={4}
                        >
                            <Card
                                sx={{
                                    p: 1,
                                    boxShadow: "0 5px 6px -3px rgba(23,29,38,.06), 0 9px 12px 1px rgba(23,29,38,.03), 0 3px 16px 2px rgba(23,29,38,.05)",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                    border: "1px solid rgba(24,29,37,.05)",
                                    background: tier.title === 'Standard' ? 'linear-gradient(rgb(37, 37, 84), rgb(5, 9, 10))' : '#fff',
                                }}
                            >
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            color: tier.title === 'Standard' ? '#fff' : '',
                                        }}
                                    >
                                        <Typography variant="h3" color={tier.title === 'Standard' ? 'grey.100' : ''}>
                                            {tier.title}
                                        </Typography>
                                        {tier.title === 'Standard' && (
                                            <Chip
                                                icon={<AutoAwesomeIcon style={{ color: 'rgb(49, 40, 192)' }} />}
                                                label={tier.subheader}
                                                size="small"
                                                sx={{ background: 'linear-gradient(to right bottom, rgb(233, 231, 252), rgb(210, 206, 250)) rgb(233, 231, 252);', color: 'rgb(49, 40, 192)' }}
                                            />
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            color: tier.title === 'Standard' ? 'grey.50' : undefined,
                                        }}
                                    >
                                        <Typography variant="h2" fontSize={34}>
                                            ${tier.price}
                                        </Typography>
                                        <Typography component="h3" variant="subtitle2" fontWeight={300}>
                                            &nbsp; per month
                                        </Typography>
                                    </Box>


                                    <Divider
                                        sx={{
                                            my: 1,
                                            opacity: 0.2,
                                            borderColor: 'grey.500',
                                        }}
                                    />
                                    {tier.description.map((line) => (
                                        <Box
                                            key={line}
                                            sx={{
                                                py: 1,
                                                display: 'flex',
                                                gap: 1.5,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CheckCircleRoundedIcon
                                                sx={{
                                                    width: 20,
                                                    color: tier.title === 'Standard' ? 'grey.100' : 'grey.300',
                                                }}
                                            />
                                            <Typography variant="subtitle1" sx={{ color: tier.title === 'Standard' ? 'lightGrey' : undefined }}>
                                                {line}
                                            </Typography>
                                        </Box>
                                    ))}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        variant={tier.buttonVariant}
                                        sx={{
                                            background: tier.title === 'Standard' ? '#7373f6' : 'rgba(162, 155, 245, 0.1)',
                                            border: tier.title === 'Premium' ? '' : '1px solid rgb(162, 155, 245)',
                                            padding: '5px 15px',
                                            height: '40px',
                                            borderRadius: '10px',
                                            fontWeight: 500,
                                            fontSize: '0.975rem',
                                            color: tier.title === 'Standard' ? '' : 'rgb(115, 115, 246)',
                                            '&:hover': {
                                                backgroundColor: tier.title === 'Standard' ? 'rgba(162, 155, 245, 0.3)' : 'rgba(162, 155, 245, 0.3)',
                                                borderColor: tier.title === 'Standard' ? '' : 'rgb(186, 181, 248)'
                                            }
                                        }}

                                        onClick={() => handleOpen(tier.title)}
                                    >
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

export default SubscriptionPage;
