import React from "react";
import { Box, Paper, Typography, Stack, Divider, Chip } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import moment from "moment";
import { grey } from "@mui/material/colors";


const ViewDataTrackingTable = ({ studentName, data }) => {
  const calculatePercentage = (results) => {
    if (results.length === 0) {
      return "No Data";
    }
    const trueCount = results.filter((result) => result === true).length;
    return `${(trueCount / results.length) * 100}%`;
  };


  const Label = ({ title, fontWeight, fontSize = 14 }) => {
    return (
      <Typography
        variant="overline"
        fontWeight={fontWeight}
        color={grey[700]}
        fontSize={fontSize}
      >
        {title}
      </Typography>
    );
  };
  return (
    <Box mt={2}>
      <Box>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={3}
        >
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Label fontWeight="500" title="Student Name" />
            <Typography variant="h4">{studentName}</Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Label fontWeight="500" title="Session Date" />

            <Typography variant="h4">
              {moment(data.startTime).format('ll')}
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Label fontWeight="500" title="Start Time - End Time" />

            <Typography variant="h4">
              {moment(data.startTime).format("LT")} - {moment(data.endTime).format("LT")}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box mt={2} mb={2}>
        {/* <Divider /> */}
        {data.goals.map((goal, index) => (
          <Stack key={goal._id} spacing={2} mb={2} mt={2}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Box mt={2}>
                <Label fontWeight="700" title={`Goal ${index + 1}`} />
                <Typography
                  fontSize={16}
                  component="div"
                >{goal.name} </Typography>
              </Box>
              <Box mt={2}>
                <Label fontWeight="700" title={'Result'} />
              </Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                {goal.results.map((result, index) => (
                  <Chip
                    key={index}
                    icon={
                      result ? (
                        <CheckCircle style={{ color: "#18b03e" }} />
                      ) : (
                        <Cancel style={{ color: "#f25656" }} />
                      )
                    }
                    label={result ? "Correct" : "Wrong"}
                    style={{ marginRight: 10, marginTop: 10, borderRadius: 5 }}
                  // variant="outlined"
                  />
                ))}
              </div>
              <Box mt={2}>
                <Stack
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={3}
                >
                  <Label fontWeight="700" title={` Score : ${calculatePercentage(goal.results)}`} fontSize={22} />
                  <Label fontWeight="700" title={` Total : ${goal.results.length}`} fontSize={22} />
                </Stack>
                <Box mt={2}>
                  <Label fontWeight="700" title={'Notes'} />
                  <Typography
                    fontSize={16}
                    dangerouslySetInnerHTML={{ __html: goal.notes }}
                  />
                </Box>
              </Box>
            </Paper>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default ViewDataTrackingTable;
