import React from "react";
import { Grid, Typography } from "@mui/material";
import ContentLayer from "components/ContentLayer";
import "./calendar.css";
import ReviewCard from "./ReviewCard";
import AgendaCard from "./AgendaCard";
import { useAuth0 } from '@auth0/auth0-react';



const Dashboard = () => {

  const { user } = useAuth0();

  function Greeting() {
    const date = new Date();
    const hour = date.getHours();
    let greeting = "";
  
    if (hour >= 5 && hour < 12) {
      greeting = "Good Morning";
    } else if (hour >= 12 && hour < 17) {
      greeting = "Good Afternoon";
    } else if (hour >= 17 && hour < 22) {
      greeting = "Good Evening";
    } else {
      greeting = "Night Owl";
    }

    let name = ""

      if(user.given_name) {
        name = user.given_name;
      } else if (user.nickname) {
        name = user.nickname
      } else {
        name = ''
      }

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h2" p={1} textAlign="center" textTransform={'capitalize'}>{`${greeting}, ${name}!`}</Typography>
      </Grid>
    );
  }

  return (
    <ContentLayer>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          {Greeting()}
        </Grid>

        <Grid item xs={12} md={12} lg={8}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <ReviewCard />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <AgendaCard />
        </Grid>
      </Grid>
    </ContentLayer>
  );
};

export default Dashboard;
