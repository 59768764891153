import React, { useEffect } from "react";
import { Container, Card, Button, Divider, Typography, Link, Box, Stack } from "@mui/material";
// import { Google, Microsoft } from '@mui/icons-material';
import logo from "../../../assets/logo-box.svg";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// Custom styles
import googleIcon from "../../../assets/google-icon.png";
import microsoftIcon from "../../../assets/icons/microsoft.png";
import { useDispatch } from "react-redux";
import { googleSSOAuth } from "redux/actions/authAction.js";
import { showNotification } from "redux/actions/notificationAction";
import { ERROR } from "../../../redux/constants/notificationType";

// app.get("/v1/google/callback", handleSSOCallback);
// app.get("/v1/auth", initiateSSOAuth);

const Background = styled("div")({
  background: "linear-gradient(rgb(23, 29, 38), rgb(6, 1, 1))",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  "@media (max-width: 960px)": {
    minHeight: "100vh",
  },
  "@media (max-width: 600px)": {
    minHeight: "100vh",
  },
});

const ContainerCentered = styled(Container)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});



const FullWidthButton = styled(Button)({
  width: "100%",
  marginTop: 15,
  background: "",
});

const AuthBox = ({ children, showSS0, maxWidth = 500, padding = '5%', onboarding }) => {

  const StyledCard = styled(Card)({
    maxWidth: maxWidth,
    borderRadius: 10,
    padding: padding,
    borderColor: "lightGrey",
    backgroundColor: "#ffffff",
    margin: "0 auto", // Center the card horizontally on all screens

    "@media (max-width: 960px)": {
      maxWidth: "90%",
      margin: "10px auto", // Center the card horizontally and add margin on medium screens
    },
    "@media (max-width: 600px)": {
      maxWidth: "95%",
      margin: "10px auto", // Center the card horizontally and add margin on small screens
    },
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const switchToSignUp = () => {
    navigate("/register");
  };

  const switchToSignIn = () => {
    navigate("/login");
  };

  const switchToForgotPassword = () => {
    navigate("/forgot-password");
  };

  function loadLabel() {
    switch (location.pathname) {
      case "/register":
        return "Sign up";
      default:
        return "Sign in";
    }
  }
  // ${process.env.CLIENT_ORIGIN}/login?error=email_already_registered`);


  function loadFooter() {
    switch (location.pathname) {
      case "/register":
        return (
          <Typography component="p" variant="body2">
            Already have an account?{" "}
            <Typography
              variant={"body2"}
              component={Link}
              onClick={switchToSignIn}
              to="/signin"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              Sign In
            </Typography>
          </Typography>
        );
      case "/forgot-password":
        return (
          <Typography
            variant="body1"
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={switchToSignIn}
          >
            <KeyboardBackspaceIcon size="medium" sx={{ mr: 1 }} />
            Back
          </Typography>
        );
      case "/login":
        return (
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box>
              <Typography component="p" variant="body2">
                Don’t have an account?{" "}
                <Typography
                  variant={"body2"}
                  component={Link}
                  onClick={switchToSignUp}
                  to="/signup"
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  Sign up
                </Typography>
              </Typography>
            </Box>

            <Box>
              <Typography component="p" variant="body2">
                <Typography
                  variant={"body2"}
                  component={Link}
                  to="/forgot-password"
                  onClick={switchToForgotPassword}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                >
                  Forgot Password ?
                </Typography>
              </Typography>
            </Box>
          </Stack>
        );
      default:
        return null;
    }
  }

  const handAuth = () => {
    dispatch(googleSSOAuth()).then((response) => {
      window.location.href = response;
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');

    if (error === 'email_already_registered') {


      showNotification({
        message: "Email address already registered!",
        type: ERROR,
      });
    }
  }, []);


  return (
    <Background>
      <ContainerCentered maxWidth="lg">
        <StyledCard variant="outlined">
          <Typography variant="h6" component="div" sx={{ textAlign: "left" }}>
            <img src={logo} alt="Logo" style={{ width: "5rem", marginLeft: "-12px" }} />
          </Typography>

          {children}
          {showSS0 && (
            <>
              <Divider sx={{ marginY: 2 }}>OR</Divider>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FullWidthButton
                  variant="outlined"
                  size="large"
                  onClick={handAuth}
                  sx={{
                    borderColor: "lightgray",
                    "&:hover": {
                      borderColor: "lightgray",
                    },
                  }}
                >
                  <img
                    src={googleIcon}
                    alt="google-icon"
                    style={{ width: "22px", marginRight: "8px" }}
                  />
                  Google {loadLabel()}
                </FullWidthButton>
                {/* <FullWidthButton
                  variant="outlined"
                  size="large"
                  sx={{
                    borderColor: "lightgray",
                    "&:hover": {
                      borderColor: "lightgray",
                    },
                  }}
                >
                  <img
                    src={microsoftIcon}
                    alt="outlook-icon"
                    style={{ width: "18px", marginRight: "8px" }}
                  />
                  Microsoft {loadLabel()}
                </FullWidthButton> */}
              </Stack>
            </>
          )}
          <Box mt={2}>{loadFooter()}</Box>
        </StyledCard>
      </ContainerCentered>
    </Background>
  );
};
export default AuthBox;
