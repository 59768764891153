import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Tabs,
  Tab,
  Accordion,
  useMediaQuery,
  Tooltip,
  AccordionSummary,
  Chip,
  AccordionDetails,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SMessageCard from "components/SMessageCard";
import { fetchAllStudents } from "redux/actions/studentActions";
import { useDispatch } from "react-redux";
import DateAndTimeConvert from "components/Common/DateAndTimeConvert";
import SLoadingIndicator from "components/SLoadingIndicator";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import AdjustIcon from "@mui/icons-material/Adjust";
import { useNavigate } from "react-router-dom";
import Ok from "../../../assets/icons/ok.png";
import { getCurrentSchoolYear } from "components/Common/getSchoolYearOptions";
import moment from "moment";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ReviewCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [caseload, setCaseload] = useState([]);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [schoolYear, setSchoolYear] = useState(getCurrentSchoolYear()); // Function to get the current school year

  const isMobile = useMediaQuery("(max-width:600px)");

  function loadCaseLoad() {
    setLoading(true);
  
    // Call the API and get student data
    dispatch(fetchAllStudents(schoolYear)).then((response) => {
      if (response && !response.success) {
        setLoading(false);
        return;
      }
    
      if (response && response.success) {
        const today = new Date();
        const todayUtc = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
    
        const records = response?.response.reduce((acc, item) => {
          const annualPlanReviewDate = item.annualPlanReview ? new Date(item.annualPlanReview) : null;
          const eligibilityEvaluationDate = item.eligibilityEvaluation ? new Date(item.eligibilityEvaluation) : null;
          const initialEvaluationDate = item.initialEvaluation ? new Date(item.initialEvaluation) : null;
    
          // Process annualPlanReview
          if (annualPlanReviewDate) {
            if (annualPlanReviewDate.toDateString() === todayUtc.toDateString()) {
              acc.annualPlanReview.today.push({ item, date: annualPlanReviewDate });
            } else if (annualPlanReviewDate < todayUtc) {
              acc.annualPlanReview.overdue.push({ item, date: annualPlanReviewDate });
            } else if (annualPlanReviewDate <= addDays(todayUtc, 60)) {
              acc.annualPlanReview.inNext60Days.push({ item, date: annualPlanReviewDate });
            }
          }
    
          // Process eligibilityEvaluation
          if (eligibilityEvaluationDate) {
            if (eligibilityEvaluationDate.toDateString() === todayUtc.toDateString()) {
              acc.eligibilityEvaluation.today.push({ item, date: eligibilityEvaluationDate });
            } else if (eligibilityEvaluationDate < todayUtc) {
              acc.eligibilityEvaluation.overdue.push({ item, date: eligibilityEvaluationDate });
            } else if (eligibilityEvaluationDate <= addDays(todayUtc, 60)) {
              acc.eligibilityEvaluation.inNext60Days.push({ item, date: eligibilityEvaluationDate });
            }
          }
    
          // Process initialEvaluation
          if (initialEvaluationDate) {
            if (initialEvaluationDate.toDateString() === todayUtc.toDateString()) {
              acc.initialEvaluation.today.push({ item, date: initialEvaluationDate });
            } else if (initialEvaluationDate < todayUtc) {
              acc.initialEvaluation.overdue.push({ item, date: initialEvaluationDate });
            } else if (initialEvaluationDate <= addDays(todayUtc, 60)) {
              acc.initialEvaluation.inNext60Days.push({ item, date: initialEvaluationDate });
            }
          }
    
          return acc;
        }, {
          annualPlanReview: { today: [], overdue: [], inNext60Days: [] },
          eligibilityEvaluation: { today: [], overdue: [], inNext60Days: [] },
          initialEvaluation: { today: [], overdue: [], inNext60Days: [] },
        });
    
        // Sort arrays by date
        const sortByDate = (arr) => arr.sort((a, b) => a.date - b.date);
    
        setCaseload({
          annualPlanReview: {
            today: sortByDate(records.annualPlanReview.today).map(entry => entry.item),
            overdue: sortByDate(records.annualPlanReview.overdue).map(entry => entry.item),
            inNext60Days: sortByDate(records.annualPlanReview.inNext60Days).map(entry => entry.item),
          },
          eligibilityEvaluation: {
            today: sortByDate(records.eligibilityEvaluation.today).map(entry => entry.item),
            overdue: sortByDate(records.eligibilityEvaluation.overdue).map(entry => entry.item),
            inNext60Days: sortByDate(records.eligibilityEvaluation.inNext60Days).map(entry => entry.item),
          },
          initialEvaluation: {
            today: sortByDate(records.initialEvaluation.today).map(entry => entry.item),
            overdue: sortByDate(records.initialEvaluation.overdue).map(entry => entry.item),
            inNext60Days: sortByDate(records.initialEvaluation.inNext60Days).map(entry => entry.item),
          },
        });
    
        console.log(records);
        setLoading(false);
      }
    });
    
  }
  

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  useEffect(() => {
    loadCaseLoad();
  }, []);

  const viewStudent = (studentId) => {
    console.log("studentId", studentId);
    navigate(`/student/${studentId}`);
  };

  return (
    <Card
      sx={{
        boxShadow:
          "0 5px 6px -3px rgba(23,29,38,.06), 0 9px 12px 1px rgba(23,29,38,.03), 0 3px 16px 2px rgba(23,29,38,.05)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
        // gap: "32px",
        flex: "1 1 auto",
        // width: 0,
        background: "#fff",
        borderRadius: "12px",
        border: "1px solid rgba(24,29,37,.05)",
        position: "relative",
      }}
    >
      <CardHeader
        title={
          <Typography variant="h4" letterSpacing={0.3}>
            Upcoming Assesments
          </Typography>
        }
        action={
          <Tooltip title="Full Screen" arrow>
            <IconButton>
              <FullscreenRoundedIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        }
        sx={{ "& .MuiCardHeader-content": { m: 0 }, padding: 2 }}
      />
      <CardContent sx={{ "&:last-child": { pb: 1 } }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", borderColor: "#f1f1f1" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Annual Plan Review" style={{ fontWeight: 500 }} {...a11yProps(0)} />
            <Tab
              label="Next Eligibility Evaluation"
              style={{ fontWeight: 500 }}
              {...a11yProps(1)}
            />
            <Tab label="Initial Evaluation" style={{ fontWeight: 500 }} {...a11yProps(2)} />
          </Tabs>
        </Box>
        {["annualPlanReview", "eligibilityEvaluation", "initialEvaluation"].map(
          (tab, index) => (
            <TabPanel value={value} index={index} key={tab}>
              {loading ? (
                <SLoadingIndicator height="30vh" />
              ) : (
                caseload[tab] &&
                Object.keys(caseload[tab]).map((category) => (
                  <Accordion
                    key={category}
                    elevation={0}
                    defaultExpanded={category === "today"}
                    disableGutters
                    sx={{
                      background: "#fafafa",
                      border: "1px solid #f1f1f1", // Set border color
                      "&:not(:last-child)": {
                        borderBottom: 0,
                      },
                      "&::before": {
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <IconButton>
                          <ExpandMoreIcon />
                        </IconButton>
                      }
                      aria-controls={`panel${category}a-content`}
                      id={`panel${category}a-header`}
                    >
                      <Chip
                        size="small"
                        icon={<AdjustIcon style={{ color: "#ffffff" }} />}
                        sx={{
                          borderRadius: 0.5,
                          backgroundColor:
                            category === "overdue"
                              ? "#d33d44"
                              : category === "today"
                              ? "#5163f4"
                              : category === "inNext60Days"
                              ? "#1390e0"
                              : "inherit",
                        }}
                        label={
                          <Typography variant="body2" color="#fff">
                            {category === "today"
                              ? "Today"
                              : category === "overdue"
                              ? "Over Due"
                              : category === "inNext60Days"
                              ? "In Next 60 Days"
                              : ""}
                            {""} ({caseload[tab][category].length})
                          </Typography>
                        }
                      />
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: "#fff" }}>
                      <Box sx={{ maxHeight: 300, overflow: "auto" }}>
                        {caseload[tab][category].length > 0 ? (
                          isMobile ? (
                            <>
                              <List>
                                {caseload[tab][category].map((record, index) => (
                                  <ListItem key={index} disableGutters>
                                    <ListItemText
                                      primary={`${record.firstName} ${record.lastName}`}
                                      secondary={
                                        <>
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="span"
                                          >
                                            School: {record.school}
                                          </Typography>
                                          <br />
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="span"
                                          >
                                            Due Date:{" "}
                                            {tab === "annualPlanReview" &&
                                            record.annualPlanReview ? (
                                              <DateAndTimeConvert
                                                timestamp={record.annualPlanReview}
                                              />
                                            ) : tab === "eligibilityEvaluation" &&
                                              record.eligibilityEvaluation ? (
                                              <DateAndTimeConvert
                                                timestamp={record.eligibilityEvaluation}
                                              />
                                            ) : null}
                                          </Typography>
                                        </>
                                      }
                                    />
                                    <ListItemSecondaryAction>
                                      <IconButton
                                        aria-label="view"
                                        onClick={() => viewStudent(record._id)}
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                ))}
                              </List>
                            </>
                          ) : (
                            <Table stickyHeader size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ fontWeight: 400, color: "#888888" }}>
                                    Student Name
                                  </TableCell>
                                  <TableCell style={{ fontWeight: 400, color: "#888888" }}>
                                    Due Date
                                  </TableCell>
                                  <TableCell style={{ fontWeight: 400, color: "#888888" }}>
                                    School
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ fontWeight: 400, color: "#888888" }}
                                  >
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {caseload[tab][category].map((record, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{ fontWeight: 500 }}
                                    >
                                      {record.firstName} {record.lastName}
                                    </TableCell>
                                    <TableCell>
                                      {tab === "annualPlanReview" &&
                                      record.annualPlanReview ? (
                     
                                        moment(record.annualPlanReview).format('ll')

                                      ) : tab === "eligibilityEvaluation" &&
                                        record.eligibilityEvaluation ? (
                                          moment(record.eligibilityEvaluation).format('LL')
                                        // <DateAndTimeConvert
                                        //   timestamp={record.eligibilityEvaluation}
                                        // />
                                      ) : null}
                                    </TableCell>
                                    <TableCell>{record?.currentAcademicYear?.school}</TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        aria-label="view"
                                        onClick={() => viewStudent(record._id)}
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          )
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <SMessageCard
                              imgUrl={Ok}
                              // icon={<HourglassEmptyRounded />}
                              subText={"Good Job! No Pending Assesments"}
                            />
                          </Box>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))
              )}
            </TabPanel>
          )
        )}
      </CardContent>
    </Card>
  );
}

export default ReviewCard;
