import { authRequest } from "./request";
import * as Actions from "../constants/constants";

export function updateDataTracking(payload) {
  return (dispatch) => {
    return authRequest(`dataTracking/update-student-dataTracking`, "POST", payload)
      .then((response) => {
        dispatch({
          type: Actions.UPDATE_DATA_TRACKING,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}
