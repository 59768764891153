import React from "react";
import { Grid, Typography, IconButton, Paper, Stack, Button, Box } from "@mui/material";
import FormField from "components/FormField";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from "@mui/icons-material";
import OptionButton from "../components/OptionButton";
import moment from "moment";

const PrevAssesment = (props) => {

    const { formField, values, errors, touched, setFieldValue } = props.formData;
    const {
        prev_assesment_history,
        prev_assesment_history_other,
        prev_assesment_services,
    } = formField.prevAssesment;

    console.log('values', values.prevAssesment)
    const handleAddRow = () => {
        const prevAssesmentServices = values.prevAssesment.prev_assesment_services || [];
        setFieldValue(prev_assesment_services.path, [
            ...prevAssesmentServices,
            {
                dateOfReport: "",
                examinerName: "",
                prev_assesment_type: null,
                serviceType: null,
                serviceType_other: null,
                serviceFrequency: null,
                serviceFrequency_other: null,
                diagnosis: "",
                serviceMinutes: ""
            }
        ]);
    };


    const handleDeleteRow = (index) => {
        const prevAssesmentServices = values.prevAssesment.prev_assesment_services || [];
        const newServices = [...prevAssesmentServices];
        newServices.splice(index, 1);
        setFieldValue(prev_assesment_services.path, newServices);
    };



    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const prevAssesmentServices = values.prevAssesment.prev_assesment_services || [];
        const newServices = [...prevAssesmentServices];

        // Check if the field is a date field
        if (name === 'dateOfReport') {
            // Convert the selected date to UTC with the start of the day
            const formattedDate = moment(value).startOf('day').utc().toISOString();
            newServices[index][name] = formattedDate;
        } else {
            // Handle other types of fields
            newServices[index][name] = value;
        }

        setFieldValue(prev_assesment_services.path, newServices);
    };

    const handleSelectChange = (index, name, value) => {
        const prevAssesmentServices = values.prevAssesment.prev_assesment_services || [];
        const newServices = [...prevAssesmentServices];
        newServices[index][name] = value;
        setFieldValue(prev_assesment_services.path, newServices);
    };

    const replaceStudentName = (label) => {
        const studentName = values?.basicInformation?.firstName || '[student info not-found]';
        return label.replace('STUDENT', studentName);
    };
    return (
        <Grid container spacing={2}>
            <OptionButton
                label={replaceStudentName(prev_assesment_history.label)}
                type="radio"
                row={true}
                name={prev_assesment_history.name}
                options={props.options?.prev_assesment_history}
                value={values.prevAssesment.prev_assesment_history}
                onChange={(name, value) => {
                    setFieldValue(prev_assesment_history.path, value)
                    if (value !== '1') {
                        setFieldValue(prev_assesment_services.path, []);
                        setFieldValue(prev_assesment_history_other.path, '');
                    }
                }}
                additionalInfoLabel={prev_assesment_history_other.label}
                additionalInfoName={prev_assesment_history_other.path}
                additionalInfoValue={values.prevAssesment.prev_assesment_history_other}
                setFieldValue={setFieldValue}
                triggerValues={[3]} // Values that should trigger the additional input field
                toolTipText={prev_assesment_history.toolTipText}
                error={errors.prevAssesment?.prev_assesment_history && touched.prevAssesment?.prev_assesment_history}
            />

            {values?.prevAssesment.prev_assesment_history === '1' &&
                <Grid item xs={12}>
                    <Stack
                        direction={'row'}
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        mb={2}
                    >
                        <Box>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="medium"
                                onClick={handleAddRow}
                                startIcon={<Add />}
                            >
                                Add Assesment
                            </Button>
                        </Box>
                    </Stack>

                    <Grid item xs={12} lg={12}>
                        {values.prevAssesment?.prev_assesment_services?.length > 0 &&
                            values.prevAssesment.prev_assesment_services.map((service, index) => (
                                <Paper variant="outlined" style={{ padding: "1rem", backgroundColor: "#fafafa", marginBottom: 10 }}>
                                    <Grid item xs={12}>
                                        <Stack
                                            direction={'row'}
                                            justifyContent="space-between"
                                            alignItems="center"
                                            mb={2}
                                        >
                                            <Box>
                                                <Typography
                                                    variant="h5"
                                                    fontWeight="500"
                                                    color="grey.500"
                                                >
                                                    {service.prev_assesment_type && service.prev_assesment_type.label
                                                        ? `Assessment ${index + 1} - ${service.prev_assesment_type.label}` // Concatenate values properly
                                                        : `Assessment ${index + 1}`
                                                    }
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <IconButton onClick={() => handleDeleteRow(index)}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid container spacing={2} key={index} alignItems="center">
                                        <Grid item xs={12} md={6} lg={6}>
                                            <FormField
                                                inputType="select"
                                                name='prev_assesment_type'
                                                label="Assesment Type"
                                                size="small"
                                                options={props.options?.prev_assesment_type_options}
                                                fullWidth
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.id}
                                                value={service.prev_assesment_type}
                                                onChange={(value) => handleSelectChange(index, 'prev_assesment_type', value)}
                                                placeholder={"Select ...."}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={3}>
                                            <FormField
                                                name='dateOfReport'
                                                type='date'
                                                label="Date of Report"
                                                size="small"
                                                fullWidth
                                                value={moment(service.dateOfReport).format('YYYY-MM-DD')} // Format for display
                                                onChange={(event) => handleChange(index, event)} // Pass the event to handleChange
                                            />

                                        </Grid>
                                        <Grid item xs={12} md={6} lg={3}>
                                            <FormField
                                                name='examinerName'
                                                label='Examiner Name'
                                                fullWidth
                                                size="small"
                                                value={service.examinerName}
                                                onChange={(event) => handleChange(index, event)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={10} lg={12}>
                                            <FormField
                                                name='diagnosis'
                                                label='Summary / Diagnosis'
                                                fullWidth
                                                rows={2}
                                                multiline
                                                size="small"
                                                value={service.diagnosis}
                                                onChange={(event) => handleChange(index, event)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <FormField
                                                inputType="select"
                                                name='serviceType'
                                                label="Service Type"
                                                size="small"
                                                options={props.options?.educationServices}
                                                fullWidth
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.id}
                                                value={service.serviceType}
                                                onChange={(value) => handleSelectChange(index, 'serviceType', value)}
                                                placeholder={"Select ...."}


                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4}>
                                            <FormField
                                                name='serviceMinutes'
                                                label='Service Minutes'
                                                type='number'
                                                size="small"
                                                fullWidth
                                                value={service.serviceMinutes}
                                                onChange={(event) => handleChange(index, event)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>

                                            <FormField
                                                inputType="select"
                                                name='serviceFrequency'
                                                label="Service Frequency"
                                                size="small"
                                                options={props.options?.serviceFrequencyOption}
                                                fullWidth
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.id}
                                                value={service.serviceFrequency}
                                                onChange={(value) => handleSelectChange(index, 'serviceFrequency', value)}
                                                placeholder={"Select ...."}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export default PrevAssesment;
