import React from "react";
import { Grid, Typography } from "@mui/material";
import FormField from "components/FormField";
import { getIn } from "formik";
import OptionButton from "../components/OptionButton";
const Eligibility = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        eligibility_Q_01,
        eligibility_Q_02,
        eligibility_Q_02_other,
        eligibility_Q_03,
        eligibility_Q_04,
        eligibility_Q_05,
        eligibility_Q_06,
        eligibility_Q_07,
        eligibility_Q_07_doc_description
    } = formField.eligibility;

    console.log('a', values.eligibility)

    return (
        <Grid container spacing={2} p={2}>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField
                    type={eligibility_Q_01.type}
                    label={eligibility_Q_01.label}
                    name={eligibility_Q_01.name}
                    size="small"
                    isRequired={false}
                    nested={true}
                    path={eligibility_Q_01.path}
                    onChange={(name, value) => setFieldValue(eligibility_Q_01.path, value)}
                    value={values.eligibility.eligibility_Q_01}
                    placeholder={eligibility_Q_01.placeholder}
                    error={getIn(errors, eligibility_Q_01.path) && getIn(touched, eligibility_Q_01.path)}
                />
            </Grid>
            <OptionButton
                type="checkbox"

                label={eligibility_Q_02.label}
                name={eligibility_Q_02.name}
                options={props.options?.eligibility_Q_02}
                value={values.eligibility.eligibility_Q_02}
                onChange={(name, value) => setFieldValue(eligibility_Q_02.path, value)}
                additionalInfoLabel={eligibility_Q_02_other.label}
                additionalInfoName={eligibility_Q_02_other.path}
                additionalInfoValue={values.eligibility.eligibility_Q_02_other}
                setFieldValue={setFieldValue}
                toolTipText={eligibility_Q_02.toolTipText}
                error={errors.eligibility?.eligibility_Q_02 && touched.eligibility?.eligibility_Q_02}
                triggerValues={[6]} // Single trigger value
                placeholder={eligibility_Q_02_other.placeholder}
            />

            <Grid item xs={12}>
                <Typography variant="subtitle1" color={'text.secondary'}>The following factors appeared to have affected the results of this evaluation:                </Typography>
            </Grid>
            <OptionButton
                type="radio"
                label={eligibility_Q_03.label}
                name={eligibility_Q_03.name}
                options={eligibility_Q_03.options}
                value={values.eligibility?.eligibility_Q_03} // Provide a default value if undefined
                onChange={(name, value) => setFieldValue(eligibility_Q_03.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eligibility_Q_03.toolTipText}
                error={errors.eligibility?.eligibility_Q_03 && touched.eligibility?.eligibility_Q_03}
            />
            <OptionButton
                type="radio"
                label={eligibility_Q_04.label}
                name={eligibility_Q_04.name}
                options={eligibility_Q_04.options}
                value={values.eligibility?.eligibility_Q_04} // Provide a default value if undefined
                onChange={(name, value) => setFieldValue(eligibility_Q_04.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={eligibility_Q_04.toolTipText}
                error={errors.eligibility?.eligibility_Q_04 && touched.eligibility?.eligibility_Q_04}
            />
            <OptionButton
                type="radio"
                label={eligibility_Q_05.label}
                name={eligibility_Q_05.name}
                options={eligibility_Q_05.options}
                value={values.eligibility?.eligibility_Q_05 ?? ''} // Provide a default value if undefined
                onChange={(name, value) => setFieldValue(eligibility_Q_05.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eligibility_Q_05.toolTipText}
                error={errors.eligibility?.eligibility_Q_05 && touched.eligibility?.eligibility_Q_05}
            />
            <OptionButton
                type="radio"
                label={eligibility_Q_06.label}
                name={eligibility_Q_06.name}
                options={eligibility_Q_06.options}
                value={values.eligibility?.eligibility_Q_06 ?? ''} // Provide a default value if undefined
                onChange={(name, value) => setFieldValue(eligibility_Q_06.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eligibility_Q_06.toolTipText}
                error={errors.eligibility?.eligibility_Q_06 && touched.eligibility?.eligibility_Q_06}
            />
            <OptionButton
                type="radio"
                label={eligibility_Q_07.label}
                name={eligibility_Q_07.name}
                options={eligibility_Q_07.options}
                value={values.eligibility?.eligibility_Q_07 ?? ''} // Provide a default value if undefined
                onChange={(name, value) => setFieldValue(eligibility_Q_07.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={eligibility_Q_07.toolTipText}
                error={errors.eligibility?.eligibility_Q_07 && touched.eligibility?.eligibility_Q_07}
            />
        </Grid>
    );
};

export default Eligibility;
