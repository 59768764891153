import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    firstName,
    middleName,
    lastName,
    caseManager,
    dateOfBirth,
    gender,
    school,
    disability,
    ethnicity,
    // New
    grade,
    primaryLanguage,
    secondaryLanguage,
    eligibilityEvaluation,
    initialEvaluation,
    annualPlanReview,
    eligibility,
    initialEvaluationReviewCompleted,
    nextEligibilityReviewCompleted,
    annualPlanReviewCompleted,
    currentAcademicYear
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [middleName.name]: Yup.string(),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [caseManager.name]: Yup.string().required(caseManager.errorMsg),
    [gender.name]: Yup.object().required(gender.errorMsg).nullable(),
    [dateOfBirth.name]: Yup.date().required(dateOfBirth.errorMsg),
    [school.name]: Yup.string().required(school.errorMsg),
    // New
    [ethnicity.name]:  Yup.object().nullable(),
    [disability.name]: Yup.string(),
    [grade.name]: Yup.object().nullable(),
    [primaryLanguage.name]: Yup.object().nullable(),
    [secondaryLanguage.name]: Yup.object().nullable(),
    [eligibilityEvaluation.name]: Yup.date(),
    [initialEvaluation.name]: Yup.date(),
    [annualPlanReview.name]: Yup.date(),
    [eligibility.name]: Yup.object().nullable(),
    [initialEvaluationReviewCompleted.name]: Yup.boolean(),
    [nextEligibilityReviewCompleted.name]: Yup.boolean(),
    [annualPlanReviewCompleted.name]: Yup.boolean(),
    [currentAcademicYear.name]: Yup.object().required(currentAcademicYear.errorMsg)

  }),
];

export default validations;
