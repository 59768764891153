import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    eventTitle,
    startTime,
    endTime,
    isRecurring,
    recurrencePattern,
    isBillable,
    studentId,
    eventType,
    sendEmailAlert,
    goals,
    duration,
    assignedAssistant,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [eventTitle.name]: Yup.string().required(eventTitle.errorMsg),
    [startTime.name]: Yup.date().required(startTime.errorMsg),
    [endTime.name]: Yup.date(),
    [isRecurring.name]: Yup.boolean(),
    [sendEmailAlert.name]: Yup.boolean(),
    [isBillable.name]: Yup.boolean().required(isBillable.errorMsg),
    [recurrencePattern.name]: Yup.object().nullable(),
    [studentId.name]: Yup.object().required(studentId.errorMsg).nullable(),
    [eventType.name]: Yup.object().required(eventType.errorMsg).nullable(),
    [assignedAssistant.name]: Yup.array(),
    [goals.name]: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
      })
    ),
    [duration.name]: Yup.string().required(duration.errorMsg),
  }),
];

export default validations;
