import React, { useState, useRef } from "react";
import { Paper, Box, Stack, Button } from "@mui/material";
import { Settings, Preview, Save, Add, ArrowBack } from "@mui/icons-material";
import ReportList from "./ReportList";
import { useDispatch } from "react-redux";
import ReportTemplateForm from "./ReportTemplateForm";
import { initialValues, form, validations } from "./components/schemas";
import { Formik, Form } from "formik";
import moment from "moment";

export default function Report() {
  const dispatch = useDispatch();
  const formRef = useRef();

  const [open, setOpen] = useState(false);

  function handleOpen() {
    setOpen(true);
  }

  function handleBack() {
    setOpen(false);
  }

  // FORM VALIDATION
  const { formId, formField } = form;
  const currentValidation = validations[0];

  function newForm(formData) {
    return <ReportTemplateForm formData={formData} />;
  }

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const submitForm = (values, actions) => {
    console.log('Form values:', values);  // Log form values to check validity
    console.log('Form errors:', actions.errors);  // Log errors from actions to debug
      const dob = moment(values.basicInformation.dataOfBirth);
    const identificationDate = moment(values.basicInformation.identificationDate);
    const dob_utc = dob.utc().toISOString();
    const identificationDate_utc = identificationDate.utc().toISOString();
    const date_of_referral = moment(values.purposeOfEvaluation.eval_type_dateOfReferal);
    const date_of_referral_utc = date_of_referral.utc().toISOString();

    console.log('dob_utc', dob_utc);
    console.log('identificationDate_utc', identificationDate_utc);
    console.log('date_of_referral_utc', date_of_referral_utc);

    // dispatch(createReport(payload));

    
  };

  return (
    <React.Fragment>
      <Paper
      //  sx={{ position: 'sticky', top: 100, height: 'calc(100vh - 100px)', overflowY: 'auto' }}
        sx={{
          position: 'sticky',
          width: "100%",
          padding: 1,
          paddingLeft: 2,
          paddingRight: 2,
          borderRadius: 0,
          borderTop: "none",
          top: 50,
          zIndex: 1

          // position: "sticky",

          // top: '5%', zIndex: 1
        }}
        variant="outlined"
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Box></Box>
          <Box>
            {open ? (
              <>
                <Button
                  variant="standard"
                  size="medium"
                  color="secondary"
                  startIcon={<ArrowBack />}
                  onClick={handleBack}
                >
                  Back
                </Button>
                <Button
                  variant="standard"
                  size="medium"
                  color="secondary"
                  sx={{ marginLeft: 1 }}
                  // onClick={() => setPreview(true)}
                  startIcon={<Preview />}
                >
                  Preview
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginLeft: 1, marginRight: 1 }}
                  startIcon={<Save />}
                  onClick={handleSubmit}
                >
                  Save & Continue
                </Button>
              </>
            ) : (
              <>
                <Button variant="standard" size="medium" color="secondary" startIcon={<Settings />}>
                  Settings
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ marginLeft: 1, marginRight: 1 }}
                  startIcon={<Add />}
                  onClick={handleOpen}
                >
                  New Report
                </Button>
              </>
            )}
          </Box>
        </Stack>
      </Paper>
      {!open ? (
        <React.Fragment>
          <ReportList setOpen={setOpen} open={open} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={submitForm}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              setTouched,
              isSubmitting,
              resetForm,
            }) => (
              <Form id={formId} autoComplete="off">
                {newForm({
                  values,
                  touched,
                  formField,
                  setFieldValue,
                  setFieldTouched,
                  resetForm,
                  setTouched,
                  errors,
                })}                 
              </Form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
