import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import OptionButton from "../components/OptionButton";
import FormField from "components/FormField";

const OPME = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        opme_lips,
        opme_tongue,
        opme_jaw,
        opme_teeth,
        opme_additional_info,
        opme_doc_description
    } = formField.oralPeripheralMechanism;


    const replaceStudentName = (label) => {
        const studentName = values?.generalInformation?.firstName || '[student info not-found]';
        return label.replace('STUDENT', studentName);
    };
    // Render the rich text for background information
    return (
        <Grid container spacing={2} p={2}>
            <HeaderComp title="Lips" />
            <OptionButton
                label={replaceStudentName(opme_lips.label)}
                type="radio"
                row={false}
                name={opme_lips.name}
                options={props.options?.opme_lips}
                value={values.oralPeripheralMechanism.opme_lips}
                onChange={(name, value) => setFieldValue(opme_lips.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={opme_lips.toolTipText}
                error={errors.oralPeripheralMechanism?.opme_lips && touched.oralPeripheralMechanism?.opme_lips}
            />
            <HeaderComp title="Tongue" />
            <OptionButton
                label={replaceStudentName(opme_tongue.label)}
                type="radio"
                row={false}
                name={opme_tongue.name}
                options={props.options?.opme_tongue}
                value={values.oralPeripheralMechanism.opme_tongue}
                onChange={(name, value) => setFieldValue(opme_tongue.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={opme_tongue.toolTipText}
                error={errors.oralPeripheralMechanism?.opme_tongue && touched.oralPeripheralMechanism?.opme_tongue}
            />
            <HeaderComp title="Jaw" />
            <OptionButton
                label={replaceStudentName(opme_jaw.label)}
                type="radio"
                name={opme_jaw.name}
                row={false}
                options={props.options?.opme_jaw}
                value={values.oralPeripheralMechanism.opme_jaw}
                onChange={(name, value) => setFieldValue(opme_jaw.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={opme_jaw.toolTipText}
                error={errors.oralPeripheralMechanism?.opme_jaw && touched.oralPeripheralMechanism?.opme_jaw}
            />
            <HeaderComp title="Teeth" />
            <OptionButton
                label={replaceStudentName(opme_teeth.label)}
                type="radio"
                name={opme_teeth.name}
                row={false}
                options={props.options?.opme_teeth}
                value={values.oralPeripheralMechanism.opme_teeth}
                onChange={(name, value) => setFieldValue(opme_teeth.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={opme_teeth.toolTipText}
                error={errors.oralPeripheralMechanism?.opme_teeth && touched.oralPeripheralMechanism?.opme_teeth}
            />
             <Grid item xs={12} sm={12} lg={12}>
                <FormField
                    label={opme_additional_info.label}
                    type={opme_additional_info.type}
                    name={opme_additional_info.name}
                    rows={2}
                    multiline
                    size="small"
                    onChange={(name, value) => setFieldValue(opme_additional_info.path, value)}
                    value={values.oralPeripheralMechanism.opme_additional_info}
                    placeholder={opme_additional_info.placeholder}
                />
            </Grid>
        </Grid>
    );
};

const HeaderComp = ({ title }) => {
    return (
        <Grid item md={12} sm={12} xs={12}>
            <Divider textAlign="left">
                <Typography variant="overline" color="text.secondary" fontWeight={800} fontSize={14}>
                    {title}
                </Typography>
            </Divider>
        </Grid>
    );
};
export default OPME;
