import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import FormField from "components/FormField";
import OptionButton from "../components/OptionButton";
import moment from "moment";

const ParentInput = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        parent_input_Q_01,
        parent_input_Q_02,
        parent_input_Q_02_other,
        parent_input_Q_03,
        parent_input_Q_03_other,
        parent_input_Q_04,
        parent_input_Q_05,
        parent_input_Q_06,
        parent_input_Q_07,
        parent_input_Q_08,
        parent_input_Q_08_other,
        parent_input_Q_09,
        parent_input_Q_09_other,
        parent_input_Q_10,
        parent_input_Q_10_other,
        parent_input_Q_11,
        parent_input_Q_11_other,
        parent_input_Q_12,
        parent_input_Q_12_other,
        parent_input_Q_13,
        parent_input_Q_13_other,
        parent_input_Q_14,
        parent_input_Q_14_other,
        parent_input_Q_15,
        parent_additional_info,
    } = formField.parentInput;

    const replaceStudentName = (label) => {
        const studentName = values?.generalInformation?.firstName || '[student info not-found]';
        return label.replace('STUDENT', studentName);
    };
    // Render the rich text for background information
    return (
        <Grid container spacing={2} p={2}>
            {/* <HeaderComp title="Education History" /> */}
            <OptionButton
                label={parent_input_Q_02.label}
                type="checkbox"
                name={parent_input_Q_02.name}
                options={props.options?.parent_input_Q_02}
                value={values.parentInput.parent_input_Q_02}
                onChange={(name, value) => setFieldValue(parent_input_Q_02.path, value)}
                additionalInfoLabel={parent_input_Q_02_other.label}
                additionalInfoName={parent_input_Q_02_other.path}
                additionalInfoValue={values.parentInput.parent_input_Q_02_other}
                setFieldValue={setFieldValue}
                toolTipText={parent_input_Q_02.toolTipText}
                triggerValues={[5]} // Single trigger value
                error={errors.parentInput?.parent_input_Q_02 && touched.parentInput?.parent_input_Q_02}
            />
            <Grid item xs={12} sm={6} lg={6}>
                <FormField
                    type={parent_input_Q_01.type}
                    label={parent_input_Q_01.label}
                    name={parent_input_Q_01.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(parent_input_Q_01.path, value)}
                    value={values.parentInput.teacher_input_Q_01}
                    placeholder={parent_input_Q_01.placeholder}
                />
            </Grid>
            
            <OptionButton
                label={parent_input_Q_03.label}
                type="radio"
                name={parent_input_Q_03.name}
                options={props.options?.info_collect}
                value={values.parentInput.parent_input_Q_03}
                onChange={(name, value) => setFieldValue(parent_input_Q_03.path, value)}
                additionalInfoLabel={parent_input_Q_03_other.label}
                additionalInfoName={parent_input_Q_03_other.path}
                additionalInfoValue={values.parentInput.parent_input_Q_03_other}
                setFieldValue={setFieldValue}
                toolTipText={parent_input_Q_03.toolTipText}
                triggerValues={[4]} // Single trigger value
                error={errors.parentInput?.parent_input_Q_03 && touched.parentInput?.parent_input_Q_03}
            />
            <Grid item xs={12} sm={6} lg={6}>
                <FormField
                    type={parent_input_Q_04.type}
                    label={parent_input_Q_04.label}
                    name={parent_input_Q_04.name}
                    size="small"
                    onChange={(e) => {
                        const date = moment(e.target.value).startOf('day').utc().toISOString();
                        setFieldValue(parent_input_Q_04.path, date);
                    }}
                    value={moment(values.parentInput.parent_input_Q_04).format('YYYY-MM-DD')}
                    placeholder={parent_input_Q_04.placeholder}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField
                    type={parent_input_Q_05.type}
                    label={replaceStudentName(parent_input_Q_05.label)}
                    name={parent_input_Q_05.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(parent_input_Q_05.path, value)}
                    value={values.parentInput.parent_input_Q_05}
                    placeholder={parent_input_Q_05.placeholder}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField
                    label={replaceStudentName(parent_input_Q_06.label)}
                    type={parent_input_Q_06.type}
                    name={parent_input_Q_06.name}
                    rows={2}
                    multiline
                    size="small"
                    onChange={(name, value) => setFieldValue(parent_input_Q_06.path, value)}
                    value={values.parentInput.parent_input_Q_06}
                    placeholder={parent_input_Q_06.placeholder}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
                <FormField
                    label={replaceStudentName(parent_input_Q_07.label)}
                    type={parent_input_Q_07.type}
                    name={parent_input_Q_07.name}
                    rows={2}
                    multiline
                    size="small"
                    onChange={(name, value) => setFieldValue(parent_input_Q_07.path, value)}
                    value={values.parentInput.parent_input_Q_07}
                    placeholder={parent_input_Q_07.placeholder}
                />
            </Grid>
            <OptionButton
                label={parent_input_Q_08.label}
                type="radio"
                name={parent_input_Q_08.name}
                options={props.options?.condition_options}
                value={values.parentInput.parent_input_Q_08}
                onChange={(name, value) => setFieldValue(parent_input_Q_08.path, value)}
                additionalInfoLabel={parent_input_Q_08_other.label}
                additionalInfoName={parent_input_Q_08_other.path}
                additionalInfoValue={values.parentInput.parent_input_Q_08_other}
                triggerValues={[1]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={parent_input_Q_08.toolTipText}
                error={errors.parentInput?.parent_input_Q_08 && touched.parentInput?.parent_input_Q_08}
            />

            <OptionButton
                label={parent_input_Q_09.label}
                type="checkbox"
                row={false}
                name={parent_input_Q_09.name}
                options={props.options?.receptive_language_options}
                value={values.parentInput.parent_input_Q_09}
                onChange={(name, value) => setFieldValue(parent_input_Q_09.path, value)}
                additionalInfoLabel={parent_input_Q_09_other.label}
                additionalInfoName={parent_input_Q_09_other.path}
                additionalInfoValue={values.parentInput.parent_input_Q_09_other}
                triggerValues={[7]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={parent_input_Q_09.toolTipText}
                error={errors.parentInput?.parent_input_Q_09 && touched.parentInput?.parent_input_Q_09}
            />

            <OptionButton
                label={parent_input_Q_10.label}
                type="checkbox"
                row={false}
                name={parent_input_Q_10.name}
                options={props.options?.expressive_language_options}
                value={values.parentInput.parent_input_Q_10}
                onChange={(name, value) => setFieldValue(parent_input_Q_10.path, value)}
                additionalInfoLabel={parent_input_Q_10_other.label}
                additionalInfoName={parent_input_Q_10_other.path}
                additionalInfoValue={values.parentInput.parent_input_Q_10_other}
                triggerValues={[6]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={parent_input_Q_10.toolTipText}
                error={errors.parentInput?.parent_input_Q_10 && touched.parentInput?.parent_input_Q_10}
            />
            <OptionButton
                label={parent_input_Q_11.label}
                type="checkbox"
                row={false}

                name={parent_input_Q_11.name}
                options={props.options?.social_pragmatics_options}
                value={values.parentInput.parent_input_Q_11}
                onChange={(name, value) => setFieldValue(parent_input_Q_11.path, value)}
                additionalInfoLabel={parent_input_Q_11_other.label}
                additionalInfoName={parent_input_Q_11_other.path}
                additionalInfoValue={values.parentInput.parent_input_Q_11_other}
                triggerValues={[6]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={parent_input_Q_11.toolTipText}
                error={errors.parentInput?.parent_input_Q_11 && touched.parentInput?.parent_input_Q_11}
            />
            <OptionButton
                label={parent_input_Q_12.label}
                type="checkbox"
                row={false}

                name={parent_input_Q_12.name}
                options={props.options?.fluency_options}
                value={values.parentInput.parent_input_Q_12}
                onChange={(name, value) => setFieldValue(parent_input_Q_12.path, value)}
                additionalInfoLabel={parent_input_Q_12_other.label}
                additionalInfoName={parent_input_Q_12_other.path}
                additionalInfoValue={values.parentInput.parent_input_Q_12_other}
                triggerValues={[7]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={parent_input_Q_12.toolTipText}
                error={errors.parentInput?.parent_input_Q_12 && touched.parentInput?.parent_input_Q_12}
            />
            <OptionButton
                label={parent_input_Q_13.label}
                type="checkbox"
                row={false}
                name={parent_input_Q_13.name}
                options={props.options?.voice_concern_options}
                value={values.parentInput.parent_input_Q_13}
                onChange={(name, value) => setFieldValue(parent_input_Q_13.path, value)}
                additionalInfoLabel={parent_input_Q_13_other.label}
                additionalInfoName={parent_input_Q_13_other.path}
                additionalInfoValue={values.parentInput.parent_input_Q_13_other}
                triggerValues={[6]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={parent_input_Q_12.toolTipText}
                error={errors.parentInput?.parent_input_Q_12 && touched.parentInput?.parent_input_Q_12}
            />
            <OptionButton
                label={parent_input_Q_14.label}
                type="checkbox"
                row={false}
                name={parent_input_Q_14.name}
                options={props.options?.articulations_options}
                value={values.parentInput.parent_input_Q_14}
                onChange={(name, value) => setFieldValue(parent_input_Q_14.path, value)}
                additionalInfoLabel={parent_input_Q_14_other.label}
                additionalInfoName={parent_input_Q_14_other.path}
                additionalInfoValue={values.parentInput.parent_input_Q_14_other}
                triggerValues={[6]} // Single trigger value
                setFieldValue={setFieldValue}
                toolTipText={parent_input_Q_14.toolTipText}
                error={errors.parentInput?.parent_input_Q_14 && touched.parentInput?.parent_input_Q_14}
            />
            <Grid item xs={12} sm={12} lg={12}>
                <FormField
                    label={parent_input_Q_15.label}
                    type={parent_input_Q_15.type}
                    name={parent_input_Q_15.name}
                    rows={2}
                    multiline
                    size="small"
                    onChange={(name, value) => setFieldValue(parent_input_Q_15.path, value)}
                    value={values.parentInput.parent_input_Q_15}
                    placeholder={parent_input_Q_15.placeholder}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <FormField
                    label={parent_additional_info.label}
                    type={parent_additional_info.type}
                    name={parent_additional_info.name}
                    rows={2}
                    multiline
                    size="small"
                    onChange={(name, value) => setFieldValue(parent_additional_info.path, value)}
                    value={values.parentInput.parent_additional_info}
                    placeholder={parent_additional_info.placeholder}
                />
            </Grid>
        </Grid>
    );
};

const HeaderComp = ({ title }) => {
    return (
        <Grid item md={12} sm={12} xs={12}>
            <Divider textAlign="left">
                <Typography variant="overline" color="text.secondary" fontWeight={800} fontSize={14}>
                    {title}
                </Typography>
            </Divider>
        </Grid>
    );
};
export default ParentInput;
