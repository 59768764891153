import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import { initialValues, form, validations } from "../components/schemas";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

import { forgotPassword } from "redux/actions/authAction";
import { useNavigate } from "react-router-dom";

import ForgotPasswordForm from "../ForgotPasswordForm";
import { styled } from "@mui/system";
import AuthBox from "../../../components/Box/AuthBox";

const FullWidthButton = styled(LoadingButton)({
  width: "100%",
});

const ForgotPasswordPage = () => {
  const { formId, formField } = form;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitForm = (values, actions) => {
    let payload = {
      email: values.email.toLowerCase(),
    };
    setLoading(true);
    dispatch(forgotPassword(payload))
      .then((response) => {
        if (response && !response.success) setLoading(false);

        if (response && response.success) {
          setLoading(false);
          navigate("/login");
        }
      })
      .catch((error) => {
        // Handle other errors here
        setLoading(false);
      });
  };

  return (
    <AuthBox showSS0={false}>
      <Formik initialValues={initialValues} validationSchema={validations[2]} onSubmit={submitForm}>
        {({ values, errors, touched, setFieldValue, isSubmitting, resetForm }) => (
          <Form id={formId} autoComplete="off">
            <ForgotPasswordForm
              values={values}
              touched={touched}
              formField={formField}
              isResetPasswordPath={false}
              setFieldValue={setFieldValue}
              resetForm={resetForm}
              errors={errors}
            />
            <Box align="center" mt={2}>
              <FullWidthButton
                size="large"
                variant="contained"
                type="submit"
                loading={loading}
                // endIcon={<EastIcon />}
                sx={{
                  background: "#6565ec",
                  color: "white",
                  "&:hover": {
                    background: "#5d5dd0",
                    borderColor: "#6565ec",
                  },
                }}
              >
                Submit
              </FullWidthButton>
            </Box>
          </Form>
        )}
      </Formik>
    </AuthBox>
  );
};

export default ForgotPasswordPage;
