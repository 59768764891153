import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { email, name, password },
} = checkout;

const validations = [
  Yup.object().shape({
    [email.name]: Yup.string()
      .email(email.errorMsg) // Use .string() before .email()
      .required(email.errorMsg),
    [name.name]: Yup.string().required(name.errorMsg),
    [password.name]: Yup.string().required(password.errorMsg),
  }),
];

export default validations;
