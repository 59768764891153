const form = {
  formId: "student",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      placeholder: "First Name",
      errorMsg: "First Name is required.",
      invalidMsg: "",
    },
    middleName: {
      name: "middleName",
      label: "Middle Name",
      type: "text",
      placeholder: "Middle Name",
      errorMsg: "Middle Name is required.",
      invalidMsg: "",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      placeholder: "Last Name",
      errorMsg: "Last Name is required.",
      invalidMsg: "",
    },
    caseManager: {
      name: "caseManager",
      label: "Case Manager",
      type: "text",
      placeholder: "Please type case manager.",
      errorMsg: "Case Manager is required.",
      invalidMsg: "",
    },
    gender: {
      name: "gender",
      label: "Gender",
      type: "text",
      placeholder: "Select ...",
      errorMsg: "Gender is required.",
      invalidMsg: "",
    },
    dateOfBirth: {
      name: "dateOfBirth",
      label: "Date of Birth",
      type: "date",
      placeholder: "DOB",
      errorMsg: "DOB is required.",
      invalidMsg: "",
    },
    school: {
      name: "school",
      label: "School",
      type: "text",
      placeholder: "Please type school name.",
      errorMsg: "School name is required.",
      invalidMsg: "",
    },
    // New
    disability: {
        name: "disability",
        label: "Disability",
        type: "text",
        placeholder: "Student Disability",
        errorMsg: "Disability is required.",
        invalidMsg: "",
    },
    ethnicity: {
      name: "ethnicity",
      label: "Ethnicity",
      type: "text",
      placeholder: "Select ...",
      errorMsg: "Ethnicity is required.",
      invalidMsg: "",
    },
    grade: {
      name: "grade",
      label: "Grade",
      type: "text",
      placeholder: "Student Grade.",
      errorMsg: "Grade is required.",
      invalidMsg: "",
    },
    primaryLanguage: {
      name: "primaryLanguage",
      label: "Primary Language",
      type: "text",
      placeholder: "Please select primary language.",
      errorMsg: "Primary Language is required.",
      invalidMsg: "",
    },
    secondaryLanguage: {
      name: "secondaryLanguage",
      label: "Secondary Language",
      type: "text",
      placeholder: "Please select secondary language.",
      errorMsg: "Secondary Language is required.",
      invalidMsg: "",
    },
    eligibilityEvaluation: {
      name: "eligibilityEvaluation",
      label: "Eligibility Evaluation",
      type: "date",
      placeholder: "Eligibility Evaluation",
      errorMsg: "Eligibility Evaluation is required.",
      invalidMsg: "",
    },
    initialEvaluation: {
      name: "initialEvaluation",
      label: "Initial Evaluation",
      type: "date",
      placeholder: "Initial Evaluation",
      errorMsg: "Initial Evaluation is required.",
      invalidMsg: "",
    },
    annualPlanReview: {
      name: "annualPlanReview",
      label: "Annual Plan Review",
      type: "date",
      placeholder: "Annual Plan Review",
      errorMsg: "Annual Plan Review is required.",
      invalidMsg: "",
    },
    eligibility: {
      name: "eligibility",
      label: "Eligibility",
      type: "text",
      placeholder: "Select ...",
      errorMsg: "Eligibility is required.",
      invalidMsg: "",
    },
    initialEvaluationReviewCompleted: {
      name: "initialEvaluationReviewCompleted",
      label: "Initial Evaluation Review Completed",
      type: "checkbox",
      placeholder: "Initial Evaluation Review Completed",
      errorMsg: "",
      invalidMsg: "",
    },
    nextEligibilityReviewCompleted: {
      name: "nextEligibilityReviewCompleted",
      label: "Eligibility Review Completed",
      type: "checkbox",
      placeholder: "Eligibility Review Completed",
      errorMsg: "",
      invalidMsg: "",
    },
    annualPlanReviewCompleted: {
      name: "annualPlanReviewCompleted",
      label: "Annual Plan Review Completed",
      type: "checkbox",
      placeholder: "Annual Plan Review Completed",
      errorMsg: "",
      invalidMsg: "",
    },
    currentAcademicYear: {
      name: "currentAcademicYear",
      label: "School Year",
      type: "text",
      placeholder: "Current Academic Year",
      errorMsg: "Current Academic Year is required.",
      invalidMsg: "",
    }
  },
};

export default form;
