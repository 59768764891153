export const getSchoolYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const isCurrentYear = currentMonth >= 6; // August is month 7
  
    const startYear = isCurrentYear ? currentYear : currentYear - 1;
    const endYear = startYear + 1;
  
    return [
      { id: `${startYear}-${endYear}`, label: `${startYear}-${endYear}` },
      { id: `${startYear + 1}-${endYear + 1}`, label: `${startYear + 1}-${endYear + 1}` },
    //   { id: `${startYear + 2}-${endYear + 2}`, label: `${startYear + 2}-${endYear + 2}` }
    ];
  };
  

  export const getCurrentSchoolYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
  
    // School year starts in July, which is month 6 (0-indexed)
    if (currentMonth >= 6) {
      return `${currentYear}-${currentYear + 1}`;
    } else {
      return `${currentYear - 1}-${currentYear}`;
    }
  };