import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    assessmentName,
    caseManager,
    assessmentDate,
    assessmentTemplate,
    startTime,
    endTime,
    repeats,
    occurrences,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [assessmentName.name]: Yup.string().required(assessmentName.errorMsg),
    [caseManager.name]: Yup.string().required(caseManager.errorMsg),
    [assessmentTemplate.name]: Yup.object().required(assessmentTemplate.errorMsg).nullable(),
    [assessmentDate.name]: Yup.string().required(assessmentDate.errorMsg),
    [startTime.name]: Yup.string().required(startTime.errorMsg),
    [endTime.name]: Yup.string().required(endTime.errorMsg),
    [repeats.name]: Yup.object().nullable(),
    [occurrences.name]: Yup.object().nullable(),
  }),
];

export default validations;
