const form = {
  formId: "student",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      placeholder: "First Name",
      errorMsg: "First Name is required.",
      invalidMsg: "",
    },
    middleName: {
      name: "middleName",
      label: "Middle Name",
      type: "text",
      placeholder: "Middle Name",
      errorMsg: "Middle Name is required.",
      invalidMsg: "",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      placeholder: "Last Name",
      errorMsg: "Last Name is required.",
      invalidMsg: "",
    },
    caseManager: {
      name: "caseManager",
      label: "Case Manager",
      type: "text",
      placeholder: "Please type case manager.",
      errorMsg: "Case Manager is required.",
      invalidMsg: "",
    },
    gender: {
      name: "gender",
      label: "Gender",
      type: "text",
      placeholder: "Select ...",
      errorMsg: "Gender is required.",
      invalidMsg: "",
    },
    dateOfBirth: {
      name: "dateOfBirth",
      label: "Date of Birth",
      type: "date",
      placeholder: "DOB",
      errorMsg: "DOB is required.",
      invalidMsg: "",
    },
    grade: {
      name: "grade",
      label: "Grade",
      type: "text",
      placeholder: "Student Grade.",
      errorMsg: "Grade is required.",
      invalidMsg: "",
    },
    eligibilityEvaluation: {
      name: "eligibilityEvaluation",
      label: "Eligibility Evaluation",
      type: "date",
      placeholder: "Eligibility Evaluation",
      errorMsg: "Eligibility Evaluation is required.",
      invalidMsg: "",
    },
    initialEvaluation: {
      name: "initialEvaluation",
      label: "Initial Evaluation",
      type: "date",
      placeholder: "Initial Evaluation",
      errorMsg: "Initial Evaluation is required.",
      invalidMsg: "",
    },
    annualPlanReview: {
      name: "annualPlanReview",
      label: "Annual Plan Review",
      type: "date",
      placeholder: "Annual Plan Review",
      errorMsg: "Annual Plan Review is required.",
      invalidMsg: "",
    },
    school: {
      name: "school",
      label: "School",
      type: "text",
      placeholder: "Please type school name.",
      errorMsg: "School name is required.",
      invalidMsg: "",
    },
    currentAcademicYear: {
      name: "currentAcademicYear",
      label: "School Year",
      type: "text",
      placeholder: "Current Academic Year",
      errorMsg: "Current Academic Year is required.",
      invalidMsg: "",
    }
  },
};

export default form;
