import { authRequest } from "./request";
import * as Actions from "../constants/constants";
import { showNotification } from "./notificationAction";
import { SUCCESS } from "redux/constants/notificationType";
export function createSchedule(payload) {
  return (dispatch) => {
    return authRequest(`scheduler/create-scheduler`, "POST", payload)
      .then((response) => {
        dispatch({
          type: Actions.CREATE_GOAL_BANK,
          payload: response,
        });
        showNotification({
          message: "Scheduled Succesfully !",
          type: SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getEventsByDate(payload) {
  return (dispatch) => {
    return authRequest(`scheduler/events`, "POST", payload)
      .then((response) => {
        dispatch({
          type: Actions.FETCH_EVENTS_BY_DATE,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function editScheduler(id, payload) {
  return (dispatch) => {
    return authRequest(`scheduler/editScheduler/${id}`, "PUT", payload)
      .then((response) => {
        dispatch({
          type: Actions.CREATE_GOAL_BANK,
          payload: response,
        });
        showNotification({
          message: "Updated Succesfully !",
          type: SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function fetchAllEvents(start, end, schoolYear) {
  return (dispatch) => {
    return authRequest(`scheduler/getAllEvents?start=${start}&end=${end}&schoolYear=${schoolYear}`, "GET")
      .then((response) => {
        dispatch({
          type: Actions.FETCH_GOALS_BY_CATEGORY,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}

export function getDataTrackingHistory(id, payload) {
  const queryParams = new URLSearchParams(payload.filter).toString();

  return (dispatch) => {
    return authRequest(`scheduler/getDataTrackingHistory/${id}?${queryParams}`, "GET")
      .then((response) => {
        dispatch({
          type: Actions.FETCH_DATA_TRACKING_HISTORY,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}
