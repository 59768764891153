import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import FormField from "components/FormField";
import OptionButton from "../components/OptionButton";
const Articulation = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        arti_skills,
        // arti_skills_other,
        arti_sound_production,
        arti_intelligibility,
        arti_behav_observations,
        arti_impact_communication,
        arti_additional_concerns,
        arti_doc_description
    } = formField.articulation_Intelligibility;


    const replaceStudentName = (label) => {
        const studentName = values?.generalInformation?.firstName || '[student info not-found]';
        return label.replace('STUDENT', studentName);
    };
    // Render the rich text for background information
    return (
        <Grid container spacing={2} p={2}>
            <HeaderComp title="Articulation Skills" />
            <OptionButton
                label={replaceStudentName(arti_skills.label)}
                type="radio"
                row={false}
                name={arti_skills.name}
                options={props.options?.arti_skills}
                value={values.articulation_Intelligibility.arti_skills}
                onChange={(name, value) => setFieldValue(arti_skills.path, value)}
               
                setFieldValue={setFieldValue}
                toolTipText={arti_skills.toolTipText}
                error={errors.articulation_Intelligibility?.arti_skills && touched.articulation_Intelligibility?.arti_skills}
            />
            <HeaderComp title="Sound Production" />

            <OptionButton
                label={replaceStudentName(arti_sound_production.label)}
                type="radio"
                row={false}
                name={arti_sound_production.name}
                options={props.options?.arti_sound_production}
                value={values.articulation_Intelligibility.arti_sound_production}
                onChange={(name, value) => setFieldValue(arti_sound_production.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={arti_sound_production.toolTipText}
                error={errors.articulation_Intelligibility?.arti_sound_production && touched.articulation_Intelligibility?.arti_sound_production}
            />
            <HeaderComp title="Intelligibility" />

            <OptionButton
                label={replaceStudentName(arti_intelligibility.label)}
                type="radio"
                row={false}
                name={arti_intelligibility.name}
                options={props.options?.arti_intelligibility}
                value={values.articulation_Intelligibility.arti_intelligibility}
                onChange={(name, value) => setFieldValue(arti_intelligibility.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={arti_intelligibility.toolTipText}
                error={errors.articulation_Intelligibility?.arti_intelligibility && touched.articulation_Intelligibility?.arti_intelligibility}
            />
            <HeaderComp title="Behavioral Observations" />

            <OptionButton
                label={replaceStudentName(arti_behav_observations.label)}
                type="radio"
                row={false}
                name={arti_behav_observations.name}
                options={props.options?.arti_behav_observations}
                value={values.articulation_Intelligibility.arti_behav_observations}
                onChange={(name, value) => setFieldValue(arti_behav_observations.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={arti_behav_observations.toolTipText}
                error={errors.articulation_Intelligibility?.arti_behav_observations && touched.articulation_Intelligibility?.arti_behav_observations}
            />
            <HeaderComp title="Impact on Communication" />

            <OptionButton
                label={replaceStudentName(arti_impact_communication.label)}
                type="radio"
                row={false}
                name={arti_impact_communication.name}
                options={props.options?.arti_impact_communication}
                value={values.articulation_Intelligibility.arti_impact_communication}
                onChange={(name, value) => setFieldValue(arti_impact_communication.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={arti_impact_communication.toolTipText}
                error={errors.articulation_Intelligibility?.arti_impact_communication && touched.articulation_Intelligibility?.arti_impact_communication}
            />
            <Grid item xs={12}>
                <FormField
                    rows={2}
                    multiline
                    type={arti_additional_concerns.type}
                    label={arti_additional_concerns.label}
                    name={arti_additional_concerns.name}
                    size="small"
                    onChange={(name, value) => setFieldValue(arti_additional_concerns.path, value)}
                    value={values.articulation_Intelligibility.arti_additional_concerns}
                    placeholder={arti_additional_concerns.placeholder}
                />
            </Grid>



        </Grid>
    );
};

const HeaderComp = ({ title }) => {
    return (
        <Grid item md={12} sm={12} xs={12}>
            <Divider textAlign="left">
                <Typography variant="overline" color="text.secondary" fontWeight={800} fontSize={14}>
                    {title}
                </Typography>
            </Divider>
        </Grid>
    );
};

export default Articulation;
