import React, { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import FormField from "components/FormField";

import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const ScheduleAssessment = ({ formData }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const [repeatsActive, setRepeatsActive] = useState(false);

  const {
    assessmentName,
    caseManager,
    assessmentDate,
    assessmentTemplate,
    startTime,
    endTime,
    repeats,
    occurrences,
  } = formField;
  const {
    assessmentName: assessmentNameV,
    caseManager: caseManagerV,
    assessmentTemplate: assessmentTemplateV,
    assessmentDate: assessmentDateV,
    startTime: startTimeV,
    endTime: endTimeV,
    repeats: repeatsV,
    occurrences: occurrencesV,
  } = values;

  const assessmentTemplateList = [
    { id: "Template 1", name: "Tempale1" },
    { id: "Template 2", name: "Template2" },
  ];

  const repeatsList = [
    { id: "daily", name: "Daily" },
    { id: "weekly", name: "Weekly" },
    { id: "every2weeks", name: "Every 2 Weeks" },
  ];
  const occurrencesList = [
    { id: "noLimit", name: "No limit" },
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "6", name: "6" },
    { id: "7", name: "7" },
    { id: "8", name: "8" },
    { id: "9", name: "9" },
    { id: "10", name: "10" },
  ];

  function handleChangeassessmentTemplate(option) {
    setFieldValue("assessmentTemplate", option);
  }
  function handleChangeRepeats(option) {
    setFieldValue("repeats", option);
  }
  function handleChangeOccurrences(option) {
    setFieldValue("occurrences", option);
  }

  const handleChangeRepeatsSwitch = () => {
    setRepeatsActive(!repeatsActive);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={12}>
        <FormField
          type={assessmentName.type}
          label={assessmentName.label}
          name={assessmentName.name}
          size="small"
          isRequired={true}
          value={assessmentNameV}
          placeholder={assessmentName.placeholder}
          error={errors.assessmentName && touched.assessmentName}
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          isClearable
          type={assessmentTemplate.type}
          menuPlacement="top"
          inputType="select"
          label={assessmentTemplate.label}
          isRequired={true}
          name={assessmentTemplate.name}
          isMulti
          size="small"
          options={assessmentTemplateList}
          onChange={handleChangeassessmentTemplate}
          value={assessmentTemplateV}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={assessmentTemplate.placeholder}
          error={errors.assessmentTemplate && touched.assessmentTemplate}
          // success={locationV.length > 0 && !errors.location}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <FormField
          type={assessmentDate.type}
          label={assessmentDate.label}
          name={assessmentDate.name}
          size="small"
          isRequired={true}
          value={assessmentDateV}
          maxDate={new Date()}
          onChange={(e) => {
            setFieldValue(assessmentDate.name, e.target.value);
          }}
          placeholder={assessmentDate.placeholder}
          error={errors.assessmentDate && touched.assessmentDate}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <FormField
          type={startTime.type}
          label={startTime.label}
          name={startTime.name}
          size="small"
          isRequired={true}
          value={startTimeV}
          onChange={(e) => {
            setFieldValue(startTime.name, e.target.value);
          }}
          placeholder={startTime.placeholder}
          error={errors.startTime && touched.startTime}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <FormField
          type={endTime.type}
          label={endTime.label}
          name={endTime.name}
          size="small"
          isRequired={true}
          value={endTimeV}
          onChange={(e) => {
            setFieldValue(endTime.name, e.target.value);
          }}
          placeholder={endTime.placeholder}
          error={errors.endTime && touched.endTime}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={1}>
        <FormGroup sx={{ marginTop: 3.7 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={false}
                onChange={handleChangeRepeatsSwitch}
                checked={repeatsActive}
              />
            }
            label="Repeats"
          />
        </FormGroup>
      </Grid>

      {repeatsActive && (
        <>
          <Grid item xs={6}>
            <FormField
              isClearable
              type={repeats.type}
              menuPlacement="auto"
              inputType="select"
              label={repeats.label}
              isRequired={true}
              name={repeats.name}
              size="small"
              options={repeatsList}
              onChange={handleChangeRepeats}
              value={repeatsV}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={repeats.placeholder}
              error={errors.repeats && touched.repeats}
              // success={locationV.length > 0 && !errors.location}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              isClearable
              type={occurrences.type}
              menuPlacement="auto"
              inputType="select"
              label={occurrences.label}
              isRequired={true}
              name={occurrences.name}
              size="small"
              options={occurrencesList}
              onChange={handleChangeOccurrences}
              value={occurrencesV}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={occurrences.placeholder}
              error={errors.occurrences && touched.occurrences}
              // success={locationV.length > 0 && !errors.location}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} sm={12} lg={12}>
        <FormField
          type={caseManager.type}
          label={caseManager.label}
          name={caseManager.name}
          size="small"
          isRequired={true}
          value={caseManagerV}
          placeholder={caseManager.placeholder}
          error={errors.caseManager && touched.caseManager}
        />
      </Grid>
    </Grid>
  );
};

export default ScheduleAssessment;
