import { Container } from "@mui/material";
import PropTypes from "prop-types"; // Import PropTypes

export default function ContentLayer({ children }) {
  return (
    <Container
      maxWidth="xl"
      sx={{
        marginTop: 2,
        marginBottom: 2,

        // height: '100vh', // Set the maximum height to the viewport height
        // overflowY: 'auto', // Add vertical scrollbar if content exceeds the height
      }}
    >
      {children}
    </Container>
  );
}

// Add PropTypes validation
ContentLayer.propTypes = {
  children: PropTypes.node.isRequired, // Ensure children is provided and is a valid node
};
