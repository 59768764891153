// HtmlToPdfText.js
import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import parse from 'html-react-parser'; // Ensure you have this library installed
import commonStyles from './commonStyles';

const HtmlToPdfText = ({ html }) => {
  if (typeof html !== 'string') {
    console.error('html prop is not a string:', html);
    return null;
  }

  if (!html) {
    console.warn('html prop is empty or undefined:', html);
    return null;
  }

  const options = {
    replace: (domNode) => {
      if (domNode.type === 'tag' && domNode.name === 'p') {
        return (
          <Text key={domNode.startIndex} style={commonStyles.body}>
            {domNode.children.map((child, index) => (child.data ? child.data : ''))}
          </Text>
        );
      }
      if (domNode.type === 'tag' && domNode.name === 'ul') {
        return (
          <View key={domNode.startIndex}>
            {domNode.children.map((child, index) => {
              if (child.name === 'li') {
                return (
                  <Text key={child.startIndex} style={commonStyles.list}>
                    {'• '}{child.children.map((liChild) => (liChild.data ? liChild.data : ''))}
                  </Text>
                );
              }
              return null;
            })}
          </View>
        );
      }
      return null;
    },
  };

  const content = parse(html, options);
  return <>{content}</>;
};

export default HtmlToPdfText;
