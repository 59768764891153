import React, { useState } from "react";
import { TextField, InputAdornment, IconButton, Box, Stack } from "@mui/material";
import { Search, Clear } from "@mui/icons-material";

const SearchBox = ({ onSearch, onClear, placeholder = "Search...", ...rest }) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = () => {
    if (onSearch) {
      onSearch(searchText);
    }
  };

  const handleClear = () => {
    setSearchText("");
    if (onClear) {
      onClear();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Box
      sx={{
        // borderRadius: "1.2rem",
        marginBottom: 2,
        transition: "transform 0.3s",
        background: "rgba(255, 255, 255, 0.08)",

        "&:hover": {
          transform: "scale(1.01)",
          boxShadow: "0px 0px 10px 0px rgb(13 183 242 / 9%)",
          cursor: "pointer",
          borderColor: (theme) => theme.palette.primary["main"],
          background: "#fff",
        },
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        value={searchText}
        size="large"
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={handleKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Stack direction="row" spacing={1}>
                {searchText && (
                  <IconButton size="large" color="primary" onClick={handleClear} edge="end">
                    <Clear fontSize="14px" />
                  </IconButton>
                )}
                <IconButton size="large" color="primary" onClick={handleSearch} edge="end">
                  <Search fontSize="14px" />
                </IconButton>
              </Stack>
            </InputAdornment>
          ),
          style: { borderColor: "#fff" },
        }}
        {...rest}
      />
    </Box>
  );
};

export default SearchBox;
