const pragmaticsForm = {
  pragmatics: {
    pragmatics_Q_01: {
      name: "pragmatics_Q_01",
      label: "How does STUDENT typically initiate communication?",
      type: "text",
      placeholder: "Enter communication skills",
      errorMsg: "Communication skills are required.",
      invalidMsg: "Invalid input for communication skills.",
      toolTipText: 'TODO',
      path: "pragmatics.pragmatics_Q_01",
    },
    pragmatics_Q_02: {
      name: "pragmatics_Q_02",
      label: "During conversations, how well does STUDENT take turns?",
      type: "text",
      placeholder: "Enter turn-taking details",
      errorMsg: "Turn-taking details are required.",
      invalidMsg: "Invalid input for turn-taking details.",
      toolTipText: 'TODO',
      path: "pragmatics.pragmatics_Q_02",
    },
    pragmatics_Q_03: {
      name: "pragmatics_Q_03",
      label: "How well does STUDENT maintain topics of conversation?",
      type: "text",
      placeholder: "Enter maintaining topics details",
      errorMsg: "Maintaining topics details are required.",
      invalidMsg: "Invalid input for maintaining topics details.",
      toolTipText: 'TODO',
      path: "pragmatics.pragmatics_Q_03",
    },
    pragmatics_Q_04: {
      name: "pragmatics_Q_04",
      label: "How does STUDENT use nonverbal communication (e.g., gestures, facial expressions)?",
      type: "text",
      placeholder: "Enter non-verbal communication details",
      errorMsg: "Non-verbal communication details are required.",
      invalidMsg: "Invalid input for non-verbal communication details.",
      toolTipText: 'TODO',
      path: "pragmatics.pragmatics_Q_04",
    },
    pragmatics_Q_05: {
      name: "pragmatics_Q_05",
      label: "How well does STUDENT understand social cues (e.g., body language, tone of voice)?",
      type: "text",
      placeholder: "Enter social cues details",
      errorMsg: "Social cues details are required.",
      invalidMsg: "Invalid input for social cues details.",
      toolTipText: 'TODO',
      path: "pragmatics.pragmatics_Q_05",
    },
    pragmatics_Q_06: {
      name: "pragmatics_Q_06",
      label: "How flexible is STUDENT in adapting communication to different social situations?",
      type: "text",
      placeholder: "Enter adapting communication details",
      errorMsg: "Adapting communication details are required.",
      invalidMsg: "Invalid input for adapting communication details.",
      toolTipText: 'TODO',
      path: "pragmatics.pragmatics_Q_06",
    },
    pragmatics_Q_07: {
      name: "pragmatics_Q_07",
      label: "How well does STUDENT problem-solve in social situations (e.g., resolving conflicts, negotiating)?",
      type: "text",
      placeholder: "Enter problem-solving details",
      errorMsg: "Problem-solving details are required.",
      invalidMsg: "Invalid input for problem-solving details.",
      toolTipText: 'TODO',
      path: "pragmatics.pragmatics_Q_07",
    },
    pragmatics_Q_08: {
      name: "pragmatics_Q_08",
      label: "Overall, how would you rate STUDENT's social communication skills?",
      type: "text",
      placeholder: "Enter overall social communication details",
      errorMsg: "Overall social communication details are required.",
      invalidMsg: "Invalid input for overall social communication details.",
      toolTipText: 'TODO',
      path: "pragmatics.pragmatics_Q_08",
    },
    prag_additional_concerns: {
      name: "prag_additional_concerns",
      label: "Additional Concerns",
      type: "text",
      placeholder: "Enter additional concerns",
      errorMsg: "Additional concerns are required.",
      invalidMsg: "Invalid input for additional concerns.",
      toolTipText: 'TODO',
      path: "pragmatics.prag_additional_concerns",
    },
    prag_doc_description: {
      name: "prag_doc_description",
      label: "Additional Description",
      type: "text",
      placeholder: "Enter additional description",
      errorMsg: "Additional description is required.",
      invalidMsg: "Invalid input for additional description.",
      toolTipText: 'TODO',
      path: "pragmatics.prag_doc_description",
    },
  },
};

export default pragmaticsForm;
