import React, { useEffect, useState } from "react";
import GeneralDialog from "components/GeneralDialog";
import { DialogContent, DialogActions, Grid, Box, Typography, Button } from "@mui/material";
import FormField from "components/FormField";
import { initialValues, form, validations } from "./schemas";
import { Formik, Form } from "formik";
import { createAssistant } from "redux/actions/assistantAction";
import { useDispatch } from "react-redux";
import BoxButton from "components/BoxButton";
import { grey } from "@mui/material/colors";
import { Shield } from "@mui/icons-material";
import ListOfCollaboration from "./ListOfCollaboration";
import HeaderComp from "components/Common/HeaderComp";

const Collaboration = ({ openCollab, setOpenCollab }) => {
    const dispatch = useDispatch();
    const { formId, formField } = form;
    const currentValidation = validations[0];
    const [loading, setLoading] = useState(false);
    const submitForm = (values, actions) => {
        setLoading(true);

        const payload = {
            ...values,
        };

        dispatch(createAssistant(payload))
            .then(() => {
                setLoading(false);
                actions.setTouched({});
                actions.setSubmitting(false);
                actions.resetForm();
                // setOpenCollab(false);
            })
            .catch((err) => {
                setOpenCollab(true);
                setLoading(false);
            });
    };

    const { name, email } = formField;

    const closeDialog = () => {
        setOpenCollab(false);
    };

    const generatePassword = () => {
        const length = 12;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
        let password = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        return password;
    };

    const handleAutoGeneration = (setFieldValue) => {
        const generatedPassword = generatePassword();
        setFieldValue('password', generatedPassword);
    };

    return (
        <React.Fragment>
            <GeneralDialog
                open={openCollab}
                title="Collaboration"
                onClose={closeDialog}
                maxWidth="md"
                fullScreen={false}
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={currentValidation}
                    onSubmit={submitForm}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        isSubmitting,
                    }) => (
                        <Form id={formId} autoComplete="off">
                            <DialogContent
                                // dividers={true}
                                style={{
                                    background: "white",
                                    flex: "1 1 auto",
                                    overflowY: "auto",
                                }}
                            >
                                <Grid container spacing={2}>
                                <Grid item xs={12}>

                                <ListOfCollaboration />
                                </Grid>
                                <HeaderComp title="Add Assistant" />

                                    <Grid item xs={6}>
                                        <FormField
                                            type={name.type}
                                            label={name.label}
                                            name={name.name}
                                            size="small"
                                            isRequired={true}
                                            autoComplete="new-name"  // Unique value for name
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={name.placeholder}
                                            error={
                                                errors.name &&
                                                touched.name &&
                                                Boolean(errors.name)
                                            }

                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormField
                                            type={email.type}
                                            label={email.label}
                                            name={email.name}
                                            size="small"
                                            isRequired={true}
                                            autoComplete="new-name"  // Unique value for name
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={email.placeholder}
                                            error={
                                                errors.email &&
                                                touched.email &&
                                                Boolean(errors.email)
                                            }

                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            component="label"
                                            variant="standard"
                                            size="medium"
                                            sx={{
                                                bgShadow: 0,
                                                mr: 1,
                                                color: '#5263f3',
                                                borderColor: grey[200],
                                                background: '#eeeffe',
                                            }}
                                            startIcon={<Shield />}

                                            onClick={() => handleAutoGeneration(setFieldValue)}
                                        >

                                            Auto Generate Password</Button>
                                        <Box mt={0.75}>
                                            <Typography component="div" variant="body2" color="error">
                                                {errors.password}

                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={6}>
                                    </Grid>
                                    {values.password && (
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1">Generated Password</Typography>
                                            <Typography variant="h2">{values.password}</Typography>


                                        </Grid>
                                    )}

                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Box mr={2}>
                                    <BoxButton
                                        loading={loading}
                                        type="submit"
                                        color="success"
                                        size="large"
                                        variant="contained"
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </BoxButton>
                                </Box>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </GeneralDialog>
        </React.Fragment>
    );
};

export default Collaboration;
