import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Snackbar, Alert } from "@mui/material";

const GlobalNotification = () => {
  const [state, setState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const notitification = useSelector((state) => state.notificationReducer);

  useEffect(() => {
    setState({ ...state, ...notitification });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notitification]);

  function handleClose() {
    setState({ ...state, open: false });
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      autoHideDuration={12000}
    >
      <Alert
        onClose={handleClose}
        severity={state.type}
        variant="filled"
        sx={{
          backgroundColor:
            state.type === "success" ? "#66bb6a" : state.type === "error" ? "#ef5350" : "#26a69a",
        }}
      >
        {state.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalNotification;
