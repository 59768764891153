import React from "react";
import { Grid } from "@mui/material";
import OptionButton from "../components/OptionButton";

const Fluency = (props) => {

    const { formField, values, errors, touched, setFieldValue, setFieldTouched } = props.formData;

    const {
        flu_student_describe,
        flu_student_experience,
        flu_student_report,
        flu_doc_description
    } = formField.fluency;


    const replaceStudentName = (label) => {
        const studentName = values?.generalInformation?.firstName || '[student info not-found]';
        return label.replace('STUDENT', studentName);
    };
    // Render the rich text for background information
    return (
        <Grid container spacing={2} p={2}>
            <OptionButton
                label={replaceStudentName(flu_student_describe.label)}
                type="radio"
                row={false}
                name={flu_student_describe.name}
                options={props.options?.flu_student_describe}
                value={values.fluency.flu_student_describe}
                onChange={(name, value) => setFieldValue(flu_student_describe.path, value)}
                setFieldValue={setFieldValue}
                toolTipText={flu_student_describe.toolTipText}
                error={errors.fluency?.flu_student_describe && touched.fluency?.flu_student_describe}
            />
            <OptionButton
                label={replaceStudentName(flu_student_experience.label)}
                type="radio"
                row={false}
                name={flu_student_experience.name}
                options={props.options?.flu_student_experience}
                value={values.fluency.flu_student_experience}
                onChange={(name, value) => setFieldValue(flu_student_experience.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={flu_student_experience.toolTipText}
                error={errors.fluency?.flu_student_experience && touched.fluency?.flu_student_experience}
            />
            <OptionButton
                label={replaceStudentName(flu_student_report.label)}
                type="radio"
                row={false}
                name={flu_student_report.name}
                options={props.options?.flu_student_report}
                value={values.fluency.flu_student_report}
                onChange={(name, value) => setFieldValue(flu_student_report.path, value)}

                setFieldValue={setFieldValue}
                toolTipText={flu_student_report.toolTipText}
                error={errors.fluency?.flu_student_report && touched.fluency?.flu_student_report}
            />
        </Grid>
    );
};

export default Fluency;
