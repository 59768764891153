import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Paper, Box, Stack, Button, Container } from "@mui/material";
import { Settings, Add, ArrowBack, Send } from "@mui/icons-material";
import InvitedList from "./components/InvitedList";
import InviteForm from "./components/InviteForm";
import { initialValues, form, validations } from "./components/InviteForm/schemas";
import { Formik, Form } from "formik";
import { sendInvite } from "redux/actions/meetingInviteAction";
import BoxButton from "components/BoxButton";
import SCard from "../../components/SCard";
import ActionHeader from "components/MainLayout/ActionHeader";
import { PlayCircleFilledOutlined } from "@mui/icons-material";


export default function SchedulingLinks() {
  const dispatch = useDispatch();

  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function newForm(formData) {
    return <InviteForm formData={formData} />;
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleBack() {
    setOpen(false);
  }
  const submitForm = (values, actions) => {
    const payload = values;
    delete payload.dateSlots;
    delete payload.duration;
    delete payload.guestEmail;
    setLoading(true);
    dispatch(sendInvite(payload))
      .then(() => {
        setLoading(false);
        actions.setTouched({});
        actions.setSubmitting(false);
        actions.resetForm();
      })
      .catch((err) => setLoading(false));
  };
  return (
    <React.Fragment>
      <ActionHeader>
        <Box></Box>
        <Box>
          {open ? (
            <>
              <Button
                variant="standard"
                size="medium"
                color="secondary"
                startIcon={<ArrowBack />}
                sx={{ marginLeft: 1 }}
                onClick={handleBack}
              >
                Back
              </Button>
            </>
          ) : (
            <>
               {/* <Button
                  variant="standard"
                  size="medium"
                  color="secondary"
                  startIcon={<PlayCircleFilledOutlined />}
                >
                  Watch Tutorial
                </Button> */}
                
              {/* <Button variant="standard" size="medium" color="secondary" startIcon={<Settings />}>
                Settings
              </Button> */}
              <Button
                variant="contained"
                size="medium"
                startIcon={<Add />}
                onClick={handleOpen}
                sx={{ marginLeft: 1 }}
              >
                New Link
              </Button>
            </>
          )}
        </Box>
      </ActionHeader>

      <Container maxWidth="md" sx={{ marginTop: 3, marginBottom: 5 }}>
        <SCard>
          {open ? (
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                isSubmitting,
                setFieldTouched,
                resetForm,
              }) => (
                <>
                  <Form id={formId} autoComplete="off">
                    {newForm({
                      values,
                      touched,
                      formField,
                      setFieldValue,
                      setFieldTouched,
                      resetForm,
                      errors,
                    })}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Box style={{ display: "flex", alignItems: "center" }}></Box>
                      <Box>
                        <BoxButton
                          variant="contained"
                          size="medium"
                          color="success"
                          startIcon={<Send />}
                          type="submit"
                          loading={loading}
                        >
                          Submit
                        </BoxButton>
                      </Box>
                    </Stack>
                  </Form>
                </>
              )}
            </Formik>
          ) : (
            <InvitedList setOpen={setOpen} />
          )}
        </SCard>
      </Container>
    </React.Fragment>
  );
}
