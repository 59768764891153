import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import { Typography, CardHeader } from "@mui/material";

const SCard = ({ title, children, renderActions, titleAlign = "left", ...otherProps }) => {
  return (
    <Card
      {...otherProps}
      sx={{
        boxShadow:
          "0 5px 6px -3px rgba(23,29,38,.06), 0 9px 12px 1px rgba(23,29,38,.03), 0 3px 16px 2px rgba(23,29,38,.05)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
        gap: "32px",
        flex: "1 1 auto",
        background: "#fff",
        padding: "24px",
        borderRadius: "12px",
        border: "1px solid rgba(24,29,37,.05)",
        position: "relative",
      }}
    >
      {title && (
        <CardHeader
          sx={{ padding: 0 }}
          title={
            <Typography variant="h4" letterSpacing={0.3} textAlign={titleAlign}>
              {title}
            </Typography>
          }
          action={renderActions && renderActions()}
        />
      )}
      <CardContent sx={{ padding: 0, paddingBottom: "0px !important" }}>{children}</CardContent>
    </Card>
  );
};

SCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  renderActions: PropTypes.func,
  titleAlign: PropTypes.string,
};

export default SCard;
