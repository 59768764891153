// import { applyMiddleware, createStore } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from './reducers/rootReducer';

// // Apply middleware using the `compose` function
// const store = createStore(rootReducer, applyMiddleware(thunk));

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/rootReducer";

const store = configureStore({
  reducer: rootReducer,
});

export default store;
