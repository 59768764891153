/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../constants/constants";

const initialState = {

};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.UPDATE_DATA_TRACKING:
    default:
      return state;
  }
};
