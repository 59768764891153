import React, { useState } from "react";
import { Grid, TextField, Paper, Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { aiAssistance } from "redux/actions/studentActions";
import BoxButton from "components/BoxButton";
import ContentLayer from "components/ContentLayer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";

export default function ImageAI() {
  const dispatch = useDispatch();
  const [promptValue, setPromptValue] = useState("");
  const [response, setResponse] = useState(null);
  const [aiLoading, setAiLoading] = useState(false);

  const generate = async () => {
    setAiLoading(true);
    setResponse("");

    let payload = {
      prompt: promptValue,
    };
    let payloadString = JSON.stringify(payload);
    dispatch(aiAssistance(payloadString))
      .then((response) => {
        setResponse(response.data);
        setAiLoading(false);
      })
      .catch((err) => setAiLoading(false));
  };

  const questions = response && response.split("\n").filter((q) => q.trim() !== "");

  return (
    <ContentLayer>
      <Paper
        sx={{
          maxWidth: 936,
          margin: "auto",
          overflow: "hidden",
          borderRadius: "0.6rem",
          padding: "1.6rem",
          boxShadow: "rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem",
        }}
        variant="outlined"
      >
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <SearchIcon color="inherit" sx={{ display: "block" }} />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Search images related to speech-language pathologists (SLPs) and special education"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: "default" },
                  }}
                  variant="standard"
                  value={promptValue}
                  onChange={(e) => setPromptValue(e.target.value)}
                />
              </Grid>
              <Grid item>
                <BoxButton
                  mt={2}
                  variant="contained"
                  size="small"
                  onClick={generate}
                  loading={aiLoading}
                  sx={{
                    borderRadius: 20,
                    backgroundColor: "black",
                    width: "10rem",
                    "&:hover": {
                      backgroundColor: "#292727",
                    },
                  }}
                >
                  Generate Image
                </BoxButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box mt={2}>
          {aiLoading && (
            <Typography variant="body1" style={{ marginLeft: "8px" }}>
              Generating image... Please wait
            </Typography>
          )}
          {response && (
            <img
              src={response}
              alt="aiImage"
              style={{ width: "100%", height: "auto", borderRadius: "1rem", objectFit: "contain" }}
            />
          )}
        </Box>
      </Paper>
    </ContentLayer>
  );
}
