import * as Actions from "../constants/constants";

const initialState = {
  getUserData: {},
  isVerified: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.AUTH_USER_LOGIN:
    case Actions.AUTH_USER_LOGOUT:
    case Actions.AUTH_USER_EMAIL_VERIFY:
    case Actions.AUTH_USER_VERIFY:
      return { ...state, isVerified: action.payload };
    case Actions.AUTH_USER_REGISTER:
      return { ...state, getUserData: action.payload };
    default:
      return state;
  }
};
