const form = {
  formId: "ScheduleAssessment",
  formField: {
    assessmentName: {
      name: "assessmentName",
      label: "Assessment Title",
      type: "text",
      placeholder: "Assessment Title",
      errorMsg: "Assessment Title is required.",
      invalidMsg: "",
    },
    caseManager: {
      name: "caseManager",
      label: "Case Manager",
      type: "text",
      placeholder: "Please type case manager.",
      errorMsg: "Case Manager is required.",
      invalidMsg: "",
    },
    assessmentDate: {
      name: "assessmentDate",
      label: "Date",
      type: "date",
      placeholder: "Date",
      errorMsg: "Assessment date is required.",
      invalidMsg: "",
    },
    assessmentTemplate: {
      name: "assessmentTemplate",
      label: "Choose Template",
      type: "text",
      placeholder: "Select ...",
      errorMsg: "Template is required.",
      invalidMsg: "",
    },
    startTime: {
      name: "time",
      label: "Start Time",
      type: "time",
      placeholder: "Date",
      errorMsg: "Assessment date is required.",
      invalidMsg: "",
    },
    endTime: {
      name: "time",
      label: "End Time",
      type: "time",
      placeholder: "Date",
      errorMsg: "Assessment date is required.",
      invalidMsg: "",
    },
    repeats: {
      name: "repeats",
      label: "Repeats",
      type: "text",
      placeholder: "Select ...",
      errorMsg: "repeats is required.",
      invalidMsg: "",
    },
    occurrences: {
      name: "occurrences",
      label: "Occurrences",
      type: "text",
      placeholder: "Select ...",
      errorMsg: "Occurrences is required.",
      invalidMsg: "",
    },
  },
};

export default form;
